import { zodResolver } from "@hookform/resolvers/zod";
import {
  useGetAttraction,
  useGetAttractions,
} from "@twocontinents/dashboard/shared";
import { NOT_SELECTED_ID } from "@twocontinents/dashboard/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateTour } from "../../data-access";

const CreateTourFormSchema = z.object({
  date: z.string().optional(),
  time: z.string().optional(),
  attractionId: z.number(),
  attractionGroupId: z.number(),
  attractionVariantId: z.number(),
  additionalDetails: z.string().optional(),
});

type CreateTourFormSchema = z.infer<typeof CreateTourFormSchema>;

export const useCreateTourForm = () => {
  const formMethods = useForm<CreateTourFormSchema>({
    resolver: zodResolver(CreateTourFormSchema),
  });

  const { watch, setValue } = formMethods;

  const attractionId = watch("attractionId");
  const attractionGroupId = watch("attractionGroupId");
  const attractionVariantId = watch("attractionVariantId");
  const date = watch("date");
  const time = watch("time");

  const { attractions } = useGetAttractions({
    select: (attractions) =>
      attractions.filter((attraction) => attraction.tour),
  });

  const currentAttraction = attractions.find(
    (attraction) => attraction.id === attractionId,
  );

  const { attraction: currentAttractionDetails } = useGetAttraction(
    currentAttraction?.id ?? NOT_SELECTED_ID,
  );

  const { attractionGroups: groupsFromSelectedAttraction } =
    currentAttractionDetails || {};

  const currentGroup = groupsFromSelectedAttraction?.find(
    (group) => group.id === attractionGroupId,
  );

  const { attractionVariants: variantsFromSelectedGroup } = currentGroup || {};

  const currentVariant = variantsFromSelectedGroup?.find(
    (variant) => variant.id === attractionVariantId,
  );

  const { createTour, isPending: isCreatingTour } = useCreateTour();

  const isVariantTimed = Boolean(currentVariant?.timeSettings?.timed);
  const isVariantPickup = Boolean(currentVariant?.timeSettings?.pickupTimed);
  const hasDefaultTourTime = Boolean(currentVariant?.tourSettings?.defaultTime);

  const displayTimeInput =
    isVariantTimed || isVariantPickup || hasDefaultTourTime;

  useEffect(() => {
    if (currentVariant?.tourSettings?.defaultTime) {
      setValue("time", currentVariant.tourSettings.defaultTime);
    } else {
      setValue("time", undefined);
    }
  }, [currentVariant?.tourSettings?.defaultTime, setValue]);

  const onSubmit = formMethods.handleSubmit((formData) => {
    const { attractionGroupId, attractionId, date, ...request } = formData;
    if (!date) {
      return;
    }
    createTour({ ...request, date });
  });

  return {
    formMethods,
    onSubmit,
    isCreatingTour,
    groupsFromSelectedAttraction: groupsFromSelectedAttraction ?? [],
    variantsFromSelectedGroup: variantsFromSelectedGroup ?? [],
    attractions,
    date,
    time,
    currentAttraction,
    currentGroup,
    isFormValid: Boolean(formMethods.formState.isValid && date),
    currentVariant,
    displayTimeInput,
  };
};
