import { zodResolver } from "@hookform/resolvers/zod";
import {
  AttractionGroup,
  AttractionVariantWithExtraOptions,
} from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttractionVariant } from "../../../data-access";
import { useAttractionVariants } from "../../../hooks";

const DescriptionSettingsSchema = z.object({
  bookingCaption: z.string(),
  defaultItinerary: z.string().optional(),
  defaultPickupLocation: z.string().optional(),
  defaultContact: z.string().optional(),
  variantId: z.coerce.number(),
});

type DescriptionSettingsSchema = z.infer<typeof DescriptionSettingsSchema>;

export const useTourMessageSettings = (
  attractionId: number,
  attractionGroups: AttractionGroup[],
) => {
  const variantsWithMessageDelivery = attractionGroups
    .flatMap((group) => group.attractionVariants)
    .filter((av) => av.tourMessageSettings);

  const { updateVariant, isPending } = useUpdateAttractionVariant();

  const form = useForm<DescriptionSettingsSchema>({
    resolver: zodResolver(DescriptionSettingsSchema),
  });

  const { handleSubmit, watch, setValue } = form;

  const onSubmit = handleSubmit(({ variantId, ...tourMessageSettings }) => {
    updateVariant({
      attractionId,
      variantId,
      body: { tourMessageSettings },
    });
  });

  const variantId = watch("variantId");
  const onVariantChange = (
    selectedVariant: AttractionVariantWithExtraOptions,
  ) => {
    const { tourMessageSettings } = selectedVariant;
    if (tourMessageSettings) {
      setValue("bookingCaption", tourMessageSettings.bookingCaption);
      setValue(
        "defaultItinerary",
        tourMessageSettings?.defaultItinerary ?? undefined,
      );
      setValue(
        "defaultPickupLocation",
        tourMessageSettings?.defaultPickupLocation ?? undefined,
      );
      setValue(
        "defaultContact",
        tourMessageSettings?.defaultContact ?? undefined,
      );
    }
  };
  const { selectedVariant } = useAttractionVariants({
    variantId,
    attractionGroups,
    onVariantChange,
  });

  return {
    form,
    onSubmit,
    isPending,
    selectedVariant,
    variantsWithMessageDelivery,
  };
};
