import { Table, buttonVariants, routes } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import React from "react";
import { Link } from "react-router-dom";

import { BusinessPartner } from "../types";

interface BusinessPartnersTableProps {
  businessPartners: BusinessPartner[];
  isLoading: boolean;
}

export const BusinessPartnersTable = ({
  businessPartners,
  isLoading,
}: BusinessPartnersTableProps) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Head className="py-5">ID</Table.Head>
          <Table.Head className="py-5">Name</Table.Head>
          <Table.Head className="py-5">Created at</Table.Head>
          <Table.Head className="py-5">Actions</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body className="w-full relative" isLoading={isLoading} columns={4}>
        {businessPartners.map((businessPartner) => (
          <Table.Row key={businessPartner.id}>
            <Table.Cell>
              <Link to={routes.financialLog.show.url(businessPartner.id)}>
                {businessPartner.id}
              </Link>
            </Table.Cell>
            <Table.Cell>{businessPartner.name}</Table.Cell>
            <Table.Cell>
              {DateFormatter.formatToDDMMYYYYHHmm(businessPartner.createdAt)}
            </Table.Cell>
            <Table.Cell>
              <Link
                className={buttonVariants({ variant: "secondary" })}
                to={routes.businessPartners.show.url(businessPartner.id)}
              >
                Details
              </Link>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
