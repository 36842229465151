import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type UpdateAttractionsSequenceRequest =
  components["schemas"]["UpdateAttractionsSequenceRequest"];

export const useUpdateAttractionsSequence = () => {
  const apiClient = usePrivateApiClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (request: UpdateAttractionsSequenceRequest) =>
      apiClient.POST("/private/attractions/sequence", {
        body: request,
      }),
    onSuccess: () => {
      toast.success("Sequence has been updated.");
    },
    onError: () => {
      toast.error("An error occurred while updating sequence.");
    },
  });

  return {
    updateAttractionsSequence: mutate,
    isPending,
  };
};
