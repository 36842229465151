import { Layout, SettingCard, Tabs } from "@twocontinents/dashboard/shared";
import { useParams } from "react-router-dom";

import { useGetTravelAgency } from "../data-access";
import { AgentsTab } from "./agents-tab";
import { TravelAgencyTab } from "./travel-agency-tab";

export const TravelAgencyShowPage = () => {
  const params = useParams<{ id: string }>();
  const travelAgencyId = Number(params.id);
  const { travelAgency, isLoading } = useGetTravelAgency(travelAgencyId);

  if (!travelAgency || isLoading) {
    return (
      <Layout
        header={{
          title: "Travel agency details",
          description: "View and manage travel agency details",
        }}
      />
    );
  }

  return (
    <Layout
      header={{
        title: "Travel agency details",
        description: "View and manage travel agency details",
      }}
    >
      <SettingCard>
        <SettingCard.Header>
          <SettingCard.Title>
            Travel agency #{travelAgencyId} data
          </SettingCard.Title>
        </SettingCard.Header>

        <Tabs defaultValue="travelAgency">
          <Tabs.List className={"mb-4"}>
            <Tabs.Trigger value="travelAgency">Travel agency</Tabs.Trigger>
            <Tabs.Trigger value="agents">Agents</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="travelAgency">
            <TravelAgencyTab travelAgency={travelAgency} />
          </Tabs.Content>
          <Tabs.Content value="agents">
            <AgentsTab
              agents={travelAgency.agents}
              travelAgencyId={travelAgencyId}
            />
          </Tabs.Content>
        </Tabs>
      </SettingCard>
    </Layout>
  );
};
