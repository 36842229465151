import {
  OfferBundleFormSchema,
  OfferEntryType,
  filterAgeGroupEntries,
  filterExtraOptionEntries,
  mapToEntries,
  useGetOfferBundle,
} from "@twocontinents/dashboard/travel-agencies/offer-bundle/shared";
import { useParams } from "react-router-dom";

import { useUpdateOfferBundle } from "../data-access";

export const useOfferBundleShowPage = () => {
  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const { offerBundle } = useGetOfferBundle(id);

  const { updateOfferBundle, isPending } = useUpdateOfferBundle(id);

  const entries = mapToEntries(
    offerBundle?.ageGroupEntries ?? [],
    offerBundle?.extraOptionEntries ?? [],
  );

  const onSubmit = ({ name, ...data }: OfferBundleFormSchema) => {
    const ageGroupEntries = data.entries.filter((entry) =>
      filterAgeGroupEntries(entry),
    );
    const extraOptionEntries = data.entries.filter((entry) =>
      filterExtraOptionEntries(entry),
    );

    updateOfferBundle({
      name,
      ageGroupEntries,
      extraOptionEntries,
    });
  };

  return {
    id,
    offerBundle,
    entries,
    isPending,
    onSubmit,
  };
};
