import {
  FinancialLogSource,
  useFinancialLogsQueryParams,
} from "@twocontinents/dashboard/financial-log/shared";
import {
  FinancialLogCategory,
  NOT_SELECTED_ID_STRING,
} from "@twocontinents/dashboard/shared";
import React from "react";

export enum FinancialLogSourceFilter {
  ALL = "ALL",
  MANUAL = "MANUAL",
  SYSTEM = "SYSTEM",
}

const getDefaultFinancialLogSourceFilter = (
  source: FinancialLogSource | undefined,
) => {
  if (source === undefined) {
    return FinancialLogSourceFilter.ALL;
  }
  return source === FinancialLogSource.MANUAL
    ? FinancialLogSourceFilter.MANUAL
    : FinancialLogSourceFilter.SYSTEM;
};

export const useFinancialLogsFilters = () => {
  const { query, setQuery } = useFinancialLogsQueryParams();

  const {
    valueFrom,
    valueTo,
    createdAtFrom,
    createdAtTo,
    updatedAtFrom,
    updatedAtTo,
    settleDateFrom,
    settleDateTo,
    cause,
    referredOrderId,
    source,
    category,
  } = query;

  const handleInputChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery({
        [key]: e.target.value,
      });
    };

  const handleSourceChange = (value?: string) => {
    if (!value || value === FinancialLogSourceFilter.ALL) {
      setQuery({ source: undefined });
      return;
    }
    setQuery({
      source:
        value === FinancialLogSourceFilter.MANUAL
          ? FinancialLogSource.MANUAL
          : FinancialLogSource.SYSTEM,
    });
  };

  const handleCategoryChange = (value?: string) => {
    if (!value || value === NOT_SELECTED_ID_STRING) {
      setQuery({ category: undefined });
      return;
    }
    setQuery({
      category: value as FinancialLogCategory,
    });
  };

  const defaultSource = getDefaultFinancialLogSourceFilter(source);

  return {
    valueFrom,
    valueTo,
    createdAtFrom,
    createdAtTo,
    updatedAtFrom,
    updatedAtTo,
    settleDateFrom,
    settleDateTo,
    cause,
    referredOrderId,
    handleInputChange,
    defaultSource,
    handleSourceChange,
    category,
    handleCategoryChange,
  };
};
