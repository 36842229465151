import { useQuery } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = () => ["private-base-age-groups"];

export const useGetBaseAgeGroups = () => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/attractions/variants/age-groups")
        .then((response) => response.data),
    queryKey: queryKey(),
  });

  data?.ageGroups?.sort((a, b) => a.id - b.id);

  return { ageGroups: data?.ageGroups ?? [], isLoading };
};
