import { useMutation } from "@tanstack/react-query";
import {
  useInvalidateGetFinancialLog,
  useInvalidateGetFinancialLogs,
} from "@twocontinents/dashboard/financial-log/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type CancelFinancialLogRequest =
  components["schemas"]["CancelFinancialLogEntryRequest"];

export const useCancelFinancialLog = (id: number) => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetFinancialLog();
  const { invalidate: invalidateGetfinancialLogs } =
    useInvalidateGetFinancialLogs();

  const { mutate, isPending } = useMutation({
    mutationFn: async (request: CancelFinancialLogRequest) => {
      const response = await apiClient.POST(
        "/private/financial-log/{id}/cancel",
        {
          params: {
            path: { id },
          },
          body: request,
        },
      );
      return response.data;
    },
    onSuccess: async () => {
      toast.success("Cancel financial log has been added");
      await invalidate(id);
      await invalidateGetfinancialLogs();
    },
    onError: () => {
      toast.error("Could not add cancel financial log");
    },
  });

  return { cancelFinancialLog: mutate, isPending };
};
