import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = (id: number) => ["private-travel-agency", id];

export const useGetTravelAgency = (id: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/travel-agencies/{id}", {
          params: { path: { id } },
        })
        .then((response) => response.data),
    queryKey: queryKey(id),
  });

  return {
    travelAgency: data,
    isLoading,
  };
};

export const useInvalidateGetTravelAgency = () => {
  const queryClient = useQueryClient();

  const invalidate = async (id: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(id),
    });
  };

  return {
    invalidate,
  };
};
