import { zodResolver } from "@hookform/resolvers/zod";
import { CurrencyCode } from "@twocontinents/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttraction } from "../../../data-access";

const CurrencySettingsSchema = z.object({
  currency: z.nativeEnum(CurrencyCode),
});

type CurrencySettingsSchema = z.infer<typeof CurrencySettingsSchema>;

export const useCurrencySettings = (
  attractionId: number,
  defaultCurrency: CurrencyCode,
) => {
  const { updatePrivateAttraction, isPending } = useUpdateAttraction();

  const form = useForm<CurrencySettingsSchema>({
    resolver: zodResolver(CurrencySettingsSchema),
    defaultValues: {
      currency: defaultCurrency,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit(({ currency }) => {
    updatePrivateAttraction({
      attractionId,
      body: {
        currency: currency,
      },
    });
  });

  const currencies = Object.values(CurrencyCode);

  return {
    onSubmit,
    form,
    isPending,
    currencies,
  };
};
