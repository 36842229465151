import { useGetReviews } from "../data-access";
import { useReviewsFiltersQueryParams } from "../hooks";

export const useReviewsManagement = () => {
  const { query } = useReviewsFiltersQueryParams();
  const { isLoading, paginatedReviews } = useGetReviews(query);

  return {
    isLoading,
    paginatedReviews,
  };
};
