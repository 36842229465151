import {
  AlertDialog,
  Button,
  buttonVariants,
} from "@twocontinents/dashboard/shared";
import { type FC } from "react";

import { useSetTourAsDoneAlertDialog } from "./use-set-tour-as-done-alert-dialog";

interface SetTourAsDoneAlertDialogProps {
  tourId: number;
}

export const SetTourAsDoneAlertDialog: FC<SetTourAsDoneAlertDialogProps> = ({
  tourId,
}) => {
  const { isPending, setTourAsDone } = useSetTourAsDoneAlertDialog();

  return (
    <AlertDialog>
      <AlertDialog.Trigger asChild>
        <Button variant="positive" className="w-fit" isLoading={isPending}>
          Set as done
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>
            Are you sure you want to set this tour as done?
          </AlertDialog.Title>
          <AlertDialog.Description>
            This action cannot be undone.
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <AlertDialog.Cancel
            className={buttonVariants({ variant: "destructive" })}
          >
            Cancel
          </AlertDialog.Cancel>
          <AlertDialog.Action
            onClick={() => setTourAsDone(tourId)}
            className={buttonVariants({ variant: "outline" })}
          >
            Yes
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
