import {
  Layout,
  buttonVariants,
  cn,
  routes,
} from "@twocontinents/dashboard/shared";
import { Link } from "react-router-dom";

import { CreateTourForm } from "./create-tour-form";

export const TourCreator = () => {
  return (
    <Layout
      header={{
        title: "Create a new tour",
        description: "Create a new tour by filling out the form below",
      }}
    >
      <section className="flex grow flex-col gap-6">
        <Link
          to={routes.attractions.tours.index}
          className={cn(
            buttonVariants({ variant: "outline" }),
            "w-fit self-end",
          )}
        >
          Go back
        </Link>
        <CreateTourForm />
      </section>
    </Layout>
  );
};
