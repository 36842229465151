import { AttractionOrder } from "@twocontinents/dashboard/attractions/shared";
import {
  DetailsTable,
  buttonVariants,
  routes,
} from "@twocontinents/dashboard/shared";
import { Link } from "react-router-dom";

import { ReservationFinancialLogsTable } from "../reservation-financial-logs-table";
import { ReservationTicketSection } from "../reservation-ticket-section";
import { useSingleReservationOrderDetails } from "./use-single-reservation-order-details";

interface SingleReservationOrderDetailsProps {
  order: AttractionOrder;
}

export const SingleReservationOrderDetails = ({
  order,
}: SingleReservationOrderDetailsProps) => {
  const { orderDetailsRows, reservation } =
    useSingleReservationOrderDetails(order);

  return (
    <>
      <DetailsTable>
        {orderDetailsRows.map((row) => (
          <DetailsTable.Row key={row.label} {...row} />
        ))}
      </DetailsTable>
      <ReservationFinancialLogsTable
        reservation={reservation}
        orderId={order.id}
      />
      <div className="flex gap-4 items-center">
        {reservation.tourId && (
          <Link
            className={buttonVariants({ variant: "secondary" })}
            to={routes.attractions.tours.show.url(reservation.tourId)}
          >
            Go to tour #{reservation.tourId}
          </Link>
        )}
        <ReservationTicketSection
          reservation={reservation}
          orderId={order.id}
        />
      </div>
    </>
  );
};
