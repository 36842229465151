import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

function queryKey(id: number) {
  return ["private-offer-bundle", id];
}

export const useGetOfferBundle = (id: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/travel-agencies/offer-bundles/{id}", {
          params: {
            path: {
              id,
            },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(id),
    enabled: Boolean(id),
  });

  data?.ageGroupEntries.sort((a, b) => a.id - b.id);

  return {
    offerBundle: data,
    isLoading,
  };
};

export const useInvalidateGetOfferBundle = () => {
  const queryClient = useQueryClient();

  const invalidate = async (id: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(id),
    });
  };

  return {
    invalidate,
  };
};
