import { useGetTours } from "@twocontinents/dashboard/attractions/shared";
import { usePaginationQueryParams } from "@twocontinents/dashboard/shared";
import { useCallback } from "react";
import { useDebounceValue } from "usehooks-ts";

import { useTourFiltersQueryParams } from "../../hooks";

export const useToursTab = () => {
  const { query, setQuery } = useTourFiltersQueryParams();

  const [debouncedAdditionalDetails] = useDebounceValue(
    query.additionalDetails,
    300,
  );

  const { isLoading, paginatedTours } = useGetTours({
    ...query,
    additionalDetails: debouncedAdditionalDetails,
  });

  const { page, size } = usePaginationQueryParams();

  const handlePageSelect = useCallback(
    (page: number) => {
      setQuery((params) => ({ ...params, page }));
    },
    [setQuery],
  );

  const handleSizeSelect = useCallback(
    (size: number) => {
      setQuery((params) => ({ ...params, size }));
    },
    [setQuery],
  );

  const showToursNotFound =
    (paginatedTours?.tours?.length ?? 0) === 0 && !isLoading;

  const showTours =
    paginatedTours?.tours &&
    (paginatedTours?.tours?.length ?? 0) > 0 &&
    !isLoading;

  const showPagination = Boolean(!isLoading && paginatedTours);

  return {
    isLoading,
    paginatedTours,
    handlePageSelect,
    handleSizeSelect,
    showToursNotFound,
    showTours,
    page,
    size,
    totalPages: paginatedTours?.pagination.totalPages ?? 0,
    showPagination,
  };
};
