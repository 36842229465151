/* eslint-disable max-lines-per-function */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";

import { FinancialLogsQueryParams } from "../hooks";
import { GetFinancialLogsFilters } from "../types";

const queryKey = (filters?: GetFinancialLogsFilters) => [
  "financial-logs",
  filters ?? "all",
];

export const useGetFinancialLogs = (queryParams?: FinancialLogsQueryParams) => {
  const apiClient = usePrivateApiClient();

  const { reservationIdFormValue, orderIdFormValue, ...filters } =
    queryParams || {};
  const mappedFilters: GetFinancialLogsFilters = {
    ...filters,
    createdAtFrom:
      queryParams?.createdAtFrom &&
      DateFormatter.formatToZonedDateTime(queryParams.createdAtFrom),
    createdAtTo:
      queryParams?.createdAtTo &&
      DateFormatter.formatToZonedDateTime(queryParams.createdAtTo),
    updatedAtFrom:
      queryParams?.updatedAtFrom &&
      DateFormatter.formatToZonedDateTime(queryParams.updatedAtFrom),
    updatedAtTo:
      queryParams?.updatedAtTo &&
      DateFormatter.formatToZonedDateTime(queryParams.updatedAtTo),
    settleDateFrom:
      queryParams?.settleDateFrom &&
      DateFormatter.formatToZonedDateTime(queryParams.settleDateFrom),
    settleDateTo:
      queryParams?.settleDateTo &&
      DateFormatter.formatToZonedDateTime(queryParams.settleDateTo),
    cause: queryParams?.cause || undefined,
  };

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/financial-log/", {
          params: { query: mappedFilters },
        })
        .then((response) => response.data),
    queryKey: queryKey(filters),
  });

  return {
    data,
    isLoading,
  };
};

export const useInvalidateGetFinancialLogs = () => {
  const queryClient = useQueryClient();

  const invalidate = async (filters?: GetFinancialLogsFilters) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(filters),
    });
  };

  return {
    invalidate,
  };
};
