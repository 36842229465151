import { zodResolver } from "@hookform/resolvers/zod";
import { ApiLocale, DateFormatter } from "@twocontinents/shared";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  useGetAttractionOrdersStatistics,
  useGetFinancialLogStatistics,
} from "../../api";
import { StatisticsTab } from "../../types";

const StatisticsFormSchema = z.object({
  tab: z.nativeEnum(StatisticsTab),
  timePurchasedFrom: z.string().optional(),
  timePurchasedTo: z.string().optional(),
  attractionId: z.number().optional(),
  attractionGroupId: z.number().optional(),
  attractionVariantId: z.number().optional(),
  locale: z.nativeEnum(ApiLocale).optional(),
});

export type StatisticsFormSchema = z.infer<typeof StatisticsFormSchema>;

export const useStatistics = () => {
  const form = useForm<StatisticsFormSchema>({
    resolver: zodResolver(StatisticsFormSchema),
  });

  const { watch, setValue } = form;

  const { tab, ...filter } = watch();

  const { statistics: orderStatistics, isLoading: orderStatisticsAreLoading } =
    useGetAttractionOrdersStatistics(filter);
  const {
    statistics: financialLogStatistics,
    isLoading: financialLogStatisticsAreLoading,
  } = useGetFinancialLogStatistics(filter);

  const isLoading =
    orderStatisticsAreLoading || financialLogStatisticsAreLoading;

  const onTabChange = (tab: string) => {
    let timePurchasedFrom: string | undefined = undefined;
    let timePurchasedTo: string | undefined = undefined;

    if (tab === StatisticsTab.Today) {
      timePurchasedFrom = DateFormatter.formatToYYYYMMDD(
        dayjs().set("hour", 0).set("minute", 0).set("second", 0),
      );
      timePurchasedTo = DateFormatter.formatToYYYYMMDD(
        dayjs().set("hour", 23).set("minute", 59).set("second", 59),
      );
    } else if (tab === StatisticsTab.All || tab === StatisticsTab.Range) {
      timePurchasedFrom = undefined;
      timePurchasedTo = undefined;
    }

    setValue("timePurchasedFrom", timePurchasedFrom);
    setValue("timePurchasedTo", timePurchasedTo);

    setValue("tab", tab as StatisticsTab);
    setValue("attractionId", undefined);
    setValue("attractionGroupId", undefined);
    setValue("attractionVariantId", undefined);
  };

  useEffect(() => {
    if (!tab) {
      onTabChange(StatisticsTab.Today);
    }
  }, []);

  return {
    form,
    onTabChange,
    orderStatistics,
    financialLogStatistics,
    tab,
    isLoading,
  };
};
