import { getApiClient } from "@twocontinents/shared";
import { useMemo } from "react";

import { useAuth } from "../auth";
import { useLocale } from "./use-locale";

export const usePrivateApiClient = () => {
  const locale = useLocale();
  const { token } = useAuth();

  return useMemo(
    () =>
      getApiClient({
        locale,
        accessToken: token,
      }),
    [locale, token],
  );
};
