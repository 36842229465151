import { AgentType } from "../types";

export const mapAgentTypeToString = (agentType: AgentType): string => {
  switch (agentType) {
    case AgentType.AGENT_NORMAL: {
      return "Normal";
    }
    case AgentType.AGENT_ROOT: {
      return "Root";
    }
    default: {
      return "Normal";
    }
  }
};
