import { useMutation } from "@tanstack/react-query";
import { useInvalidateGetTour } from "@twocontinents/dashboard/attractions/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type CreateTourFinancialLog = Omit<
  components["schemas"]["CreateManualFinancialLogEntryRequest"],
  "reservationId" | "tourId"
> & {
  tourId: number;
};

export const useCreateTourFinancialLog = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetTour();
  const { mutate, isPending } = useMutation({
    mutationFn: async (request: CreateTourFinancialLog) => {
      const response = await apiClient.POST("/private/financial-log/", {
        body: request,
      });
      return response.data;
    },
    onSuccess: async (_, request) => {
      toast.success("Financial log has been added");
      await invalidate(request.tourId);
    },
    onError: () => {
      toast.error("Could not add financial log");
    },
  });

  return { createTourFinancialLog: mutate, isPending };
};
