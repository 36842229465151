import { Form, FormItem, Select } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { useFormContext } from "react-hook-form";

import { AvailabilityForm } from "./availability-settings/use-availability-settings";

interface TimeSelectProps {
  times: string[];
  getAvailableSlotsByTime: (time: string) => number | undefined;
}

export const TimeSelect = ({
  times,
  getAvailableSlotsByTime,
}: TimeSelectProps) => {
  const form = useFormContext<AvailabilityForm>();

  return (
    <Form.Field
      control={form.control}
      name={`availability.time`}
      render={({ field }) => (
        <FormItem>
          <FormItem.Row>
            <FormItem.Label>Time</FormItem.Label>
            <FormItem.Control>
              <Select {...field} onValueChange={field.onChange}>
                <Select.Trigger className="max-w-md h-max min-h-10">
                  {field.value === undefined ? (
                    "Choose time"
                  ) : (
                    <Select.Value placeholder="Choose time" />
                  )}
                </Select.Trigger>
                <Select.Content>
                  {times.map((time) => (
                    <Select.Item value={time} key={time}>
                      {DateFormatter.formatToHHmm(time)} -{" "}
                      {getAvailableSlotsByTime(time)} slots available
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            </FormItem.Control>
          </FormItem.Row>
          <FormItem.Message />
        </FormItem>
      )}
    />
  );
};
