import { ChevronLeft, ChevronRight } from "lucide-react";
import * as React from "react";
import { DayPicker } from "react-day-picker";

import { cn } from "../../utils";
import { buttonVariants } from "./button";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      weekStartsOn={1}
      className={cn("p-3", className)}
      classNames={{
        caption:
          "flex flex-start pl-2 py-2 justify-between relative items-center ",
        caption_label: "font-bold font-[15px] calendar-font",
        root: cn(
          "w-full h-full flex justify-center items-center max-w-sm text-white",
          classNames?.root,
        ),

        month: "space-y-4 ",
        cell: "text-center mx-[2px] text-sm p-0 relative [&:has([aria-selected])]:bg-transparent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        head_cell: "text-slate-50 w-[4rem] text-center mr-1 font-semibold",
        nav: "space-x-1 flex items-center justify-center",

        months: cn(
          "bg-slate-900 flex justify-center items-center p-4 rounded-md transition h-fit w-fit",
          classNames?.months,
        ),

        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 hover:text-black",
          classNames?.nav_button,
        ),

        day_disabled: cn(
          "!bg-slate-800 cursor-not-allowed",
          classNames?.day_disabled,
        ),
        day_selected: cn(
          "!bg-slate-600 aria-selected:!bg-slate-600 hover:bg-slate-600",
          classNames?.day_selected,
        ),
        day: cn(
          "calendar-font border-transparent outline-none focus-within:border-slate-100 border relative h-12 w-12 rounded-md flex justify-center items-center p-0 font-normal  aria-selected:opacity-100, hover:text-slate-50 hover:border-slate-600 hover:bg-slate-600",
          classNames?.day,
        ),
      }}
      components={{
        IconLeft: () => <ChevronLeft className="h-8 w-8" />,
        IconRight: () => <ChevronRight className="h-8 w-8" />,
      }}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
