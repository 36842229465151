import { useOpened } from "@twocontinents/dashboard/shared";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { useConfirmPayment } from "../../../data-access";
import { OrderStatusFormValues } from "../use-order-details";

const CONFIRM_SENTENCE = "I want to confirm that payment";

export const useConfirmPaymentAlertDialog = (orderId: number) => {
  const { opened, open, close, toggle } = useOpened(false);

  const { setValue } = useFormContext<OrderStatusFormValues>();

  const { confirmPayment, isPending } = useConfirmPayment(orderId);

  const [sentence, setSentence] = useState("");

  const handleConfirmPayment = () => {
    if (sentence === CONFIRM_SENTENCE) {
      confirmPayment(
        { orderId },
        {
          onSuccess: () => {
            close();
            setValue("paymentConfirmed", true);
          },
        },
      );
    }
  };

  const handleSentenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSentence(e.target.value);
  };

  const buttonDisabled = sentence !== CONFIRM_SENTENCE;

  return {
    opened,
    open,
    close,
    toggle,
    handleConfirmPayment,
    handleSentenceChange,
    buttonDisabled,
    isPending,
    CONFIRM_SENTENCE,
  };
};
