import { useMutation } from "@tanstack/react-query";
import {
  toast,
  useInvalidateGetAttraction,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type Body = components["schemas"]["UpdateAttractionGroupRequest"];

interface Props {
  attractionId: number;
  groupId: number;
  body: Body;
}

export const useUpdateAttractionGroup = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetAttraction();

  const { mutate: updateAttractionGroup, isPending } = useMutation({
    mutationFn: ({ groupId, body }: Props) =>
      apiClient
        .PATCH(`/private/attractions/groups/{id}`, {
          params: {
            path: { id: groupId },
          },
          body,
        })
        .then((response) => response.data),

    onSuccess: async (_, { attractionId }) => {
      toast.success("Group has been updated successfully.");
      await invalidate(attractionId);
    },
    onError: () => {
      toast.error("Group could not be updated");
    },
  });

  return { updateAttractionGroup, isPending };
};
