import { useMutation } from "@tanstack/react-query";
import { useInvalidateGetTour } from "@twocontinents/dashboard/attractions/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type UpdateTourParticipantRequest =
  components["schemas"]["UpdateTourParticipantRequest"];

interface Props {
  participantId: number;
  body: UpdateTourParticipantRequest;
}

export const useUpdateTourParticipant = (tourId: number) => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetTour();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ body, participantId }: Props) => {
      return apiClient
        .PATCH("/private/attractions/tours/participants/{participantId}", {
          body,
          params: {
            path: {
              participantId,
            },
          },
        })
        .then((response) => response.data);
    },
    async onSuccess() {
      toast.success("Zaktualizowano uczestnika wycieczki");
      await invalidate(tourId);
    },
    onError() {
      toast.error("Nie udało się zaktualizować uczestnika wycieczki");
    },
  });

  return { updateTourParticipant: mutate, isPending };
};
