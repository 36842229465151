import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  buttonVariants,
} from "@twocontinents/dashboard/shared";
import {
  InvoiceStatus,
  InvoiceStatusSelect,
} from "@twocontinents/dashboard/travel-agencies/invoices/shared";

import { useUpdateStatusDialog } from "./use-update-status-dialog";

interface UpdateStatusDialogProps {
  invoiceId: number;
  invoiceStatus: InvoiceStatus;
  agencyId: number;
}
export const UpdateStatusDialog = ({
  invoiceStatus,
  invoiceId,
  agencyId,
}: UpdateStatusDialogProps) => {
  const { openAlertDialog, isPending, form, handleFormSubmit } =
    useUpdateStatusDialog(invoiceId, invoiceStatus, agencyId);
  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button variant="secondary">Update status</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content className="max-w-3xl w-fit">
        <AlertDialog.Header>
          <AlertDialog.Title>Update invoice status</AlertDialog.Title>
          <AlertDialog.Description>
            Are you sure you want to update the status of this invoice?
          </AlertDialog.Description>
        </AlertDialog.Header>
        <Form {...form}>
          <form onSubmit={handleFormSubmit}>
            <Form.Field
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Status</FormItem.Label>
                    <FormItem.Control>
                      <InvoiceStatusSelect
                        value={field.value}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <AlertDialog.Footer className="my-4">
              <AlertDialog.Cancel
                type="button"
                className={buttonVariants({ variant: "destructive" })}
              >
                Cancel
              </AlertDialog.Cancel>

              <Button isLoading={isPending} type="submit" variant="outline">
                Update
              </Button>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
