import { ALL_ITEMS_ID, Select } from "@twocontinents/dashboard/shared";
import { AttractionGroup } from "@twocontinents/dashboard/shared";

interface AttractionVariantSelectByGroupsProps {
  value?: number;
  attractionGroups: AttractionGroup[];
  onValueChange: (id: number) => void;
  showAllVariantsOption?: boolean;
  defaultOpen?: boolean;
}

export const AttractionVariantSelectByGroups = ({
  onValueChange,
  attractionGroups,
  showAllVariantsOption,
  defaultOpen,
  value,
}: AttractionVariantSelectByGroupsProps) => {
  return (
    <Select
      value={value?.toString()}
      defaultValue={showAllVariantsOption ? ALL_ITEMS_ID.toString() : undefined}
      onValueChange={(value) => onValueChange(Number.parseInt(value))}
      defaultOpen={defaultOpen}
    >
      <Select.Trigger className="max-w-md h-max min-h-10">
        <Select.Value placeholder="Choose Attraction Variant" />
      </Select.Trigger>
      <Select.Content>
        {showAllVariantsOption && (
          <Select.Item value="-1">All variants</Select.Item>
        )}
        {attractionGroups.map((group) => (
          <Select.Group key={group.id}>
            <Select.Label>{group.description}</Select.Label>
            {group.attractionVariants
              .toSorted((a, b) => a.id - b.id)
              .map((variant) => (
                <Select.Item
                  value={variant.id.toString()}
                  key={variant.id}
                  disabled={!variant.active}
                >
                  {variant.description}
                </Select.Item>
              ))}
          </Select.Group>
        ))}
      </Select.Content>
    </Select>
  );
};
