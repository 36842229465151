import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

export const useReviewsFiltersQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    attractionId: withDefault(NumberParam, undefined),
    minRating: withDefault(NumberParam, undefined),
    maxRating: withDefault(NumberParam, undefined),
    customerEmail: withDefault(StringParam, ""),
    hidden: withDefault(BooleanParam, undefined),
    createdAfter: withDefault(StringParam, undefined),
    createdBefore: withDefault(StringParam, undefined),
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 5),
  });

  return {
    query,
    setQuery,
  };
};
