import { PropsWithChildren } from "react";

import { cn } from "../utils";

interface EmptyStateProps extends PropsWithChildren {
  className?: string;
}

export const EmptyState = ({ className, children }: EmptyStateProps) => {
  return (
    <div
      className={cn(
        "flex min-h-100 w-full flex-col items-center justify-center rounded-md border border-dashed border-slate-800",
        className,
      )}
    >
      {children}
    </div>
  );
};

const EmptyStateIcon = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex h-20 w-20 items-center justify-center rounded-full bg-slate-900">
      <i className="flex h-10 w-10 items-center justify-center">{children}</i>
    </div>
  );
};

const EmptyStateHeader = ({ children }: PropsWithChildren) => {
  return <h2 className=" mt-6 text-xl font-semibold">{children}</h2>;
};

const EmptyStateParagraph = ({ children }: PropsWithChildren) => {
  return (
    <p className="text-md mb-8 mt-2 text-center leading-6 text-slate-500">
      {children}
    </p>
  );
};

EmptyState.Icon = EmptyStateIcon;
EmptyState.Header = EmptyStateHeader;
EmptyState.Paragraph = EmptyStateParagraph;
