import {
  DetailRowProps,
  DetailsTable,
  FormItem,
  NOT_SELECTED_ID,
  Select,
  routes,
} from "@twocontinents/dashboard/shared";
import {
  BaseAgent,
  mapAgentTypeToString,
} from "@twocontinents/dashboard/travel-agencies/shared";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useGetAgent } from "../data-access";
import { CreateAgentModal } from "./create-agent-modal";
import { UpdateAgentModal } from "./update-agent-modal";
import { UpdateAgentPasswordModal } from "./update-agent-password-modal";

interface AgentsTabProps {
  agents: BaseAgent[];
  travelAgencyId: number;
}

export const AgentsTab = ({ agents, travelAgencyId }: AgentsTabProps) => {
  const [agentId, setAgentId] = useState<number | undefined>();
  const { agent } = useGetAgent(agentId ?? NOT_SELECTED_ID);

  const details: DetailRowProps[] = agent
    ? [
        { label: "ID", content: agent.id },
        { label: "Name", content: agent.name },
        { label: "Email", content: agent.email },
        { label: "Login", content: agent.login },
        { label: "Type", content: mapAgentTypeToString(agent.type) },
        {
          label: "Travel agency",
          content: (
            <Link
              to={routes.travelAgencies.show.url(agent.travelAgencyId)}
              className={"font-semibold"}
            >
              {agent.travelAgencyName}
            </Link>
          ),
        },
      ]
    : [];

  return (
    <>
      <div className="flex gap-4">
        <FormItem className={"mb-4"}>
          <FormItem.Row>
            <FormItem.LabelNoContext>Agent</FormItem.LabelNoContext>
            <Select
              value={agentId?.toString()}
              onValueChange={(id) => setAgentId(Number(id))}
            >
              <Select.Trigger className={"w-52"}>
                <Select.Value placeholder="Choose agent" />
              </Select.Trigger>
              <Select.Content>
                {agents.map((agent) => (
                  <Select.Item key={agent.id} value={agent.id.toString()}>
                    {agent.name}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </FormItem.Row>
        </FormItem>
        <CreateAgentModal travelAgencyId={travelAgencyId} />
      </div>

      {agent && (
        <>
          <DetailsTable>
            {details.map((detail) => (
              <DetailsTable.Row key={detail.label} {...detail} />
            ))}
          </DetailsTable>
          <div className="justify-end flex gap-4 mt-4">
            <UpdateAgentPasswordModal agentId={agent.id} />
            <UpdateAgentModal agent={agent} />
          </div>
        </>
      )}
    </>
  );
};
