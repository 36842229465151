import { Tour } from "@twocontinents/dashboard/attractions/shared";
import { useGetAttraction } from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useUpdateTourAttractionVariant } from "../../data-access";

const ChangeTourVariantSchema = z.object({
  attractionVariantId: z.coerce.number(),
});

type ChangeTourVariantSchema = z.infer<typeof ChangeTourVariantSchema>;

export const useChangeTourVariantDialog = (tour: Tour) => {
  const openAlertDialog = useBoolean(false);
  const { isPending, updateTourAttractionVariant } =
    useUpdateTourAttractionVariant();

  const form = useForm<ChangeTourVariantSchema>({
    defaultValues: {
      attractionVariantId: tour.attractionVariant.id,
    },
  });

  const { attraction } = useGetAttraction(tour.attractionVariant.attractionId);

  const onSubmit = form.handleSubmit(async (values) => {
    updateTourAttractionVariant(
      {
        tourId: tour.id,
        body: {
          attractionVariantId: values.attractionVariantId,
        },
      },
      {
        onSettled: () => {
          openAlertDialog.setFalse();
        },
      },
    );
  });

  return {
    openAlertDialog,
    form,
    isPending,
    onSubmit,
    attractionGroups: attraction?.attractionGroups ?? [],
  };
};
