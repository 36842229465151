import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import {
  ApiError,
  ApplicationErrorCode,
  components,
} from "@twocontinents/shared";

type GenerateTicketBody = components["schemas"]["GenerateTicketRequest"];

interface Props {
  variantId: number;
  body: GenerateTicketBody;
}

export const useGenerateTicket = () => {
  const apiClient = usePrivateApiClient();
  const { mutate, isPending, data } = useMutation({
    mutationFn: ({ body, variantId }: Props) =>
      apiClient
        .POST(
          "/private/attractions/variants/{id}/age-groups/tickets/generate",
          {
            body,
            params: {
              path: {
                id: variantId,
              },
            },
          },
        )
        .then((response) => response.data),
    onSuccess: async () => {
      toast.success("Ticket generated successfully");
    },
    onError: (err: ApiError) => {
      if (
        err.applicationErrorCode ===
        ApplicationErrorCode.NOT_ENOUGH_TICKETS_AVAILABLE
      ) {
        toast.error("Not enough tickets available");
      } else {
        toast.error("Failed to generate ticket");
      }
    },
  });

  return {
    generateTicket: mutate,
    isPending,
    ticketUrl: data?.ticketUrl,
  };
};
