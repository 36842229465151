import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  NOT_SELECTED_ID,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";

const queryKey = (id: number) => ["private-agent", id];

export const useGetAgent = (id: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/travel-agencies/agents/{id}", {
          params: { path: { id } },
        })
        .then((response) => response.data),
    queryKey: queryKey(id),
    enabled: id !== NOT_SELECTED_ID,
  });

  return {
    agent: data,
    isLoading,
  };
};

export const useInvalidateGetAgent = () => {
  const queryClient = useQueryClient();

  const invalidate = async (id: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(id),
    });
  };

  return {
    invalidate,
  };
};
