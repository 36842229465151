import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import {
  AttractionBase,
  useGetAttractions,
} from "@twocontinents/dashboard/shared";
import { FormEvent, useEffect, useState } from "react";

import { useUpdateAttractionsSequence } from "../../data-access";

export const useAttractionsSequence = () => {
  const { attractions } = useGetAttractions();
  const { isPending, updateAttractionsSequence } =
    useUpdateAttractionsSequence();

  const [attractionsSequence, setAttractionsSequence] = useState<
    AttractionBase[]
  >([]);

  useEffect(() => {
    setAttractionsSequence(
      attractions?.toSorted((a, b) => a.sequence - b.sequence) ?? [],
    );
  }, [attractions]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      if (!over || !active) return;
      setAttractionsSequence((items) => {
        const oldIndex = items
          .map((item) => item.id)
          .indexOf(Number.parseInt(active.id.toString()));

        const newIndex = items
          .map((item) => item.id)
          .indexOf(Number.parseInt(over.id.toString()));

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const sortableAttractions = attractionsSequence.map((attraction) => ({
    id: attraction.id,
    name: attraction.description,
  }));

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const attractionToSequenceMap = Object.fromEntries(
      attractionsSequence.map((attraction, index) => [attraction.id, index]),
    );
    updateAttractionsSequence({
      attractionsSequence: attractionToSequenceMap,
    });
  };

  return {
    sortableAttractions,
    handleDragEnd,
    handleFormSubmit,
    isPending,
  };
};
