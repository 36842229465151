import { AttractionCrewAvailability } from "@twocontinents/dashboard/attractions/shared";

import { CrewAvailability } from "./crew-availability";

export class CrewAvailabilityList {
  private crewAvailabilityMap: Map<string, CrewAvailability> = new Map<
    string,
    CrewAvailability
  >();

  public constructor(crewAvailability: AttractionCrewAvailability[]) {
    this.processCrewAvailability(crewAvailability);
  }

  public getCrewAvailability(date: string): CrewAvailability | undefined {
    return this.crewAvailabilityMap.get(date);
  }

  private processCrewAvailability(
    crewAvailability: AttractionCrewAvailability[],
  ): void {
    crewAvailability.forEach((availability) => {
      const crewAvailabilityList = availability.crewAvailabilities ?? [];
      const crewAvailability = new CrewAvailability(crewAvailabilityList);
      this.crewAvailabilityMap.set(availability.date, crewAvailability);
    });
  }
}
