import {
  Button,
  FinancialLogLevel,
  Table,
  cn,
  routes,
  useOpened,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode, DateFormatter } from "@twocontinents/shared";
import { Link } from "react-router-dom";

import { Reservation } from "../../types";

interface ReservationFinancialLogsTableProps {
  reservation: Reservation;
  orderId: number;
}

export const ReservationFinancialLogsTable = ({
  reservation,
  orderId,
}: ReservationFinancialLogsTableProps) => {
  const primaryFinancialLogs = reservation.financialLogs.filter(
    (financialLog) => financialLog.level === FinancialLogLevel.PRIMARY,
  );

  const { opened, toggle } = useOpened(false);
  return (
    <>
      <Button onClick={toggle} variant="outline" className="w-fit self-start">
        Show financial logs
      </Button>
      {opened && (
        <>
          <h1 className=" text-xl font-semibold self-start">
            Financial log entries
          </h1>
          <Table className="w-full  bg-slate-950 ">
            <Table.Header className="w-full rounded-3xl  ">
              <Table.Row className="border-slate-700">
                <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
                  ID
                </Table.Head>
                <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
                  Source
                </Table.Head>
                <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
                  Cause
                </Table.Head>
                <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
                  Value
                </Table.Head>
                <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
                  Settled
                </Table.Head>
                <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
                  Corrected
                </Table.Head>
                <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
                  Settle Date
                </Table.Head>
                <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
                  Details
                </Table.Head>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {primaryFinancialLogs.map((financialLog) => (
                <Table.Row key={financialLog.id}>
                  <Table.Cell className="text-center px-2 border-r border-slate-800">
                    {financialLog.id}
                  </Table.Cell>
                  <Table.Cell className="text-center px-2 border-r border-slate-800">
                    {financialLog.source}
                  </Table.Cell>
                  <Table.Cell className="text-center px-2 border-r border-slate-800">
                    {financialLog.cause}
                  </Table.Cell>
                  <Table.Cell
                    className={cn(
                      "text-center px-2 border-r border-slate-800",
                      {
                        "text-green-500": financialLog.finalPlnValue > 0,
                        "text-red-500": financialLog.finalPlnValue < 0,
                        "text-slate-200": financialLog.finalPlnValue === 0,
                      },
                    )}
                  >
                    {financialLog.finalPlnValue} {CurrencyCode.PLN}
                  </Table.Cell>

                  <Table.Cell className="text-center px-2 border-r border-slate-800">
                    {financialLog.settled ? "Yes" : "No"}
                  </Table.Cell>
                  <Table.Cell className="text-center px-2 border-r border-slate-800">
                    {financialLog.childFinancialLogs.length > 0 ? "Yes" : "No"}
                  </Table.Cell>
                  <Table.Cell className="text-center px-2 border-r border-slate-800">
                    {DateFormatter.formatToDDMMYYYY(financialLog.settleDate)}
                  </Table.Cell>
                  <Table.Cell className="text-center px-2 border-r border-slate-800">
                    <Link
                      to={`${routes.financialLog.show.url(financialLog.id)}`}
                    >
                      <Button variant="outline" className="w-fit">
                        Details
                      </Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <div className="flex gap-4 items-center self-start">
            <Link
              to={`${routes.financialLog.index}?reservationIdFormValue=${reservation.id}&orderIdFormValue=${orderId}`}
            >
              <Button variant="outline" className="w-fit">
                Add new entry
              </Button>
            </Link>
            <p>
              Subtotal profit: {reservation.profitInPln} {CurrencyCode.PLN}
            </p>
          </div>
        </>
      )}
    </>
  );
};
