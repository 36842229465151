import { useQuery } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

export const useGetAllBusinessPartners = () => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/business/", {
          params: {
            query: {
              size: 20, // We are assuming that there are not more than 20 business partners
            },
          },
        })
        .then((response) => response.data),
    queryKey: ["all-business-partners"],
  });

  const businessPartners =
    data?.businessPartners?.toSorted((a, b) => a.id - b.id) ?? [];

  return {
    businessPartners,
    isLoading,
  };
};
