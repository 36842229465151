import { UserRole } from "./user-role";

export interface UserPayload {
  id: number;
  role: UserRole;
  name: string;
  email: string;
  sub: string;
  iat: number;
  exp: number;
}

export class User {
  private _id: number;
  private _role: UserRole;
  private _name: string;
  private _email: string;
  private _sub: string;
  private _iat: number;
  private _exp: number;

  public constructor(payload: UserPayload) {
    this._id = payload.id;
    this._role = payload.role;
    this._name = payload.name;
    this._email = payload.email;
    this._sub = payload.sub;
    this._iat = payload.iat;
    this._exp = payload.exp;
  }

  public get id(): number {
    return this._id;
  }

  public get role(): UserRole {
    return this._role;
  }

  public get name(): string {
    return this._name;
  }

  public get email(): string {
    return this._email;
  }

  public get isRoot(): boolean {
    return this._role === UserRole.ROOT;
  }
}
