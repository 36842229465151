import { jwtDecode } from "jwt-decode";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { User, UserPayload } from "../../types";
import { routes } from "../../utils/routes";

interface Auth {
  user: User | undefined;
  token: string | undefined;
  setToken: (token: string | undefined) => void;
}

const AuthContext = createContext<Auth | null>(null);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [token, setToken] = useState(
    localStorage.getItem("token") ?? undefined,
  );
  const [user, setUser] = useState<User | undefined>(undefined);

  const removeToken = useCallback(() => {
    localStorage.removeItem("token");
    setToken(undefined);
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      try {
        const userData = jwtDecode<UserPayload>(token);
        if (userData.exp * 1000 <= Date.now()) {
          removeToken();
          window.location.href = routes.login; // cannot use navigate here
          return;
        }
        setUser(new User(userData));
      } catch {
        removeToken();
      }
    } else {
      removeToken();
    }
  }, [token, removeToken]);

  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      user,
    }),
    [token, user],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("Missing AuthProvider in the tree!");
  }

  return context;
};
