import { DragAndDrop, SettingCard } from "@twocontinents/dashboard/shared";

import { useAttractionsSequence } from "./use-attractions-sequence";

export const AttractionsSequence = () => {
  const { handleDragEnd, handleFormSubmit, isPending, sortableAttractions } =
    useAttractionsSequence();
  return (
    <SettingCard className="my-4">
      <SettingCard.Header>
        <SettingCard.Title>Sequence settings</SettingCard.Title>
        <SettingCard.Description>
          Set the sequence of attractions
        </SettingCard.Description>
      </SettingCard.Header>
      <form onSubmit={handleFormSubmit}>
        <DragAndDrop items={sortableAttractions} onDragEnd={handleDragEnd} />
        <SettingCard.Footer>
          <SettingCard.Description>
            This setting will change the sequence of attractions.
          </SettingCard.Description>
          <SettingCard.Button isLoading={isPending}>Submit</SettingCard.Button>
        </SettingCard.Footer>
      </form>
    </SettingCard>
  );
};
