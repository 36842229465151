import { DateAvailability } from "./date-availability";

interface MergedAvailabilitiesConstructorProps {
  secondary: DateAvailability[];
  primary: DateAvailability[];
}

export class MergedAvailabilities {
  private secondary: DateAvailability[];
  private primary: DateAvailability[];

  public constructor({
    secondary,
    primary,
  }: MergedAvailabilitiesConstructorProps) {
    this.secondary = secondary;
    this.primary = primary;
  }

  public get merged(): DateAvailability[] {
    const availabilityMap: Map<string, DateAvailability> = new Map<
      string,
      DateAvailability
    >();

    this.primary.forEach((availability) => {
      availabilityMap.set(availability.id, availability);
    });

    this.secondary.forEach((availability) => {
      const id = availability.id;
      if (!availabilityMap.has(id)) {
        availabilityMap.set(id, availability);
      }
    });

    return [...availabilityMap.values()];
  }

  public findByDatetime(
    date: string,
    time: string | undefined,
  ): DateAvailability | undefined {
    return this.merged.find((availability) => availability.equals(date, time));
  }
}
