import { PopoverContent } from "@radix-ui/react-popover";
import dayjs from "dayjs";
import { Calendar as CalendarIcon } from "lucide-react";
import { Matcher } from "react-day-picker";

import { cn } from "../utils";
import { Button, Calendar, Popover } from "./shadcn";

interface DatePickerProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  placeholder?: string;
  classNames?: {
    trigger?: string;
  };
  disabled?: Matcher | Matcher[];
}

export function DatePicker({
  date,
  setDate,
  placeholder,
  classNames,
  disabled,
}: DatePickerProps) {
  const formattedDisplayDate = dayjs(date).format("DD/MM/YYYY");
  return (
    <Popover>
      <Popover.Trigger asChild>
        <Button
          className={cn(
            " justify-between text-left font-normal",
            !date && "text-muted-foreground",
            classNames?.trigger,
          )}
        >
          {date ? (
            formattedDisplayDate
          ) : (
            <span>{placeholder ?? "Pick date"}</span>
          )}
          <CalendarIcon className="h-4 w-4" />
        </Button>
      </Popover.Trigger>
      <PopoverContent className="w-auto p-0 z-50">
        <Calendar
          disabled={disabled}
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}
