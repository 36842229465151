import { DateFormatter } from "@twocontinents/shared";

import { Tour, TourBase } from "../types";

export const TourFormatter = {
  formatWithParticipantsDetails: (tour: TourBase) => {
    return `${tour.id}. ${
      tour.tourPerGroup
        ? tour.attractionGroupName
        : tour.attractionVariantDescription
    } (${DateFormatter.formatToDDMMYYYY(tour.date)} ${
      tour.time ? DateFormatter.formatToHHmm(tour.time) : ""
    }) - ${
      tour.maxParticipants === -1
        ? "Unlimited"
        : `${tour.participants.length}/${tour.maxParticipants}`
    }`;
  },
  formatDetailedWithParticipantsDetails: (tour: Tour) => {
    return `${tour.id}. ${
      tour.tourPerGroup
        ? tour.attractionGroupName
        : tour.attractionVariant.description
    } (${DateFormatter.formatToDDMMYYYY(tour.date)} ${
      tour.time ? DateFormatter.formatToHHmm(tour.time) : ""
    }) - ${
      tour.maxParticipants === -1
        ? "Unlimited"
        : `${tour.participants.length}/${tour.maxParticipants}`
    }`;
  },
  formatWithoutParticipantsDetails: (tour: Tour) => {
    return `${tour.id}. ${
      tour.tourPerGroup
        ? tour.attractionGroupName
        : tour.attractionVariant.description
    } (${DateFormatter.formatToDDMMYYYY(tour.date)} ${
      tour.time ? DateFormatter.formatToHHmm(tour.time) : ""
    })`;
  },
  fullAttractionName: (tour: TourBase) => {
    return `${tour.attractionName} - ${tour.tourPerGroup ? tour.attractionGroupName : tour.attractionVariantDescription}`;
  },
  detailedWithFullAttractionName: (tour: Tour) => {
    return `${tour.attractionName} - ${tour.tourPerGroup ? tour.attractionGroupName : tour.attractionVariant.description}`;
  },
};
