import { useQuery } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = (id: number) => ["private-business-partner", id];

export const useGetBusinessPartner = (id: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/business/{id}", {
          params: { path: { id } },
        })
        .then((response) => response.data),
    queryKey: queryKey(id),
  });

  return {
    businessPartner: data,
    isLoading,
  };
};
