import { AlertDialog, Button } from "@twocontinents/dashboard/shared";

import { Review } from "../../../types";
import { useModerateReviewAlertDialog } from "./use-moderate-review-alert-dialog";

interface Props {
  review: Review;
}

export const ModerateReviewAlertDialog = ({ review }: Props) => {
  const { handleSubmit, isPending, modalDescription } =
    useModerateReviewAlertDialog(review);
  return (
    <AlertDialog>
      <AlertDialog.Trigger asChild>
        <Button variant={review.hidden ? "positive" : "destructive"}>
          {review.hidden ? "Make visible" : "Make hidden"}
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>
            Are you sure you want to {review.hidden ? "make visible" : "hide"}{" "}
            this review?
          </AlertDialog.Title>
          <AlertDialog.Description>{modalDescription}</AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
          <AlertDialog.Action onClick={handleSubmit} disabled={isPending}>
            Yes
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
