import { Form, SettingCard, Tabs } from "@twocontinents/dashboard/shared";

import { StatisticsTab as StatisticsTabEnum } from "../../types";
import { StatisticsTab } from "./statistics-tab";
import { useStatistics } from "./use-statistics";

export const Statistics = () => {
  const { form, tab, onTabChange, orderStatistics, financialLogStatistics } =
    useStatistics();

  return (
    <Form {...form}>
      <SettingCard>
        <SettingCard.Header>
          <SettingCard.Title>Statistics</SettingCard.Title>
          <SettingCard.Description>Review Statistics</SettingCard.Description>
        </SettingCard.Header>

        <Tabs
          value={tab}
          onValueChange={onTabChange}
          defaultValue={StatisticsTabEnum.Today}
        >
          <Tabs.List>
            <Tabs.Trigger value={StatisticsTabEnum.Today}>Today</Tabs.Trigger>
            <Tabs.Trigger value={StatisticsTabEnum.All}>All</Tabs.Trigger>
            <Tabs.Trigger value={StatisticsTabEnum.Range}>Range</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value={StatisticsTabEnum.Today}>
            <StatisticsTab
              orderStatistics={orderStatistics}
              financialLogStatistics={financialLogStatistics}
            />
          </Tabs.Content>
          <Tabs.Content value={StatisticsTabEnum.All}>
            <StatisticsTab
              orderStatistics={orderStatistics}
              financialLogStatistics={financialLogStatistics}
            />
          </Tabs.Content>
          <Tabs.Content value={StatisticsTabEnum.Range}>
            <StatisticsTab
              orderStatistics={orderStatistics}
              financialLogStatistics={financialLogStatistics}
            />
          </Tabs.Content>
        </Tabs>
      </SettingCard>
    </Form>
  );
};
