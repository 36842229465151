import { Table, buttonVariants, routes } from "@twocontinents/dashboard/shared";
import { DateFormatter, PriceFormatter } from "@twocontinents/shared";
import { Link } from "react-router-dom";

import { Reservation } from "../../../types";
import { ReservationFinancialLogsTable } from "../reservation-financial-logs-table";
import { ReservationTicketSection } from "../reservation-ticket-section";

interface ReservationDetailsTableProps {
  reservation: Reservation;
  orderId: number;
}

export const ReservationDetailsTable = ({
  reservation,
  orderId,
}: ReservationDetailsTableProps) => {
  const formattedPrice = PriceFormatter.noFraction(reservation.price);

  return (
    <div className="flex flex-col gap-4">
      <Table className="w-full  bg-slate-950 ">
        <Table.Header className="w-full rounded-3xl  ">
          <Table.Row className="border-slate-700">
            <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
              Atrakcja
            </Table.Head>
            <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
              Typ atrakcji
            </Table.Head>
            <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
              Termin
            </Table.Head>
            {reservation.historicalAgeGroups.map(({ historicalAgeGroup }) => (
              <Table.Head
                key={historicalAgeGroup.id}
                className="py-5 text-center px-2 border-r border-slate-800 font-bold"
              >
                {historicalAgeGroup.description}
              </Table.Head>
            ))}

            {reservation.pickupLocation && (
              <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
                Miejsce odbioru
              </Table.Head>
            )}
            {reservation.pickupTime && (
              <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
                Godzina odbioru
              </Table.Head>
            )}

            <Table.Head className="py-5 text-center px-2 border-r border-slate-800 font-bold">
              Cena
            </Table.Head>

            {reservation.historicalExtraOptions.map(
              ({ historicalExtraOption }) => (
                <Table.Head
                  key={historicalExtraOption.id}
                  className="py-5 text-center px-2 border-r border-slate-800 font-bold"
                >
                  {historicalExtraOption.name}
                </Table.Head>
              ),
            )}
            {reservation.reservationAdditionalDetails.map(
              (additionalDetail) => (
                <Table.Head
                  key={additionalDetail.label}
                  className="py-5 text-center px-2 border-r border-slate-800 font-bold"
                >
                  {additionalDetail.label}
                </Table.Head>
              ),
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row className="border-slate-800">
            <Table.Cell className="border-r text-center border-slate-800 ">
              {reservation.attractionDescription}
            </Table.Cell>
            <Table.Cell className="border-r text-center border-slate-800">
              {reservation.attractionVariant.description}
            </Table.Cell>
            <Table.Cell className="border-r text-center border-slate-800 whitespace-nowrap">
              {DateFormatter.formatDateTimeToDDMMYYYYHHmm(
                reservation.reservationDate,
                reservation.reservationTime,
              )}
            </Table.Cell>

            {reservation.historicalAgeGroups.map(
              ({ historicalAgeGroup, amount }) => (
                <Table.Cell
                  key={`${historicalAgeGroup.id}-${amount}`}
                  className="border-r text-center border-slate-800"
                >
                  {amount}
                </Table.Cell>
              ),
            )}

            {reservation.pickupLocation && (
              <Table.Cell className="border-r text-center border-slate-800">
                {reservation.pickupLocation}
              </Table.Cell>
            )}

            {reservation.pickupTime && (
              <Table.Cell className="border-r text-center border-slate-800">
                {DateFormatter.formatToHHmm(reservation.pickupTime)}
              </Table.Cell>
            )}

            <Table.Cell className="border-r text-center border-slate-800 whitespace-nowrap">
              {formattedPrice} {reservation.currency}
            </Table.Cell>

            {reservation.historicalExtraOptions.map(
              ({ historicalExtraOption, amount }) => (
                <Table.Cell
                  key={`${historicalExtraOption.id}-${amount}`}
                  className="border-r text-center border-slate-800"
                >
                  {amount}
                </Table.Cell>
              ),
            )}
            {reservation.reservationAdditionalDetails.map(
              (additionalDetail) => (
                <Table.Cell
                  key={additionalDetail.value}
                  className="py-5 text-center px-2 border-r border-slate-800 font-bold"
                >
                  {additionalDetail.value}
                </Table.Cell>
              ),
            )}
          </Table.Row>
        </Table.Body>
      </Table>
      <div className="flex gap-y-4 justify-center flex-col">
        <ReservationFinancialLogsTable
          reservation={reservation}
          orderId={orderId}
        />

        {reservation.tourId && (
          <Link
            className={buttonVariants({
              variant: "secondary",
              className: "w-fit",
            })}
            to={routes.attractions.tours.show.url(reservation.tourId)}
          >
            Go to tour #{reservation.tourId}
          </Link>
        )}
        <ReservationTicketSection reservation={reservation} orderId={orderId} />
      </div>
    </div>
  );
};
