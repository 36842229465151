import { Button, Form, FormItem, Input } from "@twocontinents/dashboard/shared";
import { useFormContext } from "react-hook-form";

import { CreateAgeGroupTicketSchema } from "./use-age-groups-tickets-settings";

interface ManualTicketFormProps {
  fields: { id: string }[];
  append: (value: { value: string }) => void;
  remove: (index: number) => void;
}

export const ManualTicketForm = ({
  fields,
  remove,
  append,
}: ManualTicketFormProps) => {
  const form = useFormContext<CreateAgeGroupTicketSchema>();

  return (
    <>
      {fields.map((field, index) => (
        <Form.Field
          key={field.id}
          control={form.control}
          name={`codes.${index}.value`}
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Code</FormItem.Label>
                <FormItem.Control>
                  <Input {...field} />
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />
      ))}
      <div className="flex gap-2 my-4">
        <Button
          type="button"
          className="w-fit"
          onClick={() => {
            append({ value: "" });
          }}
        >
          Add Code
        </Button>
        {fields.length > 0 && (
          <Button
            type="button"
            variant="destructive"
            className={"w-fit"}
            onClick={() => {
              remove(fields.length - 1);
            }}
          >
            Remove Code
          </Button>
        )}
      </div>
    </>
  );
};
