import { OrderStatus } from "@twocontinents/dashboard/attractions/shared";
import { Form, FormItem, Switch } from "@twocontinents/dashboard/shared";
import { useFormContext } from "react-hook-form";

import { OrderStatusFormValues } from "./use-order-details";

export const OrderStatusSwitch = () => {
  const { control, watch } = useFormContext<OrderStatusFormValues>();

  const status = watch("status");
  const isChecked = status === OrderStatus.COMPLETED;

  return (
    <Form.Field
      control={control}
      name="status"
      render={({ field }) => (
        <FormItem className="flex items-center flex-col gap-2">
          <h3 className="text-sm">Order status</h3>
          <div className="flex items-center justify-start">
            <FormItem.Control>
              <Switch
                id={field.name}
                checked={field.value === OrderStatus.COMPLETED}
                onCheckedChange={(isChecked) => {
                  field.onChange(
                    isChecked ? OrderStatus.COMPLETED : OrderStatus.NEW,
                  );
                }}
              />
            </FormItem.Control>
            <FormItem.Label
              className={`ml-3 ${isChecked ? "text-green-600" : "text-red-600"}`}
            >
              {isChecked ? "Completed" : "Not completed"}
            </FormItem.Label>
          </div>
        </FormItem>
      )}
    />
  );
};
