import { DropdownMenu } from "@twocontinents/dashboard/shared";
import { useMemo } from "react";

import { Coupon } from "../../types";

interface CouponVariantsDropdownProps {
  isDropdownOpened?: boolean;
  setIsDropdownOpened: (isDropdownOpened: boolean) => void;
  coupon: Coupon;
}

export const CouponVariantsDropdown = ({
  isDropdownOpened,
  setIsDropdownOpened,
  coupon,
}: CouponVariantsDropdownProps) => {
  const groupedVariants = useMemo(() => {
    const groupedData = new Map<string, Coupon["attractionVariants"]>();

    coupon.attractionVariants.forEach((item) => {
      if (groupedData.has(item.attractionName)) {
        groupedData.get(item.attractionName)?.push(item);
      } else {
        groupedData.set(item.attractionName, [item]);
      }
    });

    return groupedData;
  }, [coupon.attractionVariants]);

  return (
    <DropdownMenu modal open={isDropdownOpened}>
      <DropdownMenu.Trigger asChild>
        <span
          className="justify-start text-left p-0 h-full hover:!bg-slate-900"
          onClick={() => {
            setIsDropdownOpened(true);
          }}
        >
          {coupon.id}
        </span>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="start"
        side="bottom"
        className="flex flex-col max-w-fit p-2 overflow-auto max-h-[350px]"
        onInteractOutside={() => {
          setIsDropdownOpened(false);
        }}
      >
        <DropdownMenu.Label>
          Coupon is applicable for variants:
        </DropdownMenu.Label>
        <DropdownMenu.Separator />
        <div className="flex flex-col gap-3">
          {[...groupedVariants.entries()].map(([key, variants]) => (
            <div key={key}>
              <DropdownMenu.Item className="font-bold text-center text-slate-300">
                {key} :
              </DropdownMenu.Item>
              {variants.map((variant) => (
                <DropdownMenu.Item key={variant.id}>
                  {variant.name}
                </DropdownMenu.Item>
              ))}
            </div>
          ))}
        </div>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};
