import { useQuery } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { Pagination } from "@twocontinents/shared";

const queryKey = (pagination: Pagination) => ["financial-logs", pagination];

export const useGetBusinessPartners = (pagination: Pagination) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/business/", {
          params: {
            query: pagination,
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(pagination),
  });

  return {
    data,
    isLoading,
  };
};
