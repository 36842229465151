import { Button, DropdownMenu } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";

import { ReservationBase } from "../../../types";

interface Props {
  orderId: number;
  reservations: ReservationBase[];
  isDropdownOpened?: boolean;
  setIsDropdownOpened: (isDropdownOpened: boolean) => void;
}

export const OrderReservationsListDropdown = ({
  reservations,
  orderId,
  isDropdownOpened,
  setIsDropdownOpened,
}: Props) => {
  const formattedId = orderId.toString().padStart(6, "0");
  return (
    <DropdownMenu modal open={isDropdownOpened}>
      <DropdownMenu.Trigger asChild>
        <Button
          variant="ghost"
          className="justify-start text-left h-full hover:!bg-slate-900 hover:text-slate-50"
          onClick={() => {
            setIsDropdownOpened(true);
          }}
        >
          {formattedId}
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className=" w-fit max-w-xs ml-16 left-10"
        onInteractOutside={() => {
          setIsDropdownOpened(false);
        }}
      >
        <DropdownMenu.Label>Lista atrakcji z zamówienia </DropdownMenu.Label>
        <DropdownMenu.Separator />
        <DropdownMenu.Group>
          {reservations.map((props, i, { length }) => (
            <ReservationGroup
              key={props.id}
              {...props}
              index={i}
              reservationsLength={length}
            />
          ))}
        </DropdownMenu.Group>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};

interface ReservationGroupProps {
  attractionDescription: string;
  attractionVariantDescription: string;
  historicalAgeGroups: ReservationBase["historicalAgeGroups"];
  historicalExtraOptions: ReservationBase["historicalExtraOptions"];
  reservationDate: string;
  reservationTime?: string;
  pickupLocation?: string;
  pickupTime?: string;
  reservationsLength: number;
  index: number;
}

const ReservationGroup = ({
  attractionDescription,
  historicalAgeGroups,
  attractionVariantDescription,
  historicalExtraOptions,
  reservationDate,
  reservationTime,
  pickupLocation,
  reservationsLength,
  index,
  pickupTime,
}: ReservationGroupProps) => {
  const formattedDatetime = DateFormatter.formatDateTimeToDDMMYYYYHHmm(
    reservationDate,
    reservationTime,
  );

  const isLastItem = index !== reservationsLength - 1;
  return (
    <>
      <DropdownMenu.Item
        className=" cursor-default flex-col items-start !data-[disabled]:bg-slate-900 !data-[disabled]:text-white data-[disabled]:opacity-100"
        disabled
      >
        <ReservationDetail label="Atrakcja" value={attractionDescription} />

        <ReservationDetail
          label="Wariant atrakcji"
          value={attractionVariantDescription}
        />
        {historicalAgeGroups.map(({ historicalAgeGroup, amount }) => (
          <ReservationDetail
            key={historicalAgeGroup.id}
            label={historicalAgeGroup.description}
            value={amount.toString()}
          />
        ))}

        {historicalExtraOptions?.map(({ amount, historicalExtraOption }) => (
          <ReservationDetail
            key={historicalExtraOption.id}
            label={historicalExtraOption.name}
            value={
              historicalExtraOption.hasQuantity ? amount.toString() : "Tak"
            }
          />
        ))}

        {pickupLocation && (
          <ReservationDetail label="Miejsce odbioru" value={pickupLocation} />
        )}

        {pickupTime && (
          <ReservationDetail
            label="Godzina odbioru"
            value={DateFormatter.formatToHHmm(pickupTime)}
          />
        )}

        <ReservationDetail
          label="Data i godzina rezerwacji"
          value={formattedDatetime}
        />
      </DropdownMenu.Item>
      {isLastItem && <DropdownMenu.Separator />}
    </>
  );
};

interface ReservationDetailProps {
  label: string;
  value: string;
}

const ReservationDetail = ({ label, value }: ReservationDetailProps) => {
  return (
    <p className="my-1 select-text">
      <span className="font-semibold select-text">{label}:</span> {value}
    </p>
  );
};
