import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttraction } from "../../../data-access";

const ActivitySettingsSchema = z.object({
  active: z.boolean(),
});

type ActivitySettingsSchema = z.infer<typeof ActivitySettingsSchema>;

export const useActivitySettings = (
  attractionId: number,
  defaultActive: boolean,
) => {
  const { updatePrivateAttraction, isPending } = useUpdateAttraction();

  const form = useForm<ActivitySettingsSchema>({
    resolver: zodResolver(ActivitySettingsSchema),
    defaultValues: {
      active: defaultActive,
    },
  });

  const { watch, setValue, handleSubmit } = form;

  const active = watch("active");
  const toggleActive = () => setValue("active", !active);
  const toggleButtonVariant = (active ? "positive" : "destructive") as
    | "positive"
    | "destructive";
  const toggleButtonLabel = active ? "Active" : "Disabled";

  const onSubmit = handleSubmit((body) => {
    updatePrivateAttraction({
      attractionId,
      body,
    });
  });

  return {
    form,
    toggleActive,
    toggleButtonVariant,
    toggleButtonLabel,
    onSubmit,
    isPending,
  };
};
