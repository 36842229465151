import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  NOT_SELECTED_ID,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";

const queryKey = (id: number) => ["tour", id];

export const useGetTour = (id: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/attractions/tours/{id}", {
          params: {
            path: {
              id,
            },
          },
        })
        .then((res) => res.data),
    queryKey: queryKey(id),
    enabled: id !== NOT_SELECTED_ID,
  });

  return {
    tour: data,
    isLoading,
  };
};

export const useInvalidateGetTour = () => {
  const queryClient = useQueryClient();

  const invalidate = async (id: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(id),
    });
  };

  return { invalidate };
};
