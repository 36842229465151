import { StatusIndicator } from "@twocontinents/dashboard/shared";

interface TourMessageStatusIndicatorProps {
  sent: boolean;
  cancelled: boolean;
}
export const TourMessageStatusIndicator = ({
  cancelled,
  sent,
}: TourMessageStatusIndicatorProps) => {
  if (sent) {
    return <StatusIndicator status="positive">Sent</StatusIndicator>;
  }
  if (cancelled) {
    return <StatusIndicator status="negative">Cancelled</StatusIndicator>;
  }
  return <StatusIndicator status="neutral">Planned</StatusIndicator>;
};
