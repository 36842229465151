import { useBoolean } from "usehooks-ts";

export const useOpened = (defaultValue?: boolean) => {
  const {
    value: opened,
    setTrue: open,
    setFalse: close,
    toggle,
  } = useBoolean(defaultValue);

  return { opened, open, close, toggle };
};
