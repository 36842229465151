import { useMutation } from "@tanstack/react-query";
import { OrderStatus } from "@twocontinents/dashboard/attractions/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetOrders } from "./get-orders";

export type BatchOrdersUpdateRequest =
  components["schemas"]["UpdateOrdersRequest"];

export const useBatchOrdersUpdate = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetOrders();

  return useMutation({
    mutationFn: (request: BatchOrdersUpdateRequest) =>
      apiClient.PATCH("/private/orders/", {
        body: request,
      }),
    onSuccess: async (_, variables) => {
      await invalidate();
      const status =
        variables.status === OrderStatus.NEW
          ? "niezrealizowane"
          : "zrealizowane";

      toast.success(`Zamówienia zostały zapisane jako ${status}`);
    },
    onError: () => {
      toast.error("Wystąpił błąd podczas aktualizacji zamówień");
    },
  });
};
