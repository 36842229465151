import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { useInvalidateGetOfferBundle } from "@twocontinents/dashboard/travel-agencies/offer-bundle/shared";
import { useInvalidateGetOfferBundles } from "@twocontinents/dashboard/travel-agencies/shared";
import { components } from "@twocontinents/shared";

type UpdateOfferBundle = components["schemas"]["UpdateOfferBundleRequest"];

export const useUpdateOfferBundle = (id: number) => {
  const apiClient = usePrivateApiClient();
  const { invalidate: invalidateOfferBundles } = useInvalidateGetOfferBundles();
  const { invalidate: invalidateOfferBundle } = useInvalidateGetOfferBundle();

  const { mutate, isPending } = useMutation({
    mutationFn: (body: UpdateOfferBundle) => {
      return apiClient
        .PATCH("/private/partners/travel-agencies/offer-bundles/{id}", {
          params: {
            path: { id },
          },
          body,
        })
        .then((response) => response.data);
    },
    onSuccess: async () => {
      toast.success("Offer bundle updated successfully");
      await invalidateOfferBundles();
      await invalidateOfferBundle(id);
    },
    onError: () => {
      toast.error("Could not update offer bundle");
    },
  });

  return { updateOfferBundle: mutate, isPending };
};
