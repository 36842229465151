import { ExtraOption } from "@twocontinents/dashboard/attractions/shared";
import { Calendar as BaseCalendar } from "@twocontinents/dashboard/shared";

import { CalendarExtraOptionAvailability } from "../availability-settings/use-extra-options-availability-settings";
import { CalendarDay } from "../calendar-day";
import { useCalendar } from "./use-calendar";

interface CalendarProps {
  allAvailabilities: CalendarExtraOptionAvailability[];
  selectedExtraOptions: ExtraOption[];
}

export const Calendar = ({
  allAvailabilities,
  selectedExtraOptions,
}: CalendarProps) => {
  const { selectCalendarDay, isDateAvailable } = useCalendar(
    allAvailabilities,
    selectedExtraOptions,
  );

  return (
    <div className={"flex flex-col gap-4"}>
      <BaseCalendar
        classNames={{
          day: "m-1 group",
        }}
        onDayClick={(day) => selectCalendarDay(day)}
        components={{
          Day: (props) => (
            <CalendarDay
              {...props}
              isAvailable={
                selectedExtraOptions.length > 0
                  ? isDateAvailable(props.date)
                  : undefined
              }
            />
          ),
        }}
      />
    </div>
  );
};
