import { Tour } from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  Button,
  DateTimePicker,
  Form,
  FormItem,
  Label,
  buttonVariants,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";

import { useChangeTourTermDialog } from "./use-change-tour-term-dialog";

interface ChangeTourTermDialogProps {
  tour: Tour;
}

export const ChangeTourTermDialog = ({ tour }: ChangeTourTermDialogProps) => {
  const {
    form,
    isPending,
    onSubmit,
    openAlertDialog,
    time,
    handleTimeChange,
    displayTimeInput,
  } = useChangeTourTermDialog(tour);

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button className="w-fit " variant="secondary">
          Change Tour Date
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Tour term settigns</AlertDialog.Title>
          <AlertDialog.Description>
            Edit tour term settings
          </AlertDialog.Description>
        </AlertDialog.Header>
        <Form {...form}>
          <form onSubmit={onSubmit}>
            <Form.Fields>
              <Form.Field
                control={form.control}
                name="date"
                render={({ field }) => (
                  <FormItem.Row>
                    <Label className="min-w-40">Date</Label>
                    <DateTimePicker
                      disablePastDates
                      date={field.value ? new Date(field.value) : undefined}
                      setDate={(date) =>
                        field.onChange(DateFormatter.formatToYYYYMMDD(date))
                      }
                      displayTimeInput={displayTimeInput}
                      time={time}
                      setTime={handleTimeChange}
                    />
                  </FormItem.Row>
                )}
              />
            </Form.Fields>

            <AlertDialog.Footer className="mt-4">
              <AlertDialog.Cancel
                className={buttonVariants({ variant: "destructive" })}
              >
                Cancel
              </AlertDialog.Cancel>
              <Button isLoading={isPending} type="submit" variant="outline">
                Save
              </Button>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
