import { EmptyState, Layout } from "@twocontinents/dashboard/shared";
import { FerrisWheel } from "lucide-react";
import { useParams } from "react-router-dom";

import { AttractionPartnershipsTable } from "../attraction-partnerships-table";
import { BusinessPartnerAttractionsFilters } from "../business-partner-attractions-filters";
import { useBusinessPartnerShowPage } from "./use-business-partner-show-page";

export function BusinessPartnerShowPage() {
  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const { businessPartner, filteredAttractionPartnerships, isLoading } =
    useBusinessPartnerShowPage(id);

  return (
    <Layout
      header={{
        title: `Business partner ${businessPartner?.name ?? ""}`,
        description: "Browse Business partner's commisions",
      }}
    >
      <BusinessPartnerAttractionsFilters />

      {filteredAttractionPartnerships && (
        <AttractionPartnershipsTable
          attractionPartnerships={filteredAttractionPartnerships}
          isLoading={isLoading}
        />
      )}
      {filteredAttractionPartnerships.length === 0 && (
        <EmptyState>
          <EmptyState.Icon>
            <FerrisWheel size={40} />
          </EmptyState.Icon>
          <EmptyState.Header>No attraction partnerships</EmptyState.Header>
          <EmptyState.Paragraph>
            Check if the entered name is correct and try again.
          </EmptyState.Paragraph>
        </EmptyState>
      )}
    </Layout>
  );
}
