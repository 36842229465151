import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type UpdateAgentPassword = components["schemas"]["UpdateAgentPasswordRequest"];

export const useUpdateAgentPassword = (id: number) => {
  const apiClient = usePrivateApiClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: UpdateAgentPassword) => {
      return apiClient
        .PATCH("/private/partners/travel-agencies/agents/{id}/password", {
          body: request,
          params: {
            path: {
              id,
            },
          },
        })
        .then((response) => response.data);
    },
    onSuccess: async () => {
      toast.success("Agent password updated successfully");
    },
    onError: () => {
      toast.error("Could not update agent password");
    },
  });

  return { updateAgentPassword: mutate, isPending };
};
