import {
  DetailedCard,
  DetailsTable,
  routes,
} from "@twocontinents/dashboard/shared";
import {
  CompanyDetails,
  CustomerDetails,
  InvoiceType,
} from "@twocontinents/shared";
import { TravelAgencyIcon } from "@twocontinents/shared-ui";

import { useCustomerDetailsSection } from "./use-customer-details-section";

interface CustomerDetailsProps {
  customerDetails: CustomerDetails;
  companyDetails?: CompanyDetails;
  invoiceType?: InvoiceType;
  travelAgencyId?: number;
  travelAgencyName?: string;
  agentName?: string;
  agentOrderReferenceId?: string;
}

export const CustomerDetailsSection = ({
  customerDetails,
  companyDetails,
  invoiceType,
  travelAgencyId,
  travelAgencyName,
  agentName,
  agentOrderReferenceId,
}: CustomerDetailsProps) => {
  const { detailsRows, hasBeenBoughtByAgent } = useCustomerDetailsSection(
    customerDetails,
    companyDetails,
    invoiceType,
    travelAgencyId,
    agentOrderReferenceId,
  );

  return (
    <DetailedCard>
      <DetailedCard.DetailsContainer>
        <DetailedCard.Title>Order Details</DetailedCard.Title>
        <DetailedCard.Description>
          {hasBeenBoughtByAgent ? (
            <p className={"inline-flex items-center flex-wrap gap-1"}>
              <span className="inline-flex gap-2 items-center">
                <TravelAgencyIcon /> This order has been made by{" "}
              </span>
              Agent <span className={"font-semibold"}>{agentName}</span>, member
              of{" "}
              <a
                href={routes.travelAgencies.show.url(travelAgencyId as number)}
              >
                <span className={"font-semibold underline"}>
                  {travelAgencyName}
                </span>{" "}
                Travel Agency
              </a>
              , See order details (Customer Full name + Travel Agency data)
            </p>
          ) : (
            "See customer's order details"
          )}
        </DetailedCard.Description>
      </DetailedCard.DetailsContainer>
      <DetailsTable>
        {detailsRows.map(({ label, content }) => (
          <DetailsTable.Row key={label} label={label} content={content} />
        ))}
      </DetailsTable>
    </DetailedCard>
  );
};
