import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type UpdateAttractionVariantsFormSequence =
  components["schemas"]["UpdateVariantsFormSequenceRequest"];

export const useUpdateAttractionVariantsFormSequence = () => {
  const apiClient = usePrivateApiClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (request: UpdateAttractionVariantsFormSequence) =>
      apiClient.POST("/private/attractions/variants/sequence", {
        body: request,
      }),
    onSuccess: () => {
      toast.success("Sequence has been updated.");
    },
    onError: () => {
      toast.error("An error occurred while updating sequence.");
    },
  });

  return {
    updateVariantsFormSequence: mutate,
    isPending,
  };
};
