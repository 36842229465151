import { Layout } from "@twocontinents/dashboard/shared";
import { OfferBundleForm } from "@twocontinents/dashboard/travel-agencies/offer-bundle/shared";

import { useOfferBundleCreatePage } from "./use-offer-bundle-create-page";

export const OfferBundleCreatePage = () => {
  const { isPending, onSubmit, entries } = useOfferBundleCreatePage();

  return (
    <Layout
      header={{
        title: "Create Offer Bundle",
        description: "Create a new offer bundle",
      }}
    >
      <OfferBundleForm
        initialData={{
          entries,
        }}
        onSubmit={onSubmit}
        isPending={isPending}
        content={{
          title: "Create Offer Bundle",
          description: "Create a new offer bundle",
          footer:
            "After creating the offer bundle, you can assign it to a Affiliation partner.",
        }}
      />
    </Layout>
  );
};
