import { TourBase } from "@twocontinents/dashboard/attractions/shared";
import {
  SortDirection,
  useTableDropdown,
} from "@twocontinents/dashboard/shared";

import { useTourFiltersQueryParams } from "../../../hooks";

export const hasBeenBoughtByAgent = <T extends TourBase>(tour: T) =>
  tour.participants.some((participant) => Boolean(participant.agentId));

export const useToursTable = () => {
  const { setQuery, query } = useTourFiltersQueryParams();
  const toggleSortDirection = () => {
    setQuery({
      sortDirection:
        query.sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC,
    });
  };

  const { areDropdownsOpened, openDropdown, handleDropdownOpenChange } =
    useTableDropdown();

  return {
    toggleSortDirection,
    areDropdownsOpened,
    openDropdown,
    handleDropdownOpenChange,
  };
};
