import { Tour } from "@twocontinents/dashboard/attractions/shared";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useUpdateTourTerm } from "../../data-access";

const ChangeTourTermSchema = z.object({
  date: z.string(),
  time: z.string().optional(),
});

type ChangeTourTermSchema = z.infer<typeof ChangeTourTermSchema>;

export const useChangeTourTermDialog = (tour: Tour) => {
  const openAlertDialog = useBoolean(false);
  const { isPending, updateTourTerm } = useUpdateTourTerm();

  const isVariantTimed = tour.attractionVariant.timeSettings.timed;
  const isVariantPickupTimed = tour.attractionVariant.timeSettings.pickupTimed;
  const isVariantDefaultTimed =
    tour.attractionVariant.tourSettings?.defaultTime;

  const form = useForm<ChangeTourTermSchema>({
    defaultValues: {
      date: tour.date,
      time: tour.time,
    },
  });

  const onSubmit = form.handleSubmit(async (values) => {
    updateTourTerm(
      {
        tourId: tour.id,
        body: {
          date: values.date,
          time: values.time,
        },
      },
      {
        onSettled: () => {
          openAlertDialog.setFalse();
        },
      },
    );
  });

  const time = form.watch("time");

  const handleTimeChange = (time: string | undefined) => {
    form.setValue("time", time);
  };

  const displayTimeInput =
    isVariantTimed || isVariantPickupTimed || isVariantDefaultTimed;

  return {
    openAlertDialog,
    form,
    isPending,
    onSubmit,
    time,
    handleTimeChange,
    displayTimeInput,
  };
};
