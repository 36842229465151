import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttraction } from "../../../data-access";

const RefundabilitySettingsSchema = z
  .object({
    refundable: z.boolean(),
    hoursPriorReservationIsRefundable: z.coerce.number().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.refundable && !data.hoursPriorReservationIsRefundable) {
      ctx.addIssue({
        message: "Hours prior reservation  is required when refundable",
        path: ["hoursPriorReservationIsRefundable"],
        code: z.ZodIssueCode.custom,
      });
    }
  });

type RefundabilitySettingsSchema = z.infer<typeof RefundabilitySettingsSchema>;

export const useRefundabilitySettings = (
  attractionId: number,
  defaultRefundable: boolean,
  defaultHoursPriorReservationIsRefundable?: number,
) => {
  const { updatePrivateAttraction, isPending } = useUpdateAttraction();

  const form = useForm<RefundabilitySettingsSchema>({
    resolver: zodResolver(RefundabilitySettingsSchema),
    defaultValues: {
      refundable: defaultRefundable,
      hoursPriorReservationIsRefundable:
        defaultHoursPriorReservationIsRefundable,
    },
  });

  const { watch, setValue, handleSubmit } = form;

  const refundable = watch("refundable");
  const hoursPriorReservationIsRefundable = watch(
    "hoursPriorReservationIsRefundable",
  );

  useEffect(() => {
    if (!refundable) {
      setValue("hoursPriorReservationIsRefundable", undefined);
    } else if (!hoursPriorReservationIsRefundable) {
      setValue(
        "hoursPriorReservationIsRefundable",
        defaultHoursPriorReservationIsRefundable,
      );
    }
  }, [refundable, setValue, hoursPriorReservationIsRefundable]);

  const toggleRefundable = () => setValue("refundable", !refundable);
  const toggleButtonVariant = (refundable ? "positive" : "destructive") as
    | "positive"
    | "destructive";
  const toggleButtonLabel = refundable ? "Refundable" : "Not refundable";

  const onSubmit = handleSubmit((body) => {
    updatePrivateAttraction({
      attractionId,
      body,
    });
  });

  return {
    form,
    toggleRefundable,
    toggleButtonVariant,
    toggleButtonLabel,
    onSubmit,
    isPending,
    refundable,
  };
};
