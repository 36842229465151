import { Button, Layout, Pagination } from "@twocontinents/dashboard/shared";

import { AddCouponForm } from "./add-coupon-form";
import { CouponsTable } from "./coupons-table/coupons-table";
import { useAttractionCoupons } from "./use-attraction-coupons";

export const AttractionCoupons = () => {
  const {
    arePaginatedCouponsLoading,
    coupons,
    handleSizeSelect,
    page,
    pagination,
    size,
    setShowForm,
    showForm,
  } = useAttractionCoupons();

  const showPagination = !arePaginatedCouponsLoading && pagination;
  const showCouponsTable = pagination && !arePaginatedCouponsLoading;

  return (
    <Layout
      header={{
        title: "Coupons management",
        description: `Manage your coupons here`,
      }}
    >
      {!showForm && (
        <Button
          onClick={() => setShowForm(true)}
          className="w-fit self-end mt-2"
        >
          Add coupon
        </Button>
      )}
      {showForm && <AddCouponForm setShowForm={setShowForm} />}
      {arePaginatedCouponsLoading && <div>Loading...</div>}
      {showCouponsTable && <CouponsTable coupons={coupons} />}
      {showPagination && (
        <Pagination
          currentPage={page}
          totalPages={pagination.totalPages}
          setSize={handleSizeSelect}
          size={size}
          sizeLabel="Coupons per page"
        />
      )}
    </Layout>
  );
};
