import { useDebounceValue } from "usehooks-ts";

import { useGetInvoices } from "../data-access";
import { useInvoicesFiltersQueryParams } from "../hooks";

export const useInvoicesTab = () => {
  const { query } = useInvoicesFiltersQueryParams();

  const [debouncedAgencyName] = useDebounceValue(query?.travelAgencyName, 300);

  const { paginatedInvoices, areInvoicesLoading } = useGetInvoices({
    ...query,
    travelAgencyName: debouncedAgencyName,
  });

  const notFound =
    (paginatedInvoices?.invoices?.length ?? 0) === 0 && !areInvoicesLoading;

  return {
    areInvoicesLoading,
    paginatedInvoices,
    notFound,
  };
};
