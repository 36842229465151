import { AttractionExtraOption } from "@twocontinents/dashboard/attractions/shared";
import { Select } from "@twocontinents/dashboard/shared";

interface ExtraOptionSelectProps {
  value?: string;
  extraOptions: AttractionExtraOption[];
  onValueChange: (id?: number) => void;
  defaultOpen?: boolean;
}

export const ExtraOptionSelect = ({
  onValueChange,
  extraOptions,
  defaultOpen,
  value,
}: ExtraOptionSelectProps) => {
  return (
    <Select
      onValueChange={(value) => onValueChange(Number.parseInt(value))}
      defaultOpen={defaultOpen}
      value={value}
    >
      <Select.Trigger className="max-w-md h-max min-h-10">
        <Select.Value placeholder="Select extra option" />
      </Select.Trigger>
      <Select.Content>
        {extraOptions.map((extraOption) => (
          <Select.Item value={extraOption.id.toString()} key={extraOption.id}>
            {extraOption.name}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
