import { useMutation } from "@tanstack/react-query";
import { toast } from "@twocontinents/dashboard/shared";
import { useAuth } from "@twocontinents/dashboard/shared/auth";
import { components, env, getFormData } from "@twocontinents/shared";

import { useInvalidateGetTravelAgency } from "./get-travel-agency";

type UpdateTravelAgency = components["schemas"]["UpdateTravelAgencyRequest"];

const updateTravelAgency = async (
  id: number,
  token: string,
  body: UpdateTravelAgency,
) => {
  const formData = getFormData(body, []);

  return await fetch(
    `${env.API_URL}/api/v1/private/partners/travel-agencies/${id}`,
    {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((res) => {
    if (!res.ok) {
      return res.json().then((err) => {
        throw err;
      });
    }
    return res;
  });
};

export const useUpdateTravelAgency = (id: number) => {
  const { invalidate } = useInvalidateGetTravelAgency();

  const { token } = useAuth();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: UpdateTravelAgency) =>
      updateTravelAgency(id, token ?? "", request),
    onSuccess: async () => {
      toast.success("Travel agency updated successfully");
      await invalidate(id);
    },
    onError: () => {
      toast.error("Failed to update travel agency");
    },
  });

  return { updateTravelAgency: mutate, isPending };
};
