import { useOpened } from "@twocontinents/dashboard/shared";
import { useRef, useState } from "react";

import { useAssignTicketToReservation } from "../../../data-access";
import { Reservation } from "../../../types";

export const useAssignTicketToReservationDialog = (
  orderId: number,
  reservation: Reservation,
) => {
  const { opened, open, close, toggle } = useOpened(false);

  const { assignTicket, isPending } = useAssignTicketToReservation(orderId);
  const [ticket, setTicket] = useState<File | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = () => {
    const files = fileInputRef.current?.files;
    if (!files) return;

    setTicket(files[0]);
  };

  const handleAssignTicket = () => {
    if (!ticket) return;

    assignTicket(
      { reservationId: reservation.id, ticket },
      {
        onSuccess: () => {
          close();
          setTicket(null);
        },
      },
    );
  };

  return {
    opened,
    open,
    close,
    toggle,
    isPending,
    ticket,
    fileInputRef,
    handleFileInputChange,
    handleAssignTicket,
  };
};
