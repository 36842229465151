import { AttractionGroup } from "@twocontinents/dashboard/shared";
import { useEffect, useMemo } from "react";

import {
  useGetAttractionGroupAvailability,
  useGetAttractionGroupVariantsActivationLogs,
} from "../data-access";
import { AttractionGroupEntity, AttractionGroupEntityList } from "../types";

interface UseAttractionGroups {
  attractionGroups: AttractionGroup[];
  groupId: number;
  onGroupChange?: (attractionGroup: AttractionGroupEntity) => void;
}

export const useAttractionGroups = ({
  attractionGroups,
  groupId,
  onGroupChange,
}: UseAttractionGroups) => {
  const { availability } = useGetAttractionGroupAvailability(groupId);
  const { activationLogs } =
    useGetAttractionGroupVariantsActivationLogs(groupId);

  const selectedGroup = useMemo(() => {
    const group = new AttractionGroupEntityList(attractionGroups).findById(
      groupId,
    );

    if (group && availability) {
      group.availabilities = availability.availabilities;
      group.extraOptionAvailabilities = availability.extraOptionAvailabilities;
      group.activationLogs = activationLogs;
      group.attractionVariants = group.attractionVariants.toSorted(
        (a, b) => a.id - b.id,
      );
    }

    return group;
  }, [groupId, attractionGroups, availability, activationLogs]);

  useEffect(() => {
    if (selectedGroup && onGroupChange) {
      onGroupChange(selectedGroup);
    }
    // Disable for correct app behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  return {
    selectedGroup,
  };
};
