import {
  AttractionGroup,
  AttractionGroupSelect,
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { useDefaultSlotsAvailableSettings } from "./use-default-slots-available-settings";

interface DefaultSlotsAvailableSettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const DefaultSlotsAvailableSettings = ({
  attractionId,
  attractionGroups,
}: DefaultSlotsAvailableSettingsProps) => {
  const { form, onSubmit, isPending } = useDefaultSlotsAvailableSettings(
    attractionId,
    attractionGroups,
  );

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Default slots available settings</SettingCard.Title>
        <SettingCard.Description>
          Change group default slots available
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="groupId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Group</FormItem.Label>
                    <FormItem.Control>
                      <AttractionGroupSelect
                        attractionGroups={attractionGroups}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="defaultSlotsAvailable"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Default slots available</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} type={"number"} className="max-w-md" />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change default slots available in form's
              calendar
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
