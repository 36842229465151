import { Tour } from "@twocontinents/dashboard/attractions/shared";
import { useBoolean } from "usehooks-ts";

import { useSendTourMessage } from "../../../data-access";

export const useSendMessageDialog = (tour: Tour) => {
  const openAlertDialog = useBoolean(false);

  const { sendTourMessage, isPending } = useSendTourMessage(tour.id);

  const onSubmit = () => {
    if (!tour.message) {
      return;
    }
    sendTourMessage(
      { messageId: tour.message.id },
      {
        onSettled() {
          openAlertDialog.setFalse();
        },
      },
    );
  };

  return {
    openAlertDialog,
    isPending,
    onSubmit,
  };
};
