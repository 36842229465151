import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetReviews } from "./get-reviews";

type ModerateReviewBody =
  components["schemas"]["AttractionReservationReviewModerateRequest"];

interface ModerateReviewProps {
  reviewId: number;
  body: ModerateReviewBody;
}

export const useModerateReview = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetReviews();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ reviewId, body }: ModerateReviewProps) => {
      return apiClient.PATCH(
        "/private/orders/attraction/reservations/reviews/{id}/moderate",
        {
          params: {
            path: {
              id: reviewId,
            },
          },
          body,
        },
      );
    },
    onSuccess: async () => {
      toast.success("Review has been moderated.");
      await invalidate();
    },
    onError: () => {
      toast.error("An error occurred while moderating the review.");
    },
  });

  return {
    moderateReview: mutate,
    isPending,
  };
};
