import { zodResolver } from "@hookform/resolvers/zod";
import { useFinancialLogsQueryParams } from "@twocontinents/dashboard/financial-log/shared";
import {
  FinancialLogCategory,
  NOT_SELECTED_ID,
  useOpened,
} from "@twocontinents/dashboard/shared";
import { ApiLocale, CurrencyCode, DateFormatter } from "@twocontinents/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  useCreateFinancialLog,
  useGetOrderReservations,
} from "../../data-access";

const CreateFinancialLogModalSchema = z
  .object({
    value: z.coerce.number(),
    cause: z.string().min(3),
    settleDate: z.coerce.date(),
    currency: z.nativeEnum(CurrencyCode),
    category: z.nativeEnum(FinancialLogCategory),
    orderId: z.coerce.number().optional(),
    reservationId: z.coerce.number().optional(),
    notes: z.string().optional(),
    locale: z.nativeEnum(ApiLocale).optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.reservationId && !data.locale) {
      //error - locale is required
      ctx.addIssue({
        path: ["locale"],
        code: "custom",
        message: "Locale or Order is required",
      });
    }
  });

type CreateFinancialLogModalSchema = z.infer<
  typeof CreateFinancialLogModalSchema
>;

export const useCreateManualFinancialLogModal = () => {
  const { query, setQuery } = useFinancialLogsQueryParams();
  const reservationId = query.reservationIdFormValue;
  const defaultOrderId = query.orderIdFormValue;

  const { opened, open, close } = useOpened(
    Boolean(reservationId && defaultOrderId),
  );

  const { createFinancialLog, isPending } = useCreateFinancialLog();

  const form = useForm<CreateFinancialLogModalSchema>({
    resolver: zodResolver(CreateFinancialLogModalSchema),
    mode: "onBlur",
  });

  useEffect(() => {
    form.setValue(
      "reservationId",
      reservationId ? Number(reservationId) : undefined,
    );
    form.setValue(
      "orderId",
      defaultOrderId ? Number(defaultOrderId) : undefined,
    );
  }, [reservationId, defaultOrderId, form]);

  const handleClose = () => {
    setQuery({
      orderIdFormValue: undefined,
      reservationIdFormValue: undefined,
    });
    close();
  };

  const orderId = form.watch("orderId");
  const { reservations } = useGetOrderReservations(orderId ?? NOT_SELECTED_ID);

  const wrongOrderId = Boolean(
    (orderId || orderId === 0) && reservations.length === 0,
  );

  useEffect(() => {
    if (reservations.length > 0) {
      form.setValue("reservationId", reservations[0].id);
    }
  }, [reservations]);

  const onSubmit = form.handleSubmit((data) => {
    const { orderId, locale, ...financialLogRequest } = data;

    createFinancialLog({
      ...financialLogRequest,
      settleDate: DateFormatter.formatToYYYYMMDD(data.settleDate),
      locales: locale ? [locale] : undefined,
    });

    form.reset({
      value: undefined,
      cause: undefined,
      settleDate: undefined,
      notes: undefined,
    });
    handleClose();
  });

  useEffect(() => {
    if (orderId === undefined) {
      form.setValue("reservationId", undefined);
    }
  }, [orderId, form]);

  return {
    form,
    onSubmit,
    opened,
    open,
    close: handleClose,
    isPending,
    reservations,
    orderId,
    wrongOrderId,
  };
};
