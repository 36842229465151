import {
  PercentageConverter,
  Table,
  buttonVariants,
  routes,
} from "@twocontinents/dashboard/shared";
import React from "react";
import { Link } from "react-router-dom";

import { AttractionPartnership } from "../types";

interface AttractionPartnershipsTableProps {
  attractionPartnerships: AttractionPartnership[];
  isLoading: boolean;
}

export const AttractionPartnershipsTable = ({
  attractionPartnerships,
  isLoading,
}: AttractionPartnershipsTableProps) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Head className="py-5">ID</Table.Head>
          <Table.Head className="py-5">Description</Table.Head>
          <Table.Head className="py-5">Commission (%)</Table.Head>
          <Table.Head className="py-5">Locale</Table.Head>
          <Table.Head className="py-5">Actions</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body className="w-full relative" isLoading={isLoading} columns={5}>
        {attractionPartnerships.map((attractionPartnership) => (
          <Table.Row
            key={`${attractionPartnership.id}-${attractionPartnership.locale}`}
          >
            <Table.Cell>
              <Link
                to={routes.attractions.index.show.url(attractionPartnership.id)}
              >
                {attractionPartnership.id}
              </Link>
            </Table.Cell>
            <Table.Cell>{attractionPartnership.description}</Table.Cell>
            <Table.Cell>
              {PercentageConverter.to(attractionPartnership.commission)}
            </Table.Cell>
            <Table.Cell>{attractionPartnership.locale}</Table.Cell>
            <Table.Cell>
              <Link
                className={buttonVariants({ variant: "secondary" })}
                to={routes.attractions.index.show.url(attractionPartnership.id)}
              >
                Details
              </Link>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
