import {
  TourFormatter,
  TourStatus,
  useGetTour,
} from "@twocontinents/dashboard/attractions/shared";
import { Layout, LoaderCard } from "@twocontinents/dashboard/shared";
import { useParams } from "react-router-dom";

import { CancelTourAlertDialog } from "./cancel-tour-alert-dialog";
import { ChangeTourTermDialog } from "./change-tour-term-dialog";
import { ChangeTourVariantDialog } from "./change-tour-variant-dialog";
import { CreateTourFinancialLogEntryDialog } from "./create-tour-financial-log-entry-dialog/create-tour-financial-log-entry";
import { SetTourAsDoneAlertDialog } from "./set-tour-as-done-alert-dialog";
import { TourMessageDialog } from "./tour-message-dialog";
import { TourParticipantsManager } from "./tour-participants-manager";
import { UpdateTourDialog } from "./update-tour-dialog";

export const TourManagement = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, tour } = useGetTour(Number(id));

  const shouldShowTour = !isLoading && !!tour;

  return (
    <Layout
      header={{
        title: "Tour Management",
        description: `Manage tour #${tour?.id} ${tour && TourFormatter.detailedWithFullAttractionName(tour)} / ${tour?.date} ${tour?.time ?? ""}`,
      }}
    >
      {isLoading && <LoaderCard />}

      {tour?.status === TourStatus.CANCELLED && (
        <p className="text-2xl font-bold text-center text-red-500">
          Tour is cancelled
        </p>
      )}

      {shouldShowTour && (
        <>
          {tour.status === TourStatus.DONE && (
            <p className="text-2xl font-bold text-center text-green-500">
              Tour is realized
            </p>
          )}
          {tour.status !== TourStatus.CANCELLED && (
            <div className="w-full">
              <div className="flex gap-2 ">
                <CreateTourFinancialLogEntryDialog tour={tour} />
                <TourMessageDialog tour={tour} />
              </div>
              <div className="flex gap-2 ml-auto flex-wrap mt-4 ">
                <CancelTourAlertDialog tourId={tour.id} />
                <UpdateTourDialog tour={tour} />
                {tour.status !== TourStatus.DONE && (
                  <SetTourAsDoneAlertDialog tourId={tour.id} />
                )}
                <ChangeTourTermDialog tour={tour} />
                <ChangeTourVariantDialog tour={tour} />
              </div>
            </div>
          )}

          <TourParticipantsManager tour={tour} />
        </>
      )}
    </Layout>
  );
};
