import {
  Button,
  Input,
  Tooltip,
  buttonVariants,
} from "@twocontinents/dashboard/shared";
import { useRef } from "react";

interface CsvTicketFormProps {
  csvFile: File | null;
  setCsvFile: (file: File | null) => void;
}

export const CsvTicketForm = ({ csvFile, setCsvFile }: CsvTicketFormProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = () => {
    const files = fileInputRef.current?.files;
    if (!files) return;

    setCsvFile(files[0]);
  };

  return (
    <>
      <Input
        accept=".csv"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        ref={fileInputRef}
        type="file"
        id="csv-ticket-file"
      />
      <div className="flex gap-2">
        <label htmlFor="csv-ticket-file">
          <Tooltip.Provider>
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Button
                  type="button"
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                >
                  Upload CSV
                </Button>
              </Tooltip.Trigger>
              <Tooltip.Content>
                No header, codes in first column, one code per row
              </Tooltip.Content>
            </Tooltip>
          </Tooltip.Provider>
        </label>
        {csvFile && (
          <a
            className={buttonVariants({
              variant: "link",
              className: "text-slate-100 w-fit",
            })}
            href={URL.createObjectURL(csvFile)}
            target={"_blank"}
          >
            Uploaded file: {csvFile.name}
          </a>
        )}
      </div>
    </>
  );
};
