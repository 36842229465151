import { Tour } from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  Input,
  Label,
  Textarea,
  buttonVariants,
} from "@twocontinents/dashboard/shared";
import { FaWhatsapp } from "react-icons/fa";

import { CancelMessageDialog } from "./cancel-message-dialog";
import { MessageHeaderDescription } from "./message-header-description";
import { OverrideTourMessageDialog } from "./override-tour-message-dialog";
import { SendMessageDialog } from "./send-message-dialog";
import { useTourMessageDialog } from "./use-tour-message-dialog";

interface TourMessageAlertDialogProps {
  tour: Tour;
}

export const TourMessageDialog = ({ tour }: TourMessageAlertDialogProps) => {
  const { message } = tour;

  const { form, handleFormSubmit, openAlertDialog, isPending } =
    useTourMessageDialog(tour);

  if (!message) {
    return null;
  }

  const isMessageEditable = !message.sent && !message.cancelled;

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button variant="outline">
          <FaWhatsapp size={30} />
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Edit message additions</AlertDialog.Title>
          <AlertDialog.Description>
            <MessageHeaderDescription
              message={message}
              tourStatus={tour.status}
            />
          </AlertDialog.Description>
        </AlertDialog.Header>
        <Form {...form}>
          <form
            className="flex flex-col gap-6 mt-4"
            onSubmit={handleFormSubmit}
          >
            {isMessageEditable && (
              <div className="flex gap-4">
                <OverrideTourMessageDialog tour={tour} />
                <CancelMessageDialog tour={tour} />
              </div>
            )}
            <Form.Fields>
              <Label>Message</Label>
              <Textarea
                disabled
                rows={10}
                className=" text-slate-200"
                name="message"
                value={message.overriddenMessage || message.stringMessage}
              ></Textarea>
              {isMessageEditable && (
                <>
                  <Form.Field
                    control={form.control}
                    name="pickupLocation"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>Pickup Location</FormItem.Label>
                        </FormItem.Row>

                        <FormItem.Row>
                          <FormItem.Control>
                            <Input {...field} className="max-w-none" />
                          </FormItem.Control>
                        </FormItem.Row>

                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="contact"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>Contakt</FormItem.Label>
                        </FormItem.Row>
                        <FormItem.Row>
                          <FormItem.Control>
                            <Input {...field} className="max-w-none" />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="additions"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>Additions</FormItem.Label>
                        </FormItem.Row>
                        <FormItem.Row>
                          <FormItem.Control>
                            <Textarea
                              {...field}
                              className="max-w-none"
                              rows={10}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                </>
              )}
            </Form.Fields>

            <AlertDialog.Footer className="flex-row flex !justify-between">
              {isMessageEditable && <SendMessageDialog tour={tour} />}
              <div className="flex gap-4 ml-auto">
                <AlertDialog.Cancel
                  className={buttonVariants({ variant: "destructive" })}
                >
                  Cancel
                </AlertDialog.Cancel>
                {isMessageEditable && (
                  <Button
                    isLoading={isPending}
                    type="submit"
                    className="bg-yellow-400 hover:bg-yellow-500 !text-slate-600 !dark:text-slate-600"
                  >
                    Edit
                  </Button>
                )}
              </div>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
