import {
  DetailRowProps,
  FinancialLogType,
  PropsWithClassName,
  cn,
  routes,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode, DateFormatter } from "@twocontinents/shared";
import { Link } from "react-router-dom";

import { FinancialLog } from "../types";

export const useFinancialLogDetails = (financialLog: FinancialLog) => {
  const details: (DetailRowProps & PropsWithClassName)[] = [
    {
      label: "Id",
      content: financialLog.id.toString(),
    },
    {
      label: "Source",
      content: financialLog.source,
    },
    {
      label: "Cause",
      content:
        financialLog.childFinancialLogs.at(-1)?.cause ?? financialLog.cause,
    },
    {
      label: "Value",
      content: `${financialLog.finalValue} ${financialLog.currency}`,
    },
    {
      label: "Value in PLN",
      content: `${financialLog.finalPlnValue} ${CurrencyCode.PLN}`,
      className: cn({
        "text-green-500": financialLog.type === FinancialLogType.INCOME,
        "text-red-500": financialLog.type === FinancialLogType.COST,
        "!text-slate-200": financialLog.finalPlnValue === 0,
      }),
    },
    {
      label: "Category",
      content: financialLog.category ?? "N/A",
    },
    {
      label: "Type",
      content: financialLog.type,
    },
    {
      label: "Settled",
      content: financialLog.settled ? "Yes" : "No",
    },
    {
      label: "Settle date",
      content: DateFormatter.formatToDDMMYYYY(financialLog.settleDate),
    },
    {
      label: "Created at",
      content: DateFormatter.formatToDDMMYYYYHHmm(financialLog.createdAt),
    },
    {
      label: "Updated at",
      content: DateFormatter.formatToDDMMYYYYHHmm(financialLog.updatedAt),
    },
    {
      label: "Order id",
      content: (
        <>
          {financialLog.referredOrderId ? (
            <Link
              className={"font-semibold"}
              to={routes.attractions.orders.show.url(
                financialLog.referredOrderId,
              )}
            >
              Order #{financialLog.referredOrderId}
            </Link>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      label: "Reservation",
      content:
        financialLog.attractionName && financialLog.attractionVariantName ? (
          <Link
            className={"font-semibold"}
            to={routes.attractions.orders.show.url(
              financialLog.referredOrderId ?? -1,
            )}
          >
            {financialLog.attractionName} / {financialLog.attractionVariantName}
          </Link>
        ) : (
          "N/A"
        ),
    },
    {
      label: "Tour ID",
      content: financialLog.referredTourId ? (
        <Link
          className={"font-semibold"}
          to={routes.attractions.tours.show.url(financialLog.referredTourId)}
        >
          Tour #{financialLog.referredTourId}
        </Link>
      ) : (
        "N/A"
      ),
    },
    ...(financialLog.notes
      ? [
          {
            label: "Notes",
            content:
              financialLog.childFinancialLogs.at(-1)?.notes ??
              financialLog.notes,
          },
        ]
      : []),
  ];

  return {
    details,
    getFinancialLogLabel,
    getFinancialLogContent,
    getFinancialLogClassName,
  };
};

const getFinancialLogLabel = (
  sequence: number,
  financialLog: FinancialLog,
): JSX.Element => {
  return (
    <div className="flex flex-row gap-4">
      {sequence}. {financialLog.cause}
      {financialLog.notes && (
        <h5 className="text-gray-500">{financialLog.notes}</h5>
      )}
    </div>
  );
};

const getFinancialLogContent = (financialLog: FinancialLog) => {
  return `${financialLog.plnValue} ${CurrencyCode.PLN}`;
};

const getFinancialLogClassName = (financialLog: FinancialLog) => {
  return cn({
    "text-green-500": financialLog.plnValue > 0,
    "text-red-500": financialLog.plnValue < 0,
    "text-slate-200": financialLog.plnValue === 0,
  });
};
