import { Tour } from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  Textarea,
  buttonVariants,
} from "@twocontinents/dashboard/shared";

import { useOverrideTourMessageDialog } from "./use-override-tour-message-dialog";

interface OverrideTourMessageDialogProps {
  tour: Tour;
}

export const OverrideTourMessageDialog = ({
  tour,
}: OverrideTourMessageDialogProps) => {
  const { openAlertDialog, isPending, form, handleButtonClick } =
    useOverrideTourMessageDialog(tour);

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button className="w-fit" variant="outline">
          Override message
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title> Override entire message</AlertDialog.Title>
        </AlertDialog.Header>
        <Form {...form}>
          <form className="flex flex-col gap-6 mt-4">
            <Form.Field
              control={form.control}
              name="overriddenMessage"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Description</FormItem.Label>
                  </FormItem.Row>
                  <FormItem.Row>
                    <FormItem.Control>
                      <Textarea {...field} rows={10} className="max-w-none" />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <AlertDialog.Footer className="flex-row flex !justify-between">
              <div className="flex gap-4 ml-auto">
                <AlertDialog.Cancel
                  className={buttonVariants({ variant: "destructive" })}
                >
                  Cancel
                </AlertDialog.Cancel>
                <Button
                  onClick={handleButtonClick}
                  isLoading={isPending}
                  type="button"
                  variant="positive"
                >
                  Edit
                </Button>
              </div>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
