import { ExtraOption } from "@twocontinents/dashboard/attractions/shared";
import {
  CurrencyCodeSelect,
  Form,
  FormItem,
  Input,
  Layout,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode } from "@twocontinents/shared";

import { ExtraOptionSelect } from "../variants-settings/extra-option-select";
import { useExtraOptionsSettings } from "./use-extra-options-settings";

interface ExtraOptionsSettingsProps {
  extraOptions: ExtraOption[];
  attractionId: number;
}

export const ExtraOptionsSettings = ({
  attractionId,
  extraOptions,
}: ExtraOptionsSettingsProps) => {
  const { form, handleFormSubmit, isPending, isButtonDisabled } =
    useExtraOptionsSettings(extraOptions, attractionId);

  return (
    <Layout.Content>
      <SettingCard>
        <SettingCard.Header>
          <SettingCard.Title>Extra options</SettingCard.Title>
          <SettingCard.Description>
            Manage extra options
          </SettingCard.Description>
        </SettingCard.Header>
        <Form {...form}>
          <form onSubmit={handleFormSubmit}>
            <Form.Fields>
              <Form.Field
                control={form.control}
                name="extraOptionId"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Extra option</FormItem.Label>
                      <FormItem.Control>
                        <ExtraOptionSelect
                          value={field?.value?.toString()}
                          extraOptions={extraOptions}
                          onValueChange={field.onChange}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Description</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} className="max-w-md" />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="price"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Price</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} className="max-w-md" type="number" />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="currency"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Currency</FormItem.Label>
                      <FormItem.Control>
                        <CurrencyCodeSelect
                          value={field.value}
                          currencies={Object.values(CurrencyCode)}
                          onValueChange={field.onChange}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="providerPrice"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Provider Price</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} className="max-w-md" type="number" />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="providerCurrency"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Provider Currency</FormItem.Label>
                      <FormItem.Control>
                        <CurrencyCodeSelect
                          value={field.value}
                          currencies={Object.values(CurrencyCode)}
                          onValueChange={field.onChange}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            </Form.Fields>
            <SettingCard.Footer>
              <SettingCard.Description>
                Change extra option's settings
              </SettingCard.Description>
              <SettingCard.Button
                isLoading={isPending}
                disabled={isButtonDisabled}
              >
                Submit
              </SettingCard.Button>
            </SettingCard.Footer>
          </form>
        </Form>
      </SettingCard>
    </Layout.Content>
  );
};
