import { ALL_ITEMS_ID } from "../constants";
import { AttractionGroup } from "../types";
import { cn } from "../utils";
import { Select } from "./shadcn";

interface AttractionGroupSelectProps {
  attractionGroups: AttractionGroup[];
  onValueChange: (id: number) => void;
  showAllGroupsOption?: boolean;
  className?: string;
  defaultOpen?: boolean;
}

export const AttractionGroupSelect = ({
  onValueChange,
  attractionGroups,
  showAllGroupsOption = false,
  className,
  defaultOpen,
}: AttractionGroupSelectProps) => {
  return (
    <Select
      defaultValue={showAllGroupsOption ? ALL_ITEMS_ID.toString() : undefined}
      onValueChange={(value) => onValueChange(Number.parseInt(value))}
      defaultOpen={defaultOpen}
    >
      <Select.Trigger className={cn("max-w-md h-max min-h-10", className)}>
        <Select.Value placeholder="Choose group" />
      </Select.Trigger>
      <Select.Content>
        {showAllGroupsOption && (
          <Select.Item value={ALL_ITEMS_ID.toString()}>
            Wszystkie grupy
          </Select.Item>
        )}
        {attractionGroups.map((group) => (
          <Select.Item value={group.id.toString()} key={group.id}>
            {group.description}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
