import {
  DataTable,
  Input,
  Layout,
  buttonVariants,
  routes,
} from "@twocontinents/dashboard/shared";
import {
  TravelAgencyTab,
  TravelAgencyTabs,
  useGetTravelAgencies,
} from "@twocontinents/dashboard/travel-agencies/shared";
import React from "react";
import { Link } from "react-router-dom";

import { useTravelAgencyFiltersQueryParams } from "../hooks";
import { columns } from "./columns";

export const TravelAgencyIndexPage = () => {
  const { query, setName } = useTravelAgencyFiltersQueryParams();
  const { data } = useGetTravelAgencies(query);
  const travelAgencies = data?.travelAgencies ?? [];
  const totalPages = data?.pagination.totalPages ?? 0;

  return (
    <Layout
      header={{
        title: "Travel agencies",
        description: "Browse travel agencies",
      }}
    >
      <Layout.Content>
        <div className="flex justify-between">
          <TravelAgencyTabs activeTab={TravelAgencyTab.TRAVEL_AGENCY} />
          <Link
            to={routes.travelAgencies.create}
            className={buttonVariants({ variant: "secondary" })}
          >
            Create
          </Link>
        </div>

        <DataTable
          columns={columns}
          data={travelAgencies}
          totalPages={totalPages}
          header={
            <Input
              placeholder={"Search by name"}
              value={query.name}
              onChange={(e) => setName(e.target.value)}
              className={"max-w-sm"}
            />
          }
        />
      </Layout.Content>
    </Layout>
  );
};
