import { Reservation } from "../../types";
import { AssignTicketToReservationDialog } from "./assign-ticket-to-reservation-dialog";

interface ReservationTicketSectionProps {
  reservation: Reservation;
  orderId: number;
}

export const ReservationTicketSection = ({
  reservation,
  orderId,
}: ReservationTicketSectionProps) => {
  return (
    <>
      {reservation.withTicket && !reservation.ticketUrl && (
        <AssignTicketToReservationDialog
          reservation={reservation}
          orderId={orderId}
        />
      )}
      {reservation.withTicket && reservation.ticketUrl && (
        <div className="flex gap-8 items-center ml-auto">
          <a href={reservation.ticketUrl} className="underline">
            Current ticket
          </a>
          <AssignTicketToReservationDialog
            reservation={reservation}
            orderId={orderId}
          />
        </div>
      )}
    </>
  );
};
