import { useMutation } from "@tanstack/react-query";
import {
  toast,
  useInvalidateGetAttraction,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type Body = components["schemas"]["UpdateAttractionVariantMetadataRequest"];

interface Props {
  attractionId: number;
  variantId: number;
  body: Body;
}

export const useUpdateAttractionVariantMetadata = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetAttraction();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ variantId, body }: Props) =>
      apiClient
        .PATCH(`/private/attractions/variants/{id}/metadata`, {
          params: {
            path: { id: variantId },
          },
          body,
        })
        .then((response) => response.data),

    onSuccess: async (_, { attractionId }) => {
      toast.success("Variant's metadata has been updated successfully.");
      await invalidate(attractionId);
    },
    onError: () => {
      toast.error("Variant's metadata could not be updated");
    },
  });

  return { updateVariantMetadata: mutate, isPending };
};
