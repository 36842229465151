import { ReactNode } from "react";

import { DropdownMenu } from "../shadcn/dropdown-menu";
import { NavbarItem } from "./navbar-item";

interface NavbarLabel {
  label: ReactNode;
  to: string;
  icon: ReactNode;
}
export interface NavbarItemWithDropdownProps {
  title: ReactNode;
  icon: ReactNode;
  labels?: NavbarLabel[];
}

export const NavbarDropdownItem = ({
  title,
  icon,
  labels,
}: NavbarItemWithDropdownProps) => {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger
        className="rounded-md hover:bg-slate-800 border-none cursor-pointer"
        asChild
      >
        <span className="flex items-center px-3 py-2 text-sm font-medium text-slate-100">
          <i className=" mr-2">{icon}</i>
          {title}
        </span>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="start" side="bottom">
        <DropdownMenu.Label>{title}</DropdownMenu.Label>
        <DropdownMenu.Separator />
        <DropdownMenu.Group>
          {labels &&
            labels.map((label, index) => (
              <DropdownMenu.Item key={index}>
                <NavbarItem
                  icon={label.icon}
                  to={label.to}
                  label={label.label}
                />
              </DropdownMenu.Item>
            ))}
        </DropdownMenu.Group>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};
