import { zodResolver } from "@hookform/resolvers/zod";
import {
  AttractionOrder,
  OrderStatus,
} from "@twocontinents/dashboard/attractions/shared";
import { DateFormatter } from "@twocontinents/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateOrder } from "../../data-access";

const OrderStatusFormSchema = z.object({
  status: z.nativeEnum(OrderStatus),
  paymentConfirmed: z.boolean(),
});

export type OrderStatusFormValues = z.infer<typeof OrderStatusFormSchema>;

export const useOrderDetails = (order: AttractionOrder) => {
  const { isPending, updateOrder } = useUpdateOrder(order.id);

  const formattedPurchasedTime = DateFormatter.formatToDDMMYYYYHHmm(
    order.timePurchased,
  );

  const isMultiReservation = order.reservations.length > 1;

  const form = useForm<OrderStatusFormValues>({
    defaultValues: {
      status: order.status,
      paymentConfirmed: order.paymentConfirmed ?? false,
    },
    resolver: zodResolver(OrderStatusFormSchema),
  });

  const onSubmit = form.handleSubmit((values) => {
    updateOrder(values);
  });

  return {
    form,
    formattedPurchasedTime,
    onSubmit,
    isPending,
    isMultiReservation,
  };
};
