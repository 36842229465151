import { useEffect, useMemo } from "react";

import { AttractionGroup } from "../types";

interface UseAttractionGroups {
  attractionGroups: AttractionGroup[];
  groupId: number;
  onGroupChange?: (attractionGroup: AttractionGroup) => void;
}

export const useAttractionGroups = ({
  attractionGroups,
  groupId,
  onGroupChange,
}: UseAttractionGroups) => {
  const selectedGroup = useMemo(() => {
    return attractionGroups.find((group) => group.id === groupId);
  }, [groupId, attractionGroups]);

  useEffect(() => {
    if (selectedGroup && onGroupChange) {
      onGroupChange(selectedGroup);
    }
    // Disable for correct app behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  return {
    selectedGroup,
  };
};
