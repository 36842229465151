import { zodResolver } from "@hookform/resolvers/zod";
import { useOpened } from "@twocontinents/dashboard/shared";
import { AgentType } from "@twocontinents/dashboard/travel-agencies/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateAgent } from "../../data-access";

const CreateAgentFormSchema = z.object({
  name: z.string().min(3),
  email: z.string().email(),
  login: z.string().min(3),
  password: z.string().min(3),
  type: z.nativeEnum(AgentType),
});

export type CreateAgentFormSchema = z.infer<typeof CreateAgentFormSchema>;

export const useCreateAgentModal = (travelAgencyId: number) => {
  const { opened, open, close } = useOpened();
  const { createAgent, isPending } = useCreateAgent();

  const form = useForm<CreateAgentFormSchema>({
    resolver: zodResolver(CreateAgentFormSchema),
    mode: "onBlur",
  });

  const onSubmit = form.handleSubmit((data) => {
    createAgent(
      {
        ...data,
        travelAgencyId,
      },
      {
        onSuccess: () => close(),
      },
    );
  });

  return {
    onSubmit,
    isPending,
    form,
    opened,
    open,
    close,
  };
};
