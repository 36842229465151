import { AttractionVariantSelectByGroups } from "@twocontinents/dashboard/attractions/shared";
import {
  AttractionGroup,
  AttractionVariantSelect,
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { MetadataFormFields } from "./metadata-form-fields";
import { useMetadataSettings } from "./use-metadata-settings";

interface Props {
  attractionGroups: AttractionGroup[];
  attractionId: number;
}

export const MetadataSettings = ({ attractionGroups, attractionId }: Props) => {
  const {
    raynaVariants,
    onSubmit,
    form,
    isPending,
    hasSelectedVariantRaynaTicketFromRayna,
    hasSelectedVariantRaynaAvailabilityFromRayna,
  } = useMetadataSettings(attractionGroups, attractionId);

  if (raynaVariants.length === 0) {
    return null;
  }

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Rayna metadata settings</SettingCard.Title>
        <SettingCard.Description>
          Change variant's metadata
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="variantId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Variant</FormItem.Label>
                    <FormItem.Control>
                      <AttractionVariantSelect
                        attractionVariants={raynaVariants}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            {hasSelectedVariantRaynaTicketFromRayna && (
              <div className="flex flex-col gap-4">
                <h3 className="text-lg font-semibold">Ticket metadata</h3>
                <MetadataFormFields type="ticketMetadata" />
              </div>
            )}
            {hasSelectedVariantRaynaAvailabilityFromRayna && (
              <div className="flex flex-col gap-4">
                <h3 className="text-lg font-semibold">Availability metadata</h3>
                <MetadataFormFields type="availabilityMetadata" />
              </div>
            )}
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change variant's metadata
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
