import { getCodes } from "iso-3166-1-alpha-2";

import { Locale } from "../types/locale";

export const getIsoCountries = (locale: string) => {
  const displayNames = new Intl.DisplayNames([locale], { type: "region" });
  const codes = getCodes();

  return codes
    .map((code) => ({
      code,
      name: displayNames.of(code) ?? "",
    }))
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });
};

export const getCountryNameByIsoCode = (isoCode: string) => {
  const displayNames = new Intl.DisplayNames([Locale.EN], { type: "region" });

  return displayNames.of(isoCode);
};
