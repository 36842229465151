import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type Body = components["schemas"]["ManualGeneratePaymentLinkRequest"];

export const useGeneratePaymentLink = () => {
  const apiClient = usePrivateApiClient();

  const { mutate, isPending, data, isError } = useMutation({
    mutationFn: (body: Body) =>
      apiClient
        .POST("/private/payment/generate-link", { body })
        .then((res) => res.data),
    onSuccess: () => {
      toast.success("Payment link generated successfully");
    },
    onError: () => {
      toast.error("Failed to generate payment link");
    },
  });

  return {
    isError,
    isPending,
    generatePaymentLink: mutate,
    paymentLink: data?.paymentUrl,
  };
};
