import { useCreateTravelAgency } from "../../data-access";

export const useCreateTravelAgencyForm = () => {
  const { createTravelAgency, isPending } = useCreateTravelAgency();

  return {
    createTravelAgency,
    isPending,
  };
};
