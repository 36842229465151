import { PriceFormatter } from "@twocontinents/shared";

import {
  AttractionOrdersStatistics,
  FinancialLogStatistics,
} from "../../../types";
import { StatisticCard } from "./statistic-card";
import { StatisticsFilter } from "./statistics-filter";

interface StatisticsProps {
  orderStatistics: AttractionOrdersStatistics | undefined;
  financialLogStatistics: FinancialLogStatistics | undefined;
}

export const StatisticsTab = ({
  orderStatistics,
  financialLogStatistics,
}: StatisticsProps) => {
  const ordersCount = orderStatistics?.ordersCount;
  const paidOrdersCount = orderStatistics?.paidOrdersCount;
  const customersCount = orderStatistics?.customersCount;

  const profit = financialLogStatistics?.profit;
  const costs = financialLogStatistics?.costs;
  const income = financialLogStatistics?.income;

  const profitClassName =
    profit && profit > 0 ? "text-green-500" : "text-red-500";

  return (
    <div className={"flex flex-col gap-4"}>
      <StatisticsFilter />

      <div className={"grid grid-cols-3 gap-6"}>
        <StatisticCard>
          <StatisticCard.Label>Orders</StatisticCard.Label>
          <StatisticCard.Value>{ordersCount}</StatisticCard.Value>
        </StatisticCard>
        <StatisticCard>
          <StatisticCard.Label>Paid orders</StatisticCard.Label>
          <StatisticCard.Value>{paidOrdersCount}</StatisticCard.Value>
        </StatisticCard>
        <StatisticCard>
          <StatisticCard.Label>Customers</StatisticCard.Label>
          <StatisticCard.Value>{customersCount}</StatisticCard.Value>
        </StatisticCard>

        <StatisticCard>
          <StatisticCard.Label className="text-green-500">
            Income
          </StatisticCard.Label>
          <StatisticCard.Value className="text-green-500">
            {income && PriceFormatter.full(income)} PLN
          </StatisticCard.Value>
        </StatisticCard>
        <StatisticCard>
          <StatisticCard.Label className="text-red-500">
            Costs
          </StatisticCard.Label>
          <StatisticCard.Value className="text-red-500">
            {costs && PriceFormatter.full(costs)} PLN
          </StatisticCard.Value>
        </StatisticCard>
        <StatisticCard>
          <StatisticCard.Label className={profitClassName}>
            Profit
          </StatisticCard.Label>
          <StatisticCard.Value className={profitClassName}>
            {profit && PriceFormatter.full(profit)} PLN
          </StatisticCard.Value>
        </StatisticCard>
      </div>
    </div>
  );
};
