import { cn } from "@twocontinents/dashboard/shared";
import { Button } from "react-day-picker";

import { CalendarDayProps, useCalendarDay } from "./use-calendar-day";

export const CalendarDay = (props: CalendarDayProps) => {
  const {
    dayRender,
    buttonRef,
    isAvailable,
    isNotAvailable,
    showAvailabilityLetters,
    isPolishGuideAvailable,
    isEnglishGuideAvailable,
    isPolishDriverAvailable,
  } = useCalendarDay(props);

  return (
    <Button
      {...dayRender.buttonProps}
      ref={buttonRef}
      className={cn(
        {
          "!bg-green-600": isAvailable,
          "bg-red-600": isNotAvailable,
          "!bg-blue-600": props.isMutated,
        },
        "calendar-font m-1 border-transparent outline-none focus-within:border-slate-100 border relative h-12 w-12 rounded-md flex justify-center items-center p-0 font-normal  aria-selected:opacity-100, hover:text-slate-50 hover:border-slate-600 hover:bg-slate-600",
      )}
    >
      {dayRender.buttonProps.children}
      {showAvailabilityLetters && (
        <>
          {isEnglishGuideAvailable && (
            <span
              className={
                "absolute bottom-[2px] right-0.5  text-orange-300 font-semibold text-xs"
              }
            >
              E
            </span>
          )}
          {isPolishGuideAvailable && (
            <span
              className={
                "absolute top-[-1px] right-0.5  text-orange-300 font-semibold text-xs"
              }
            >
              G
            </span>
          )}{" "}
          {isPolishDriverAvailable && (
            <span
              className={
                "absolute bottom-[2px] left-0.5  text-orange-300 font-semibold text-xs"
              }
            >
              D
            </span>
          )}
        </>
      )}
    </Button>
  );
};
