import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components, uniqueArray } from "@twocontinents/shared";

import { useInvalidateGetAttractionCrewAvailability } from "./get-attraction-crew-availability";
import { useInvalidateGetAttractionGroupAvailability } from "./get-attraction-group-availabilities";
import { useInvalidateAttractionGroupVariantsActivationLogs } from "./get-attraction-group-variants-activation-logs";
import { useInvalidateExtraOptionAvailabilities } from "./get-extra-options-availabilities";

type Body = components["schemas"]["UpdateAttractionGroupAvailabilityRequest"] &
  components["schemas"]["UpdateExtraOptionAvailabilityRequest"] &
  components["schemas"]["UpdateAttractionGroupVariantsActivationLogsRequest"];

interface Props {
  attractionId: number;
  groupId: number;
  body: Body;
}

// eslint-disable-next-line max-lines-per-function
export const useUpdateAttractionGroupAvailabilities = () => {
  const apiClient = usePrivateApiClient();
  const { invalidateGroupAvailability } =
    useInvalidateGetAttractionGroupAvailability();
  const { invalidateCrewAvailability } =
    useInvalidateGetAttractionCrewAvailability();
  const invalidateExtraOptionAvailabilities =
    useInvalidateExtraOptionAvailabilities();

  const invalidateActivationLogs =
    useInvalidateAttractionGroupVariantsActivationLogs();

  const { mutate: updateGroupAvailability, isPending } = useMutation({
    mutationFn: ({
      groupId,
      body: { availabilities, extraOptionAvailabilities, activationLogs },
    }: Props) =>
      Promise.all([
        apiClient.POST(`/private/attractions/groups/{id}/availability`, {
          params: {
            path: { id: groupId },
          },
          body: { availabilities },
        }),
        apiClient.POST(
          `/private/attractions/groups/{id}/extra-options/availability`,
          {
            params: {
              path: { id: groupId },
            },
            body: { extraOptionAvailabilities },
          },
        ),
        apiClient.POST("/private/attractions/groups/{id}/activation-logs", {
          params: {
            path: { id: groupId },
          },
          body: { activationLogs: activationLogs },
        }),
      ]).then((results) => {
        const errorResult = results.find((result) => result.error);
        if (errorResult && errorResult.error) {
          throw new Error(errorResult.error.message);
        }

        return results;
      }),

    onSuccess: async (_, { groupId, attractionId, body }) => {
      toast.success("Group availability has been updated successfully.");
      await invalidateGroupAvailability(groupId);
      await invalidateCrewAvailability(attractionId);
      await invalidateActivationLogs(groupId);
      const uniqueGroupIds = uniqueArray(
        body?.extraOptionAvailabilities?.map((a) => a.extraOptionId) ?? [],
      );
      await invalidateExtraOptionAvailabilities(uniqueGroupIds);
    },
    onError: () => {
      toast.error("Group availability could not be updated");
    },
  });

  return { updateGroupAvailability, isPending };
};
