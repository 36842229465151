import {
  AttractionCombobox,
  DatePicker,
  Input,
  Label,
  Select,
} from "@twocontinents/dashboard/shared";

import {
  ReviewVisibilityFilter,
  useReviewsFilterBar,
} from "./use-reviews-filter-bar";

export const ReviewsFilterBar = () => {
  const {
    attractionsOptions,
    selectedId,
    handleAttractionComboboxSelect,
    createdAfterDate,
    createdBeforeDate,
    customerEmail,
    handleCreatedAfterDateChange,
    handleCreatedBeforeDateChange,
    handleCustomerEmailChange,
    maxRating,
    minRating,
    handleMaxRatingChange,
    handleMinRatingChange,
    defaultVisibilityStatus,
    handleVisibilityStatusSelect,
  } = useReviewsFilterBar();

  return (
    <div className="flex flex-col mt-4">
      <div className="gap-5 flex  items-start border-slate-700 flex-wrap">
        <div className="flex gap-4 flex-wrap">
          <Input
            name="customerEmail"
            className="w-44"
            placeholder="mail@wp.pl"
            value={customerEmail}
            onChange={handleCustomerEmailChange}
          />
          <Input
            name="minRating"
            className="w-44"
            type="number"
            step={0.5}
            placeholder="Min rating"
            value={minRating}
            onChange={handleMinRatingChange}
          />
          <Input
            name="maxRating"
            className="w-44"
            type="number"
            step={0.5}
            placeholder="Max rating"
            value={maxRating}
            onChange={handleMaxRatingChange}
          />
        </div>
      </div>
      <div className="flex gap-2 items-center my-4">
        <div className="flex flex-col gap-4">
          <AttractionCombobox
            attractions={attractionsOptions}
            selectedId={selectedId}
            setSelectedOption={handleAttractionComboboxSelect}
          />
        </div>
        <div className="w-fit  justify-start flex flex-col gap-2">
          <Label>Visibility</Label>
          <Select
            defaultValue={defaultVisibilityStatus}
            onValueChange={handleVisibilityStatusSelect}
          >
            <Select.Trigger className="w-44">
              <Select.Value placeholder="Wszystkie" />
            </Select.Trigger>
            <Select.Content>
              <Select.Item value={ReviewVisibilityFilter.ALL}>All</Select.Item>
              <Select.Item value={ReviewVisibilityFilter.VISIBLE}>
                Visible
              </Select.Item>
              <Select.Item value={ReviewVisibilityFilter.HIDDEN}>
                Hidden
              </Select.Item>
            </Select.Content>
          </Select>
        </div>
        <div className="flex flex-col gap-2">
          <Label className="inline-flex justify-between">
            Review Date from
          </Label>
          <DatePicker
            date={createdAfterDate}
            setDate={handleCreatedAfterDateChange}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label className="inline-flex justify-between">Review Date to</Label>
          <DatePicker
            date={createdBeforeDate}
            setDate={handleCreatedBeforeDateChange}
          />
        </div>
      </div>
    </div>
  );
};
