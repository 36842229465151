import { zodResolver } from "@hookform/resolvers/zod";
import {
  NOT_SELECTED_ID,
  toast,
  useGetAttractions,
} from "@twocontinents/dashboard/shared";
import { useGetOfferBundles } from "@twocontinents/dashboard/travel-agencies/shared";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useGetOfferBundle } from "../data-access";
import { OfferEntry, OfferEntryType } from "../types";
import { mapToEntries } from "../utils/map-to-entries";

export interface OfferBundleFormProps {
  onSubmit: (data: OfferBundleFormSchema) => void;
  initialData?: {
    name?: string;
    entries?: OfferEntry[];
  };
  isPending: boolean;
  content: {
    title: string;
    description: string;
    footer: string;
  };
  offerBundleId?: number;
}

const OfferBundleFormSchema = z.object({
  name: z.string().min(3),
  entries: z
    .object({
      id: z.number(),
      offerPrice: z.coerce.number(),
      type: z.nativeEnum(OfferEntryType),
    })
    .array()
    .default([]),
});

export type OfferBundleFormSchema = z.infer<typeof OfferBundleFormSchema>;

export const useOfferBundleForm = ({
  onSubmit,
  isPending,
  initialData: { entries = [], name = "" } = {},
  content,
  offerBundleId,
}: OfferBundleFormProps) => {
  const form = useForm<OfferBundleFormSchema>({
    resolver: zodResolver(OfferBundleFormSchema),
    mode: "onBlur",
  });

  const { offerBundles } = useGetOfferBundles();
  const [copiedBundleId, setCopiedBundleId] = useState<number | undefined>();
  const { offerBundle } = useGetOfferBundle(copiedBundleId ?? NOT_SELECTED_ID);

  const { attractions } = useGetAttractions();

  const offerBundlesWithoutCurrent = offerBundles.filter(
    (bundle) => bundle.id !== offerBundleId,
  );

  const handleSubmit = form.handleSubmit((data) => {
    onSubmit(data);
  });

  const copyOfferBundle = () => {
    if (offerBundle) {
      const entries = mapToEntries(
        offerBundle.ageGroupEntries,
        offerBundle.extraOptionEntries,
      );

      form.setValue("entries", entries);
      toast.success("Offer bundle copied successfully");
    }
  };

  useEffect(() => {
    form.setValue("name", name);
    form.setValue("entries", entries);
  }, [entries, name]);

  return {
    form,
    handleSubmit,
    isPending,
    content,
    offerBundles: offerBundlesWithoutCurrent,
    copiedBundleId,
    setCopiedBundleId,
    entries,
    attractions,
    copyOfferBundle,
  };
};
