/* eslint-disable max-lines-per-function */
import { useState } from "react";

import { ALL_ITEMS_ID } from "../../constants";

export interface AttractionOption {
  id: number;
  description: string;
}

const defaultAttractionOption: AttractionOption = {
  description: "All attractions",
  id: ALL_ITEMS_ID,
};

export const useAttractionCombobox = (
  attractions: AttractionOption[],
  setSelectedOption: (value: AttractionOption) => void,
  selectedId?: number,
) => {
  const [open, setOpen] = useState(false);

  const onSelect = (value: string) => {
    const foundAttraction = attractions?.find(
      (attraction) =>
        attraction.description.toLowerCase() === value.toLowerCase(),
    );

    if (!foundAttraction) {
      setSelectedOption(defaultAttractionOption);
      setOpen(false);
      return;
    }

    setSelectedOption({
      id: foundAttraction.id,
      description: foundAttraction.description,
    });
    setOpen(false);
  };

  const selectedOption =
    attractions?.find((attraction) => attraction.id === selectedId) ??
    defaultAttractionOption;

  const attractionsOptions: AttractionOption[] = [
    defaultAttractionOption,
    ...(attractions
      ? attractions.map((attraction) => ({
          id: attraction.id,
          description: attraction.description,
        }))
      : []),
  ];

  return {
    open,
    setOpen,
    onSelect,
    attractionsOptions,
    defaultAttractionOption,
    selectedOption,
  };
};
