import { Check, X } from "lucide-react";
import { type UIEvent, useRef, useState } from "react";

import { cn } from "../utils";
import { Command, Popover, buttonVariants } from "./shadcn";

export type MultiSelectOptionType = {
  label: string;
  value: number;
};

export type MultiSelectOptionGroup = {
  label: string;
  options: MultiSelectOptionType[];
};

interface MultiSelectProps {
  options: MultiSelectOptionGroup[];
  selected: MultiSelectOptionType[];
  onChange: React.Dispatch<React.SetStateAction<MultiSelectOptionType[]>>;
  className?: string;
  placeholder?: string;
  next?: () => void;
}

export function MultiSelect({
  options,
  selected,
  onChange,
  className,
  placeholder,
  next,
  ...props
}: MultiSelectProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const handleUnselect = (item: MultiSelectOptionType) => {
    onChange(selected.filter((i) => i.label !== item.label));
  };

  const handleScroll = (e: UIEvent<HTMLDivElement, globalThis.UIEvent>) => {
    const target = e.currentTarget;
    const threshold = target.scrollTop + target.clientHeight + 100;

    if (threshold >= target.scrollHeight && next) {
      next();
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen} {...props}>
      <Popover.Trigger>
        <div
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          className={cn(
            buttonVariants({}),
            "w-64 min-h-10 max-h-48 overflow-auto  h-auto flex-wrap !border-slate-700 flex gap-4",
            className,
          )}
        >
          {selected.length > 0 ? (
            <div className="flex flex-wrap p-1 gap-2">
              {selected.map((item) => (
                <div
                  key={item.value}
                  className={cn(
                    buttonVariants({ size: "sm" }),
                    "mr-1 mb-1 cursor-pointer h-auto py-1 text-xxs text-wrap bg-slate-100 hover:bg-slate-300 text-slate-900",
                  )}
                  onClick={(e) => {
                    handleUnselect(item);
                    e.stopPropagation();
                  }}
                >
                  {item.label}
                  <button
                    className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleUnselect(item);
                      }
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUnselect(item);
                    }}
                  >
                    <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                  </button>
                </div>
              ))}
              <p className="w-full">Add more</p>
            </div>
          ) : (
            <span className="w-full text-slate-400">{placeholder}</span>
          )}
        </div>
      </Popover.Trigger>
      <Popover.Content
        ref={contentRef}
        onScroll={handleScroll}
        className="w-full max-h-80 overflow-auto p-0"
      >
        <Command className={className}>
          <Command.Input placeholder="Search ..." />
          <Command.List>
            <Command.Empty>No item found.</Command.Empty>
            {options.map((group) => (
              <Command.Group key={group.label} className="py-0.5">
                <Command.Item
                  onSelect={() => {
                    const someGroupOptionsSelected = group.options.some(
                      (option) =>
                        selected.some(
                          (selectedOption) =>
                            selectedOption.label === option.label,
                        ),
                    );

                    if (someGroupOptionsSelected) {
                      const updatedSelected = selected.filter(
                        (item) =>
                          !group.options.some(
                            (option) => item.label === option.label,
                          ),
                      );
                      onChange(updatedSelected);
                    } else {
                      const updatedSelected = [
                        ...selected,
                        ...group.options.map((option) => option),
                      ];
                      onChange(updatedSelected);
                    }
                  }}
                  className="font-bold"
                >
                  {group.label}
                </Command.Item>
                {group.options.map((option) => (
                  <Command.Item
                    key={option.value}
                    onSelect={() => {
                      onChange((currentSelected) => {
                        // eslint-disable-next-line unicorn/prefer-ternary
                        if (
                          currentSelected.some(
                            (selectedOption) =>
                              selectedOption.label === option.label,
                          )
                        ) {
                          return currentSelected.filter(
                            (item) => item.label !== option.label,
                          );
                        }
                        return [...currentSelected, option];
                      });
                      setOpen(true);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        selected.some(
                          (selectedOption) =>
                            selectedOption.label === option.label,
                        )
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                    {option.label}
                  </Command.Item>
                ))}
              </Command.Group>
            ))}
          </Command.List>
        </Command>
      </Popover.Content>
    </Popover>
  );
}
