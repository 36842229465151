import { Select } from "@twocontinents/dashboard/shared";

import { ReservationBase } from "../../data-access";

interface OrderReservationSelectProps {
  reservations: ReservationBase[];
  onValueChange: (id?: number) => void;
  defaultOpen?: boolean;
  value?: number;
}

export const OrderReservationSelect = ({
  onValueChange,
  reservations,
  defaultOpen,
  value,
}: OrderReservationSelectProps) => {
  return (
    <Select
      defaultValue={reservations[0]?.id?.toString()}
      onValueChange={(value) => onValueChange(Number.parseInt(value))}
      defaultOpen={defaultOpen}
      value={value?.toString()}
    >
      <Select.Trigger className="max-w-md h-max min-h-10">
        <Select.Value placeholder="Select currency" />
      </Select.Trigger>
      <Select.Content>
        {reservations.map((reservation) => (
          <Select.Item value={reservation.id.toString()} key={reservation.id}>
            {reservation.attractionDescription} - {reservation.date}{" "}
            {reservation.time}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
