import {
  AlertDialog,
  Button,
  buttonVariants,
} from "@twocontinents/dashboard/shared";

import { useDeleteCoupon } from "../../data-access";

interface DeleteCouponDialogProps {
  couponId: number;
}

export const DeleteCouponDialog = ({ couponId }: DeleteCouponDialogProps) => {
  const { isDeletingCoupon, deleteCoupon } = useDeleteCoupon();

  return (
    <AlertDialog>
      <AlertDialog.Trigger asChild>
        <Button
          variant="destructive"
          className="w-fit"
          onClick={(e) => {
            e.stopPropagation();
          }}
          isLoading={isDeletingCoupon}
        >
          Delete
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>
            Are you sure you want to delete this coupon?
          </AlertDialog.Title>
          <AlertDialog.Description>
            Are you sure you want to delete this coupon?
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <AlertDialog.Cancel
            className={buttonVariants({ variant: "destructive" })}
          >
            Cancel
          </AlertDialog.Cancel>
          <AlertDialog.Action
            onClick={() => deleteCoupon(couponId)}
            className={buttonVariants({ variant: "outline" })}
          >
            Yes
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
