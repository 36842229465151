import { useCallback, useState } from "react";

import { useGetCoupons } from "../data-access";
import { useCouponFiltersQueryParams } from "../hooks";

export const useAttractionCoupons = () => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const { query, setQuery } = useCouponFiltersQueryParams();
  const { coupons, pagination, arePaginatedCouponsLoading } = useGetCoupons({
    page: query.page,
    size: query.size,
  });

  const { page, size } = query;

  const handleSizeSelect = useCallback(
    (size: number) => {
      setQuery((params) => ({ ...params, size }));
    },
    [setQuery],
  );

  return {
    coupons,
    pagination,
    arePaginatedCouponsLoading,
    page,
    size,
    handleSizeSelect,
    showForm,
    setShowForm,
  };
};
