import {
  Layout,
  Pagination,
  usePaginationQueryParams,
} from "@twocontinents/dashboard/shared";
import React from "react";

import { useGetBusinessPartners } from "../data-access";
import { BusinessPartnersTable } from "./business-partners-table";

export function BusinessPartnersIndexPage() {
  const { page, size } = usePaginationQueryParams();
  const { data, isLoading } = useGetBusinessPartners({
    page,
    size,
  });

  const businessPartners = data?.businessPartners ?? [];
  const totalPages = data?.pagination.totalPages ?? 0;

  return (
    <Layout
      header={{
        title: "Business partners",
        description: "Browse Business partners and change their commissions",
      }}
    >
      <BusinessPartnersTable
        businessPartners={businessPartners}
        isLoading={isLoading}
      />
      <Pagination totalPages={totalPages} />
    </Layout>
  );
}
