import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = (id: number) => ["financial-log", id];

export const useGetFinancialLog = (id: number) => {
  const apiClient = usePrivateApiClient();

  const { data: financialLog, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/financial-log/{id}", {
          params: { path: { id } },
        })
        .then((response) => response.data),
    queryKey: queryKey(id),
  });

  return {
    financialLog,
    isLoading,
  };
};

export const useInvalidateGetFinancialLog = () => {
  const queryClient = useQueryClient();

  const invalidate = async (id: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(id),
    });
  };

  return { invalidate };
};
