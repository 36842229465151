import { FinancialLogCategory } from "../types";
import { Select } from "./shadcn";

interface FinancialLogCategorySelectProps {
  categories: FinancialLogCategory[];
  defaultValue?: FinancialLogCategory;
  onValueChange: (category?: FinancialLogCategory) => void;
  defaultOpen?: boolean;
  reset?: () => void;
  value?: FinancialLogCategory;
}

export const FinancialLogCategorySelect = ({
  onValueChange,
  categories,
  defaultOpen,
  defaultValue,
  value,
  reset,
}: FinancialLogCategorySelectProps) => {
  return (
    <Select
      defaultValue={defaultValue}
      onValueChange={(value) => onValueChange(value as FinancialLogCategory)}
      defaultOpen={defaultOpen}
      value={value}
    >
      <Select.Trigger className="max-w-md h-max min-h-10">
        <Select.Value placeholder="Select category" />
      </Select.Trigger>
      <Select.Content>
        {reset && (
          <Select.Item value=" " onClick={() => reset()}>
            Reset
          </Select.Item>
        )}
        {categories.map((category) => (
          <Select.Item value={category} key={category}>
            {category}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
