import { EmptyState, Pagination } from "@twocontinents/dashboard/shared";
import { ShoppingCartIcon } from "lucide-react";

import { OrderFilters } from "./order-filters";
import { OrdersTable } from "./orders-table";
import { useOrdersTab } from "./use-orders-tab";

export const OrdersTab = () => {
  const {
    handleSizeSelect,
    arePaginatedOrdersLoading,
    paginatedOrders,
    page,
    size,
    showOrdersNotFound,
    showOrders,
  } = useOrdersTab();

  const isLoading = arePaginatedOrdersLoading || !paginatedOrders;
  const showPagination = !arePaginatedOrdersLoading && paginatedOrders;
  const showOrdersTable = showOrders && !isLoading;

  return (
    <div>
      <OrderFilters />

      {showOrdersNotFound && (
        <EmptyState>
          <EmptyState.Icon>
            <ShoppingCartIcon size={40} />
          </EmptyState.Icon>
          <EmptyState.Header>Orders not found</EmptyState.Header>
          <EmptyState.Paragraph>
            Check if filters are valid or try again later
          </EmptyState.Paragraph>
        </EmptyState>
      )}
      {showOrdersTable && <OrdersTable orders={paginatedOrders.orders} />}
      {showPagination && (
        <Pagination
          currentPage={page}
          totalPages={paginatedOrders.pagination.totalPages}
          setSize={handleSizeSelect}
          size={size}
          sizeLabel="Orders per page"
        />
      )}
    </div>
  );
};
