import {
  Form,
  FormItem,
  Input,
  PhoneInput,
  Select,
} from "@twocontinents/dashboard/shared";
import { FundingType } from "@twocontinents/dashboard/travel-agencies/shared";

import {
  TravelAgencyFormProps,
  useTravelAgencyForm,
} from "./use-travel-agency-form";

export const TravelAgencyForm = ({
  footer,
  ...props
}: TravelAgencyFormProps) => {
  const { form, onSubmit, offerBundles } = useTravelAgencyForm(props);

  return (
    <Form {...form}>
      <form onSubmit={onSubmit} className="flex gap-6 flex-col justify-between">
        <Form.Field
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Name</FormItem.Label>
                <FormItem.Control>
                  <Input {...field} />
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />
        <Form.Field
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Email</FormItem.Label>
                <FormItem.Control>
                  <Input {...field} type={"email"} />
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />
        <Form.Field
          control={form.control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Phone number</FormItem.Label>
                <FormItem.Control>
                  <PhoneInput {...field} />
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />

        <Form.Field
          control={form.control}
          name="nip"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Nip</FormItem.Label>
                <FormItem.Control>
                  <Input {...field} />
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />

        <Form.Field
          control={form.control}
          name="street"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Street</FormItem.Label>
                <FormItem.Control>
                  <Input {...field} />
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />

        <Form.Field
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>City</FormItem.Label>
                <FormItem.Control>
                  <Input {...field} />
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />

        <Form.Field
          control={form.control}
          name="zipCode"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Zip code</FormItem.Label>
                <FormItem.Control>
                  <Input {...field} />
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />

        <Form.Field
          control={form.control}
          name="offerBundleId"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Offer bundle</FormItem.Label>
                <FormItem.Control>
                  <Select
                    value={field.value?.toString()}
                    onValueChange={(value) => field.onChange(value)}
                  >
                    <Select.Trigger className="max-w-md h-max min-h-10">
                      <Select.Value placeholder="Choose offer bundle" />
                    </Select.Trigger>
                    <Select.Content>
                      {offerBundles.map((offerBundle) => (
                        <Select.Item
                          key={offerBundle.id}
                          value={offerBundle.id?.toString()}
                        >
                          {offerBundle.name}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />

        <Form.Field
          control={form.control}
          name="fundingType"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Funding type</FormItem.Label>
                <FormItem.Control>
                  <Select
                    value={field.value}
                    onValueChange={(value) =>
                      field.onChange(value as FundingType)
                    }
                  >
                    <Select.Trigger className="max-w-md h-max min-h-10">
                      <Select.Value placeholder="Choose payment type" />
                    </Select.Trigger>
                    <Select.Content>
                      {Object.values(FundingType).map((paymentType) => (
                        <Select.Item key={paymentType} value={paymentType}>
                          {paymentType}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />

        <Form.Field
          control={form.control}
          name="additionalDetails"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label>Additional details</FormItem.Label>
                <FormItem.Control>
                  <Input {...field} />
                </FormItem.Control>
              </FormItem.Row>
              <FormItem.Message />
            </FormItem>
          )}
        />

        {footer}
      </form>
    </Form>
  );
};
