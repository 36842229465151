import { zodResolver } from "@hookform/resolvers/zod";
import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  Input,
  MAX_PAGE_SIZE,
  Select,
  useOpened,
} from "@twocontinents/dashboard/shared";
import {
  AgentType,
  mapAgentTypeToString,
  useGetTravelAgencies,
} from "@twocontinents/dashboard/travel-agencies/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAgent } from "../data-access";
import { Agent } from "../types";

interface UpdateAgentModalProps {
  agent: Agent;
}

const UpdateAgentFormSchema = z.object({
  name: z.string().min(3),
  email: z.string().email(),
  login: z.string().min(3),
  type: z.nativeEnum(AgentType),
  travelAgencyId: z.coerce.number(),
});

export type UpdateAgentFormSchema = z.infer<typeof UpdateAgentFormSchema>;

export const UpdateAgentModal = ({ agent }: UpdateAgentModalProps) => {
  const { opened, open, close } = useOpened();
  const { updateAgent, isPending } = useUpdateAgent(agent.id);

  const form = useForm<UpdateAgentFormSchema>({
    resolver: zodResolver(UpdateAgentFormSchema),
    mode: "onBlur",
  });

  const { data } = useGetTravelAgencies({
    size: MAX_PAGE_SIZE,
  });

  const travelAgencies = data?.travelAgencies ?? [];

  const handleUpdateAgent = form.handleSubmit((data) => {
    updateAgent(data);
    close();
  });

  useEffect(() => {
    if (agent) {
      form.reset(agent);
    }
  }, [agent]);

  return (
    <AlertDialog open={opened}>
      <AlertDialog.Trigger asChild onClick={open}>
        <Button variant={"secondary"}>Update data</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Update agent data</AlertDialog.Title>
        </AlertDialog.Header>

        <Form {...form}>
          <form
            onSubmit={handleUpdateAgent}
            className="flex gap-6 flex-col justify-between"
          >
            <Form.Field
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Name</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Email</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} type={"email"} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="login"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Login</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Type</FormItem.Label>
                    <FormItem.Control>
                      <Select
                        value={field.value?.toString()}
                        onValueChange={(value) => field.onChange(value)}
                      >
                        <Select.Trigger className="max-w-md h-max min-h-10">
                          <Select.Value placeholder="Choose type" />
                        </Select.Trigger>
                        <Select.Content>
                          {Object.values(AgentType).map((type) => (
                            <Select.Item key={type} value={type}>
                              {mapAgentTypeToString(type)}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />{" "}
            <Form.Field
              control={form.control}
              name="travelAgencyId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Travel agency</FormItem.Label>
                    <FormItem.Control>
                      <Select
                        value={field.value?.toString()}
                        onValueChange={(value) => field.onChange(value)}
                      >
                        <Select.Trigger className="max-w-md h-max min-h-10">
                          <Select.Value placeholder="Choose Travel Agency" />
                        </Select.Trigger>
                        <Select.Content>
                          {travelAgencies.map((offerBundle) => (
                            <Select.Item
                              key={offerBundle.id}
                              value={offerBundle.id?.toString()}
                            >
                              {offerBundle.name}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <AlertDialog.Footer>
              <AlertDialog.Cancel onClick={close}>Cancel</AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button type={"submit"} isLoading={isPending}>
                  Submit
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
