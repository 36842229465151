import { InvoiceStatus } from "@twocontinents/dashboard/travel-agencies/invoices/shared";
import React from "react";

import { useInvoicesFiltersQueryParams } from "../../hooks";

export const INVOICE_STATUSES = ["ALL", ...Object.values(InvoiceStatus)];

export const useFilterBar = () => {
  const { query, setQuery } = useInvoicesFiltersQueryParams();

  const handleInputChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery({
        [key]: e.target.value,
      });
    };

  const handleEnumChange = (key: string, value?: string) => {
    setQuery({
      [key]: value === "ALL" ? undefined : (value as InvoiceStatus),
    });
  };

  return {
    query,
    handleInputChange,
    handleEnumChange,
    INVOICE_STATUSES,
  };
};
