import { useQuery } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = () => ["private-base-extra-options"];

export const useGetBaseExtraOptions = () => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/attractions/extra-options")
        .then((response) => response.data),
    queryKey: queryKey(),
  });

  data?.extraOptions?.sort((a, b) => a.id - b.id);

  return { extraOptions: data?.extraOptions ?? [], isLoading };
};
