import { usePaginationQueryParams } from "@twocontinents/dashboard/shared";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

export const useTravelAgencyFiltersQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    name: withDefault(StringParam, undefined),
  });

  const { name } = query;
  const setName = (name: string) => setQuery({ name });
  const { page, size } = usePaginationQueryParams();

  return {
    query: {
      page,
      size,
      name,
    },
    setName,
  };
};
