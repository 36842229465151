import { zodResolver } from "@hookform/resolvers/zod";
import { Tour } from "@twocontinents/dashboard/attractions/shared";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useUpdateTour } from "../../data-access";

const UpdateTourFormSchema = z.object({
  maxParticipants: z.coerce.number(),
  additionalDetails: z.string(),
});

type UpdateTourForm = z.infer<typeof UpdateTourFormSchema>;

export const useUpdateTourDialog = (tour: Tour) => {
  const openAlertDialog = useBoolean(false);

  const { isPending, updateTour } = useUpdateTour();
  const form = useForm<UpdateTourForm>({
    resolver: zodResolver(UpdateTourFormSchema),
    defaultValues: {
      additionalDetails: tour.additionalDetails,
      maxParticipants: tour.maxParticipants,
    },
  });

  const handleFormSubmit = form.handleSubmit(async (values) => {
    updateTour(
      { tourId: tour.id, body: values },
      {
        onSettled: () => openAlertDialog.setValue(false),
      },
    );
  });

  return {
    openAlertDialog,
    form,
    isPending,
    handleFormSubmit,
  };
};
