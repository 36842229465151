import {
  AttractionGroup,
  AttractionGroupSelect,
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { useDescriptionSettings } from "./use-description-settings";

interface DescriptionSettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const DescriptionSettings = ({
  attractionId,
  attractionGroups,
}: DescriptionSettingsProps) => {
  const { form, onSubmit, isPending } = useDescriptionSettings(
    attractionId,
    attractionGroups,
  );

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Description settings</SettingCard.Title>
        <SettingCard.Description>Change description</SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="groupId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Variant</FormItem.Label>
                    <FormItem.Control>
                      <AttractionGroupSelect
                        attractionGroups={attractionGroups}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Description</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} className="max-w-md" />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change groups's description only on the
              dashboard.
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
