import { ALL_ITEMS_ID } from "../constants";
import { AttractionVariant } from "../types";
import { cn } from "../utils";
import { Select } from "./shadcn";

interface AttractionVariantSelectProps {
  value?: number;
  attractionVariants: AttractionVariant[];
  onValueChange: (id: number) => void;
  showAllVariantsOption?: boolean;
  defaultOpen?: boolean;
  className?: string;
}

export const AttractionVariantSelect = ({
  onValueChange,
  attractionVariants,
  showAllVariantsOption,
  defaultOpen,
  value,
  className,
}: AttractionVariantSelectProps) => {
  return (
    <Select
      value={value?.toString()}
      defaultValue={showAllVariantsOption ? ALL_ITEMS_ID.toString() : undefined}
      onValueChange={(value) => onValueChange(Number.parseInt(value))}
      defaultOpen={defaultOpen}
    >
      <Select.Trigger className={cn("max-w-md h-max min-h-10", className)}>
        <Select.Value placeholder="Choose Attraction Variant" />
      </Select.Trigger>
      <Select.Content>
        {showAllVariantsOption && (
          <Select.Item value="-1">All Variants</Select.Item>
        )}

        {attractionVariants.map((variant) => (
          <Select.Item
            value={variant.id.toString()}
            key={variant.id}
            disabled={!variant.active}
          >
            {variant.description}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
