import { Layout, SettingCard } from "@twocontinents/dashboard/shared";

import { GenerateTicketForm } from "./generate-ticket-form";

export const TicketsGenerator = () => {
  return (
    <Layout
      header={{
        title: "Tickets Generator",
        description: "Generate tickets",
      }}
    >
      <SettingCard>
        <SettingCard.Header>
          <SettingCard.Title>Tickets generator</SettingCard.Title>
          <SettingCard.Description>
            Fill the form to generate a ticket
          </SettingCard.Description>
        </SettingCard.Header>
        <GenerateTicketForm />
      </SettingCard>
    </Layout>
  );
};
