import { useMutation } from "@tanstack/react-query";
import {
  toast,
  useInvalidateGetAttraction,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type Body = components["schemas"]["UpdateAttractionRequest"];

interface Props {
  attractionId: number;
  body: Body;
}

export const useUpdateAttraction = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetAttraction();

  const { mutate: updateAttraction, isPending } = useMutation({
    mutationFn: ({ attractionId, body }: Props) =>
      apiClient
        .PATCH(`/private/attractions/{id}`, {
          params: {
            path: { id: attractionId },
          },
          body,
        })
        .then((response) => response.data),

    onSuccess: async (_, { attractionId }) => {
      toast.success("Attraction has been updated successfully.");
      await invalidate(attractionId);
    },
    onError: () => {
      toast.error("Attraction could not be updated");
    },
  });

  return { updatePrivateAttraction: updateAttraction, isPending };
};
