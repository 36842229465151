import {
  EmptyState,
  Layout,
  Pagination,
  buttonVariants,
  routes,
} from "@twocontinents/dashboard/shared";
import {
  TravelAgencyTab,
  TravelAgencyTabs,
} from "@twocontinents/dashboard/travel-agencies/shared";
import { ShoppingCartIcon } from "lucide-react";
import { Link } from "react-router-dom";

import { FilterBar } from "./filter-bar";
import { InvoicesTable } from "./invoices-table";
import { useInvoicesTab } from "./use-invoices-tab";

export const InvoicesTab = () => {
  const { paginatedInvoices, areInvoicesLoading, notFound } = useInvoicesTab();

  const isLoading = areInvoicesLoading || !paginatedInvoices;
  const showPagination = !areInvoicesLoading && paginatedInvoices;
  const showTable = paginatedInvoices && !isLoading && !notFound;

  return (
    <Layout
      header={{
        title: "Invoices",
        description: "Here you can find all your invoices.",
      }}
    >
      <Layout.Content>
        <div className="flex justify-between">
          <TravelAgencyTabs activeTab={TravelAgencyTab.INVOICES} />
          <Link
            to={routes.travelAgencies.invoices.create}
            className={buttonVariants({ variant: "secondary" })}
          >
            Create
          </Link>
        </div>
        <FilterBar />
        {notFound && (
          <EmptyState>
            <EmptyState.Icon>
              <ShoppingCartIcon size={40} />
            </EmptyState.Icon>
            <EmptyState.Header>Invoices not found</EmptyState.Header>
            <EmptyState.Paragraph>
              Check if the entered filters are correct and try
            </EmptyState.Paragraph>
          </EmptyState>
        )}
        {showTable && <InvoicesTable invoices={paginatedInvoices.invoices} />}
        {showPagination && (
          <Pagination totalPages={paginatedInvoices.pagination.totalPages} />
        )}
      </Layout.Content>
    </Layout>
  );
};
