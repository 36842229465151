import { zodResolver } from "@hookform/resolvers/zod";
import { AttractionGroup } from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttractionGroup } from "../../../data-access";
import { useAttractionGroups } from "../../../hooks";
import { AttractionGroupEntity } from "../../../types";

const DefaultSlotsAvailableSettingsSchema = z.object({
  groupId: z.number(),
  defaultSlotsAvailable: z.coerce.number().min(-1),
});

type DefaultSlotsAvailabileSettingsSchema = z.infer<
  typeof DefaultSlotsAvailableSettingsSchema
>;

export const useDefaultSlotsAvailableSettings = (
  attractionId: number,
  attractionGroups: AttractionGroup[],
) => {
  const { updateAttractionGroup, isPending } = useUpdateAttractionGroup();

  const form = useForm<DefaultSlotsAvailabileSettingsSchema>({
    resolver: zodResolver(DefaultSlotsAvailableSettingsSchema),
  });

  const { handleSubmit, watch, setValue } = form;

  const onSubmit = handleSubmit(({ groupId, ...body }) => {
    updateAttractionGroup({
      attractionId,
      groupId,
      body,
    });
  });

  const groupId = watch("groupId");

  const onGroupChange = (selectedGroup: AttractionGroupEntity) => {
    setValue("defaultSlotsAvailable", selectedGroup.defaultSlotsAvailable);
  };

  useAttractionGroups({
    attractionGroups,
    groupId,
    onGroupChange,
  });

  return {
    form,
    onSubmit,
    isPending,
  };
};
