import { AgeGroupSelect } from "@twocontinents/dashboard/attractions/shared";
import {
  AttractionGroup,
  AttractionVariantSelect,
  Form,
  FormItem,
  Pagination,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { AgeGroupTicketsTable } from "./age-group-tickets-table";
import { CreateTicketForm } from "./create-ticket-form";
import { useAgeGroupsTicketsSettings } from "./use-age-groups-tickets-settings";

interface AgeGroupsTicketsSettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const AgeGroupsTicketsSettings = ({
  attractionGroups,
}: AgeGroupsTicketsSettingsProps) => {
  const {
    ageGroups,
    ageGroup,
    isPending,
    form,
    tickets,
    anyVariantHasInternalTickets,
    variantsWithTickets,
    onSubmit,
    pagination,
    availableTicketsAmount,
    csvFile,
    setCsvFile,
  } = useAgeGroupsTicketsSettings(attractionGroups);

  if (!anyVariantHasInternalTickets) {
    return null;
  }

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Age Group Tickets</SettingCard.Title>
        <SettingCard.Description>
          Change variant's description
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="variantId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Variant</FormItem.Label>
                    <FormItem.Control>
                      <AttractionVariantSelect
                        attractionVariants={variantsWithTickets}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <Form.Field
              control={form.control}
              name="id"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Age group</FormItem.Label>
                    <FormItem.Control>
                      <AgeGroupSelect
                        ageGroups={ageGroups}
                        onValueChange={field.onChange}
                        value={field?.value?.toString()}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            {ageGroup && (
              <CreateTicketForm
                csvFile={csvFile}
                setCsvFile={setCsvFile}
                isPending={isPending}
              />
            )}
          </Form.Fields>
          {ageGroup && pagination && (
            <>
              <h1 className="p-4 font-semibold text-xl">
                Total available tickets: {availableTicketsAmount}
              </h1>
              <AgeGroupTicketsTable tickets={tickets} />
              <Pagination totalPages={pagination.totalPages} />
            </>
          )}
        </form>
      </Form>
    </SettingCard>
  );
};
