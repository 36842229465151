import { ExtraOption } from "@twocontinents/dashboard/attractions/shared";
import { DateFormatter } from "@twocontinents/shared";
import { useFormContext } from "react-hook-form";

import {
  CalendarExtraOptionAvailability,
  ExtraOptionsAvailabilityForm,
} from "../availability-settings/use-extra-options-availability-settings";

export const useCalendar = (
  allAvailabilities: CalendarExtraOptionAvailability[],
  selectedExtraOptions: ExtraOption[],
) => {
  const { setValue } = useFormContext<ExtraOptionsAvailabilityForm>();

  const getDateAvailabilities = (date: Date) => {
    return allAvailabilities.filter(
      (availability) =>
        availability.date === DateFormatter.formatToYYYYMMDD(date),
    );
  };

  const isDateAvailable = (date: Date) => {
    const availabilities = getDateAvailabilities(date);
    const mutatedAvailabilities = availabilities.filter(
      (av) => av.id === undefined,
    );
    const fetchedAvailabilities = availabilities.filter(
      (av) => av.id !== undefined,
    );

    const allAvailabilities = [
      ...mutatedAvailabilities,
      ...fetchedAvailabilities.filter(
        (av) =>
          !mutatedAvailabilities.some(
            (mutatedAvail) => mutatedAvail.date === av.date,
          ),
      ),
    ];

    const notAvailable = allAvailabilities.filter(
      (availability) => !availability.available,
    );

    return notAvailable.length !== selectedExtraOptions.length;
  };

  const selectCalendarDay = (date: Date) => {
    setValue("date", DateFormatter.formatToYYYYMMDD(date));
  };

  return {
    selectCalendarDay,
    isDateAvailable,
  };
};
