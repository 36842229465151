import {
  Tour,
  TourStatus,
  useGetTours,
} from "@twocontinents/dashboard/attractions/shared";
import {
  NOT_SELECTED_ID,
  useGetAttraction,
  useGetAttractions,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useMoveTourParticipants } from "../../data-access";

export const TourFilterFormSchema = z.object({
  dateFrom: z.string().optional(),
  dateTo: z.string().optional(),
  attractionGroupId: z.number().optional(),
  attractionId: z.number().optional(),
});

type TourFiltersFormValues = z.infer<typeof TourFilterFormSchema>;

export const useMoveTourParticipantsDialog = (
  tour: Tour,
  participantIds: number[],
) => {
  const openAlertDialog = useBoolean(false);

  const { attractions } = useGetAttractions({
    select: (attractions) =>
      attractions.filter((attraction) => attraction.tour),
  });

  const { moveTourParticipants, isPending } = useMoveTourParticipants(tour.id);

  const form = useForm<TourFiltersFormValues>({
    defaultValues: {
      attractionId: tour.attractionVariant.attractionId,
      attractionGroupId: tour.attractionVariant.attractionGroupId,
    },
  });

  const [targetTourId, setTargetTourId] = useState<number | undefined>(
    undefined,
  );

  const selectedAttractionId = form.watch("attractionId");

  const { attraction } = useGetAttraction(selectedAttractionId ?? 0);

  const selectedAttractionGroupId = form.watch("attractionGroupId");

  const dateFrom = form.watch("dateFrom");
  const dateTo = form.watch("dateTo");

  const { paginatedTours } = useGetTours({
    size: 1000,
    attractionGroupId: selectedAttractionGroupId,
    attractionId: selectedAttractionId,
    dateFrom: dateFrom,
    dateTo: dateTo,
    page: 0,
    status: TourStatus.PLANNED,
  });

  const setAttractionId = (attractionId: number | undefined) => {
    form.setValue("attractionId", attractionId);
  };

  const setAttractionGroupId = useCallback(
    (attractionGroupId: number | undefined) => {
      form.setValue("attractionGroupId", attractionGroupId);
    },
    [form],
  );

  const setDateTo = useCallback(
    (date: Date | undefined) => {
      form.setValue(
        "dateTo",
        date ? DateFormatter.formatToYYYYMMDD(date) : undefined,
      );
    },
    [form],
  );

  const matchingGroup = attraction?.attractionGroups?.find(
    (group) => group.id === tour.attractionVariant.attractionGroupId,
  );

  useEffect(() => {
    setTargetTourId(undefined);
  }, [selectedAttractionGroupId]);

  useEffect(() => {
    if (matchingGroup?.id) {
      form.setValue("attractionGroupId", matchingGroup.id);
    }
  }, [matchingGroup, form]);

  useEffect(() => {
    const doesCurrentGroupBelongsToNewAttraction = attraction?.attractionGroups
      ?.map((group) => group.id)
      ?.includes(selectedAttractionGroupId ?? NOT_SELECTED_ID);

    if (attraction && !doesCurrentGroupBelongsToNewAttraction) {
      setAttractionGroupId(attraction.attractionGroups?.[0]?.id);
    }
  }, [attraction, setAttractionGroupId, selectedAttractionGroupId]);

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!targetTourId) {
      return;
    }
    moveTourParticipants(
      { body: { participantIds }, targetTourId },
      {
        onSettled() {
          openAlertDialog.setFalse();
        },
      },
    );
  };

  return {
    form,
    attractions,
    setAttractionId,
    selectedAttractionId,
    attractionGroups: attraction?.attractionGroups ?? [],
    selectedAttractionGroupId,
    setAttractionGroupId,
    comboboxTours: paginatedTours?.tours ?? [],
    openAlertDialog,
    handleFormSubmit,
    setTargetTourId,
    targetTourId,
    isPending,
    dateTo,
    setDateTo,
  };
};
