import { zodResolver } from "@hookform/resolvers/zod";
import { useGetAttractionVariantAgeGroups } from "@twocontinents/dashboard/attractions/shared";
import { AttractionGroup } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateTickets, useGetAgeGroupTickets } from "../../../data-access";
import { useAgeGroupTicketsFiltersQueryParams } from "../../../hooks";

const CreateAgeGroupTicketSchema = z
  .object({
    variantId: z.coerce.number().nonnegative(),
    id: z.coerce.number().nonnegative(),
    codes: z.array(z.object({ value: z.string().min(2) })),
    expirationDate: z.string(),
    expirationTime: z.string(),
  })
  .superRefine((data, ctx) => {
    const codes = data.codes.map((code) => code.value);
    if (new Set(codes).size !== codes.length) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Codes must be unique",
        path: [`codes.0.value`],
      });
    }
  });

export type CreateAgeGroupTicketSchema = z.infer<
  typeof CreateAgeGroupTicketSchema
>;

export const useAgeGroupsTicketsSettings = (
  attractionGroups: AttractionGroup[],
) => {
  const [csvFile, setCsvFile] = useState<File | null>(null);

  const { query } = useAgeGroupTicketsFiltersQueryParams();
  const variantsWithTickets = attractionGroups.flatMap((group) =>
    group.attractionVariants.filter((variant) => variant.internalTicket),
  );

  const anyVariantHasInternalTickets = variantsWithTickets.length > 0;

  const form = useForm<CreateAgeGroupTicketSchema>({
    resolver: zodResolver(CreateAgeGroupTicketSchema),
  });

  const { setValue, handleSubmit } = form;

  const variantId = form.watch("variantId");

  const { ageGroups } = useGetAttractionVariantAgeGroups(variantId, {
    placeholderData: (prev) => prev,
    enabled: Boolean(variantId),
    refetchOnWindowFocus: false,
  });

  const ageGroupId = form.watch("id");
  const ageGroup = ageGroups.find((group) => group.id === ageGroupId);

  const { createTicket, isPending } = useCreateTickets(variantId, ageGroupId);

  const { tickets, availableTicketsAmount, isLoading, pagination } =
    useGetAgeGroupTickets(variantId, ageGroupId, query);

  const onSubmit = handleSubmit((data) => {
    createTicket(
      {
        ageGroupId,
        id: variantId,
        file: csvFile,
        body: {
          codes:
            data.codes.length === 0
              ? undefined
              : data.codes.map((code) => code.value),
          expiresAt: DateFormatter.formatToYYYYMMDDHHmmss(
            data.expirationDate,
            data.expirationTime,
          ),
        },
      },
      {
        onSuccess: () => {
          setValue("codes", []);
          setCsvFile(null);
        },
      },
    );
  });

  return {
    attractionGroups,
    ageGroups,
    form,
    ageGroup,
    createTicket,
    isPending,
    tickets,
    availableTicketsAmount,
    isLoading,
    anyVariantHasInternalTickets,
    variantsWithTickets,
    onSubmit,
    pagination,
    csvFile,
    setCsvFile,
  };
};
