import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = (attractionId: number) => [
  "private-attraction-crew-availability",
  attractionId,
];

export const useGetAttractionCrewAvailability = (attractionId: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/attractions/{id}/crew-availability", {
          params: {
            path: {
              id: attractionId,
            },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(attractionId),
    enabled: Boolean(attractionId),
  });

  return { crewAvailability: data?.availabilities ?? [], isLoading };
};

export const useInvalidateGetAttractionCrewAvailability = () => {
  const queryClient = useQueryClient();

  const invalidate = async (attractionId: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(attractionId),
    });
  };

  return { invalidateCrewAvailability: invalidate };
};
