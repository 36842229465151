import { useMutation } from "@tanstack/react-query";
import {
  toast,
  useInvalidateGetAttraction,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type Body = components["schemas"]["UpdateAttractionVariantRequest"];

interface Props {
  attractionId: number;
  variantId: number;
  body: Body;
}

export const useUpdateAttractionVariant = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetAttraction();

  const { mutate: updateVariant, isPending } = useMutation({
    mutationFn: ({ variantId, body }: Props) =>
      apiClient
        .PATCH(`/private/attractions/variants/{id}`, {
          params: {
            path: { id: variantId },
          },
          body,
        })
        .then((response) => response.data),

    onSuccess: async (_, { attractionId }) => {
      toast.success("Variant has been updated successfully.");
      await invalidate(attractionId);
    },
    onError: () => {
      toast.error("Variant could not be updated");
    },
  });

  return { updateVariant, isPending };
};
