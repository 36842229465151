import {
  Button,
  Checkbox,
  CurrencyCodeSelect,
  Form,
  FormItem,
  Input,
  Label,
  MultiSelect,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode } from "@twocontinents/shared";
import { type Dispatch, type FC, type SetStateAction } from "react";

import { useAddCouponForm } from "./use-add-coupon-form";

interface AddCouponFormProps {
  setShowForm: Dispatch<SetStateAction<boolean>>;
}

export const AddCouponForm: FC<AddCouponFormProps> = ({ setShowForm }) => {
  const {
    form,
    handleSubmit,
    infinityUsages,
    isCreatingCoupon,
    onSubmit,
    register,
    selectedVariants,
    setSelectedVariants,
    variantsOptions,
    setValue,
    closeForm,
  } = useAddCouponForm(setShowForm);

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Add coupon</SettingCard.Title>
        <SettingCard.Description>
          Set coupon name, value and activity date.
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex gap-6 flex-col justify-between"
        >
          <Form.Field
            control={form.control}
            name="code"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">Code</FormItem.Label>
                  <Input {...field} placeholder="DUBAJ2023" className="w-fit" />
                </FormItem.Row>
              </FormItem>
            )}
          />

          <Form.Field
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">
                    Description
                  </FormItem.Label>
                  <Input
                    {...field}
                    placeholder="Description"
                    className="w-fit"
                  />
                </FormItem.Row>
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="attractionVariantIds"
            render={() => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">Variants</FormItem.Label>
                  <MultiSelect
                    options={variantsOptions}
                    selected={selectedVariants}
                    onChange={setSelectedVariants}
                    placeholder="Choose Attraction Variants"
                    className=" w-96"
                  />
                </FormItem.Row>
              </FormItem>
            )}
          />

          <Form.Field
            control={form.control}
            name="infinityUsages"
            render={() => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">
                    Infinity usages
                  </FormItem.Label>
                  <Checkbox
                    className="bg-slate-200"
                    id="infinityUsages"
                    {...register("infinityUsages")}
                    onCheckedChange={(e) => {
                      setValue("availableUsages", 1);
                      setValue("infinityUsages", e as boolean);
                    }}
                  />
                </FormItem.Row>
              </FormItem>
            )}
          />

          {!infinityUsages && (
            <Form.Field
              control={form.control}
              name="availableUsages"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-64">
                      Available Usages
                    </FormItem.Label>
                    <Input {...field} placeholder="100" className="w-fit" />
                  </FormItem.Row>
                </FormItem>
              )}
            />
          )}

          <Form.Field
            control={form.control}
            name="value"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">Value</FormItem.Label>
                  <Input
                    {...field}
                    placeholder="100"
                    type="number"
                    className="w-fit"
                  />
                </FormItem.Row>
              </FormItem>
            )}
          />

          <Form.Field
            control={form.control}
            name="currency"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">Currency</FormItem.Label>
                  <FormItem.Control>
                    <CurrencyCodeSelect
                      value={field.value}
                      currencies={Object.values(CurrencyCode)}
                      onValueChange={field.onChange}
                    />
                  </FormItem.Control>
                </FormItem.Row>
              </FormItem>
            )}
          />

          <Form.Field
            control={form.control}
            name="startDateTime"
            render={({ field }) => (
              <FormItem.Row>
                <Label className="min-w-64">
                  Start date (in attraction timezone)
                </Label>
                <Input {...field} type="datetime-local" className="w-fit" />
              </FormItem.Row>
            )}
          />

          <Form.Field
            control={form.control}
            name="endDateTime"
            render={({ field }) => (
              <FormItem.Row>
                <Label className="min-w-64">
                  End date (in attraction timezone)
                </Label>
                <Input {...field} type="datetime-local" className="w-fit" />
              </FormItem.Row>
            )}
          />

          <Form.Field
            control={form.control}
            name="reservationPeriodStartDateTime"
            render={({ field }) => (
              <FormItem.Row>
                <Label className="min-w-64">
                  Reservation period start date (in attraction timezone)
                </Label>
                <Input {...field} type="datetime-local" className="w-fit" />
              </FormItem.Row>
            )}
          />

          <Form.Field
            control={form.control}
            name="reservationPeriodEndDateTime"
            render={({ field }) => (
              <FormItem.Row>
                <Label className="min-w-64">
                  Reservation period end date (in attraction timezone)
                </Label>
                <Input
                  {...field}
                  type="datetime-local"
                  className="w-fit"
                  placeholder="2023-01-01T00:00"
                />
              </FormItem.Row>
            )}
          />

          <SettingCard.Footer>
            <SettingCard.Description>
              Leaving the start and end date fields empty means no restrictions
            </SettingCard.Description>
            <SettingCard.ButtonGroup>
              <Button variant="destructive" onClick={closeForm}>
                Cancel
              </Button>
              <SettingCard.Button isLoading={isCreatingCoupon}>
                Create
              </SettingCard.Button>
            </SettingCard.ButtonGroup>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
