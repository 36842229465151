import { NumberParam, useQueryParams, withDefault } from "use-query-params";

export const useCouponFiltersQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 5),
  });

  return {
    query,
    setQuery,
  };
};
