/* eslint-disable max-lines-per-function */
import { zodResolver } from "@hookform/resolvers/zod";
import { ExtraOption } from "@twocontinents/dashboard/attractions/shared";
import { CurrencyCode } from "@twocontinents/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateExtraOption } from "../../data-access";

const UpdateExtraOptionFormSchema = z.object({
  extraOptionId: z.number().nonnegative(),
  description: z.string(),
  providerPrice: z.coerce.number(),
  price: z.coerce.number(),
  currency: z.nativeEnum(CurrencyCode),
  providerCurrency: z.nativeEnum(CurrencyCode),
});

type UpdateExtraOptionFormSchema = z.infer<typeof UpdateExtraOptionFormSchema>;

export const useExtraOptionsSettings = (
  extraOptions: ExtraOption[],
  attractionId: number,
) => {
  const { isPending, updateExtraOption, newId } = useUpdateExtraOption();

  const form = useForm<UpdateExtraOptionFormSchema>({
    resolver: zodResolver(UpdateExtraOptionFormSchema),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = form;

  const extraOptionId = watch("extraOptionId");
  const selectedExtraOption = extraOptions.find(
    (extraOption) => extraOption.id === extraOptionId,
  );

  useEffect(() => {
    if (selectedExtraOption) {
      setValue("description", selectedExtraOption.name);
      setValue("price", selectedExtraOption.price);
      setValue("currency", selectedExtraOption.currency);
      setValue("providerPrice", selectedExtraOption.providerPrice);

      setValue("providerCurrency", selectedExtraOption.providerCurrency);
    }
  }, [selectedExtraOption, setValue]);

  const handleFormSubmit = handleSubmit(({ extraOptionId, ...body }) => {
    updateExtraOption(
      {
        attractionId,
        extraOptionId,
        body,
      },
      {
        onSuccess(newExtraOptionId) {
          setValue("extraOptionId", newExtraOptionId);
        },
      },
    );
  });

  const isButtonDisabled = isPending || !extraOptionId || !isValid;

  return {
    isPending,
    handleFormSubmit,
    form,
    isButtonDisabled,
  };
};
