import { CrewAvailability } from "./crew-availability";

export class CalendarDayAvailability {
  public isAvailable: boolean;
  public date: string;
  public crewAvailability: CrewAvailability | undefined;

  public constructor(
    date: string,
    isAvailable: boolean,
    crewAvailability?: CrewAvailability,
  ) {
    this.date = date;
    this.isAvailable = isAvailable;
    if (crewAvailability) {
      this.crewAvailability = crewAvailability;
    }
  }
}
