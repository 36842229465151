export const routes = {
  home: "/",
  login: "/login",
  financialLog: {
    index: "/financial-log",
    show: {
      url: (id: number) => `/financial-log/${id}`,
      path: "/financial-log/:id",
    },
  },
  businessPartners: {
    index: "/business-partners",
    show: {
      url: (id: number) => `/business-partners/${id}`,
      path: "/business-partners/:id",
    },
  },
  travelAgencies: {
    create: "/travel-agencies/create",
    show: {
      url: (id: number) => `/travel-agencies/${id}`,
      path: "/travel-agencies/:id",
    },
    index: "/travel-agencies",
    offerBundle: {
      create: "/travel-agencies/offer-bundle/create",
      show: {
        url: (id: number) => `/travel-agencies/offer-bundle/${id}`,
        path: "/travel-agencies/offer-bundle/:id",
      },
      index: "/travel-agencies/offer-bundle",
    },
    invoices: {
      index: "/travel-agencies/invoices",
      create: "/travel-agencies/invoices/create",
    },
  },
  attractions: {
    index: {
      index: "/attractions",
      show: {
        url: (id: number) => `/attractions/${id}`,
        path: "/attractions/:id",
      },
    },
    sequence: "/attractions/sequence",
    orders: {
      index: "/attractions/orders",
      show: {
        url: (id: number) => `/attractions/orders/${id}`,
        path: "/attractions/orders/:id",
      },
    },
    tours: {
      index: "/attractions/tours",
      create: "/attractions/tours/create",
      show: {
        url: (id: number) => `/attractions/tours/${id}`,
        path: "/attractions/tours/:id",
      },
    },
    coupons: {
      index: "/attractions/coupons",
    },
    reviews: {
      index: "/attractions/reviews",
    },
  },
  paymentLinkGenerator: "/payment-link-generator",
  ticketGenerator: "/ticket-generator",
};
