import { AttractionCrewAvailability } from "@twocontinents/dashboard/attractions/shared";

import { CalendarDayAvailability } from "./calendar-day-availability";
import { CrewAvailabilityList } from "./crew-availability-list";
import { DateAvailability } from "./date-availability";

export class CalendarDayAvailabilityList {
  private availabilityMap: Map<string, DateAvailability[]> = new Map<
    string,
    DateAvailability[]
  >();
  private crewAvailabilityList: CrewAvailabilityList;

  public constructor(
    availability: DateAvailability[],
    crewAvailability: AttractionCrewAvailability[],
  ) {
    this.crewAvailabilityList = new CrewAvailabilityList(crewAvailability);

    availability.forEach((availability) => {
      const previousAvailabilities =
        this.availabilityMap.get(availability.date) ?? [];
      this.availabilityMap.set(availability.date, [
        ...previousAvailabilities,
        availability,
      ]);
    });
  }

  public getDateAvailability(
    date: string,
  ): CalendarDayAvailability | undefined {
    const availabilities = this.availabilityMap.get(date);
    const crewAvailability =
      this.crewAvailabilityList.getCrewAvailability(date);

    if (!availabilities) {
      return;
    }

    const isAvailable =
      availabilities.some((availability) => availability.isAvailable) ?? true;

    return new CalendarDayAvailability(date, isAvailable, crewAvailability);
  }
}
