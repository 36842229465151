import { DetailsTable, SettingCard } from "@twocontinents/dashboard/shared";

import { FinancialLog } from "../types";
import { CancelFinancialLogModal } from "./cancel-financial-log-modal";
import { CreateCorrectionFinancialLogModal } from "./create-correction-financial-log-modal/create-correction-financial-log-modal";
import { useFinancialLogDetails } from "./use-financial-log-details";

interface FinancialLogDetailsProps {
  financialLog: FinancialLog;
}

export const FinancialLogDetails = ({
  financialLog,
}: FinancialLogDetailsProps) => {
  const {
    details,
    getFinancialLogClassName,
    getFinancialLogContent,
    getFinancialLogLabel,
  } = useFinancialLogDetails(financialLog);

  return (
    <>
      <SettingCard>
        <SettingCard.Header>
          <SettingCard.Title>
            Financial log #{financialLog.id} data
          </SettingCard.Title>
        </SettingCard.Header>
        <DetailsTable>
          {details.map((detail, index) => (
            <DetailsTable.Row key={index} {...detail} />
          ))}
        </DetailsTable>

        <div className="flex gap-4 justify-end">
          <CancelFinancialLogModal
            financialLog={financialLog}
            settled={financialLog.settled}
          />
          <CreateCorrectionFinancialLogModal financialLog={financialLog} />
        </div>
      </SettingCard>
      <SettingCard>
        <SettingCard.Header>
          <SettingCard.Title>
            Financial log #{financialLog.id} history
          </SettingCard.Title>
        </SettingCard.Header>
        <DetailsTable>
          <DetailsTable.Row
            label={getFinancialLogLabel(1, financialLog)}
            content={getFinancialLogContent(financialLog)}
            className={getFinancialLogClassName(financialLog)}
          />
          {financialLog.childFinancialLogs
            .toSorted((a, b) => a.id - b.id)
            .map((log, index) => (
              <DetailsTable.Row
                key={log.id}
                label={getFinancialLogLabel(index + 2, log)}
                content={getFinancialLogContent(log)}
                className={getFinancialLogClassName(log)}
              />
            ))}
        </DetailsTable>
      </SettingCard>
    </>
  );
};
