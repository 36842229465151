import { zodResolver } from "@hookform/resolvers/zod";
import {
  Tour,
  TourFormatter,
} from "@twocontinents/dashboard/attractions/shared";
import {
  FinancialLogCategory,
  useOpened,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode, DateFormatter } from "@twocontinents/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateTourFinancialLog } from "../../data-access/create-tour-financial-log";

const CreateFinancialLogFormSchema = z.object({
  value: z.coerce.number().min(0.01),
  cause: z.string().min(3),
  settleDate: z.coerce.date(),
  currency: z.nativeEnum(CurrencyCode),
  category: z.nativeEnum(FinancialLogCategory),
});

type CreateFinancialLogFormSchema = z.infer<
  typeof CreateFinancialLogFormSchema
>;

export const useCreateTourFinancialLogDialog = (tour: Tour) => {
  const { createTourFinancialLog, isPending } = useCreateTourFinancialLog();

  const { opened, open, close } = useOpened(false);

  const form = useForm<CreateFinancialLogFormSchema>({
    resolver: zodResolver(CreateFinancialLogFormSchema),
    mode: "onBlur",
    defaultValues: {
      settleDate: DateFormatter.formatToNativeDate(tour.date),
      currency: CurrencyCode.AED,
      cause: TourFormatter.formatWithoutParticipantsDetails(tour),
      value: undefined,
      category: FinancialLogCategory.TOURS_AND_TRAVEL,
    },
  });

  const onSubmit = form.handleSubmit((data) => {
    createTourFinancialLog({
      ...data,
      settleDate: DateFormatter.formatToYYYYMMDD(data.settleDate),
      tourId: tour.id,
      value: data.value * -1,
    });

    form.reset({
      value: undefined,
      cause: undefined,
      settleDate: undefined,
      currency: CurrencyCode.AED,
      category: FinancialLogCategory.TOURS_AND_TRAVEL,
    });
    close();
  });

  return {
    form,
    onSubmit,
    opened,
    open,
    close,
    isPending,
    isFormValid: form.formState.isValid,
  };
};
