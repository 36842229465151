/* eslint-disable max-lines-per-function */
import { AttractionOrder } from "@twocontinents/dashboard/attractions/shared";
import { DetailRowProps } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";

export const useSingleReservationOrderDetails = (order: AttractionOrder) => {
  const [reservation] = order.reservations;

  const orderDetailsRows: DetailRowProps[] = [
    {
      label: "Attraction Name",
      content: reservation.attractionDescription,
    },
    {
      label: "Variant Name",
      content: reservation.attractionVariant.description,
    },
    {
      label: "Date",
      content: DateFormatter.formatDateTimeToDDMMYYYYHHmm(
        reservation.reservationDate,
        reservation.reservationTime,
      ),
    },
  ];

  reservation.historicalAgeGroups.forEach(({ historicalAgeGroup, amount }) => {
    orderDetailsRows.push({
      label: historicalAgeGroup.description,
      content: amount,
    });
  });

  reservation.historicalExtraOptions.forEach(
    ({ historicalExtraOption, amount }) => {
      orderDetailsRows.push({
        label: historicalExtraOption.name,
        content: historicalExtraOption.hasQuantity ? amount : "YES",
      });
    },
  );

  if (reservation.pickupLocation) {
    orderDetailsRows.push({
      label: "Pickup location",
      content: reservation.pickupLocation,
    });
  }

  if (reservation.pickupTime) {
    orderDetailsRows.push({
      label: "Pickup time",
      content: DateFormatter.formatToHHmm(reservation.pickupTime),
    });
  }

  reservation.reservationAdditionalDetails.forEach((additionalDetail) => {
    orderDetailsRows.push({
      label: additionalDetail.label,
      content: additionalDetail.value,
    });
  });

  if (order.couponCode && order.appliedDiscount) {
    orderDetailsRows.push(
      {
        label: "Coupon used",
        content: order.couponCode,
      },
      {
        label: "Order value",
        content: `${(order.totalPrice + order.appliedDiscount).toFixed(2)} ${order.currency}`,
      },
      {
        label: "Discount",
        content: `${order.appliedDiscount.toFixed(2)} ${order.currency}`,
      },
    );
  }

  orderDetailsRows.push({
    label: "Price",
    content: `${order.totalPrice.toFixed(2)} ${order.currency}`,
  });

  return {
    orderDetailsRows,
    reservation,
  };
};
