import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

function queryKey(id: number) {
  return ["order", id];
}

export const useGetOrder = (id: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/orders/attraction/{id}", {
          params: {
            path: {
              id,
            },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(id),
    refetchOnWindowFocus: true,
  });

  return {
    order: data,
    isLoading,
  };
};

export const useInvalidateGetOrder = () => {
  const queryClient = useQueryClient();
  return async (id: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(id),
    });
  };
};
