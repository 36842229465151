import { NumberParam, useQueryParams, withDefault } from "use-query-params";

export const usePaginationQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 5),
  });

  const { page, size } = query;

  const setPage = (page: number) => setQuery({ page });
  const setSize = (size: number) => setQuery({ size });

  return {
    page,
    size,
    setPage,
    setSize,
  };
};
