import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { routes } from "../../utils/routes";
import { toast } from "../../utils/toast";
import { useAuthContext } from "../providers";

export const useAuth = () => {
  const { setToken, token, user } = useAuthContext();

  const navigate = useNavigate();

  const signOut = useCallback(() => {
    setToken(undefined);
    navigate(routes.login);
    toast.success("Wylogowano pomyślnie");
  }, [navigate, setToken]);

  return { user, token, signOut, setToken };
};
