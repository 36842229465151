import { Select } from "@twocontinents/dashboard/shared";

import { InvoiceStatus } from "../types";

interface InvoiceStatusSelect {
  defaultValue?: InvoiceStatus;
  onValueChange: (invoiceStatus?: InvoiceStatus) => void;
  defaultOpen?: boolean;
  value?: InvoiceStatus;
}

export const InvoiceStatusSelect = ({
  onValueChange,
  defaultOpen,
  defaultValue,
  value,
}: InvoiceStatusSelect) => {
  return (
    <Select
      defaultValue={defaultValue}
      onValueChange={(value) => onValueChange(value as InvoiceStatus)}
      defaultOpen={defaultOpen}
      value={value}
    >
      <Select.Trigger className="max-w-md h-max min-h-10">
        <Select.Value placeholder="Select invoice status" />
      </Select.Trigger>
      <Select.Content>
        {Object.values(InvoiceStatus).map((status) => (
          <Select.Item value={status} key={status}>
            {status}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
