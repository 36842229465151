import { Tour } from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  Input,
  buttonVariants,
} from "@twocontinents/dashboard/shared";

import { useUpdateTourDialog } from "./use-update-tour-dialog";

interface UpdateTourDialogProps {
  tour: Tour;
}

export const UpdateTourDialog = ({ tour }: UpdateTourDialogProps) => {
  const { form, handleFormSubmit, isPending, openAlertDialog } =
    useUpdateTourDialog(tour);

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button
          variant="default"
          className="w-fit bg-orange-500 hover:bg-orange-600"
          isLoading={isPending}
        >
          Update settings
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>
            You can change tour settings here
          </AlertDialog.Title>
          <AlertDialog.Description>
            Max participants and additional details
          </AlertDialog.Description>
        </AlertDialog.Header>
        <Form {...form}>
          <form onSubmit={handleFormSubmit}>
            <Form.Fields>
              <Form.Field
                control={form.control}
                name="maxParticipants"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Max participants</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} className="max-w-md" type="number" />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="additionalDetails"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Additional Details</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} className="max-w-md" />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            </Form.Fields>
            <AlertDialog.Footer className="mt-4">
              <AlertDialog.Cancel
                className={buttonVariants({ variant: "destructive" })}
              >
                Cancel
              </AlertDialog.Cancel>
              <Button type="submit" variant="outline" isLoading={isPending}>
                Update
              </Button>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
