import { Select } from "@twocontinents/dashboard/shared";

import { AgeGroup } from "../types";

interface AgeGroupSelectProps {
  value?: string;
  ageGroups: AgeGroup[];
  onValueChange: (id?: number) => void;
  defaultOpen?: boolean;
}

export const AgeGroupSelect = ({
  onValueChange,
  ageGroups,
  defaultOpen,
  value,
}: AgeGroupSelectProps) => {
  return (
    <Select
      onValueChange={(value) => onValueChange(Number.parseInt(value))}
      defaultOpen={defaultOpen}
      value={value}
    >
      <Select.Trigger className="max-w-md h-max min-h-10">
        <Select.Value placeholder="Choose Age Group" />
      </Select.Trigger>
      <Select.Content>
        {ageGroups.map((ageGroup) => (
          <Select.Item value={ageGroup.id.toString()} key={ageGroup.id}>
            {ageGroup.description}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
