import { EmptyState, Pagination } from "@twocontinents/dashboard/shared";
import { Ship } from "lucide-react";

import { TourFilters } from "./tour-filters";
import { ToursTable } from "./tours-table";
import { useToursTab } from "./use-tours-tab";

export const ToursTab = () => {
  const {
    handleSizeSelect,
    isLoading,
    page,
    paginatedTours,
    showTours,
    showToursNotFound,
    size,
    totalPages,
    showPagination,
  } = useToursTab();

  return (
    <div>
      <TourFilters />

      {/* TODO: Replace with skeleton */}
      {isLoading && <div>Loading...</div>}

      {showToursNotFound && (
        <EmptyState>
          <EmptyState.Icon>
            <Ship size={40} />
          </EmptyState.Icon>
          <EmptyState.Header>Tours not found</EmptyState.Header>
          <EmptyState.Paragraph>
            There are no tours that match your search criteria
          </EmptyState.Paragraph>
        </EmptyState>
      )}
      {showTours && <ToursTable tours={paginatedTours?.tours ?? []} />}
      {showPagination && (
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          setSize={handleSizeSelect}
          size={size}
          sizeLabel="Tours per page"
        />
      )}
    </div>
  );
};
