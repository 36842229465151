import {
  CurrencyCodeSelect,
  Form,
  FormItem,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode } from "@twocontinents/shared";

import { useCurrencySettings } from "./use-currency-settings";

interface CurrencySettingsProps {
  attractionId: number;
  defaultCurrency: CurrencyCode;
}

export const CurrencySettings = ({
  attractionId,
  defaultCurrency,
}: CurrencySettingsProps) => {
  const { form, onSubmit, isPending, currencies } = useCurrencySettings(
    attractionId,
    defaultCurrency,
  );

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Currency settings</SettingCard.Title>
        <SettingCard.Description>
          Change attraction currency
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Field
            control={form.control}
            name="currency"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label>Currency</FormItem.Label>
                  <CurrencyCodeSelect
                    defaultValue={defaultCurrency}
                    currencies={currencies}
                    onValueChange={field.onChange}
                  />
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />

          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change currency in form.
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
