import { useQuery } from "@tanstack/react-query";
import {
  NOT_SELECTED_ID,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";

const queryKey = (orderId: number) => ["order-reservations", orderId];

export interface ReservationBase {
  id: number;
  date: string;
  time?: string;
  attractionDescription: string;
  attractionGroupDescription: string;
  attractionVariantDescription: string;
}

export const useGetOrderReservations = (orderId: number) => {
  const apiClient = usePrivateApiClient();
  const { data: reservations, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/orders/attraction/{id}", {
          params: { path: { id: orderId } },
        })
        .then((response) => response.data?.reservations ?? []),
    queryKey: queryKey(orderId),
    select: (reservations) =>
      reservations.map((reservation) => ({
        id: reservation.id,
        date: DateFormatter.formatToDDMMYYYY(reservation.reservationDate),
        time: reservation.reservationTime
          ? DateFormatter.formatToHHmm(reservation.reservationTime)
          : undefined,
        attractionDescription: reservation.attractionDescription,
        attractionGroupDescription: reservation.attractionGroupDescription,
        attractionVariantDescription: reservation.attractionVariant.description,
      })),
    enabled: orderId !== NOT_SELECTED_ID,
  });

  return {
    reservations: reservations ?? [],
    isLoading,
  };
};
