import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { useInvalidateGetReviews } from "./get-reviews";

interface Props {
  reviewId: number;
}

export const useDeleteReviewPicture = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetReviews();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ reviewId }: Props) => {
      return apiClient.DELETE(
        "/private/orders/attraction/reservations/reviews/pictures/{id}",
        {
          params: {
            path: {
              id: reviewId,
            },
          },
        },
      );
    },
    onSuccess: async () => {
      toast.success("Review picture has been deleted.");
      await invalidate();
    },
    onError: () => {
      toast.error("An error occurred while deleting the review picture.");
    },
  });

  return {
    deleteReviewPicture: mutate,
    isPending,
  };
};
