import { Button, Form, FormItem, Input } from "@twocontinents/dashboard/shared";

import { useSignIn } from "./use-sign-in";

export const SignIn = () => {
  const { form, isPending, onSubmit } = useSignIn();

  return (
    <main className="bg-slate-950 text-slate-50  min-h-screen flex flex-col gap-10 items-center justify-center">
      <h1 className="text-3xl font-semibold">Logowanie</h1>
      <Form {...form}>
        <form
          onSubmit={onSubmit}
          className="mt-5 flex flex-col gap-5 w-full max-w-xs"
        >
          <Form.Field
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label>Username</FormItem.Label>
                </FormItem.Row>
                <FormItem.Row>
                  <FormItem.Control>
                    <Input {...field} />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Row>
                  <FormItem.Message />
                </FormItem.Row>
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label>Password</FormItem.Label>
                </FormItem.Row>
                <FormItem.Row>
                  <FormItem.Control>
                    <Input {...field} type="password" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Row>
                  <FormItem.Message />
                </FormItem.Row>
              </FormItem>
            )}
          />
          <Button className="my-3 justify-center" disabled={isPending}>
            Zaloguj się
          </Button>
        </form>
      </Form>
    </main>
  );
};
