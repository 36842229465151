import { Layout, SettingCard } from "@twocontinents/dashboard/shared";

import { GeneratePaymentLinkForm } from "./generate-payment-link-form";

export const PaymentLinkGenerator = () => {
  return (
    <Layout
      header={{
        title: "Payment Link Generator",
        description: "Generate payment links",
      }}
    >
      <SettingCard>
        <SettingCard.Header>
          <SettingCard.Title>Payment link generator</SettingCard.Title>
          <SettingCard.Description>
            Fill the form to generate a payment link
          </SettingCard.Description>
        </SettingCard.Header>
        <GeneratePaymentLinkForm />
      </SettingCard>
    </Layout>
  );
};
