import {
  AttractionGroup,
  AttractionVariantSelect,
  Form,
  FormItem,
  Input,
  SettingCard,
  Textarea,
} from "@twocontinents/dashboard/shared";

import { useTourMessageSettings } from "./use-tour-message-settings";

interface TourMessageSettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const TourMessageSettings = ({
  attractionId,
  attractionGroups,
}: TourMessageSettingsProps) => {
  const {
    form,
    onSubmit,
    isPending,
    selectedVariant,
    variantsWithMessageDelivery,
  } = useTourMessageSettings(attractionId, attractionGroups);

  if (variantsWithMessageDelivery.length === 0) {
    return null;
  }

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Tour Message settings</SettingCard.Title>
        <SettingCard.Description>
          Change tour message settings
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="variantId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Variant</FormItem.Label>
                    <FormItem.Control>
                      <AttractionVariantSelect
                        attractionVariants={variantsWithMessageDelivery}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="bookingCaption"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Booking Caption</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} className="max-w-md" />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            {selectedVariant?.tourMessageSettings?.defaultContact && (
              <Form.Field
                control={form.control}
                name="defaultContact"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Default Contact</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} className="max-w-md" />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            )}
            {selectedVariant?.tourMessageSettings?.defaultItinerary && (
              <Form.Field
                control={form.control}
                name="defaultItinerary"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Default Itinerary</FormItem.Label>
                      <FormItem.Control>
                        <Textarea {...field} className="max-w-md" rows={10} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            )}
            {selectedVariant?.tourMessageSettings?.defaultPickupLocation && (
              <Form.Field
                control={form.control}
                name="defaultPickupLocation"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Default Pickup Location</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} className="max-w-md" />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            )}
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change variant's tour message settings
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
