import { useRef } from "react";
import { type DateRange } from "react-day-picker";
import { useBoolean, useOnClickOutside } from "usehooks-ts";

interface UseApartmentDateRangePicker {
  dateFrom?: Date;
  dateTo?: Date;
  setDateFrom: (date: Date | undefined) => void;
  setDateTo: (date: Date | undefined) => void;
}

export const useDateRangePicker = ({
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}: UseApartmentDateRangePicker) => {
  const {
    value: calendarOpened,
    setFalse: closeCalendar,
    toggle: toggleCalendar,
  } = useBoolean(false);

  const notAvailableDates: Date[] = [];

  const calendarRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(calendarRef, () => closeCalendar());

  const onSetDateRange = (dateRange: DateRange | undefined) => {
    setDateFrom(dateRange?.from);
    setDateTo(dateRange?.to);
  };

  const buttonText = getButtonText(dateFrom, dateTo);

  return {
    onSetDateRange,
    calendarOpened,
    toggleCalendar,
    calendarRef,
    notAvailableDates,
    buttonText,
  };
};

const getButtonText = (
  dateFrom: Date | undefined,
  dateTo: Date | undefined,
) => {
  if (dateFrom) {
    return dateTo
      ? `${dateFrom.toLocaleDateString()} - ${dateTo.toLocaleDateString()}`
      : dateFrom.toLocaleDateString();
  }
  return "Pick date range";
};
