import {
  TourBase,
  TourGenerationMode,
} from "@twocontinents/dashboard/attractions/shared";

export const getFullNameFromTour = (tour: TourBase) => {
  if (tour.tourGenerationMode === TourGenerationMode.ADD_TO_EXISTING_TOUR) {
    return "nd.";
  }

  const [firstParticipant] = tour.participants;

  return firstParticipant
    ? `${firstParticipant.name} ${firstParticipant.surname}`
    : "nd.";
};
