"use client";

import {
  AdvancedMarker,
  AdvancedMarkerProps,
  AdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { PropsWithChildren, forwardRef } from "react";

export interface MarkerProps extends AdvancedMarkerProps, PropsWithChildren {}

export const Marker = forwardRef<AdvancedMarkerRef, MarkerProps>(
  (props, ref) => (
    <AdvancedMarker ref={ref} {...props}>
      {props.children}
    </AdvancedMarker>
  ),
);
