import { z } from "zod";

export const MetadataFormSchema = z.object({
  variantId: z.coerce.number(),
  availabilityMetadata: z
    .object({
      tourId: z.string(),
      optionId: z.string(),
      transferId: z.string(),
    })
    .optional(),
  ticketMetadata: z
    .object({
      tourId: z.string(),
      optionId: z.string(),
      transferId: z.string(),
    })
    .optional(),
});

export type MetadataForm = z.infer<typeof MetadataFormSchema>;

export type Metadata = NonNullable<MetadataForm["ticketMetadata"]>;
