import { Form, FormItem, Switch } from "@twocontinents/dashboard/shared";
import { useFormContext } from "react-hook-form";

import { ConfirmPaymentAlertDialog } from "./confirm-payment-alert-dialog";
import { OrderStatusFormValues } from "./use-order-details";

interface PaymentStatusSwitch {
  orderId: number;
}

export const PaymentStatusSwitch = ({ orderId }: PaymentStatusSwitch) => {
  const { control, watch } = useFormContext<OrderStatusFormValues>();

  const paymentConfirmed = watch("paymentConfirmed");

  return (
    <Form.Field
      control={control}
      name="status"
      render={({ field }) => (
        <FormItem className="flex items-center flex-col gap-2">
          {paymentConfirmed ? (
            <h3 className="text-sm">Payment status</h3>
          ) : (
            <ConfirmPaymentAlertDialog orderId={orderId} />
          )}
          <div className="flex items-center justify-start">
            <FormItem.Control>
              <Switch
                id={field.name}
                disabled
                checked={paymentConfirmed}
                onCheckedChange={field.onChange}
              />
            </FormItem.Control>
            <FormItem.Label
              className={`ml-3 ${paymentConfirmed ? "text-green-600" : "text-red-600"}`}
            >
              {paymentConfirmed ? "Paid" : "Unpaid"}
            </FormItem.Label>
          </div>
        </FormItem>
      )}
    />
  );
};
