import {
  AgeGroupSelect,
  GenderSelect,
  Tour,
} from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  ApiLocaleSelect,
  Button,
  Form,
  FormItem,
  Input,
  Textarea,
  buttonVariants,
} from "@twocontinents/dashboard/shared";

import { useCreateTourParticipantsDialog } from "./use-create-tour-participant-dialog";

interface CreateTourParticipantsDialogProps {
  tour: Tour;
}

export const CreateTourParticipantsDialog = ({
  tour,
}: CreateTourParticipantsDialogProps) => {
  const { ageGroups, isPending, form, onSubmit, openAlertDialog } =
    useCreateTourParticipantsDialog(tour);

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button className="w-fit self-end my-2" variant="outline">
          Add participants
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Add tour participants</AlertDialog.Title>
          <AlertDialog.Description className="text-white">
            Add tour participants
          </AlertDialog.Description>
        </AlertDialog.Header>
        <Form {...form}>
          <form onSubmit={onSubmit} className="flex flex-col gap-6 mt-4">
            <Form.Fields>
              <Form.Field
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Name</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="surname"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Surname</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="locale"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Locale</FormItem.Label>
                      <FormItem.Control>
                        <ApiLocaleSelect
                          value={field.value}
                          onValueChange={field.onChange}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="gender"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Gender</FormItem.Label>
                      <FormItem.Control>
                        <GenderSelect
                          value={field.value}
                          onValueChange={field.onChange}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="peopleAmount"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>People amount</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} type="number" />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="pickupLocation"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Pickup Location</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Phone number</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="ageGroupId"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Age group</FormItem.Label>
                      <FormItem.Control>
                        <AgeGroupSelect
                          ageGroups={ageGroups}
                          onValueChange={field.onChange}
                          value={field?.value?.toString()}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="additionalDetails"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Additional Details</FormItem.Label>
                      <FormItem.Control>
                        <Textarea {...field} className="max-w-none" rows={10} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            </Form.Fields>

            <AlertDialog.Footer>
              <AlertDialog.Cancel
                className={buttonVariants({
                  variant: "destructive",
                  className: "border-none",
                })}
              >
                Cancel
              </AlertDialog.Cancel>
              <Button
                isLoading={isPending}
                disabled={isPending}
                type="submit"
                variant="outline"
              >
                Add
              </Button>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
