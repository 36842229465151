import {
  AlertDialog,
  Button,
  useOpened,
} from "@twocontinents/dashboard/shared";
import {
  TravelAgency,
  TravelAgencyForm,
  TravelAgencyPersistRequest,
} from "@twocontinents/dashboard/travel-agencies/travel-agency/shared";

import { useUpdateTravelAgency } from "../../data-access";

interface UpdateTravelAgencyModalProps {
  travelAgency: TravelAgency;
}

export const UpdateTravelAgencyModal = ({
  travelAgency,
}: UpdateTravelAgencyModalProps) => {
  const { opened, open, close } = useOpened();
  const { updateTravelAgency, isPending } = useUpdateTravelAgency(
    travelAgency.id,
  );

  const handleUpdateTravelAgency = (data: TravelAgencyPersistRequest) => {
    updateTravelAgency(data);
    close();
  };

  return (
    <AlertDialog open={opened}>
      <AlertDialog.Trigger asChild onClick={open}>
        <Button variant={"secondary"}>Update travel agency</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Update travel agency</AlertDialog.Title>
        </AlertDialog.Header>

        <TravelAgencyForm
          handleSubmit={handleUpdateTravelAgency}
          initialTravelAgency={travelAgency}
          footer={
            <AlertDialog.Footer>
              <AlertDialog.Cancel onClick={close}>Cancel</AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button type={"submit"} isLoading={isPending}>
                  Submit
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Footer>
          }
        />
      </AlertDialog.Content>
    </AlertDialog>
  );
};
