import { ReactNode } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

export interface NavbarItemProps extends NavLinkProps {
  label: ReactNode;
  icon: ReactNode;
}

export const NavbarItem = ({ label, to, icon, ...props }: NavbarItemProps) => {
  return (
    <NavLink
      {...props}
      to={to}
      end
      className={({ isActive }) =>
        [
          "rounded-md",
          "hover:bg-slate-800",
          isActive ? "bg-slate-800" : "",
        ].join(" ")
      }
    >
      <span className="flex items-center px-3 py-2 text-sm font-medium text-slate-100">
        <i className=" mr-2">{icon}</i>
        {label}
      </span>
    </NavLink>
  );
};
