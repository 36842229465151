import { ApiLocale } from "@twocontinents/shared";

import { Select } from "./shadcn";

interface ApiLocaleSelectProps {
  defaultValue?: ApiLocale;
  onValueChange: (locale?: ApiLocale) => void;
  defaultOpen?: boolean;
  value?: ApiLocale;
  reset?: () => void;
  resetLabel?: string;
}

export const ApiLocaleSelect = ({
  onValueChange,
  defaultOpen,
  defaultValue,
  value,
  reset,
  resetLabel = "Reset",
}: ApiLocaleSelectProps) => {
  return (
    <Select
      defaultValue={defaultValue}
      onValueChange={(value) => onValueChange(value as ApiLocale)}
      defaultOpen={defaultOpen}
      value={value}
    >
      <Select.Trigger className="max-w-md h-max min-h-10">
        <Select.Value placeholder="Pick locale" />
      </Select.Trigger>
      <Select.Content>
        {reset && (
          <Select.Item value=" " onClick={() => reset()}>
            {resetLabel}
          </Select.Item>
        )}
        {Object.values(ApiLocale).map((currency) => (
          <Select.Item value={currency} key={currency}>
            {currency}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
