import {
  AttractionVariantSelectByGroups,
  TourSortBy,
} from "@twocontinents/dashboard/attractions/shared";
import {
  ApiLocaleSelect,
  AttractionCombobox,
  AttractionGroup,
  AttractionGroupSelect,
  Input,
  Label,
  Select,
  SingleOrRangeDatePicker,
} from "@twocontinents/dashboard/shared";

import { useTourFilters } from "./use-tour-filters";

export const TourFilters = () => {
  const {
    showRangePicker,
    toggleRangePicker,
    values,
    setters,
    tourableAttractions,
    groupsFromSelectedAttraction,
  } = useTourFilters();

  const {
    currentVariant,
    date,
    dateFrom,
    dateTo,
    time,
    additionalDetails,
    currentAttraction,
    currentGroup,
    sortBy,
    attractionId,
    tourId,
    locale,
  } = values;

  const {
    setCurrentVariantId,
    setTourDateFrom,
    setTourDateTo,
    setTourDate,
    setTourTime,
    setAdditionalDetails,
    setCurrentAttractionId,
    setCurrentGroupId,
    setSortBy,
    handleTourIdChange,
    setApiLocale,
  } = setters;

  return (
    <div className="flex gap-6 items-center flex-wrap py-4">
      <Input
        name="id"
        className="w-44"
        placeholder="Tour ID"
        type="number"
        value={tourId}
        onChange={handleTourIdChange}
      />
      <AttractionCombobox
        attractions={tourableAttractions}
        selectedId={attractionId}
        setSelectedOption={setCurrentAttractionId}
      />

      {currentAttraction !== undefined && (
        <AttractionGroupSelect
          onValueChange={setCurrentGroupId}
          attractionGroups={groupsFromSelectedAttraction}
          showAllGroupsOption
        />
      )}

      {currentGroup !== undefined && (
        <AttractionVariantSelectByGroups
          value={currentVariant?.id}
          onValueChange={setCurrentVariantId}
          attractionGroups={[currentGroup satisfies AttractionGroup]}
          showAllVariantsOption
        />
      )}

      <SingleOrRangeDatePicker
        showRangePicker={showRangePicker}
        toggleRangePicker={toggleRangePicker}
        dateFrom={dateFrom}
        setDateFrom={setTourDateFrom}
        dateTo={dateTo}
        setDateTo={setTourDateTo}
        time={time}
        setTime={setTourTime}
        date={date}
        setDate={setTourDate}
      />
      <div>
        <ApiLocaleSelect
          value={locale}
          onValueChange={setApiLocale}
          reset={() => setApiLocale(undefined)}
        />
      </div>

      <Input
        placeholder="Additional details"
        name="additionalDetails"
        value={additionalDetails}
        className="w-fit"
        onChange={(e) => setAdditionalDetails(e.currentTarget.value)}
      />
      <div className="flex flex-col gap-2">
        <Label htmlFor="sortBy">Sort by</Label>
        <Select onValueChange={setSortBy} value={sortBy} name="sortBy">
          <Select.Trigger className="w-[180px]">
            <Select.Value placeholder="Sort By" />
          </Select.Trigger>
          <Select.Content>
            <Select.Group>
              <Select.Label>Sort By</Select.Label>
              <Select.Item value={TourSortBy.DATE}>Date</Select.Item>
              <Select.Item value={TourSortBy.ATTRACTION_NAME}>
                Attraction name
              </Select.Item>
            </Select.Group>
          </Select.Content>
        </Select>
      </div>
    </div>
  );
};
