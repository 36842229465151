import {
  SortDirection,
  usePaginationQueryParams,
} from "@twocontinents/dashboard/shared";
import {
  BooleanParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

export const useAgeGroupTicketsFiltersQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    sortDirection: withDefault(
      createEnumParam(Object.values(SortDirection)),
      SortDirection.DESC,
    ),
    available: withDefault(BooleanParam, undefined),
  });

  const { page, size } = usePaginationQueryParams();

  return {
    query: {
      ...query,
      page,
      size,
    },
    setQuery,
  };
};
