import { TourSortBy } from "@twocontinents/dashboard/attractions/shared";
import {
  ALL_ITEMS_ID,
  AttractionOption,
  NOT_SELECTED_ID,
  useGetAttraction,
  useGetAttractions,
} from "@twocontinents/dashboard/shared";
import { ApiLocale, DateFormatter } from "@twocontinents/shared";
import { ChangeEvent, useEffect } from "react";
import { useBoolean } from "usehooks-ts";

import { useTourFiltersQueryParams } from "../../../hooks";

export const useTourFilters = () => {
  const { query, setQuery } = useTourFiltersQueryParams();

  const { attractions, isLoading } = useGetAttractions({
    select: (attractions) =>
      attractions.filter((attraction) => attraction.tour),
  });

  const currentAttraction = attractions.find(
    (attraction) => attraction.id === query.attractionId,
  );

  const { attraction: currentAttractionDetails } = useGetAttraction(
    currentAttraction?.id ?? NOT_SELECTED_ID,
  );

  const { attractionGroups: groupsFromSelectedAttraction } =
    currentAttractionDetails || {};

  const currentGroup = groupsFromSelectedAttraction?.find(
    (group) => group.id === query.attractionGroupId,
  );

  const { attractionVariants: variantsFromSelectedGroup } = currentGroup || {};

  const currentVariant = variantsFromSelectedGroup?.find(
    (variant) => variant.id === query.attractionVariantId,
  );

  const setCurrentAttractionId = (attraction?: AttractionOption) => {
    if (attraction?.id === ALL_ITEMS_ID) {
      setQuery({ attractionId: undefined });
      return;
    }
    setQuery({ attractionId: attraction?.id });
  };

  const setCurrentGroupId = (id: number | undefined) => {
    if (id === ALL_ITEMS_ID) {
      setQuery({
        attractionGroupId: undefined,
      });
      return;
    }
    setQuery({
      attractionGroupId: id,
    });
  };

  const setCurrentVariantId = (id: number | undefined) => {
    if (id === ALL_ITEMS_ID) {
      setQuery({
        attractionVariantId: undefined,
      });
      return;
    }
    setQuery({
      attractionVariantId: id,
    });
  };

  const setApiLocale = (locale: ApiLocale | undefined) => {
    setQuery({
      locale,
    });
  };

  const { value: showRangePicker, toggle: toggleShowRangePicker } =
    useBoolean(false);

  const handleTourIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery({
      tourId: event.target.valueAsNumber || undefined,
    });
  };

  const setTourDate = (date: Date | undefined) => {
    setQuery({
      date: date ? DateFormatter.formatToYYYYMMDD(date) : undefined,
    });
  };

  const setTourTime = (time: string | undefined) => {
    setQuery({
      time: time,
    });
  };

  const setTourDateFrom = (date: Date | undefined) => {
    setQuery({
      dateFrom: date ? DateFormatter.formatToYYYYMMDD(date) : undefined,
    });
  };

  const setTourDateTo = (date: Date | undefined) => {
    setQuery({
      dateTo: date ? DateFormatter.formatToYYYYMMDD(date) : undefined,
    });
  };

  const setAdditionalDetails = (details: string | undefined) => {
    setQuery({
      additionalDetails: details?.trim() || undefined,
    });
  };

  const setSortBy = (sortBy: TourSortBy) => {
    setQuery({
      sortBy,
    });
  };

  useEffect(() => {
    setCurrentVariantId(ALL_ITEMS_ID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroup?.id]);

  useEffect(() => {
    setCurrentGroupId(ALL_ITEMS_ID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAttraction?.id]);

  const toggleRangePicker = () => {
    toggleShowRangePicker();
    setQuery({
      date: undefined,
      time: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    });
  };

  return {
    isLoading,
    tourableAttractions: attractions,
    groupsFromSelectedAttraction: groupsFromSelectedAttraction ?? [],
    showRangePicker,
    toggleRangePicker,
    values: {
      ...query,
      currentAttraction,
      currentVariant,
      currentGroup,
    },
    setters: {
      setTourDate,
      setTourTime,
      setTourDateFrom,
      setTourDateTo,
      setAdditionalDetails,
      setCurrentAttractionId,
      setCurrentGroupId,
      setCurrentVariantId,
      setSortBy,
      handleTourIdChange,
      setApiLocale,
    },
  };
};
