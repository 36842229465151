/* eslint-disable max-lines-per-function */
import { useGetAttractions } from "@twocontinents/dashboard/shared";
import {
  ALL_ITEMS_ID,
  AttractionOption,
} from "@twocontinents/dashboard/shared";
import { ApiLocale, DateFormatter } from "@twocontinents/shared";
import dayjs from "dayjs";
import { ChangeEvent } from "react";
import { useBoolean } from "usehooks-ts";

import { useOrderFiltersQueryParams } from "../../../hooks";
import { PaymentStatusFilter } from "../../../types";

const getPaymentStatus = (paymentConfirmed: boolean | undefined) => {
  if (paymentConfirmed === undefined) {
    return PaymentStatusFilter.ALL;
  }
  return paymentConfirmed
    ? PaymentStatusFilter.CONFIRMED
    : PaymentStatusFilter.NOT_CONFIRMED;
};

export const useOrderFilters = () => {
  const { query, setQuery } = useOrderFiltersQueryParams();

  const { attractions } = useGetAttractions();

  const selectedId = query.attractionId ?? -1;

  const defaultPaymentStatus = getPaymentStatus(query.paymentConfirmed);

  const attractionsOptions =
    attractions?.map((attraction) => ({
      description: attraction.description,
      id: attraction.id,
    })) ?? [];

  const {
    customerName,
    customerSurname,
    customerEmail,
    orderId,
    reservationDate,
  } = query;

  const handleAttractionComboboxSelect = (value?: AttractionOption) => {
    if (value?.id === ALL_ITEMS_ID) {
      setQuery({ attractionId: undefined });
      return;
    }
    setQuery({ attractionId: value?.id });
  };

  const setDateRangeAsThisMonth = () => {
    openDateRangePicker();

    const today = dayjs();
    const firstDayOfThisMonth = today.startOf("month");
    const lastDayOfThisMonth = today.endOf("month");

    setQuery({
      reservationDateFrom: DateFormatter.formatToYYYYMMDD(firstDayOfThisMonth),
      reservationDateTo: DateFormatter.formatToYYYYMMDD(lastDayOfThisMonth),
    });
  };

  const handlePaymentStatusSelect = (value: PaymentStatusFilter) => {
    const paymentStatusAsNumber = Number(value);
    if (paymentStatusAsNumber === -1) {
      setQuery({ paymentConfirmed: undefined });
      return;
    }
    setQuery({ paymentConfirmed: !!paymentStatusAsNumber });
  };

  const handleReservationDateChange = (date?: Date) => {
    setQuery({
      reservationDate: date ? dayjs(date).format("YYYY-MM-DD") : undefined,
    });
  };

  function handleNameChange() {
    return (event: ChangeEvent<HTMLInputElement>) =>
      setQuery({ customerName: event.target.value });
  }

  function handleSurnameChange() {
    return (event: ChangeEvent<HTMLInputElement>) =>
      setQuery({ customerSurname: event.target.value });
  }

  function handleEmailChange() {
    return (event: ChangeEvent<HTMLInputElement>) =>
      setQuery({ customerEmail: event.target.value });
  }

  function handleOrderIdChange() {
    return (event: ChangeEvent<HTMLInputElement>) =>
      setQuery({ orderId: event.target.valueAsNumber || undefined });
  }

  const setReservationDateFrom = (date: Date | undefined) => {
    setQuery({
      reservationDateFrom: date
        ? DateFormatter.formatToYYYYMMDD(date)
        : undefined,
    });
  };

  const setReservationDateTo = (date: Date | undefined) => {
    setQuery({
      reservationDateTo: date
        ? DateFormatter.formatToYYYYMMDD(date)
        : undefined,
    });
  };

  const {
    value: showRangePicker,
    toggle: toggleShowRangePicker,
    setTrue: openDateRangePicker,
  } = useBoolean(false);

  const toggleRangePicker = () => {
    toggleShowRangePicker();
    setQuery({
      reservationDate: undefined,
      reservationDateFrom: undefined,
      reservationDateTo: undefined,
    });
  };

  const onLocaleChange = (locale: ApiLocale | undefined | " ") => {
    if (locale === " ") {
      return setQuery({ locale: undefined });
    }
    setQuery({ locale });
  };

  return {
    attractionsOptions,
    selectedId,
    defaultPaymentStatus,
    setQuery,
    handleAttractionComboboxSelect,
    handlePaymentStatusSelect,
    customerName,
    customerSurname,
    customerEmail,
    orderId,
    reservationDate,
    reservationDateFrom: query.reservationDateFrom,
    reservationDateTo: query.reservationDateTo,
    handleReservationDateChange,
    handleNameChange,
    handleSurnameChange,
    handleEmailChange,
    handleOrderIdChange,
    setReservationDateFrom,
    setReservationDateTo,
    toggleRangePicker,
    showRangePicker,
    onLocaleChange,
    locale: query.locale,
    setDateRangeAsThisMonth,
  };
};
