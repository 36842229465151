import { zodResolver } from "@hookform/resolvers/zod";
import {
  Tour,
  TourParticipant,
  useGetAttractionVariantAgeGroups,
  useGetAttractionVariantExtraOptions,
  useInvalidateGetTour,
} from "@twocontinents/dashboard/attractions/shared";
import { NOT_SELECTED_ID_STRING } from "@twocontinents/dashboard/shared";
import {
  ApiLocale,
  Gender,
  replaceNullWithUndefined,
} from "@twocontinents/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useUpdateTourParticipant } from "../../data-access";

const EditTourParticipantSchema = z.object({
  name: z.string().min(3, "Imię powinno mieć przynajmniej 3 znaki"),
  surname: z.string().min(3, "Nazwisko powinno mieć przynajmniej 3 znaki"),
  gender: z.nativeEnum(Gender).or(z.literal(NOT_SELECTED_ID_STRING)),
  locale: z.nativeEnum(ApiLocale),
  pickupLocation: z.string().optional(),
  ageGroupId: z.coerce.number(),
  phoneNumber: z.string().optional(),
  additionalDetails: z.string().optional(),
  extraOptions: z.array(
    z.object({
      amount: z.coerce
        .number()
        .nonnegative("Ilość nie może być mniejsza niż 0")
        .optional(),
      id: z.coerce.number().optional(),
    }),
  ),
});

export type EditTourParticipantSchema = z.infer<
  typeof EditTourParticipantSchema
>;

export const useEditParticipantDialog = (
  tour: Tour,
  tourParticipant: TourParticipant,
) => {
  const { updateTourParticipant, isPending } = useUpdateTourParticipant(
    tour.id,
  );

  const openAlertDialog = useBoolean(false);

  const form = useForm<EditTourParticipantSchema>({
    resolver: zodResolver(EditTourParticipantSchema),
    defaultValues: {
      ...replaceNullWithUndefined(tourParticipant),
      ageGroupId: tourParticipant.ageGroup.originalId,
      extraOptions: [],
    },
    shouldUnregister: true,
  });

  const { extraOptions } = useGetAttractionVariantExtraOptions(
    tour.attractionVariant.id,
  );

  const { ageGroups } = useGetAttractionVariantAgeGroups(
    tour.attractionVariant.id,
  );

  useEffect(() => {
    if (extraOptions) {
      const newExtraOptions = extraOptions.map((option) => {
        const participantOption = tourParticipant.extraOptions.find(
          (participantOption) =>
            participantOption.extraOption.originalId === option.id,
        );
        return {
          id: option.id,
          amount: participantOption?.amount ?? 0,
        };
      });

      form.setValue("extraOptions", newExtraOptions);
    }
  }, [extraOptions.length, openAlertDialog.value]);

  const onSubmit = form.handleSubmit((data) => {
    const { gender, ...rest } = data;
    updateTourParticipant(
      {
        participantId: tourParticipant.id,
        body: {
          ...rest,
          gender: gender === NOT_SELECTED_ID_STRING ? undefined : gender,
        },
      },
      {
        onSuccess(_, { body }) {
          if (body.ageGroupId) {
            form.reset({ ageGroupId: body.ageGroupId });
            openAlertDialog.setFalse();
          }
        },
      },
    );
  });

  return {
    form,
    isPending,
    ageGroups,
    extraOptions,
    onSubmit,
    openAlertDialog,
  };
};
