import { useMutation } from "@tanstack/react-query";
import { getApiClient } from "@twocontinents/shared";

interface SignIn {
  username: string;
  password: string;
}

export const useSignIn = () => {
  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ password, username }: SignIn) => {
      const apiClient = getApiClient();

      return apiClient
        .POST("/public/system/auth/users/login", {
          body: {
            login: username,
            password,
          },
        })
        .then((response) => {
          if (!response.data?.accessToken) {
            throw new Error("Invalid credentials");
          }
          return response.data.accessToken;
        });
    },
  });

  return {
    signIn: mutate,
    isPending,
    error,
  };
};
