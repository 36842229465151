import {
  SortDirection,
  Table,
  buttonVariants,
  routes,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { Link } from "react-router-dom";

import { useAgeGroupTicketsFiltersQueryParams } from "../../../hooks";
import { AgeGroupTicket } from "../../../types";

interface AgeGroupTicketsTableProps {
  tickets: AgeGroupTicket[];
}
export const AgeGroupTicketsTable = ({
  tickets,
}: AgeGroupTicketsTableProps) => {
  const { setQuery } = useAgeGroupTicketsFiltersQueryParams();

  const handleToggleSortDirection = () => {
    setQuery((prev) => ({
      ...prev,
      sortDirection:
        prev.sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC,
    }));
  };

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Cell>Code</Table.Cell>
          <Table.Cell>Available</Table.Cell>
          <Table.Cell>Created At</Table.Cell>
          <Table.Cell
            className="cursor-pointer"
            onClick={handleToggleSortDirection}
          >
            Expires At
          </Table.Cell>
          <Table.Cell>Order</Table.Cell>
          <Table.Cell>Used in ticket: </Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {tickets.map((ticket) => (
          <Table.Row key={ticket.code}>
            <Table.Cell>{ticket.code}</Table.Cell>
            <Table.Cell>
              {ticket.available ? (
                <span className="text-green-500">YES</span>
              ) : (
                <span className="text-red-500">NO</span>
              )}
            </Table.Cell>
            <Table.Cell>
              {DateFormatter.formatToDDMMYYYYHHmm(ticket.createdAt)}
            </Table.Cell>
            <Table.Cell>
              {DateFormatter.formatToDDMMYYYYHHmm(ticket.expiresAt)}
            </Table.Cell>
            <Table.Cell>
              {ticket.orderId ? (
                <Link
                  className={buttonVariants({ variant: "outline" })}
                  to={routes.attractions.orders.show.url(ticket.orderId)}
                >
                  Order #{ticket.orderId}
                </Link>
              ) : (
                "N/A"
              )}
            </Table.Cell>
            <Table.Cell>
              {ticket.ticketId && ticket.ticketUrl ? (
                <Link
                  className={buttonVariants({ variant: "outline" })}
                  to={ticket.ticketUrl}
                  target="_blank"
                >
                  Ticket #{ticket.ticketId}
                </Link>
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
