import { zodResolver } from "@hookform/resolvers/zod";
import { PercentageConverter } from "@twocontinents/dashboard/shared";
import { ApiLocale } from "@twocontinents/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  useGetAttractionBusinessPartners,
  useUpdateAttractionCommissions,
} from "../../data-access";
import { AttractionBusinessPartner } from "../../types";

const CommissionsSettingsFormSchema = z
  .object({
    businessPartners: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        commission: z.coerce.number().nonnegative().max(100),
        locale: z.nativeEnum(ApiLocale),
      }),
    ),
    locale: z.nativeEnum(ApiLocale),
  })
  .refine(
    ({ businessPartners }) => {
      const totalPercentage = businessPartners.reduce(
        (acc, { commission }) => acc + commission,
        0,
      );

      return totalPercentage === 100;
    },
    ({ businessPartners }) => ({
      message: "Total commission percentage should be 100",
      path: [`businessPartners.${businessPartners.length - 1}.commission`],
    }),
  );

type CommissionsSettingsFormSchema = z.infer<
  typeof CommissionsSettingsFormSchema
>;

export const useCommissionsSettings = (attractionId: number) => {
  const { updateAttractionCommissions, isPending } =
    useUpdateAttractionCommissions();

  const { businessPartners } = useGetAttractionBusinessPartners(attractionId);

  const form = useForm<CommissionsSettingsFormSchema>({
    resolver: zodResolver(CommissionsSettingsFormSchema),
  });

  const locale = form.watch("locale");

  const filteredBusinessPartners: AttractionBusinessPartner[] = [];
  const commissionSettableLocales: ApiLocale[] = [];

  businessPartners.forEach((partner) => {
    if (partner.locale === locale) {
      filteredBusinessPartners.push(partner);
    }
    if (!commissionSettableLocales.includes(partner.locale)) {
      commissionSettableLocales.push(partner.locale);
    }
  });

  const onSubmit = form.handleSubmit(({ businessPartners }) => {
    updateAttractionCommissions({
      attractionId,
      body: {
        partnersDetails: businessPartners.map(
          ({ id: partnerId, commission, ...partner }) => ({
            ...partner,
            partnerId,
            commission: PercentageConverter.from(commission),
          }),
        ),
      },
    });
  });

  useEffect(() => {
    form.setValue(
      "businessPartners",
      filteredBusinessPartners.map(({ commission, ...partner }) => ({
        ...partner,
        commission: PercentageConverter.to(commission),
      })),
    );
  }, [businessPartners, locale]);

  return {
    form,
    onSubmit,
    isPending,
    filteredBusinessPartners,
    commissionSettableLocales,
  };
};
