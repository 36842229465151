import { cn } from "@twocontinents/dashboard/shared";
import { Button } from "react-day-picker";

import { CalendarDayProps, useCalendarDay } from "./use-calendar-day";

export const CalendarDay = (props: CalendarDayProps) => {
  const { dayRender, buttonRef, isAvailable, isNotAvailable } =
    useCalendarDay(props);

  return (
    <Button
      {...dayRender.buttonProps}
      ref={buttonRef}
      className={cn(
        {
          "!bg-green-600": isAvailable,
          "!bg-red-600": isNotAvailable,
        },
        "calendar-font m-1 border-transparent outline-none focus-within:border-slate-100 border relative h-12 w-12 rounded-md flex justify-center items-center p-0 font-normal  aria-selected:opacity-100, hover:text-slate-50 hover:border-slate-600 hover:bg-slate-600",
      )}
    >
      {dayRender.buttonProps.children}
    </Button>
  );
};
