export const getFormData = (
  body: Record<string, unknown>,
  files: {
    key: string;
    value: File;
  }[],
) => {
  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === "object" && item !== null) {
          Object.entries(item).forEach(([itemKey, itemValue]) => {
            formData.append(`${key}[${index}].${itemKey}`, String(itemValue));
          });
        } else {
          formData.append(`${key}[${index}]`, String(item));
        }
      });
    } else {
      formData.append(key, String(value));
    }
  });

  files.forEach(({ key, value }) => {
    formData.append(key, value);
  });

  return formData;
};
