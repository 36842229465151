import { TourStatus } from "@twocontinents/dashboard/attractions/shared";
import { DateFormatter } from "@twocontinents/shared";

import { TourMessage } from "../../types";

interface MessageHeaderDescriptionProps {
  message: TourMessage;
  tourStatus: TourStatus;
}
export const MessageHeaderDescription = ({
  message,
  tourStatus,
}: MessageHeaderDescriptionProps) => {
  if (message.cancelled) {
    return (
      <p className="text-yellow-400 font-bold text-lg">
        Message sending was cancelled
      </p>
    );
  }

  if (message.sent && message.sentAt) {
    return (
      <p className="text-green-500 font-bold text-lg">
        Sent at: {DateFormatter.formatToDDMMYYYYHHmm(message.sentAt)}
      </p>
    );
  }

  return (
    <p className="text-yellow-400 font-bold text-lg">
      {getMessageBasedOnStatus(tourStatus, message.scheduledFor)}
    </p>
  );
};

const getMessageBasedOnStatus = (
  tourStatus: TourStatus,
  scheduledFor: string,
) => {
  switch (tourStatus) {
    case TourStatus.CANCELLED: {
      return "Message was not sent due to tour cancellation";
    }
    case TourStatus.DONE: {
      return "Message was not sent due to early tour completion";
    }
    default: {
      return `Message will be sent ${DateFormatter.formatToDDMMYYYYHHmm(
        scheduledFor,
      )}`;
    }
  }
};
