import { Tabs, routes } from "@twocontinents/dashboard/shared";
import { Link } from "react-router-dom";

import { TravelAgencyTab } from "../types";

interface TravelAgencyTabsProps {
  activeTab: TravelAgencyTab;
}
export const TravelAgencyTabs = ({ activeTab }: TravelAgencyTabsProps) => {
  return (
    <Tabs defaultValue={activeTab}>
      <Tabs.List>
        <Link to={routes.travelAgencies.index}>
          <Tabs.Trigger value={TravelAgencyTab.TRAVEL_AGENCY}>
            Travel Agencies
          </Tabs.Trigger>
        </Link>
        <Link to={routes.travelAgencies.offerBundle.index}>
          <Tabs.Trigger value={TravelAgencyTab.OFFER_BUNDLE}>
            Offer Bundles
          </Tabs.Trigger>
        </Link>
        <Link to={routes.travelAgencies.invoices.index}>
          <Tabs.Trigger value={TravelAgencyTab.INVOICES}>Invoices</Tabs.Trigger>
        </Link>
      </Tabs.List>
    </Tabs>
  );
};
