import { z } from "zod";

export enum Locale {
  PL = "pl",
  EN = "en",
  RO = "ro",
}

export const LocaleSchema = z
  .enum([Locale.PL, Locale.EN, Locale.RO])
  .default(Locale.PL);
