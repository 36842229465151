import {
  AttractionCombobox,
  Button,
  DataTable,
  Form,
  FormItem,
  Input,
  Select,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { columns } from "./columns";
import {
  OfferBundleFormProps,
  useOfferBundleForm,
} from "./use-offer-bundle-form";

export const OfferBundleForm = (props: OfferBundleFormProps) => {
  const {
    content,
    form,
    handleSubmit,
    isPending,
    copiedBundleId,
    setCopiedBundleId,
    copyOfferBundle,
    offerBundles,
    entries,
    attractions,
  } = useOfferBundleForm(props);

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>{content.title}</SettingCard.Title>
        <SettingCard.Description>{content.description}</SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form
          onSubmit={handleSubmit}
          className="flex gap-6 flex-col justify-between"
        >
          <div className={"flex flex-col gap-8 justify-between"}>
            <Form.Field
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Name</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <div className={"flex gap-4"}>
              <Form.Field
                name={"copyFrom"}
                render={() => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.LabelNoContext>
                        Copy Offers
                      </FormItem.LabelNoContext>
                      <FormItem.Control>
                        <Select
                          value={copiedBundleId?.toString()}
                          onValueChange={(value) => {
                            setCopiedBundleId(Number(value));
                          }}
                        >
                          <Select.Trigger className={"w-64"}>
                            <Select.Value placeholder="Select Offer Bundle" />
                          </Select.Trigger>
                          <Select.Content>
                            {offerBundles.map((offerBundle) => (
                              <Select.Item
                                key={offerBundle.id}
                                value={offerBundle.id.toString()}
                              >
                                {offerBundle.name}
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />

              <Button type={"button"} onClick={copyOfferBundle}>
                Copy
              </Button>
            </div>
          </div>

          <DataTable
            paginationType={"client"}
            initialState={{
              columnVisibility: {
                attractionId: false,
              },
            }}
            header={(table) => (
              <div className={"flex gap-4 w-full"}>
                <AttractionCombobox
                  attractions={attractions}
                  selectedId={
                    table.getColumn("attractionId")?.getFilterValue() as
                      | number
                      | undefined
                  }
                  setSelectedOption={(attractionId) => {
                    table
                      .getColumn("attractionId")
                      ?.setFilterValue(attractionId.id);
                  }}
                />
                <Input
                  placeholder="Filter by Age Group / Extra Option"
                  value={
                    (table
                      .getColumn("description")
                      ?.getFilterValue() as string) ?? ""
                  }
                  onChange={(event) =>
                    table
                      .getColumn("description")
                      ?.setFilterValue(event.target.value)
                  }
                  className="max-w-sm"
                />{" "}
                <Input
                  placeholder="Filter by Variant / Attraction"
                  value={
                    (table
                      .getColumn("relatedDescription")
                      ?.getFilterValue() as string) ?? ""
                  }
                  onChange={(event) =>
                    table
                      .getColumn("relatedDescription")
                      ?.setFilterValue(event.target.value)
                  }
                  className="max-w-sm"
                />
              </div>
            )}
            columns={columns}
            data={entries}
          />

          <SettingCard.Footer>
            <SettingCard.Description>{content.footer}</SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
