import {
  ColumnDef,
  ColumnFiltersState,
  TableOptions,
  Table as TableType,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ReactNode, useState } from "react";

import { Pagination } from "../pagination";
import { Table } from "./table";

interface DataTableProps<TData, TValue> extends Partial<TableOptions<TData>> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  header?: ((table: TableType<TData>) => ReactNode) | ReactNode;
  paginationType?: "server" | "client";
  totalPages?: number;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  header,
  paginationType = "server",
  totalPages,
  ...options
}: DataTableProps<TData, TValue>) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    ...options,
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
    },
  });

  const clientSideTotalPages = table.getPageCount() ?? 1;
  const currentPage = table.getState().pagination.pageIndex;
  const size = table.getState().pagination.pageSize;

  return (
    <div>
      <div className="flex items-center pb-4">
        {typeof header === "function" ? header(table) : header}
      </div>
      <div className="rounded-md">
        <Table>
          <Table.Header>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Table.Head key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </Table.Head>
                  );
                })}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Table.Row
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      {paginationType === "client" ? (
        <Pagination
          totalPages={clientSideTotalPages}
          currentPage={currentPage}
          setPage={(page) => table.setPageIndex(page)}
          size={size}
          setSize={(size) => table.setPageSize(size)}
        />
      ) : (
        <Pagination totalPages={totalPages ?? 1} />
      )}
    </div>
  );
}
