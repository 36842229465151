import {
  TourStatus,
  useInvalidateGetTour,
  useInvalidateGetTours,
} from "@twocontinents/dashboard/attractions/shared";
import { toast } from "@twocontinents/dashboard/shared";

import { useUpdateTourStatus } from "../../data-access";

export const useCancelTourAlertDialog = () => {
  const { updateTourStatus, isPending } = useUpdateTourStatus();
  const { invalidate } = useInvalidateGetTour();
  const { invalidate: invalidateGetTours } = useInvalidateGetTours();

  const cancelTour = (tourId: number) => {
    updateTourStatus(
      {
        tourId,
        body: {
          status: TourStatus.CANCELLED,
        },
      },
      {
        onSuccess: () => {
          toast.success("Tour has been cancelled");
        },
        onError: () => {
          toast.error("Failed to cancel tour");
        },
        onSettled: () => {
          void invalidate(tourId);
          void invalidateGetTours();
        },
      },
    );
  };

  return {
    cancelTour,
    isPending,
  };
};
