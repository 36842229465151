import {
  TourBase,
  TourFormatter,
  checkIfTourHasPrimaryFinancialLog,
} from "@twocontinents/dashboard/attractions/shared";
import {
  StatusIndicator,
  Table,
  buttonVariants,
  cn,
  routes,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { TravelAgencyIcon } from "@twocontinents/shared-ui";
import { AlertTriangle, DollarSignIcon } from "lucide-react";
import { Link } from "react-router-dom";

import { getFullNameFromTour, getOrderIdFromTour } from "../../../utils";
import { TourDetailsDropdown } from "./tour-details-dropdown";
import { TourMessageStatusIndicator } from "./tour-message-status-indicator";
import { hasBeenBoughtByAgent, useToursTable } from "./use-tours-table";

interface ToursTableProps {
  tours: TourBase[];
}

export const ToursTable = ({ tours }: ToursTableProps) => {
  const {
    areDropdownsOpened,
    openDropdown,
    toggleSortDirection,
    handleDropdownOpenChange,
  } = useToursTable();

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Head className="py-5">ID</Table.Head>
          <Table.Head className="w-1/4">Tour type</Table.Head>
          <Table.Head className="w-1/4">Locale</Table.Head>
          <Table.Head
            onClick={toggleSortDirection}
            className="w-1/5 cursor-pointer font-bold"
          >
            Tour Date
          </Table.Head>
          <Table.Head>Participants amount</Table.Head>
          <Table.Head>Places left</Table.Head>
          <Table.Head>Full name</Table.Head>
          <Table.Head>Order ID</Table.Head>
          <Table.Head>Message</Table.Head>
          <Table.Head>Action</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body className="w-full relative">
        {tours.map((tour, i) => (
          <Table.Row key={tour.id} className="cursor-pointer">
            <Table.Cell>
              <TourDetailsDropdown
                tour={tour}
                setIsDropdownOpened={(opened) =>
                  handleDropdownOpenChange(opened, i)
                }
                isDropdownOpened={areDropdownsOpened[i]}
              />
            </Table.Cell>
            <Table.Cell onClick={() => openDropdown(i)}>
              {TourFormatter.fullAttractionName(tour)}
            </Table.Cell>
            <Table.Cell onClick={() => openDropdown(i)}>
              {tour.locales.join("/")}
            </Table.Cell>
            <Table.Cell onClick={() => openDropdown(i)}>
              {DateFormatter.formatToDDMMYYYY(tour.date)}{" "}
              {tour.time ? tour.time.slice(0, 5) : ""}
            </Table.Cell>
            <Table.Cell onClick={() => openDropdown(i)}>
              {tour.participants.length}
            </Table.Cell>
            <Table.Cell onClick={() => openDropdown(i)}>
              {tour.maxParticipants === -1 ? "nd." : tour.placesLeft}
            </Table.Cell>
            <Table.Cell onClick={() => openDropdown(i)}>
              {getFullNameFromTour(tour)}
            </Table.Cell>
            <Table.Cell onClick={() => openDropdown(i)}>
              {getOrderIdFromTour(tour)}
            </Table.Cell>

            <Table.Cell>
              {tour.messageSent === undefined ||
              tour.messageCancelled === undefined ? (
                <StatusIndicator status="negative">No message</StatusIndicator>
              ) : (
                <TourMessageStatusIndicator
                  sent={tour.messageSent}
                  cancelled={tour.messageCancelled}
                />
              )}
            </Table.Cell>
            <Table.Cell onClick={() => openDropdown(i)}>
              <div className="flex items-center gap-2 relative">
                {tour.additionalDetails && (
                  <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                    <AlertTriangle size={20} color="#002aff" />
                  </div>
                )}
                {!checkIfTourHasPrimaryFinancialLog(tour.financialLogs) && (
                  <div className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2">
                    <DollarSignIcon size={20} color="#ff0000" />
                  </div>
                )}
                {hasBeenBoughtByAgent(tour) && (
                  <div className="absolute bottom-0 left-0 transform -translate-x-1/2 translate-y-1/2">
                    <TravelAgencyIcon />
                  </div>
                )}
                <Link
                  to={routes.attractions.tours.show.url(tour.id)}
                  className={cn(buttonVariants({ variant: "outline" }))}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Open
                </Link>
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
