import { useMutation } from "@tanstack/react-query";
import {
  useFinancialLogsQueryParams,
  useInvalidateGetFinancialLogs,
} from "@twocontinents/dashboard/financial-log/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type CreateFinancialLog =
  components["schemas"]["CreateManualFinancialLogEntryRequest"];

export const useCreateFinancialLog = () => {
  const apiClient = usePrivateApiClient();
  const { query } = useFinancialLogsQueryParams();
  const { invalidate } = useInvalidateGetFinancialLogs();

  const { mutate, isPending } = useMutation({
    mutationFn: async (request: CreateFinancialLog) => {
      const response = await apiClient.POST("/private/financial-log/", {
        body: request,
      });
      return response.data;
    },
    onSuccess: async () => {
      toast.success("Financial log has been added");
      await invalidate(query);
    },
    onError: () => {
      toast.error("Could not add financial log");
    },
  });

  return { createFinancialLog: mutate, isPending };
};
