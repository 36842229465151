import { TourParticipant } from "@twocontinents/dashboard/attractions/shared";

export const useTourDetailsDropdown = (participants: TourParticipant[]) => {
  const uniqueParticipants = participants.reduce((acc, curr) => {
    const { name, surname } = curr;
    const participant = acc.find(
      (participant) =>
        participant.name === name &&
        participant.surname === surname &&
        participant.phoneNumber === curr.phoneNumber &&
        participant.pickupLocation === curr.pickupLocation &&
        participant.orderId === curr.orderId,
    );
    if (!participant) {
      acc.push(curr);
    }
    return acc;
  }, [] as TourParticipant[]);

  return {
    uniqueParticipants,
  };
};
