import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetInvoices } from "./get-invoices";

type UpdateInvoiceStatus = components["schemas"]["UpdateInvoiceStatusRequest"];

type Props = {
  agencyId: number;
  id: number;
  body: UpdateInvoiceStatus;
};

export const useUpdateInvoiceStatus = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetInvoices();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ agencyId, id, body }: Props) => {
      return apiClient
        .PATCH("/private/partners/travel-agencies/{id}/invoices/{invoiceId}", {
          body,
          params: {
            path: {
              id: agencyId,
              invoiceId: id,
            },
          },
        })
        .then((response) => response.data);
    },
    onSuccess: async () => {
      await invalidate({});
      toast.success("Invoice updated successfully");
    },
    onError: () => {
      toast.error("Could not update invoice");
    },
  });

  return { updateInvoiceStatus: mutate, isPending };
};
