import {
  NOT_SELECTED_ID_STRING,
  Select,
} from "@twocontinents/dashboard/shared";
import { Gender } from "@twocontinents/shared";

interface GenderSelectProps {
  defaultValue?: Gender;
  onValueChange: (gender?: string) => void;
  defaultOpen?: boolean;
  value?: Gender | typeof NOT_SELECTED_ID_STRING;
}

export const GenderSelect = ({
  onValueChange,
  defaultOpen,
  defaultValue,
  value,
}: GenderSelectProps) => {
  return (
    <Select
      defaultValue={defaultValue}
      onValueChange={(selected) => {
        onValueChange(selected);
      }}
      defaultOpen={defaultOpen}
      value={value}
    >
      <Select.Trigger className="max-w-md h-max min-h-10">
        <Select.Value placeholder="-" />
      </Select.Trigger>
      <Select.Content>
        <Select.Item value={NOT_SELECTED_ID_STRING}>-</Select.Item>
        {Object.values(Gender).map((gender) => (
          <Select.Item value={gender} key={gender}>
            {gender}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
