import {
  CurrencyCodeSelect,
  Form,
  FormItem,
  Input,
  NOT_SELECTED_ID,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { InvoiceStatusSelect } from "@twocontinents/dashboard/travel-agencies/invoices/shared";
import { TravelAgenciesCombobox } from "@twocontinents/dashboard/travel-agencies/shared";
import { CurrencyCode } from "@twocontinents/shared";

import { useCreateInvoiceForm } from "./use-create-invoice-form";

export const CreateInvoiceForm = () => {
  const {
    handleCreateInvoiceFormSubmit,
    form,

    isPending,
    handleFileInputChange,
    isFormValid,
  } = useCreateInvoiceForm();

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Create Invoice</SettingCard.Title>
        <SettingCard.Description>
          Create a new invoice by filling out the form below
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form
          className="flex gap-6 flex-col justify-between"
          onSubmit={handleCreateInvoiceFormSubmit}
        >
          <Form.Field
            control={form.control}
            name="providerNumber"
            render={({ field: { onChange } }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-24">Number</FormItem.Label>
                  <FormItem.Control>
                    <Input onChange={onChange} />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="issueDate"
            render={({ field: { onChange } }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-24">
                    Issue Date
                  </FormItem.Label>
                  <FormItem.Control>
                    <Input onChange={onChange} type="date" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="sellDate"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-24">
                    Sell Date
                  </FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} type="date" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="paymentDeadline"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-24">
                    Payment To
                  </FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} type="date" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="travelAgencyId"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-24">
                    Travel Agency
                  </FormItem.Label>
                  <TravelAgenciesCombobox
                    setSelectedOption={(option) => {
                      field.onChange(option?.id ?? NOT_SELECTED_ID);
                    }}
                    selectedId={field.value}
                  />
                </FormItem.Row>
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-24">Status</FormItem.Label>
                  <InvoiceStatusSelect onValueChange={field.onChange} />
                </FormItem.Row>
              </FormItem>
            )}
          />

          <FormItem>
            <FormItem.Row>
              <FormItem.Label className="min-w-24">Invoice</FormItem.Label>
              <Input
                type="file"
                className="w-fit"
                onChange={handleFileInputChange}
              />
            </FormItem.Row>
          </FormItem>
          <Form.Field
            control={form.control}
            name={`amount`}
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-24">Amount</FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} type="number" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="currency"
            render={({ field: { onChange } }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-24">Currency</FormItem.Label>
                  <CurrencyCodeSelect
                    defaultValue={CurrencyCode.AED}
                    currencies={Object.values(CurrencyCode)}
                    onValueChange={onChange}
                  />
                </FormItem.Row>
              </FormItem>
            )}
          />

          <SettingCard.Footer>
            <SettingCard.Description>
              Create a new invoice by filling out the form
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending} disabled={!isFormValid}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
