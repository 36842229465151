import { Form, FormItem, Input } from "@twocontinents/dashboard/shared";
import { useFormContext } from "react-hook-form";

import { MetadataForm } from "./metadata-form-schema";

interface MetadataFormFieldsProps {
  type: "availabilityMetadata" | "ticketMetadata";
}

export const MetadataFormFields = ({ type }: MetadataFormFieldsProps) => {
  const form = useFormContext<MetadataForm>();
  return (
    <>
      <Form.Field
        control={form.control}
        name={`${type}.tourId`}
        render={({ field }) => (
          <FormItem>
            <FormItem.Row>
              <FormItem.Label className="min-w-32">Tour ID</FormItem.Label>
              <FormItem.Control>
                <Input {...field} className="max-w-md" />
              </FormItem.Control>
            </FormItem.Row>
            <FormItem.Message />
          </FormItem>
        )}
      />
      <Form.Field
        control={form.control}
        name={`${type}.optionId`}
        render={({ field }) => (
          <FormItem>
            <FormItem.Row>
              <FormItem.Label className="min-w-32">Option ID</FormItem.Label>
              <FormItem.Control>
                <Input {...field} className="max-w-md" />
              </FormItem.Control>
            </FormItem.Row>
            <FormItem.Message />
          </FormItem>
        )}
      />
      <Form.Field
        control={form.control}
        name={`${type}.transferId`}
        render={({ field }) => (
          <FormItem>
            <FormItem.Row>
              <FormItem.Label className="min-w-32">Transfer ID</FormItem.Label>
              <FormItem.Control>
                <Input {...field} className="max-w-md" />
              </FormItem.Control>
            </FormItem.Row>
            <FormItem.Message />
          </FormItem>
        )}
      />
    </>
  );
};
