import {
  Form,
  FormItem,
  Input,
  Layout,
  Select,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { useCommissionsSettings } from "./use-commissions-settings";

interface CommissionsSettingsProps {
  attractionId: number;
}

export const CommissionsSettings = ({
  attractionId,
}: CommissionsSettingsProps) => {
  const {
    form,
    onSubmit,
    isPending,
    filteredBusinessPartners,
    commissionSettableLocales,
  } = useCommissionsSettings(attractionId);

  return (
    <Layout.Content>
      <SettingCard>
        <SettingCard.Header>
          <SettingCard.Title>Commissions settings</SettingCard.Title>
          <SettingCard.Description>
            Change attraction commissions per business partner
          </SettingCard.Description>
        </SettingCard.Header>
        <Form {...form}>
          <form onSubmit={onSubmit}>
            <Form.Fields>
              <Form.Field
                control={form.control}
                name="locale"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Locale</FormItem.Label>
                      <FormItem.Control>
                        <Select onValueChange={field.onChange}>
                          <Select.Trigger className="max-w-md h-max min-h-10 min-w-44">
                            <Select.Value placeholder="Choose locale" />
                          </Select.Trigger>
                          <Select.Content>
                            {commissionSettableLocales.map((locale) => (
                              <Select.Item key={locale} value={locale}>
                                {locale}
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />

              {filteredBusinessPartners.map((businessPartner, index) => (
                <Form.Field
                  key={`${businessPartner.id}-${businessPartner.locale}`}
                  control={form.control}
                  name={`businessPartners.${index}.commission`}
                  render={({ field }) => (
                    <FormItem>
                      <FormItem.Row className={"gap-0"}>
                        <FormItem.Label className={"mr-10"}>
                          {businessPartner.name} ({businessPartner.locale})
                        </FormItem.Label>
                        <FormItem.Control>
                          <Input
                            {...field}
                            type={"number"}
                            max={100}
                            className={"w-14"}
                          />
                        </FormItem.Control>
                        <FormItem.Description className={"ml-2"}>
                          %
                        </FormItem.Description>
                      </FormItem.Row>
                      <FormItem.Message />
                    </FormItem>
                  )}
                />
              ))}
            </Form.Fields>

            <SettingCard.Footer>
              <SettingCard.Description>
                This setting will change attraction description only on the
                dashboard.
              </SettingCard.Description>
              <SettingCard.Button isLoading={isPending}>
                Submit
              </SettingCard.Button>
            </SettingCard.Footer>
          </form>
        </Form>
      </SettingCard>
    </Layout.Content>
  );
};
