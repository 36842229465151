import { Skeleton } from "@twocontinents/dashboard/shared";

export const OrderDetailsSkeleton = () => {
  return (
    <article className="flex grow flex-wrap py-12">
      <div className="flex w-full flex-col gap-6">
        <div className="relative border border-slate-800 divide-y divide-slate-800 rounded-md p-5">
          <Skeleton className=" h-7 w-36" />
          <Skeleton className=" my-4 h-6 w-96 text-slate-300 " />

          <div className="flex w-full gap-3 border-transparent   mx-auto flex-wrap h-min">
            <Skeleton className="grow h-7  border-transparent" />
            <Skeleton className="grow h-7 border-transparent" />
            <Skeleton className="grow h-7 border-transparent" />
          </div>

          <div className="mt-5">
            <Skeleton className="py-5 px-6 bg-slate-900 transition flex justify-between mt-3"></Skeleton>
            <Skeleton className="py-5 px-6 bg-slate-900 transition flex justify-between mt-3"></Skeleton>
            <Skeleton className="py-5 px-6 bg-slate-900 transition flex justify-between mt-3"></Skeleton>
            <Skeleton className="py-5 px-6 bg-slate-900 transition flex justify-between mt-3"></Skeleton>
          </div>

          <div className="flex justify-center gap-3 mt-7 mb-20">
            <div className="flex flex-col items-center mt-5">
              <Skeleton className="w-24 h-5"></Skeleton>
              <Skeleton className="py-5 px-6  w-36 bg-slate-700 transition flex justify-between mt-3"></Skeleton>
            </div>
            <div className="flex flex-col items-center mt-5">
              <Skeleton className="w-24 h-5"></Skeleton>
              <Skeleton className="py-5 px-6  w-36 bg-slate-700 transition flex justify-between mt-3"></Skeleton>
            </div>
          </div>
          <footer className=" w-full absolute bottom-0 left-0 px-6 mt-5 py-4 items-center justify-between bg-slate-900 flex">
            <Skeleton className="h-6 text-slate-300 w-124" />
            <Skeleton className=" h-10 w-20" />
          </footer>
        </div>
        <div className="relative border border-slate-800 divide-y divide-slate-800 rounded-md p-5">
          <Skeleton className=" h-7 w-36" />
          <Skeleton className=" my-4 h-6 w-96 text-slate-300 " />

          <div className="mt-5">
            <Skeleton className="py-5 px-6 bg-slate-900 transition flex justify-between mt-3"></Skeleton>
          </div>
        </div>
        <div className="relative border border-slate-800 divide-y divide-slate-800 rounded-md p-5">
          <Skeleton className=" h-7 w-36" />
          <Skeleton className=" my-4 h-6 w-96 text-slate-300 " />

          <div className="mt-5 mb-20">
            <Skeleton className="py-5 px-6 bg-slate-900 transition flex justify-between mt-3"></Skeleton>
            <Skeleton className="py-5 px-6 bg-slate-900 transition flex justify-between mt-3"></Skeleton>
            <Skeleton className="py-5 px-6 bg-slate-900 transition flex justify-between mt-3"></Skeleton>
            <Skeleton className="py-5 px-6 bg-slate-900 transition flex justify-between mt-3"></Skeleton>
          </div>

          <footer className=" w-full absolute bottom-0 left-0 px-6 mt-5 py-4 items-center justify-between bg-slate-900 flex">
            <Skeleton className="h-6 w-124 text-slate-300" />
            <Skeleton className=" h-10 w-20" />
          </footer>
        </div>
      </div>
    </article>
  );
};
