import { AttractionGroup } from "@twocontinents/dashboard/attractions/shared";
import { DragAndDrop, SettingCard } from "@twocontinents/dashboard/shared";

import { useFormSequenceSettings } from "./use-form-sequence-settings";

interface FormSequenceSettingsProps {
  attractionGroups: AttractionGroup[];
  attractionId: number;
}

export const FormSequenceSettings = ({
  attractionId,
  attractionGroups,
}: FormSequenceSettingsProps) => {
  const { handleDragEnd, handleFormSubmit, isPending, sortableVariants } =
    useFormSequenceSettings(attractionGroups, attractionId);

  return (
    <SettingCard className="my-4">
      <SettingCard.Header>
        <SettingCard.Title>Form Sequence settings</SettingCard.Title>
        <SettingCard.Description>
          Set the sequence of attractions variants in the form.
        </SettingCard.Description>
      </SettingCard.Header>
      <form onSubmit={handleFormSubmit}>
        <DragAndDrop items={sortableVariants} onDragEnd={handleDragEnd} />
        <SettingCard.Footer>
          <SettingCard.Description>
            This setting will change the sequence of variants.
          </SettingCard.Description>
          <SettingCard.Button isLoading={isPending}>Submit</SettingCard.Button>
        </SettingCard.Footer>
      </form>
    </SettingCard>
  );
};
