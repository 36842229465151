import { useCallback, useState } from "react";

export const useTableDropdown = () => {
  const [areDropdownsOpened, setAreDropdownsOpened] = useState<boolean[]>([]);

  const openDropdown = useCallback(
    (index: number) => {
      setAreDropdownsOpened((prev) => {
        const newDropdowns = [...prev];
        newDropdowns[index] = true;
        return newDropdowns;
      });
    },
    [setAreDropdownsOpened],
  );

  const handleDropdownOpenChange = (opened: boolean, index: number) => {
    setAreDropdownsOpened((previous) => {
      const newDropdowns = [...previous];
      newDropdowns[index] = opened;
      return newDropdowns;
    });
  };

  return {
    areDropdownsOpened,
    openDropdown,
    handleDropdownOpenChange,
  };
};
