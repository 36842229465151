import { Navigate, Outlet } from "react-router-dom";

import { UserRole } from "../types";
import { useAuth } from "./hooks";

interface ProtectedRouteProps {
  roles?: UserRole[];
}

export const ProtectedRoute = ({ roles }: ProtectedRouteProps) => {
  const { token, user } = useAuth();

  const hasRole = user && roles && !roles.includes(user.role);

  if (!token || hasRole) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};
