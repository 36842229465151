import {
  Button,
  Command,
  Label,
  NOT_SELECTED_ID,
  Popover,
  cn,
} from "@twocontinents/dashboard/shared";
import { ChevronsUpDown } from "lucide-react";

import { BaseTravelAgency } from "../../types";
import { useTravelAgenciesCombobox } from "./use-travel-agencies-combobox";

export interface TravelAgenciesComboboxProps {
  selectedId?: number;
  setSelectedOption: (value: BaseTravelAgency) => void;
  withLabel?: boolean;
}

export function TravelAgenciesCombobox({
  selectedId,
  setSelectedOption,
  withLabel = false,
}: TravelAgenciesComboboxProps) {
  const { onSelect, open, setOpen, selectedOption, travelAgencies } =
    useTravelAgenciesCombobox(setSelectedOption, selectedId);

  const notFoundText = "Not found.";
  const searchPlaceholder = "Find travel agency...";

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <div className="flex flex-col gap-2 border-slate-700">
          {withLabel && <Label>Atrakcja</Label>}

          {(travelAgencies?.length ?? 0) > 0 && (
            <Button
              type="button"
              role="combobox"
              aria-expanded={open}
              className=" justify-between"
            >
              {selectedOption?.name ?? "Not selected"}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50 border-slate-700" />
            </Button>
          )}
        </div>
      </Popover.Trigger>
      <Popover.Content
        asChild
        align="start"
        className="w-80 p-0 bg-slate-950 text-slate-100 border-slate-700"
      >
        <Command
          className={cn("border-slate-700 font-sans  !overflow-y-hidden")}
        >
          <Command.Input placeholder={searchPlaceholder} />
          <Command.List>
            <Command.Empty>{notFoundText}</Command.Empty>
            <Command.Group className="border-slate-700 h-96 ">
              {travelAgencies.map((agenct) => {
                return (
                  <SelectItem
                    key={agenct.id}
                    description={agenct.name}
                    selectedOptionId={selectedOption?.id ?? NOT_SELECTED_ID}
                    {...agenct}
                    onSelect={onSelect}
                  />
                );
              })}
            </Command.Group>
          </Command.List>
        </Command>
      </Popover.Content>
    </Popover>
  );
}

interface SelectItemProps {
  selectedOptionId: number;
  id: number;
  description: string;
  onSelect: (value: string) => void;
}

const SelectItem = ({
  selectedOptionId,
  id,
  description,
  onSelect,
}: SelectItemProps) => {
  const isSelected = selectedOptionId === id;
  return (
    <Command.Item
      className={cn(
        "cursor-pointer  aria-selected:bg-slate-800 aria-selected:text-white",
        {
          "bg-slate-600 text-white": isSelected,
          "text-slate-100": !isSelected,
        },
      )}
      value={description}
      onSelect={onSelect}
    >
      {description}
    </Command.Item>
  );
};
