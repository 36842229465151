import {
  StatusIndicator,
  Table,
  useTableDropdown,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";

import { Coupon } from "../../types";
import { CouponVariantsDropdown } from "./coupon-variants-dropdown";
import { DeleteCouponDialog } from "./delete-coupon-dialog";

interface CouponsTableProps {
  coupons: Coupon[];
}

export const CouponsTable = ({ coupons }: CouponsTableProps) => {
  const { areDropdownsOpened, openDropdown, handleDropdownOpenChange } =
    useTableDropdown();

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Head className="py-2">ID</Table.Head>
          <Table.Head className="py-2">Code</Table.Head>
          <Table.Head className="py-2">Description</Table.Head>
          <Table.Head className="py-2">Available usages</Table.Head>
          <Table.Head className="py-2">Value</Table.Head>
          <Table.Head className="py-2">
            Start datetime (in your timezone)
          </Table.Head>
          <Table.Head className="py-2">
            End datetime (in your timezone)
          </Table.Head>
          <Table.Head className="py-2">
            Date of start of accepted reservations (in your timezone)
          </Table.Head>
          <Table.Head className="py-2">
            Date of expiration of accepted reservations (in your timezone)
          </Table.Head>
          <Table.Head className="py-2">State</Table.Head>
          <Table.Head className="py-2">Action</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {coupons.map((coupon, i) => (
          <Table.Row
            className="cursor-pointer"
            key={coupon.id}
            onClick={() => openDropdown(i)}
          >
            <Table.Cell>
              <CouponVariantsDropdown
                coupon={coupon}
                setIsDropdownOpened={(opened: boolean) =>
                  handleDropdownOpenChange(opened, i)
                }
                isDropdownOpened={areDropdownsOpened[i]}
              />
            </Table.Cell>

            <Table.Cell>{coupon.code}</Table.Cell>
            <Table.Cell>{coupon.description}</Table.Cell>
            <Table.Cell>
              {coupon.infinityUsages ? "Bez limitu" : coupon.availableUsages}{" "}
            </Table.Cell>
            <Table.Cell>
              {coupon.value} {coupon.currency}
            </Table.Cell>
            <Table.Cell>
              {coupon.startDateTime
                ? DateFormatter.formatToDDMMYYYYHHmm(coupon.startDateTime)
                : "Unlimited"}
            </Table.Cell>
            <Table.Cell>
              {coupon.endDateTime
                ? DateFormatter.formatToDDMMYYYYHHmm(coupon.endDateTime)
                : "Unlimited"}
            </Table.Cell>
            <Table.Cell>
              {coupon.reservationPeriodStartDateTime
                ? DateFormatter.formatToDDMMYYYYHHmm(
                    coupon.reservationPeriodStartDateTime,
                  )
                : "Unlimited"}
            </Table.Cell>
            <Table.Cell>
              {coupon.reservationPeriodEndDateTime
                ? DateFormatter.formatToDDMMYYYYHHmm(
                    coupon.reservationPeriodEndDateTime,
                  )
                : "Unlimited"}
            </Table.Cell>
            <Table.Cell>
              <StatusIndicator status={coupon.active ? "positive" : "negative"}>
                {coupon.active ? "Active" : "Inactive"}
              </StatusIndicator>
            </Table.Cell>
            <Table.Cell>
              <DeleteCouponDialog couponId={coupon.id} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
