import { Layout } from "@twocontinents/dashboard/shared";

import { Statistics } from "./statistics";

export const StatisticsPage = () => {
  return (
    <Layout
      header={{
        title: "Statistics",
        description: "View statistics of your shop",
      }}
    >
      <Statistics />
    </Layout>
  );
};
