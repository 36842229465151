import { Tour } from "@twocontinents/dashboard/attractions/shared";
import { DetailedCard } from "@twocontinents/dashboard/shared";

import { CreateTourParticipantsDialog } from "../create-tour-participants-dialog";
import { TourParticipantsTable } from "./tour-participants-table";

interface TourParticipantsManagerProps {
  tour: Tour;
}

export const TourParticipantsManager = ({
  tour,
}: TourParticipantsManagerProps) => {
  return (
    <DetailedCard>
      <DetailedCard.DetailsContainer>
        <DetailedCard.Title>Manage participants of the tour</DetailedCard.Title>
        <DetailedCard.Description>
          Check and edit information about tour participants
        </DetailedCard.Description>
      </DetailedCard.DetailsContainer>
      <div className="flex gap-4">
        <p>Participants amount: {tour.participants.length}</p>
        <p>
          Places left: {tour.maxParticipants === -1 ? "nd." : tour.placesLeft}
        </p>
      </div>
      <div className="flex justify-between gap-4 flex-wrap">
        <CreateTourParticipantsDialog tour={tour} />
        <TourParticipantsTable tour={tour} />
      </div>
    </DetailedCard>
  );
};
