import { Tour } from "@twocontinents/dashboard/attractions/shared";
import { useBoolean } from "usehooks-ts";

import { useUpdateTourMessage } from "../../../data-access";

export const useCancelMessageDialog = (tour: Tour) => {
  const openAlertDialog = useBoolean(false);

  const { updateTourMessage, isPending } = useUpdateTourMessage(tour.id);

  const onSubmit = () => {
    if (!tour.message) {
      return;
    }
    updateTourMessage(
      {
        messageId: tour.message.id,
        body: {
          cancelled: true,
        },
      },
      {
        onSettled() {
          openAlertDialog.setFalse();
        },
      },
    );
  };

  return {
    openAlertDialog,
    isPending,
    onSubmit,
  };
};
