import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetAttractionBusinessPartners } from "./get-attraction-business-partners";

type Body = components["schemas"]["UpdateAttractionPartnershipRequest"];

interface Props {
  attractionId: number;
  body: Body;
}

export const useUpdateAttractionCommissions = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetAttractionBusinessPartners();

  const { mutate: updateAttractionCommissions, isPending } = useMutation({
    mutationFn: ({ attractionId, body }: Props) =>
      apiClient
        .PATCH(`/private/partners/business/attraction/{attractionId}`, {
          params: {
            path: { attractionId },
          },
          body,
        })
        .then((response) => response.data),

    onSuccess: async (_, { attractionId }) => {
      toast.success("Attraction commissions have been updated successfully.");
      await invalidate(attractionId);
    },
    onError: () => {
      toast.error("Attraction commissions could not be updated");
    },
  });

  return { updateAttractionCommissions, isPending };
};
