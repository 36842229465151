import { Layout } from "@twocontinents/dashboard/shared";
import { OfferBundleForm } from "@twocontinents/dashboard/travel-agencies/offer-bundle/shared";
import React from "react";

import { useOfferBundleShowPage } from "./use-offer-bundle-show-page";

export const OfferBundleShowPage = () => {
  const { id, offerBundle, entries, isPending, onSubmit } =
    useOfferBundleShowPage();

  return (
    <Layout
      header={{
        title: "Offer Bundle",
        description: "Show details of the offer bundle",
      }}
    >
      <OfferBundleForm
        initialData={{
          name: offerBundle?.name,
          entries,
        }}
        onSubmit={onSubmit}
        isPending={isPending}
        content={{
          title: "Update Offer Bundle",
          description: "Update offer bundle",
          footer:
            "After updating the offer bundle, the changes will be reflected in Affiliation partners commissions.",
        }}
        offerBundleId={id}
      />
    </Layout>
  );
};
