import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  Input,
} from "@twocontinents/dashboard/shared";

import { FinancialLog } from "../../types";
import { useCancelFinancialLogModal } from "./use-cancel-financial-log-modal";

interface CancelFinancialLogModalProps {
  financialLog: FinancialLog;
  settled: boolean;
}

export const CancelFinancialLogModal = ({
  financialLog,
  settled,
}: CancelFinancialLogModalProps) => {
  const { form, onSubmit, isPending, isOpened, open, close } =
    useCancelFinancialLogModal(financialLog.id);

  return (
    <AlertDialog open={isOpened}>
      <AlertDialog.Trigger asChild onClick={open}>
        <Button
          disabled={financialLog.finalValue === 0}
          variant={"destructive"}
          title={
            settled
              ? "Cancelling settled financial log will create new one with negated value"
              : ""
          }
        >
          Cancel
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <Form {...form}>
          <form onSubmit={onSubmit}>
            <AlertDialog.Header>
              <AlertDialog.Title>Cancel financial log</AlertDialog.Title>
              <AlertDialog.Description className={"py-4"}>
                <Form.Fields>
                  <Form.Field
                    control={form.control}
                    name="cause"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>Cause</FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                </Form.Fields>
              </AlertDialog.Description>
            </AlertDialog.Header>
            <AlertDialog.Footer>
              <AlertDialog.Cancel onClick={close}>Cancel</AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button type={"submit"} isLoading={isPending}>
                  Submit
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
