import { useMutation } from "@tanstack/react-query";
import {
  toast,
  useInvalidateGetAttraction,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type UpdateExtraOptionRequest =
  components["schemas"]["UpdateExtraOptionRequest"];

interface Props {
  attractionId: number;
  extraOptionId: number;
  body: UpdateExtraOptionRequest;
}

export const useUpdateExtraOption = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetAttraction();

  const { mutate, isPending, data } = useMutation({
    mutationFn: ({ attractionId, body, extraOptionId }: Props) => {
      return apiClient
        .PATCH("/private/attractions/{id}/extra-options/{extraOptionId}", {
          body,
          params: {
            path: {
              id: attractionId,
              extraOptionId: extraOptionId,
            },
          },
        })
        .then((response) => response?.data?.id ?? extraOptionId);
    },
    onSuccess: async (_, { attractionId }) => {
      toast.success("Extra option has been updated successfully.");
      await invalidate(attractionId);
    },
    onError: () => {
      toast.error("Extra option could not be updated");
    },
  });

  return {
    updateExtraOption: mutate,
    isPending,
    newId: data,
  };
};
