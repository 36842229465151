import {
  TourBase,
  TourGenerationMode,
} from "@twocontinents/dashboard/attractions/shared";

export const getOrderIdFromTour = (tour: TourBase) => {
  if (tour.tourGenerationMode === TourGenerationMode.NEW_TOUR_PER_RESERVATION) {
    return (
      tour.participants.find((participant) => participant.orderId)?.orderId ??
      "nd."
    );
  }
  return "nd.";
};
