import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { GetTravelAgenciesFilters } from "../types";

const queryKey = (filters?: GetTravelAgenciesFilters) => [
  "private-travel-agencies",
  filters,
];

export const useGetTravelAgencies = (filters?: GetTravelAgenciesFilters) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/travel-agencies/", {
          params: {
            query: filters,
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(filters),
  });

  data?.travelAgencies.sort((a, b) => a.id - b.id);

  return {
    data,
    isLoading,
  };
};

export const useInvalidateGetTravelAgencies = () => {
  const queryClient = useQueryClient();

  const invalidate = async (filters?: GetTravelAgenciesFilters) => {
    await queryClient.invalidateQueries({ queryKey: queryKey(filters) });
  };

  return {
    invalidate,
  };
};
