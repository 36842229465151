import { AttractionOrder } from "@twocontinents/dashboard/attractions/shared";
import { DetailedCard } from "@twocontinents/dashboard/shared";
import { PropsWithChildren } from "react";

import { ReservationDetailsTable } from "./reservation-details-table";

interface MultiReservationsOrderDetailsProps {
  order: AttractionOrder;
}

export const MultiReservationsOrderDetails = ({
  order,
}: MultiReservationsOrderDetailsProps) => {
  const { reservations } = order;

  return (
    <>
      {reservations.map((reservation, index) => (
        <DetailedCard key={reservation.id}>
          <DetailedCard.DetailsContainer>
            <DetailedCard.Title>
              {index + 1}. {reservation.attractionDescription} -{" "}
              {reservation.attractionVariant.description}
            </DetailedCard.Title>
          </DetailedCard.DetailsContainer>
          <ReservationDetailsTable
            reservation={reservation}
            orderId={order.id}
          />
        </DetailedCard>
      ))}
      {order.couponCode && order.appliedDiscount && (
        <>
          <SummaryParagraph label="Used coupon">
            {order.couponCode}
          </SummaryParagraph>
          <SummaryParagraph label="Order value">
            {(order.totalPrice + order.appliedDiscount).toFixed(2)}{" "}
            {order.currency}
          </SummaryParagraph>
          <SummaryParagraph label="Discount">
            {order.appliedDiscount.toFixed(2)} {order.currency}
          </SummaryParagraph>
        </>
      )}
      <SummaryParagraph label="Price paid by customer">
        {order.totalPrice.toFixed(2)} {order.currency}
      </SummaryParagraph>
    </>
  );
};

interface SummaryParagraphProps extends PropsWithChildren {
  label: string;
}

const SummaryParagraph = ({ label, children }: SummaryParagraphProps) => {
  return (
    <p className=" self-end w-full max-w-xs inline-flex justify-between mr-6 mb-6 px-6 text-xl py-3 border-t border-t-slate-700">
      <span className=" font-semibold">{label}:</span> {children}
    </p>
  );
};
