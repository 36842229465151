import { useGetFinancialLog } from "@twocontinents/dashboard/financial-log/shared";
import { Layout } from "@twocontinents/dashboard/shared";
import { useParams } from "react-router-dom";

import { FinancialLogDetails } from "./financial-log-details";
import { ShowFinancialLogPageSkeleton } from "./show-financial-log-page-skeleton";

export const FinancialLogShowPage = () => {
  const params = useParams<{ id: string }>();
  const id = Number(params.id);
  const { financialLog, isLoading } = useGetFinancialLog(id);

  if (!financialLog || isLoading) {
    return <ShowFinancialLogPageSkeleton id={id} />;
  }

  return (
    <Layout header={{ title: `Financial log #${id}` }}>
      <FinancialLogDetails financialLog={financialLog} />
    </Layout>
  );
};
