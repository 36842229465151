import { zodResolver } from "@hookform/resolvers/zod";
import {
  FundingType,
  useGetOfferBundles,
} from "@twocontinents/dashboard/travel-agencies/shared";
import { components, getCountryNameByIsoCode } from "@twocontinents/shared";
import parsePhoneNumber from "libphonenumber-js";
import { ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { TravelAgency } from "../types";

const TravelAgencyFormSchema = z.object({
  name: z.string().min(3),
  email: z.string().email(),
  phoneNumber: z.string().trim(),
  nip: z.string().min(10),
  fundingType: z.nativeEnum(FundingType),
  offerBundleId: z.coerce.number(),
  street: z.string().min(3),
  city: z.string().min(3),
  zipCode: z.string().min(3),
  additionalDetails: z.string().optional().default(""),
});

type TravelAgencyFormSchema = z.infer<typeof TravelAgencyFormSchema>;

export type TravelAgencyPersistRequest =
  components["schemas"]["CreateTravelAgencyRequest"];

export interface TravelAgencyFormProps {
  handleSubmit: (data: TravelAgencyPersistRequest) => void;
  footer?: ReactNode;
  initialTravelAgency?: TravelAgency;
}

export const useTravelAgencyForm = ({
  handleSubmit,
  initialTravelAgency,
}: TravelAgencyFormProps) => {
  const form = useForm<TravelAgencyFormSchema>({
    resolver: zodResolver(TravelAgencyFormSchema),
    mode: "onBlur",
  });

  const { offerBundles } = useGetOfferBundles();

  const onSubmit = form.handleSubmit((data) => {
    const parsedPhoneNumber = parsePhoneNumber(data.phoneNumber);
    const countryIsoCode = parsedPhoneNumber?.country ?? "PL";
    const country = getCountryNameByIsoCode(countryIsoCode) ?? "Poland";

    handleSubmit({
      ...data,
      countryIsoCode,
      country,
    });
  });

  useEffect(() => {
    if (initialTravelAgency) {
      form.reset(initialTravelAgency);
    }
  }, [initialTravelAgency, form.reset]);

  return {
    form,
    onSubmit,
    offerBundles,
  };
};
