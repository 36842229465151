import { CellContext, ColumnDef } from "@tanstack/react-table";
import { buttonVariants, routes } from "@twocontinents/dashboard/shared";
import { BaseTravelAgency } from "@twocontinents/dashboard/travel-agencies/shared";
import { Link } from "react-router-dom";

export const columns: ColumnDef<BaseTravelAgency>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "actions",
    header: "Actions",
    cell: (props) => <Cell {...props} />,
  },
];

const Cell = ({ row }: CellContext<BaseTravelAgency, unknown>) => {
  const {
    original: { id },
  } = row;

  return (
    <Link
      className={buttonVariants({ variant: "secondary" })}
      to={routes.travelAgencies.show.url(id ?? -1)}
    >
      Show
    </Link>
  );
};
