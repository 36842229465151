import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components, paths } from "@twocontinents/shared";

type GetAgeGroupTicketsFilter = NonNullable<
  paths["/private/attractions/variants/{id}/age-groups/{ageGroupId}/tickets"]["get"]["parameters"]["query"]
>;
const queryKey = (
  variantId: number,
  ageGroupId: number,
  filters?: GetAgeGroupTicketsFilter,
) => ["age-group-tickets", variantId, ageGroupId, filters];

export const useGetAgeGroupTickets = (
  variantId: number,
  ageGroupId: number,
  filters?: GetAgeGroupTicketsFilter,
) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET(
          "/private/attractions/variants/{id}/age-groups/{ageGroupId}/tickets",
          {
            params: {
              path: {
                id: variantId,
                ageGroupId,
              },
              query: filters,
            },
          },
        )
        .then((response) => response.data),
    queryKey: queryKey(variantId, ageGroupId, filters),
    enabled: Boolean(variantId) && Boolean(ageGroupId),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  return {
    tickets: data?.tickets ?? [],
    availableTicketsAmount: data?.availableTickets ?? 0,
    pagination: data?.pagination,
    isLoading,
  };
};

export const useInvalidateGetAgeGroupTickets = () => {
  const queryClient = useQueryClient();

  const invalidate = async (
    variantId: number,
    ageGroupId: number,
    filters?: GetAgeGroupTicketsFilter,
  ) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(variantId, ageGroupId, filters),
    });
  };

  return { invalidate };
};
