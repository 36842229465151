import {
  useFinancialLogsQueryParams,
  useGetFinancialLogs,
} from "@twocontinents/dashboard/financial-log/shared";
import {
  Table,
  buttonVariants,
  cn,
  routes,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode, DateFormatter } from "@twocontinents/shared";
import { Link } from "react-router-dom";

export const FinancialLogsTable = () => {
  const { query } = useFinancialLogsQueryParams();
  const { data } = useGetFinancialLogs(query);

  const financialLogs = data?.financialLogs ?? [];

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Head className="py-5">ID</Table.Head>
          <Table.Head className="py-5">Source</Table.Head>
          <Table.Head className="py-5">Cause</Table.Head>
          <Table.Head className="py-5">Value</Table.Head>
          <Table.Head className="py-5">Category</Table.Head>
          <Table.Head className="py-5">Settle date</Table.Head>
          <Table.Head className="py-5">Created at</Table.Head>
          <Table.Head className="py-5">Actions</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body className="w-full relative">
        {financialLogs.map((financialLog) => (
          <Table.Row
            key={financialLog.id}
            className={cn("cursor-pointer", {
              "bg-slate-900": financialLog.settled,
            })}
          >
            <Table.Cell>
              <Link to={routes.financialLog.show.url(financialLog.id)}>
                {financialLog.id}
              </Link>
            </Table.Cell>
            <Table.Cell>{financialLog.source}</Table.Cell>
            <Table.Cell>{financialLog.cause}</Table.Cell>
            <Table.Cell
              className={cn({
                "text-green-500": financialLog.finalValue > 0,
                "text-red-500": financialLog.finalValue < 0,
              })}
            >
              {financialLog.finalPlnValue} {CurrencyCode.PLN}
            </Table.Cell>
            <Table.Cell>{financialLog.category ?? "N/A"}</Table.Cell>
            <Table.Cell>
              {DateFormatter.formatToDDMMYYYYHHmm(financialLog.settleDate)}
            </Table.Cell>
            <Table.Cell>
              {DateFormatter.formatToDDMMYYYYHHmm(financialLog.createdAt)}
            </Table.Cell>
            <Table.Cell className={"flex gap-4 "}>
              <Link
                className={buttonVariants({ variant: "secondary" })}
                to={routes.financialLog.show.url(financialLog.id)}
              >
                Details
              </Link>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
