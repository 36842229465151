import {
  AttractionGroup,
  Calendar as BaseCalendar,
} from "@twocontinents/dashboard/shared";

import { DateAvailability } from "../../../../types";
import { CalendarDay } from "../calendar-day";
import { useCalendar } from "./use-calendar";

interface CalendarProps {
  attractionGroups: AttractionGroup[];
  mutatedAvailabilities: DateAvailability[];
  attractionId: number;
}

export const Calendar = ({
  attractionGroups,
  mutatedAvailabilities,
  attractionId,
}: CalendarProps) => {
  const {
    selectCalendarDay,
    areAllDatesUnavailable,
    getDateAvailability,
    isDateMutated,
    hasAnyCrew,
  } = useCalendar(attractionGroups, mutatedAvailabilities, attractionId);

  return (
    <div className={"flex flex-col gap-4"}>
      <BaseCalendar
        classNames={{
          day: "m-1 group",
        }}
        onDayClick={(day) => selectCalendarDay(day)}
        components={{
          Day: (props) => (
            <CalendarDay
              {...props}
              dateAvailability={getDateAvailability(props.date)}
              isMutated={isDateMutated(props.date)}
              areAllDatesUnavailable={areAllDatesUnavailable}
              hasAnyCrew={hasAnyCrew}
            />
          ),
        }}
      />

      {hasAnyCrew && (
        <div>
          <p className="text-accent">
            <span className="font-bold">G</span> - Polish guide
          </p>
          <p className="text-accent">
            <span className="font-bold">D</span> - Polish driver
          </p>
          <p className="text-accent">
            <span className="font-bold">E</span> - English guide
          </p>
        </div>
      )}
    </div>
  );
};
