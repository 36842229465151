import {
  Button,
  Form,
  FormItem,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { useActivitySettings } from "./use-activity-settings";

interface ActivitySettingsProps {
  attractionId: number;
  defaultActive: boolean;
}

export const ActivitySettings = ({
  attractionId,
  defaultActive,
}: ActivitySettingsProps) => {
  const {
    form,
    toggleActive,
    toggleButtonVariant,
    toggleButtonLabel,
    onSubmit,
    isPending,
  } = useActivitySettings(attractionId, defaultActive);
  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Active settings</SettingCard.Title>
        <SettingCard.Description>
          Activate or disable Attraction
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Field
            control={form.control}
            name="active"
            render={() => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label>Active</FormItem.Label>
                  <FormItem.Control>
                    <Button
                      variant={toggleButtonVariant}
                      onClick={toggleActive}
                      type="button"
                    >
                      {toggleButtonLabel}
                    </Button>
                  </FormItem.Control>
                </FormItem.Row>
              </FormItem>
            )}
          />

          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will disable Attraction form.
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
