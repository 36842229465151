import { z } from "zod";

export const PlurableLabelSchema = z.object({
  one: z.string().default("one"),
  few: z.string().default("few"),
  many: z.string().default("many"),
  other: z.string().default("other"),
});

export type PlurableLabel = z.infer<typeof PlurableLabelSchema>;
