import { useMutation } from "@tanstack/react-query";
import {
  useInvalidateGetFinancialLog,
  useInvalidateGetFinancialLogs,
} from "@twocontinents/dashboard/financial-log/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type UpdateFinancialLogRequest =
  components["schemas"]["ModifyFinancialLogEntryRequest"];

interface Props {
  id: number;
  body: UpdateFinancialLogRequest;
}

export const useUpdateFinancialLog = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetFinancialLog();
  const { invalidate: invalidateGetfinancialLogs } =
    useInvalidateGetFinancialLogs();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ id, body }: Props) => {
      return apiClient
        .PATCH("/private/financial-log/{id}", {
          params: { path: { id } },
          body,
        })
        .then((response) => response.data);
    },
    onSuccess: async (_, { id }) => {
      await invalidate(id);
      await invalidateGetfinancialLogs();
      toast.success("Financial log has been updated");
    },
    onError: () => {
      toast.error("Could not update financial log");
    },
  });

  return { updateFinancialLog: mutate, isPending };
};
