import {
  DataTable,
  Input,
  Layout,
  buttonVariants,
  routes,
} from "@twocontinents/dashboard/shared";
import {
  TravelAgencyTab,
  TravelAgencyTabs,
} from "@twocontinents/dashboard/travel-agencies/shared";
import { Link } from "react-router-dom";

import { useGetOfferBundles } from "../data-access";
import { columns } from "./columns";

export const OfferBundleIndexPage = () => {
  const { offerBundles } = useGetOfferBundles();

  return (
    <Layout
      header={{
        title: "Offer bundles",
        description: "Browse offer bundles",
      }}
    >
      <Layout.Content>
        <div className="flex justify-between">
          <TravelAgencyTabs activeTab={TravelAgencyTab.OFFER_BUNDLE} />

          <Link
            to={routes.travelAgencies.offerBundle.create}
            className={buttonVariants({ variant: "secondary" })}
          >
            Create
          </Link>
        </div>

        <DataTable
          header={(table) => (
            <Input
              placeholder="Search by name"
              value={
                (table.getColumn("name")?.getFilterValue() as string) ?? ""
              }
              onChange={(event) =>
                table.getColumn("name")?.setFilterValue(event.target.value)
              }
              className="max-w-sm"
            />
          )}
          columns={columns}
          data={offerBundles}
          paginationType={"client"}
        />
      </Layout.Content>
    </Layout>
  );
};
