import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { GetToursFilters } from "../types";

const queryKey = (filters?: GetToursFilters) => [
  "private-attraction-tours",
  filters,
];

export const useGetTours = (filters?: GetToursFilters) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/attractions/tours/", {
          params: { query: filters },
        })
        .then((response) => response.data),
    queryKey: queryKey(filters),
  });

  return {
    paginatedTours: data,
    isLoading,
  };
};

export const useInvalidateGetTours = () => {
  const queryClient = useQueryClient();

  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(),
    });
  };

  return {
    invalidate,
  };
};
