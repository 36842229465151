import { useMutation } from "@tanstack/react-query";
import {
  routes,
  toast,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { useInvalidateGetOfferBundles } from "@twocontinents/dashboard/travel-agencies/shared";
import { components } from "@twocontinents/shared";
import { useNavigate } from "react-router-dom";

type CreateOfferBundle = components["schemas"]["CreateOfferBundleRequest"];

export const useCreateOfferBundle = () => {
  const apiClient = usePrivateApiClient();
  const navigate = useNavigate();
  const { invalidate } = useInvalidateGetOfferBundles();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: CreateOfferBundle) => {
      return apiClient
        .POST("/private/partners/travel-agencies/offer-bundles/", {
          body: request,
        })
        .then((response) => response.data);
    },
    onSuccess: async () => {
      toast.success("Offer bundle created successfully");
      await invalidate();
      navigate(routes.travelAgencies.offerBundle.index);
    },
    onError: () => {
      toast.error("Could not create offer bundle");
    },
  });

  return { createOfferBundle: mutate, isPending };
};
