import { zodResolver } from "@hookform/resolvers/zod";
import {
  Tour,
  useInvalidateGetTour,
} from "@twocontinents/dashboard/attractions/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useOverrideTourMessageContent } from "../../../data-access";

const OverrideMessageFormSchema = z.object({
  overriddenMessage: z.string(),
});

type OverrideMessageFormValues = z.infer<typeof OverrideMessageFormSchema>;

export const useOverrideTourMessageDialog = (tour: Tour) => {
  const openAlertDialog = useBoolean(false);

  const form = useForm<OverrideMessageFormValues>({
    resolver: zodResolver(OverrideMessageFormSchema),
  });

  const overriddenMessage = form.watch("overriddenMessage");

  const { overrideTourMessage, isPending } = useOverrideTourMessageContent(
    tour.id,
  );

  useEffect(() => {
    if (!tour.message) return;
    form.setValue(
      "overriddenMessage",
      tour.message.overriddenMessage || tour.message.stringMessage,
    );
  }, [tour.message, form]);

  const handleButtonClick = () => {
    if (!tour.message) return;
    overrideTourMessage(
      {
        messageId: tour.message?.id,
        body: {
          overriddenMessage,
        },
      },
      {
        onSettled: () => {
          openAlertDialog.setFalse();
        },
      },
    );
  };

  return {
    openAlertDialog,
    handleButtonClick,
    isPending,
    form,
  };
};
