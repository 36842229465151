import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@twocontinents/dashboard/shared";
import { AuthProvider } from "@twocontinents/dashboard/shared/auth";
import { env } from "@twocontinents/shared";
import { APIProvider as GoogleMapsAPIProvider } from "@vis.gl/react-google-maps";
import { StrictMode, Suspense } from "react";
import * as ReactDOM from "react-dom/client";

import "./i18next";
import { Router } from "./router";
import "./styles.css";

const root = ReactDOM.createRoot(
  document.querySelector("#root") as HTMLElement,
);

const { GOOGLE_MAPS_API_KEY } = env;

const queryClient = new QueryClient();

root.render(
  <StrictMode>
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <GoogleMapsAPIProvider apiKey={GOOGLE_MAPS_API_KEY}>
          <AuthProvider>
            <Router />
            <Toaster />
          </AuthProvider>
        </GoogleMapsAPIProvider>
      </QueryClientProvider>
    </Suspense>
  </StrictMode>,
);
