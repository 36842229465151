import { useMutation } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type DeleteTourParticipantsRequest =
  components["schemas"]["DeleteTourParticipantsRequest"];

interface Props {
  body: DeleteTourParticipantsRequest;
}

export const useDeleteTourParticipants = () => {
  const apiClient = usePrivateApiClient();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ body }: Props) => {
      return apiClient
        .DELETE("/private/attractions/tours/participants", {
          body,
        })
        .then((response) => response.data);
    },
  });

  return { deleteTourParticipants: mutate, isPending };
};
