import { zodResolver } from "@hookform/resolvers/zod";
import { Tour } from "@twocontinents/dashboard/attractions/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useUpdateTourMessage } from "../../data-access";

export const EditMessageFormSchema = z.object({
  additions: z.string().optional(),
  pickupLocation: z.string().optional(),
  contact: z.string().optional(),
});

type EditMessageFormValues = z.infer<typeof EditMessageFormSchema>;

export const useTourMessageDialog = (tour: Tour) => {
  const { message } = tour;

  const form = useForm<EditMessageFormValues>({
    resolver: zodResolver(EditMessageFormSchema),
    mode: "onBlur",
  });

  const { isPending, updateTourMessage } = useUpdateTourMessage(tour.id);

  useEffect(() => {
    form.setValue("additions", message?.additions);
    form.setValue("pickupLocation", message?.pickupLocation);
    form.setValue("contact", message?.contact);
  }, [message, form]);

  const openAlertDialog = useBoolean(false);

  const handleFormSubmit = form.handleSubmit((values) => {
    if (!message) {
      return;
    }
    updateTourMessage(
      {
        messageId: message.id,
        body: {
          additions: values.additions,
          pickupLocation: values.pickupLocation,
          contact: values.contact,
        },
      },
      {
        onSettled: () => openAlertDialog.setFalse(),
      },
    );
  });

  return {
    form,
    openAlertDialog,
    handleFormSubmit,
    isPending,
  };
};
