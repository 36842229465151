import {
  ApiLocaleSelect,
  Button,
  CurrencyCodeSelect,
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { ApiLocale, CurrencyCode } from "@twocontinents/shared";

import { useGeneratePaymentLinkForm } from "./use-generate-payment-link-form";

export const GeneratePaymentLinkForm = () => {
  const {
    form,
    handleFormSubmit,
    paymentLink,
    isPending,
    businessPartners,
    handleCopyLinkButtonClick,
  } = useGeneratePaymentLinkForm();

  return (
    <Form {...form}>
      <form onSubmit={handleFormSubmit} autoComplete="off">
        <Form.Fields>
          <Form.Field
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">Name</FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} className="max-w-md" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="price"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">Price</FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} type="number" className="max-w-md" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="currency"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">Currency</FormItem.Label>
                  <FormItem.Control>
                    <CurrencyCodeSelect
                      defaultValue={CurrencyCode.PLN}
                      value={field.value}
                      currencies={Object.values(CurrencyCode)}
                      onValueChange={field.onChange}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="quantity"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">Quantity</FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} type="number" className="max-w-md" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="customerName"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">
                    Customer Name
                  </FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} className="max-w-md" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="customerSurname"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">
                    Customer Surname
                  </FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} className="max-w-md" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="customerEmail"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">
                    Customer Email
                  </FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} className="max-w-md" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />

          <Form.Field
            control={form.control}
            name="locale"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">Locale</FormItem.Label>
                  <FormItem.Control>
                    <ApiLocaleSelect
                      value={field.value ?? ApiLocale.PL}
                      onValueChange={field.onChange}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-32">Notes</FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} className="max-w-md" />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          {businessPartners.map((businessPartner, index) => (
            <div className="hidden" key={businessPartner.id}>
              <Form.Field
                control={form.control}
                name={`businessPartnerCommissions.${index}.businessPartnerId`}
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Control>
                        <Input
                          name={field.name}
                          value={businessPartner.id}
                          type={"number"}
                          className="max-w-md hidden"
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                key={businessPartner.id}
                control={form.control}
                name={`businessPartnerCommissions.${index}.commission`}
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label className="min-w-32">
                        {businessPartner.name}'s commission
                      </FormItem.Label>
                      <FormItem.Control>
                        <Input
                          {...field}
                          type={"number"}
                          step={0.01}
                          className="max-w-md"
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            </div>
          ))}
        </Form.Fields>
        {paymentLink && (
          <div className="my-4 flex gap-8 items-center">
            <p className="text-green-500">{paymentLink}</p>
            <Button
              type="button"
              onClick={handleCopyLinkButtonClick}
              variant="positive"
            >
              Copy
            </Button>
          </div>
        )}
        <SettingCard.Footer>
          <SettingCard.Description>
            After filling the form, click the button to generate a payment link
          </SettingCard.Description>
          <SettingCard.Button type="submit" isLoading={isPending}>
            Generate
          </SettingCard.Button>
        </SettingCard.Footer>
      </form>
    </Form>
  );
};
