import {
  AgeGroupEntry,
  ExtraOptionEntry,
  OfferEntry,
  OfferEntryType,
} from "../types";
import { AgeGroupBase } from "../types/age-group-base";
import { ExtraOptionBase } from "../types/extra-option-base";

// eslint-disable-next-line max-lines-per-function
export const mapToEntries = (
  ageGroupEntries: (AgeGroupEntry | AgeGroupBase)[],
  extraOptionEntries: (ExtraOptionEntry | ExtraOptionBase)[],
) => {
  const entries: OfferEntry[] = [];

  ageGroupEntries.forEach((ageGroup) => {
    entries.push({
      id: ageGroup.id,
      description: ageGroup.description,
      relatedDescription: ageGroup.attractionVariant.description,
      offerPrice: isAgeGroupEntry(ageGroup)
        ? ageGroup.offerPrice
        : ageGroup.price,
      livePrice: `${
        isAgeGroupEntry(ageGroup) ? ageGroup.livePrice : ageGroup.price
      } ${ageGroup.attraction.currency}`,
      type: OfferEntryType.AGE_GROUP,
      attractionId: ageGroup.attraction.id,
      providerPrice: `${
        isAgeGroupEntry(ageGroup) ? ageGroup.providerPrice : ageGroup.price
      } ${ageGroup.attraction.currency}`,
    });
  });

  extraOptionEntries.forEach((extraOption) => {
    entries.push({
      id: extraOption.id,
      description: extraOption.description,
      relatedDescription: extraOption.attraction.description,
      offerPrice: isExtraOptionEntry(extraOption)
        ? extraOption.offerPrice
        : extraOption.price,
      livePrice: `${
        isExtraOptionEntry(extraOption)
          ? extraOption.livePrice
          : extraOption.price
      } ${extraOption.attraction.currency}`,
      type: OfferEntryType.EXTRA_OPTION,
      attractionId: extraOption.attraction.id,
      providerPrice: `${
        isExtraOptionEntry(extraOption)
          ? extraOption.providerPrice
          : extraOption.price
      } ${extraOption.attraction.currency}`,
    });
  });

  return entries;
};

const isAgeGroupEntry = (
  entry: AgeGroupEntry | AgeGroupBase,
): entry is AgeGroupEntry => {
  return "offerPrice" in entry;
};

const isExtraOptionEntry = (
  entry: ExtraOptionEntry | ExtraOptionBase,
): entry is ExtraOptionEntry => {
  return "offerPrice" in entry;
};
