import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";
import { useMemo } from "react";

type AttractionVariantExtraOptions =
  components["schemas"]["PrivateExtraOptionsResponse"];

const queryKey = (variantId: number) => [
  "private-attraction-variant-extra-options",
  variantId,
];

export const useGetAttractionVariantExtraOptions = (
  variantId: number,
  options?: Partial<
    UseQueryOptions<AttractionVariantExtraOptions | undefined, unknown>
  >,
) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/attractions/variants/{id}/extra-options", {
          params: {
            path: {
              id: variantId,
            },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(variantId),
    ...options,
  });

  const extraOptions = useMemo(
    () => data?.extraOptions?.toSorted((a, b) => a.price - b.price) ?? [],
    [data?.extraOptions],
  );

  return {
    extraOptions,
    isLoading,
  };
};

export const useInvalidateGetAttractionVariantExtraOptions = () => {
  const queryClient = useQueryClient();

  const invalidate = async (variantId: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(variantId),
    });
  };

  return { invalidate };
};
