import { useModerateReview } from "../../../data-access";
import { Review } from "../../../types";

export const useModerateReviewAlertDialog = (review: Review) => {
  const { moderateReview, isPending } = useModerateReview();

  const handleSubmit = () => {
    moderateReview({
      reviewId: review.id,
      body: {
        hidden: !review.hidden,
      },
    });
  };

  const modalDescription = review.hidden
    ? "The review will be visible to all users."
    : "The review will be hidden from all users (except the author).";

  return {
    isPending,
    handleSubmit,
    modalDescription,
  };
};
