import { TourStatus } from "@twocontinents/dashboard/attractions/shared";
import {
  Layout,
  Tabs,
  buttonVariants,
  cn,
  routes,
} from "@twocontinents/dashboard/shared";
import { Link } from "react-router-dom";

import { useTourFiltersQueryParams } from "../hooks";
import { ToursTab } from "./tours-tab";

export const ToursListing = () => {
  const { query, setQuery } = useTourFiltersQueryParams();

  const tourStatus = query.status ?? TourStatus.PLANNED;

  function setStatus(status: TourStatus | undefined) {
    return () => {
      setQuery({ status });
    };
  }

  return (
    <Layout
      header={{
        title: "Tours",
        description: "Manage tours",
      }}
    >
      <Link
        to={routes.attractions.tours.create}
        className={cn(
          buttonVariants({ variant: "outline" }),
          "w-fit self-end mt-2",
        )}
      >
        Create tour
      </Link>
      <Tabs defaultValue={tourStatus} className="w-full" value={tourStatus}>
        <Tabs.List className="mt-5 flex flex-col gap-y-2 min-[830px]:grid  w-full h-min min-[830px]:h-14  min-[830px]:grid-cols-3  p-2 ">
          <Tabs.Trigger
            className="h-full w-full min-[830px]:w-auto  py-5 min-[830px]:py-0 "
            value={TourStatus.PLANNED}
            onClick={setStatus(TourStatus.PLANNED)}
          >
            Planned
          </Tabs.Trigger>
          <Tabs.Trigger
            className="h-full w-full min-[830px]:w-auto py-5 min-[830px]:py-0 "
            value={TourStatus.CANCELLED}
            onClick={setStatus(TourStatus.CANCELLED)}
          >
            Cancelled
          </Tabs.Trigger>
          <Tabs.Trigger
            className="h-full w-full min-[830px]:w-auto py-5 min-[830px]:py-0 "
            value={TourStatus.DONE}
            onClick={setStatus(TourStatus.DONE)}
          >
            Done
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value={TourStatus.PLANNED}>
          <ToursTab />
        </Tabs.Content>
        <Tabs.Content value={TourStatus.CANCELLED}>
          <ToursTab />
        </Tabs.Content>
        <Tabs.Content value={TourStatus.DONE}>
          <ToursTab />
        </Tabs.Content>
      </Tabs>
    </Layout>
  );
};
