import { Input, Label, Select } from "@twocontinents/dashboard/shared";

import { useFilterBar } from "./use-filter-bar";

export const FilterBar = () => {
  const { INVOICE_STATUSES, handleEnumChange, handleInputChange, query } =
    useFilterBar();

  return (
    <div className="grid grid-cols-4 gap-4">
      <div className="flex flex-col gap-2">
        <Label>Travel agency name</Label>
        <Input
          value={query.travelAgencyName}
          onChange={handleInputChange("travelAgencyName")}
          type={"text"}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label>Invoice ID</Label>
        <Input
          value={query.invoiceId}
          onChange={handleInputChange("invoiceId")}
          type={"number"}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label>Creation date</Label>
        <Input
          value={query.creationDate}
          onChange={handleInputChange("creationDate")}
          type={"date"}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label>Status</Label>
        <Select
          value={query.status || "ALL"}
          onValueChange={(value) => handleEnumChange("status", value)}
        >
          <Select.Trigger className="max-w-md h-max min-h-10">
            <Select.Value placeholder="Invoice status" />
          </Select.Trigger>
          <Select.Content>
            {INVOICE_STATUSES.map((status) => (
              <Select.Item value={status} key={status}>
                {status}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
    </div>
  );
};
