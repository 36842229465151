import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableItem } from "./sortable-item";

interface DragAndDropProps {
  onDragEnd: (event: DragEndEvent) => void;
  items: {
    id: number;
    name: string;
  }[];
}
export const DragAndDrop = ({ onDragEnd, items }: DragAndDropProps) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={items.map((item) => item.id.toString())}
          strategy={verticalListSortingStrategy}
        >
          <div className="flex flex-col gap-4 px-6 pb-4">
            {items.map((item, index) => (
              <SortableItem
                key={item.id}
                id={item.id.toString()}
                name={item.name}
                order={index}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </>
  );
};
