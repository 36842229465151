import { Plane } from "lucide-react";

interface TravelAgencyIconProps {
  size?: number;
  color?: "green" | "white";
}

export const TravelAgencyIcon = ({
  size = 20,
  color = "green",
}: TravelAgencyIconProps) => {
  const colorClassName = color === "green" ? "text-green-600" : "text-white";

  return <Plane size={size} className={colorClassName} />;
};
