import { useMutation } from "@tanstack/react-query";
import { useInvalidateGetAttractionVariantAgeGroups } from "@twocontinents/dashboard/attractions/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type UpdateAgeGroupRequest =
  components["schemas"]["UpdateAttractionVariantAgeGroup"];

interface Props {
  variantId: number;
  attractionId: number;
  ageGroupId: number;
  body: UpdateAgeGroupRequest;
}

export const useUpdateAgeGroup = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate: invalidateAgeGroups } =
    useInvalidateGetAttractionVariantAgeGroups();

  const { mutate, isPending, data } = useMutation({
    mutationFn: async ({ variantId, body, ageGroupId }: Props) => {
      const response = await apiClient.PATCH(
        "/private/attractions/variants/{id}/age-groups/{ageGroupId}",
        {
          body,
          params: {
            path: {
              id: variantId,
              ageGroupId: ageGroupId,
            },
          },
        },
      );
      return response?.data?.id ?? ageGroupId;
    },
    onSuccess: async (_, { variantId }) => {
      toast.success("Age group has been updated successfully.");
      await invalidateAgeGroups(variantId);
    },
    onError: () => {
      toast.error("Age group could not be updated");
    },
  });

  return {
    updateAgeGroup: mutate,
    isPending,
    newId: data,
  };
};
