import { useMutation } from "@tanstack/react-query";
import {
  NOT_SELECTED_ID,
  routes,
  toast,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import {
  ApiError,
  ApplicationErrorCode,
  components,
} from "@twocontinents/shared";
import { useNavigate } from "react-router-dom";

type CreateTourRequest = components["schemas"]["CreateTourRequest"];

export const useCreateTour = () => {
  const apiClient = usePrivateApiClient();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: CreateTourRequest) => {
      return apiClient
        .POST("/private/attractions/tours/", {
          body: request,
        })
        .then((response) => response.data);
    },
    onSuccess: (createdTour) => {
      const tourId = createdTour?.tourId ?? NOT_SELECTED_ID;

      toast.success("Tour created successfully");

      navigate(routes.attractions.tours.show.url(tourId));
    },
    onError: (err: ApiError) => {
      const message =
        err.applicationErrorCode === ApplicationErrorCode.TOUR_TIME_REQUIRED
          ? "Tour time is required for this attraction"
          : "An error occurred";

      toast.error(message);
    },
  });

  return { createTour: mutate, isPending };
};
