import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = (groupId: number) => [
  "private-attraction-group-availability",
  groupId,
];

export const useGetAttractionGroupAvailability = (groupId: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/attractions/groups/{id}/availability", {
          params: {
            path: {
              id: groupId,
            },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(groupId),
    enabled: Boolean(groupId),
  });

  return { availability: data, isLoading };
};

export const useInvalidateGetAttractionGroupAvailability = () => {
  const queryClient = useQueryClient();

  const invalidate = async (variantId: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(variantId),
    });
  };

  return { invalidateGroupAvailability: invalidate };
};
