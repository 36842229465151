import { useMutation } from "@tanstack/react-query";
import { useInvalidateGetTour } from "@twocontinents/dashboard/attractions/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type UpdateTourMessageRequest =
  components["schemas"]["UpdateTourMessageRequest"];

interface Props {
  messageId: number;
  body: UpdateTourMessageRequest;
}

export const useUpdateTourMessage = (tourId: number) => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetTour();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ body, messageId }: Props) => {
      return apiClient
        .PATCH("/private/attractions/tours/messages/{id}", {
          body,
          params: {
            path: {
              id: messageId,
            },
          },
        })
        .then((response) => response.data);
    },
    async onSuccess() {
      toast.success("Zaktualizowano wiadomość");
      await invalidate(tourId);
    },
    onError() {
      toast.error("Nie udało się zaktualizować wiadomości");
    },
  });

  return { updateTourMessage: mutate, isPending };
};
