import { DateFormatter } from "@twocontinents/shared";
import dayjs from "dayjs";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "../../utils";
import { Button, Calendar, Input, Label, Popover } from "../shadcn";
import { useDateTimePicker } from "./use-date-time-picker";

interface DateTimePickerProps {
  date: Date | undefined;
  setDate: (date?: Date) => void;
  time: string | undefined;
  setTime: (time?: string) => void;
  displayTimeInput?: boolean;
  disablePastDates?: boolean;
}

export function DateTimePicker({
  date,
  setDate,
  time,
  setTime,
  displayTimeInput = true,
  disablePastDates = false,
}: DateTimePickerProps) {
  const { handleSelect, handleTimeChange, selectedDateTime } =
    useDateTimePicker({
      date,
      setDate,
      setTime,
      time,
    });

  return (
    <Popover>
      <Popover.Trigger asChild className="z-10">
        <Button
          className={cn(
            "w-full max-w-sm justify-start text-left font-normal",
            !date && "text-muted-foreground",
          )}
        >
          {selectedDateTime ? (
            `${DateFormatter.formatToDDMMYYYY(selectedDateTime)} ${time ?? ""}`
          ) : (
            <span>Pick date</span>
          )}
          <CalendarIcon className="h-4 w-4" />
        </Button>
      </Popover.Trigger>
      <Popover.Content className="w-auto p-0">
        <Calendar
          disabled={{
            before: disablePastDates
              ? dayjs().toDate()
              : dayjs().subtract(10, "year").toDate(),
          }}
          mode="single"
          selected={date}
          onSelect={handleSelect}
          initialFocus
        />
        {displayTimeInput && (
          <div className="px-4 pt-0 pb-4 bg-slate-900 text-white calendar-font">
            <Label>Time</Label>
            <Input type="time" onChange={handleTimeChange} value={time} />
          </div>
        )}

        {!selectedDateTime && <p>Pick date.</p>}
      </Popover.Content>
    </Popover>
  );
}
