import { DetailedCard } from "./detailed-card";
import { DetailsTable } from "./details-table";

export interface AdditionalDetailsProps {
  additionalDetails: string;
}

export const AdditionalDetails = ({
  additionalDetails,
}: AdditionalDetailsProps) => {
  return (
    <DetailedCard>
      <DetailedCard.DetailsContainer>
        <DetailedCard.Title>Additional Details</DetailedCard.Title>
        <DetailedCard.Description>
          Check additional details to the order that the customer has provided.
        </DetailedCard.Description>
      </DetailedCard.DetailsContainer>
      <DetailsTable>
        <DetailsTable.Row
          label="Additional Details"
          content={additionalDetails}
        />
      </DetailsTable>
    </DetailedCard>
  );
};
