import { EmptyState } from "@twocontinents/dashboard/shared";
import { FerrisWheel } from "lucide-react";
import { FormProvider } from "react-hook-form";

import { AttractionsFilters } from "./attractions-filters";
import { AttractionsTable } from "./attractions-table";
import { useAttractionsListing } from "./use-attractions-listing";

export const AttractionsListing = () => {
  const { attractions, form } = useAttractionsListing();
  return (
    <>
      <FormProvider {...form}>
        <AttractionsFilters />
      </FormProvider>
      {attractions && <AttractionsTable attractions={attractions} />}
      {attractions.length === 0 && (
        <EmptyState>
          <EmptyState.Icon>
            <FerrisWheel size={40} />
          </EmptyState.Icon>
          <EmptyState.Header>Brak atrakcji</EmptyState.Header>
          <EmptyState.Paragraph>
            Sprawdź czy wpisana nazwa jest poprawna i spróbuj ponownie.
          </EmptyState.Paragraph>
        </EmptyState>
      )}
    </>
  );
};
