import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = (attractionId: number) => [
  "private-attraction-business-partners",
  attractionId,
];

export const useGetAttractionBusinessPartners = (attractionId: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/business/attraction/{attractionId}", {
          params: {
            path: {
              attractionId,
            },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(attractionId),
    enabled: Boolean(attractionId),
  });

  return { businessPartners: data?.businessPartners ?? [], isLoading };
};

export const useInvalidateGetAttractionBusinessPartners = () => {
  const queryClient = useQueryClient();

  const invalidate = async (attractionId: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(attractionId),
    });
  };

  return { invalidate };
};
