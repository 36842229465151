import {
  OfferBundleFormSchema,
  filterAgeGroupEntries,
  filterExtraOptionEntries,
  mapToEntries,
} from "@twocontinents/dashboard/travel-agencies/offer-bundle/shared";

import {
  useCreateOfferBundle,
  useGetBaseAgeGroups,
  useGetBaseExtraOptions,
} from "../data-access";

export const useOfferBundleCreatePage = () => {
  const { ageGroups } = useGetBaseAgeGroups();
  const { extraOptions } = useGetBaseExtraOptions();

  const entries = mapToEntries(ageGroups, extraOptions);

  const { createOfferBundle, isPending } = useCreateOfferBundle();
  const onSubmit = ({ name, ...data }: OfferBundleFormSchema) => {
    const ageGroupEntries = data.entries.filter((entry) =>
      filterAgeGroupEntries(entry),
    );
    const extraOptionEntries = data.entries.filter((entry) =>
      filterExtraOptionEntries(entry),
    );
    createOfferBundle({
      name,
      ageGroupEntries,
      extraOptionEntries,
    });
  };

  return {
    entries,
    isPending,
    onSubmit,
  };
};
