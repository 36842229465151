import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { ApplicationErrorCode, components } from "@twocontinents/shared";

import { useInvalidateGetAgent } from "./get-agent";

type UpdateAgent = components["schemas"]["UpdateAgentRequest"];

export const useUpdateAgent = (id: number) => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetAgent();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: UpdateAgent) => {
      return apiClient
        .PATCH("/private/partners/travel-agencies/agents/{id}", {
          body: request,
          params: {
            path: {
              id,
            },
          },
        })
        .then((response) => response.data);
    },
    onSuccess: async () => {
      toast.success("Agent updated successfully");
      await invalidate(id);
    },
    onError: (err) => {
      if (
        err.applicationErrorCode ===
        ApplicationErrorCode.USER_WITH_GIVEN_LOGIN_ALREADY_EXISTS
      ) {
        toast.error("User with given login already exists");
        return;
      }
      toast.error("Could not update agent");
    },
  });

  return { updateAgent: mutate, isPending };
};
