import {
  TourBase,
  TourParticipant,
} from "@twocontinents/dashboard/attractions/shared";
import { Button, DropdownMenu } from "@twocontinents/dashboard/shared";

import { useTourDetailsDropdown } from "./use-tour-details-dropdown";

interface Props {
  tour: TourBase;
  isDropdownOpened?: boolean;
  setIsDropdownOpened: (isDropdownOpened: boolean) => void;
}

export const TourDetailsDropdown = ({
  tour,
  isDropdownOpened,
  setIsDropdownOpened,
}: Props) => {
  const { uniqueParticipants } = useTourDetailsDropdown(tour.participants);

  return (
    <DropdownMenu modal open={isDropdownOpened}>
      <DropdownMenu.Trigger asChild>
        <Button
          variant="ghost"
          className="justify-start text-left h-full hover:!bg-slate-900"
          onClick={() => {
            setIsDropdownOpened(true);
          }}
        >
          {tour.id.toString().padStart(6, "0")}
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className=" w-fit max-w-xs ml-16 left-10"
        onInteractOutside={() => {
          setIsDropdownOpened(false);
        }}
      >
        <DropdownMenu.Label>Participants: </DropdownMenu.Label>
        <DropdownMenu.Separator />
        <DropdownMenu.Group>
          {uniqueParticipants.map(
            (
              participant,
              currentParticipantIndex,
              { length: totalParticipants },
            ) => (
              <ParticipantDetails
                key={participant.id}
                currentParticipantIndex={currentParticipantIndex}
                totalParticipants={totalParticipants}
                participant={participant}
              />
            ),
          )}
        </DropdownMenu.Group>
        {tour.additionalDetails && (
          <>
            <DropdownMenu.Separator />
            <DropdownMenu.Label>Additional details</DropdownMenu.Label>
            <p>{tour.additionalDetails}</p>
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};

interface ParticipantDetailsProps {
  participant: TourParticipant;
  currentParticipantIndex: number;
  totalParticipants: number;
}

const ParticipantDetails = ({
  participant,
  currentParticipantIndex,
  totalParticipants,
}: ParticipantDetailsProps) => {
  const {
    extraOptions,
    name,
    surname,
    additionalDetails,
    orderId,
    phoneNumber,
    pickupLocation,
  } = participant;
  return (
    <>
      <DropdownMenu.Item
        className="!data-[disabled]:select-text cursor-default flex-col items-start !data-[disabled]:bg-slate-900 !data-[disabled]:text-white data-[disabled]:opacity-100"
        disabled
      >
        <ParticipantDetailsItem
          label="Order ID"
          value={orderId ? orderId.toString() : "Manually created"}
        />
        <ParticipantDetailsItem
          label="Full name"
          value={`${name} ${surname}`}
        />

        <ParticipantDetailsItem label="Locale" value={participant.locale} />

        {phoneNumber && (
          <ParticipantDetailsItem label="Phone number" value={phoneNumber} />
        )}

        {pickupLocation && (
          <ParticipantDetailsItem
            label="Pickup location"
            value={pickupLocation}
          />
        )}

        {extraOptions?.map(({ extraOption, amount }) => (
          <ParticipantDetailsItem
            key={`${extraOption.id}`}
            label={extraOption.name}
            value={amount.toString()}
          />
        ))}

        {additionalDetails && (
          <ParticipantDetailsItem
            label="Additional details"
            value={additionalDetails}
          />
        )}
      </DropdownMenu.Item>
      {currentParticipantIndex !== totalParticipants - 1 && (
        <DropdownMenu.Separator />
      )}
    </>
  );
};

interface ParticipantDetailsItemProps {
  label: string;
  value: string;
}

const ParticipantDetailsItem = ({
  label,
  value,
}: ParticipantDetailsItemProps) => {
  return (
    <p className="my-1 select-text">
      <span className="font-semibold select-text">{label}:</span> {value}
    </p>
  );
};
