import {
  Tour,
  checkIfTourHasPrimaryFinancialLog,
} from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  Button,
  CurrencyCodeSelect,
  DatePicker,
  FinancialLogCategory,
  FinancialLogCategorySelect,
  Form,
  FormItem,
  Input,
  buttonVariants,
  routes,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode } from "@twocontinents/shared";
import { Link } from "react-router-dom";

import { useCreateTourFinancialLogDialog } from "./use-create-tour-financial-log-entry";

interface CreateTourFinancialLogEntryDialogProps {
  tour: Tour;
}

export const CreateTourFinancialLogEntryDialog = ({
  tour,
}: CreateTourFinancialLogEntryDialogProps) => {
  const { form, onSubmit, opened, open, close, isPending, isFormValid } =
    useCreateTourFinancialLogDialog(tour);

  const hasPrimaryCostFinancialLog = checkIfTourHasPrimaryFinancialLog(
    tour.financialLogs,
  );

  if (hasPrimaryCostFinancialLog) {
    return (
      <Link
        to={routes.financialLog.show.url(hasPrimaryCostFinancialLog.id)}
        className={buttonVariants({ variant: "outline" })}
      >
        Referring financial log
      </Link>
    );
  }

  return (
    <AlertDialog open={opened}>
      <AlertDialog.Trigger asChild onClick={open}>
        <Button variant={"secondary"}>Create cost financial log</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <Form {...form}>
          <form onSubmit={onSubmit}>
            <AlertDialog.Header>
              <AlertDialog.Title>Create cost financial log</AlertDialog.Title>
              <AlertDialog.Description className={"py-4"}>
                <Form.Fields className="max-w-md">
                  <Form.Field
                    control={form.control}
                    name="value"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Value
                          </FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} type={"number"} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="currency"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Currency
                          </FormItem.Label>
                          <FormItem.Control>
                            <CurrencyCodeSelect
                              value={field.value}
                              currencies={Object.values(CurrencyCode)}
                              onValueChange={field.onChange}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="category"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Category
                          </FormItem.Label>
                          <FormItem.Control>
                            <FinancialLogCategorySelect
                              value={field.value}
                              categories={Object.values(FinancialLogCategory)}
                              onValueChange={field.onChange}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="cause"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Cause
                          </FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="settleDate"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Settle date
                          </FormItem.Label>
                          <FormItem.Control>
                            <DatePicker
                              date={field.value ?? undefined}
                              setDate={field.onChange}
                              classNames={{
                                trigger: "w-72",
                              }}
                              disabled={{ before: new Date() }}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                </Form.Fields>
              </AlertDialog.Description>
            </AlertDialog.Header>
            <AlertDialog.Footer>
              <AlertDialog.Cancel onClick={close}>Cancel</AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button
                  type={"submit"}
                  isLoading={isPending}
                  disabled={!isFormValid}
                >
                  Submit
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
