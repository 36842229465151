import { zodResolver } from "@hookform/resolvers/zod";
import { useGetAttractionVariantAgeGroups } from "@twocontinents/dashboard/attractions/shared";
import { AttractionGroup } from "@twocontinents/dashboard/shared";
import { CurrencyCode } from "@twocontinents/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAgeGroup } from "../../../data-access";

const AgeGroupSchema = z.object({
  variantId: z.coerce.number().nonnegative(),
  id: z.coerce.number().nonnegative(),
  description: z.string(),
  ageFrom: z.coerce.number(),
  ageTo: z.coerce.number().optional(),
  price: z.coerce.number(),
  providerCurrency: z.nativeEnum(CurrencyCode).optional(),
  providerPrice: z.coerce.number().optional(),
  internalProviderPrice: z.coerce.number().optional(),
  alertTicketsAmountThreshold: z.coerce.number().optional(),
});

type AgeGroupSchema = z.infer<typeof AgeGroupSchema>;

export const useAgeGroupsSettings = (
  attractionId: number,
  attractionGroups: AttractionGroup[],
) => {
  const form = useForm<AgeGroupSchema>({
    resolver: zodResolver(AgeGroupSchema),
  });

  const {
    setValue,
    formState: { isValid },
  } = form;

  const variantId = form.watch("variantId");

  const selectedVariant = attractionGroups
    .flatMap((group) => group.attractionVariants)
    .find((variant) => variant.id === variantId);

  const { ageGroups } = useGetAttractionVariantAgeGroups(variantId, {
    placeholderData: (prev) => prev,
    enabled: Boolean(variantId),
    refetchOnWindowFocus: false,
  });

  const { updateAgeGroup, isPending, newId } = useUpdateAgeGroup();

  const ageGroupId = form.watch("id");
  const ageGroup = ageGroups.find((group) => group.id === ageGroupId);

  useEffect(() => {
    if (ageGroup) {
      setValue("description", ageGroup.description);
      setValue("ageFrom", ageGroup.ageFrom);
      setValue("ageTo", ageGroup.ageTo ?? undefined);
      setValue("price", ageGroup.price);
      setValue("providerCurrency", ageGroup.providerCurrency ?? undefined);
      setValue("providerPrice", ageGroup.providerPrice);
      setValue("internalProviderPrice", ageGroup.internalProviderPrice);
      setValue(
        "alertTicketsAmountThreshold",
        ageGroup.alertTicketsAmountThreshold,
      );
    }
  }, [ageGroup, setValue]);

  useEffect(() => {
    if (newId) {
      return setValue("id", newId);
    }
  }, [ageGroups, setValue, newId]);

  const handleFormSubmit = form.handleSubmit((values) => {
    if (!ageGroup) {
      return;
    }

    updateAgeGroup({
      variantId,
      ageGroupId: ageGroup.id,
      body: values,
      attractionId,
    });
  });

  const isSubmitDisabled = !isValid || isPending;

  return {
    form,
    isPending,
    onSubmit: handleFormSubmit,
    ageGroups,
    isSubmitDisabled,
    selectedVariantHasInternalTicket: selectedVariant?.internalTicket ?? false,
  };
};
