/* eslint-disable max-lines-per-function */
import {
  CompanyDetails,
  CustomerDetails,
  InvoiceType,
} from "@twocontinents/shared";

export const useCustomerDetailsSection = (
  customerDetails: CustomerDetails,
  companyDetails?: CompanyDetails,
  invoiceType?: InvoiceType,
  travelAgencyId?: number,
  agentOrderReferenceId?: string,
) => {
  const {
    customerCity,
    customerCountryIsoCode,
    customerEmail,
    customerName,
    customerPhoneNumber,
    customerPostalCode,
    customerStreet,
    customerSurname,
    customerLocale,
  } = customerDetails;

  const detailsRows = [
    {
      label: "Full name",
      content: `${customerName} ${customerSurname}`,
    },
    {
      label: "Locale",
      content: customerLocale,
    },
    {
      label: "Country",
      content: customerCountryIsoCode,
    },
    customerCity
      ? {
          label: "City",
          content: customerCity,
        }
      : undefined,
    customerStreet
      ? {
          label: "Street",
          content: customerStreet,
        }
      : undefined,
    customerPostalCode
      ? {
          label: "Postal code",
          content: customerPostalCode,
        }
      : undefined,
    {
      label: "Phone number",
      content: customerPhoneNumber,
    },
    {
      label: "Email",
      content: customerEmail,
    },
  ];

  if (invoiceType) {
    detailsRows.push(
      {
        label: "Invoice",
        content: `Yes`,
      },
      {
        label: "Invoice type",
        content: invoiceType,
      },
    );
  }

  if (
    companyDetails &&
    companyDetails.companyName &&
    companyDetails.companyNip
  ) {
    detailsRows.push(
      {
        label: "Company name",
        content: companyDetails.companyName,
      },
      {
        label: "VAT number",
        content: companyDetails.companyNip,
      },
    );
  }

  if (agentOrderReferenceId) {
    detailsRows.push({
      label: "Agent order reference ID",
      content: agentOrderReferenceId,
    });
  }

  const hasBeenBoughtByAgent = Boolean(travelAgencyId);

  return {
    detailsRows: detailsRows.filter((detail) => detail !== undefined),
    hasBeenBoughtByAgent,
  };
};
