import { AttractionVariantSelectByGroups } from "@twocontinents/dashboard/attractions/shared";
import { AttractionGroup } from "@twocontinents/dashboard/shared";
import {
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { useTimeSettings } from "./use-time-settings";

interface TimePriorToReservationSettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const TimeSettings = ({
  attractionId,
  attractionGroups,
}: TimePriorToReservationSettingsProps) => {
  const { form, onSubmit, isPending, isTimed, timedAttractionGroups } =
    useTimeSettings(attractionId, attractionGroups);

  if (!isTimed) {
    return null;
  }

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Time settings</SettingCard.Title>
        <SettingCard.Description>
          Change variant's time settings
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="variantId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Variant</FormItem.Label>
                    <FormItem.Control>
                      <AttractionVariantSelectByGroups
                        attractionGroups={timedAttractionGroups}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Fields className="max-w-xs">
              <Form.Field
                control={form.control}
                name="timeStart"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row justifyBetween>
                      <FormItem.Label>Time start</FormItem.Label>
                      <FormItem.Control>
                        <Input
                          {...field}
                          type={"time"}
                          step="1"
                          className={"w-28"}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="timeFinish"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row justifyBetween>
                      <FormItem.Label>Time finish</FormItem.Label>
                      <FormItem.Control>
                        <Input
                          {...field}
                          type={"time"}
                          step="1"
                          className={"w-28"}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="duration"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row justifyBetween>
                      <FormItem.Label>Duration</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} type={"number"} className={"w-16"} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            </Form.Fields>
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change attraction's time settings.
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
