import { zodResolver } from "@hookform/resolvers/zod";
import { AttractionGroup } from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttractionGroup } from "../../../data-access";
import { useAttractionGroups } from "../../../hooks";
import { AttractionGroupEntity } from "../../../types";

const DescriptionSettingsSchema = z.object({
  groupId: z.number().nonnegative(),
  description: z.string().min(3),
});

type DescriptionSettingsValues = z.infer<typeof DescriptionSettingsSchema>;

export const useDescriptionSettings = (
  attractionId: number,
  attractionGroups: AttractionGroup[],
) => {
  const { updateAttractionGroup, isPending } = useUpdateAttractionGroup();

  const form = useForm<DescriptionSettingsValues>({
    resolver: zodResolver(DescriptionSettingsSchema),
  });

  const { handleSubmit, watch, setValue } = form;

  const onSubmit = handleSubmit(({ groupId, ...body }) => {
    updateAttractionGroup({
      attractionId,
      groupId,
      body,
    });
  });

  const groupId = watch("groupId");

  const onGroupChange = (selectedGroup: AttractionGroupEntity) => {
    setValue("description", selectedGroup.description);
  };

  useAttractionGroups({
    attractionGroups,
    groupId,
    onGroupChange,
  });

  return {
    form,
    onSubmit,
    isPending,
  };
};
