import { CellContext, ColumnDef } from "@tanstack/react-table";
import {
  ALL_ITEMS_ID,
  Form,
  FormItem,
  Input,
} from "@twocontinents/dashboard/shared";

import { OfferEntry } from "../types";

export const columns: ColumnDef<OfferEntry>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "relatedDescription",
    header: "Attraction / Variant",
    cell: (props) => (
      <p className={""}>{props.row.original.relatedDescription}</p>
    ),
  },
  {
    accessorKey: "attractionId",
    header: "",
    enableColumnFilter: true,
    filterFn: (row, id, filterValue) => {
      const attractionId = row.getValue(id);
      if (filterValue === ALL_ITEMS_ID) {
        return true;
      }
      return attractionId === filterValue;
    },
  },
  {
    accessorKey: "type",
    header: "Type",
  },
  {
    accessorKey: "offerPrice",
    header: "Offer Price",
    cell: (props) => <OfferPriceCell {...props} />,
  },
  {
    accessorKey: "livePrice",
    header: "Live Price",
    cell: (props) => <LivePriceCell {...props} />,
  },
  {
    accessorKey: "providerPrice",
    header: "Provider price",
    cell: (props) => <ProviderPriceCell {...props} />,
  },
];

const LivePriceCell = ({ row }: CellContext<OfferEntry, unknown>) => {
  const {
    original: { livePrice },
  } = row;

  return <p className={"text-slate-400"}>{livePrice}</p>;
};

const ProviderPriceCell = ({ row }: CellContext<OfferEntry, unknown>) => {
  const {
    original: { providerPrice },
  } = row;

  return <p className={"text-slate-400"}>{providerPrice}</p>;
};

const OfferPriceCell = ({ row }: CellContext<OfferEntry, unknown>) => {
  const {
    index,
    original: { id },
  } = row;

  return (
    <Form.Field
      key={id}
      name={`entries.${index}.offerPrice`}
      render={({ field }) => (
        <FormItem>
          <FormItem.Control>
            <Input {...field} type="number" className={"w-20"} />
          </FormItem.Control>
          <FormItem.Message />
        </FormItem>
      )}
    />
  );
};
