import { zodResolver } from "@hookform/resolvers/zod";
import { useOpened } from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useAddTravelAgencyBalance } from "../../data-access";

const AddTravelAgencyBalanceSchema = z.object({
  valueAed: z.coerce.number(),
});

type AddTravelAgencyBalanceSchema = z.infer<
  typeof AddTravelAgencyBalanceSchema
>;

export const useAddTravelAgencyBalanceModal = (travelAgencyId: number) => {
  const { opened, open, close } = useOpened();

  const { addTravelAgencyBalance, isPending } =
    useAddTravelAgencyBalance(travelAgencyId);

  const form = useForm<AddTravelAgencyBalanceSchema>({
    resolver: zodResolver(AddTravelAgencyBalanceSchema),
    mode: "onBlur",
  });

  const onSubmit = form.handleSubmit((data) => {
    addTravelAgencyBalance(data);

    form.reset({
      valueAed: undefined,
    });
    close();
  });

  return {
    form,
    onSubmit,
    opened,
    open,
    close,
    isPending,
  };
};
