import { Layout, Tabs } from "@twocontinents/dashboard/shared";
import { useTranslation } from "react-i18next";

import { AttractionsListing } from "./attractions-listing";
import { AttractionsSequence } from "./attractions-sequence";

export const AttractionsPage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      header={{
        title: t("attractions"),
        description: t("manageAttractions"),
      }}
    >
      <Tabs defaultValue="table">
        <Tabs.List className="self-center">
          <Tabs.Trigger value={"table"}>Table</Tabs.Trigger>
          <Tabs.Trigger value={"sequence"}>Sequence</Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value={"table"}>
          <AttractionsListing />
        </Tabs.Content>
        <Tabs.Content value={"sequence"}>
          <AttractionsSequence />
        </Tabs.Content>
      </Tabs>
    </Layout>
  );
};
