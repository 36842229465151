import { AttractionOrder } from "@twocontinents/dashboard/attractions/shared";
import { Form, SettingCard } from "@twocontinents/dashboard/shared";

import { ConfirmPaymentAlertDialog } from "./confirm-payment-alert-dialog/confirm-payment-alert-dialog";
import { MultiReservationsOrderDetails } from "./multi-reservations-order-details";
import { OrderStatusSwitch } from "./order-status-switch";
import { PaymentStatusSwitch } from "./payment-status-switch";
import { SingleReservationOrderDetails } from "./single-reservation-order-details";
import { useOrderDetails } from "./use-order-details";

interface OrderDetailsProps {
  order: AttractionOrder;
}

export const OrderDetails = ({ order }: OrderDetailsProps) => {
  const {
    formattedPurchasedTime,
    form,
    onSubmit,
    isPending,
    isMultiReservation,
  } = useOrderDetails(order);

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>
          Order #{order.id} from {formattedPurchasedTime}
        </SettingCard.Title>
        <SettingCard.Description>
          Review order details and change its statuses.
        </SettingCard.Description>
      </SettingCard.Header>
      {isMultiReservation ? (
        <MultiReservationsOrderDetails order={order} />
      ) : (
        <SingleReservationOrderDetails order={order} />
      )}
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <div className="flex gap-10 mb-6 w-full justify-center ">
            <OrderStatusSwitch />
            <PaymentStatusSwitch orderId={order.id} />
          </div>

          <SettingCard.Footer>
            <SettingCard.Description>
              Order status will be changed after pressing save.
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>Save</SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
