import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetTravelAgency } from "./get-travel-agency";

type AddTravelAgencyBalance =
  components["schemas"]["AddTravelAgencyBalanceRequest"];

export const useAddTravelAgencyBalance = (id: number) => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetTravelAgency();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: AddTravelAgencyBalance) => {
      return apiClient
        .POST("/private/partners/travel-agencies/{id}/balance", {
          body: request,
          params: {
            path: {
              id,
            },
          },
        })
        .then((response) => response.data);
    },
    onSuccess: async () => {
      toast.success("Travel agency balance added successfully");
      await invalidate(id);
    },
    onError: () => {
      toast.error("Failed to add travel agency balance");
    },
  });

  return { addTravelAgencyBalance: mutate, isPending };
};
