import {
  ApiLocaleSelect,
  AttractionCombobox,
  Button,
  Input,
  Label,
  Select,
  SingleOrRangeDatePicker,
} from "@twocontinents/dashboard/shared";

import { PaymentStatusFilter } from "../../../types";
import { useOrderFilters } from "./use-order-filters";

export const OrderFilters = () => {
  const {
    attractionsOptions,
    defaultPaymentStatus,
    selectedId,
    handleAttractionComboboxSelect,
    handlePaymentStatusSelect,
    customerEmail,
    customerName,
    customerSurname,
    orderId,
    reservationDate,
    handleReservationDateChange,
    handleNameChange,
    handleSurnameChange,
    handleEmailChange,
    handleOrderIdChange,
    setReservationDateTo,
    setReservationDateFrom,
    showRangePicker,
    toggleRangePicker,
    reservationDateTo,
    reservationDateFrom,
    locale,
    onLocaleChange,
    setDateRangeAsThisMonth,
  } = useOrderFilters();

  return (
    <div className="flex flex-col mt-4">
      <div className="gap-5 flex  items-start border-slate-700 flex-wrap">
        <div className="flex  gap-4">
          <AttractionCombobox
            attractions={attractionsOptions}
            selectedId={selectedId}
            setSelectedOption={handleAttractionComboboxSelect}
          />
          <Button onClick={setDateRangeAsThisMonth}>This month</Button>
        </div>
        <div className="flex gap-4 flex-wrap">
          <Input
            name="firstName"
            className="w-44"
            placeholder="Jan"
            value={customerName}
            onChange={handleNameChange()}
          />
          <Input
            name="lastName"
            className="w-44"
            placeholder="Kowalski"
            value={customerSurname}
            onChange={handleSurnameChange()}
          />
          <Input
            name="email"
            className="w-44"
            placeholder="Email"
            value={customerEmail}
            onChange={handleEmailChange()}
          />
          <Input
            name="id"
            className="w-44"
            placeholder="Order ID"
            type="number"
            value={orderId}
            onChange={handleOrderIdChange()}
          />
        </div>
      </div>
      <div className="flex gap-8 items-center my-4">
        <div className="w-fit  justify-start flex flex-col gap-2">
          <Label>Payment status</Label>
          <Select
            defaultValue={defaultPaymentStatus}
            onValueChange={handlePaymentStatusSelect}
          >
            <Select.Trigger className="w-44">
              <Select.Value placeholder="Wszystkie" />
            </Select.Trigger>
            <Select.Content>
              <Select.Item value={PaymentStatusFilter.ALL}>All</Select.Item>
              <Select.Item value={PaymentStatusFilter.CONFIRMED}>
                Only Paid
              </Select.Item>
              <Select.Item value={PaymentStatusFilter.NOT_CONFIRMED}>
                Only Not Paid
              </Select.Item>
            </Select.Content>
          </Select>
        </div>

        <div className="flex flex-col gap-2">
          <SingleOrRangeDatePicker
            showRangePicker={showRangePicker}
            toggleRangePicker={toggleRangePicker}
            dateFrom={reservationDateFrom}
            setDateFrom={setReservationDateFrom}
            dateTo={reservationDateTo}
            setDateTo={setReservationDateTo}
            time={undefined}
            setTime={undefined}
            date={reservationDate}
            setDate={handleReservationDateChange}
          />
        </div>
        <div>
          <Label>Locale</Label>
          <ApiLocaleSelect
            value={locale}
            onValueChange={onLocaleChange}
            reset={() => onLocaleChange(undefined)}
          />
        </div>
      </div>
    </div>
  );
};
