import { useGetAttractionVariantAgeGroups } from "@twocontinents/dashboard/attractions/shared";
import {
  NOT_SELECTED_ID,
  useGetAttraction,
  useGetAttractions,
} from "@twocontinents/dashboard/shared";
import { DateFormatter, Gender } from "@twocontinents/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useGenerateTicket } from "../../data-access";

export const GenerateTicketFormSchema = z.object({
  date: z.coerce.date(),
  attractionId: z.number(),
  variantId: z.number(),
  ageGroupId: z.number(),
  amount: z.number(),
  customerDetails: z.object({
    customerGender: z.nativeEnum(Gender),
    customerName: z.string(),
    customerSurname: z.string(),
  }),
});

type GenerateTicketFormValues = z.infer<typeof GenerateTicketFormSchema>;

export const useGenerateTicketForm = () => {
  const form = useForm<GenerateTicketFormValues>({
    defaultValues: {
      customerDetails: {
        customerGender: Gender.MALE,
        customerName: "Two",
        customerSurname: "Continents",
      },
    },
  });

  const attractionId = form.watch("attractionId");
  const attractionVariantId = form.watch("variantId");
  const ageGroupId = form.watch("ageGroupId");

  const { attractions } = useGetAttractions();

  const currentAttraction = attractions.find(
    (attraction) => attraction.id === attractionId,
  );

  const { attraction: currentAttractionDetails } = useGetAttraction(
    currentAttraction?.id ?? NOT_SELECTED_ID,
  );

  const variants =
    currentAttractionDetails?.attractionGroups.flatMap(
      (group) => group.attractionVariants,
    ) ?? [];

  const currentVariant = variants?.find(
    (variant) => variant.id === attractionVariantId,
  );

  const { ageGroups } = useGetAttractionVariantAgeGroups(
    currentVariant?.id ?? NOT_SELECTED_ID,
  );

  const selectedAgeGroup = ageGroups?.find((group) => group.id === ageGroupId);

  const { generateTicket, isPending, ticketUrl } = useGenerateTicket();

  const handleFormSubmit = form.handleSubmit(async (values) => {
    if (selectedAgeGroup) {
      generateTicket({
        variantId: attractionVariantId,
        body: {
          date: DateFormatter.formatToYYYYMMDD(values.date),
          customerDetails: {
            ...values.customerDetails,
            customerEmail: "kontakt@twocontinents.com",
            customerPhoneNumber: "+48 123 456 789",
          },
          ageGroups: [
            {
              id: selectedAgeGroup.id,
              type: selectedAgeGroup.type,
              amount: values.amount,
            },
          ],
        },
      });
    }
  });

  return {
    form,
    handleFormSubmit,
    isPending,
    ticketUrl,
    ageGroups: ageGroups ?? [],
    attractions: attractions ?? [],
    variants,
  };
};
