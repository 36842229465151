import { AttractionVariantSelectByGroups } from "@twocontinents/dashboard/attractions/shared";
import { AttractionGroup } from "@twocontinents/dashboard/shared";
import {
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { useTimePriorToReservationSettings } from "./use-time-prior-to-reservation-settings";

interface TimePriorToReservationSettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const TimePriorToReservationSettings = ({
  attractionId,
  attractionGroups,
}: TimePriorToReservationSettingsProps) => {
  const { form, onSubmit, isPending } = useTimePriorToReservationSettings(
    attractionId,
    attractionGroups,
  );

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>
          Time prior to reservation settings
        </SettingCard.Title>
        <SettingCard.Description>
          Change variant's time prior to reservation (You provide values in
          timezone of the attraction).
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="variantId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Variant</FormItem.Label>
                    <FormItem.Control>
                      <AttractionVariantSelectByGroups
                        attractionGroups={attractionGroups}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Fields className="max-w-xs">
              <Form.Field
                control={form.control}
                name="minDaysPriorToReservation"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row justifyBetween>
                      <FormItem.Label>
                        Min days prior to reservation
                      </FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} type={"number"} className={"w-16"} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="timePriorToReservation"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row justifyBetween>
                      <FormItem.Label>Time prior to reservation</FormItem.Label>
                      <FormItem.Control>
                        <Input
                          {...field}
                          type={"time"}
                          step="1"
                          className={"w-28"}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            </Form.Fields>
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will affect form calendar reservation rules.
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
