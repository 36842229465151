import * as React from "react";

import { cn } from "../../utils";
import { Skeleton } from "./skeleton";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="relative w-full overflow-auto border border-slate-700 rounded-lg ">
    <table
      ref={ref}
      className={cn("w-full caption-bottom text-sm h-full", className)}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn("[&_tr]:border-b", className)} {...props} />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & {
    isLoading?: boolean;
    columns?: number;
    rows?: number;
  }
>(({ className, isLoading, columns, rows, ...props }, ref) => {
  if (isLoading) {
    return <TableBodySkeleton columns={columns} rows={rows} />;
  }

  return (
    <tbody
      ref={ref}
      className={cn("[&_tr:last-child]:border-0 ", className)}
      {...props}
    />
  );
});
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "border-t bg-slate-100/50 font-medium [&>tr]:last:border-b-0 dark:bg-slate-800/50",
      className,
    )}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "border-b border-slate-700 transition-colors hover:bg-slate-100/50 data-[state=selected]:bg-slate-100 dark:hover:bg-slate-900 dark:data-[state=selected]:bg-slate-800",
      className,
    )}
    {...props}
  />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-12 px-4 text-left align-middle font-medium text-slate-500 [&:has([role=checkbox])]:pr-0 dark:text-slate-400",
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "p-4 font-medium  align-middle [&:has([role=checkbox])]:pr-0",
      className,
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-slate-500 dark:text-slate-400", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

interface TableBodySkeletonProps {
  columns?: number;
  rows?: number;
}

const TableBodySkeleton = ({
  columns = 4,
  rows = 5,
}: TableBodySkeletonProps) => {
  return (
    <TableBody>
      {Array.from({ length: rows }).map((_, i) => (
        <TableRow key={i}>
          {Array.from({ length: columns }).map((_, j) => (
            <TableCell key={j}>
              <Skeleton className={"h-4"} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

type TableComponent = typeof Table & {
  Header: typeof TableHeader;
  Body: typeof TableBody;
  Footer: typeof TableFooter;
  Head: typeof TableHead;
  Row: typeof TableRow;
  Cell: typeof TableCell;
  Caption: typeof TableCaption;
};

const TableWithComponents = Table as TableComponent;

TableWithComponents.Header = TableHeader;
TableWithComponents.Body = TableBody;
TableWithComponents.Footer = TableFooter;
TableWithComponents.Head = TableHead;
TableWithComponents.Row = TableRow;
TableWithComponents.Cell = TableCell;
TableWithComponents.Caption = TableCaption;

export { TableWithComponents as Table };
