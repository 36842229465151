import {
  Tour,
  TourParticipant,
} from "@twocontinents/dashboard/attractions/shared";
import { useState } from "react";

export const hasBeenBoughtByAgent = (participant: TourParticipant) =>
  Boolean(participant.agentId);

export const useTourParticipantsTable = (tour: Tour) => {
  const sortedParticipants = tour.participants.toSorted((a, b) => a.id - b.id);

  const [selectedParticipants, setSelectedParticipants] = useState<number[]>(
    [],
  );

  const allParticipantsSelected =
    selectedParticipants.length === sortedParticipants.length &&
    sortedParticipants.length > 0;

  return {
    allParticipantsSelected,
    selectedParticipants,
    setSelectedParticipants,
    sortedParticipants,
  };
};
