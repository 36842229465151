import {
  EmptyState,
  Layout,
  Pagination,
} from "@twocontinents/dashboard/shared";
import { Logs } from "lucide-react";

import { CreateManualFinancialLogModal } from "./create-manual-financial-log-modal";
import { FinancialLogsFilters } from "./financial-logs-filters";
import { FinancialLogsTable } from "./financial-logs-table";
import { useFinancialLogsIndexPage } from "./use-financial-logs-index-page";

export function FinancialLogsIndexPage() {
  const {
    handleSizeSelect,
    page,
    size,
    totalPages,
    showFinancialLogsNotFound,
    showFinancialLogs,
    showPagination,
  } = useFinancialLogsIndexPage();

  return (
    <Layout
      header={{
        title: "Financial Log",
        description: "Financial log",
      }}
    >
      <FinancialLogsFilters />
      <div className="flex justify-end">
        <CreateManualFinancialLogModal />
      </div>
      {showFinancialLogsNotFound && (
        <EmptyState>
          <EmptyState.Icon>
            <Logs size={40} />
          </EmptyState.Icon>
          <EmptyState.Header>No financial logs</EmptyState.Header>
          <EmptyState.Paragraph>
            Check if provided filters are correct
          </EmptyState.Paragraph>
        </EmptyState>
      )}

      {showFinancialLogs && <FinancialLogsTable />}

      {showPagination && (
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          setSize={handleSizeSelect}
          size={size}
          sizeLabel="Financial logs per page"
        />
      )}
    </Layout>
  );
}
