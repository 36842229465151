import {
  useFinancialLogsQueryParams,
  useGetFinancialLogs,
} from "@twocontinents/dashboard/financial-log/shared";
import { usePaginationQueryParams } from "@twocontinents/dashboard/shared";
import { useCallback } from "react";

export const useFinancialLogsIndexPage = () => {
  const { query, setQuery } = useFinancialLogsQueryParams();
  const { page, size } = usePaginationQueryParams();

  const { data, isLoading } = useGetFinancialLogs(query);

  const handleSizeSelect = useCallback(
    (size: number) => {
      setQuery((params) => ({ ...params, size }));
    },
    [setQuery],
  );
  const showFinancialLogsNotFound =
    (data?.financialLogs?.length ?? 0) === 0 && !isLoading;

  const showFinancialLogs =
    data?.financialLogs && (data?.financialLogs.length ?? 0) > 0 && !isLoading;

  const showPagination = Boolean(!isLoading && data?.financialLogs);
  return {
    data,
    isLoading,
    handleSizeSelect,
    page,
    size,
    totalPages: data?.pagination.totalPages ?? 0,
    showFinancialLogsNotFound,
    showFinancialLogs,
    showPagination,
  };
};
