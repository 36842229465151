import { FormItem, Input, Select } from "@twocontinents/dashboard/shared";
import { Locale } from "@twocontinents/shared";

import { useAttractionPartnershipsQueryParams } from "../hooks";

export const BusinessPartnerAttractionsFilters = () => {
  const { description, locale, setDescription, setLocale } =
    useAttractionPartnershipsQueryParams();

  return (
    <div className="flex gap-4">
      <FormItem>
        <FormItem.Row>
          <FormItem.LabelNoContext>Description</FormItem.LabelNoContext>
        </FormItem.Row>
        <FormItem.Row>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Dubai Sightseeing"
          />
        </FormItem.Row>
      </FormItem>
      <FormItem>
        <FormItem.Row>
          <FormItem.LabelNoContext>Locale</FormItem.LabelNoContext>
        </FormItem.Row>
        <FormItem.Row>
          <Select
            value={locale}
            onValueChange={(value) => setLocale(value as Locale)}
          >
            <Select.Trigger className="max-w-md h-max min-h-10 min-w-44">
              <Select.Value placeholder="Choose locale" />
            </Select.Trigger>
            <Select.Content>
              {Object.values(Locale).map((locale) => (
                <Select.Item key={locale} value={locale}>
                  {locale.toUpperCase()}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        </FormItem.Row>
      </FormItem>
    </div>
  );
};
