import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = () => ["private-offer-bundles"];

export const useGetOfferBundles = () => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/travel-agencies/offer-bundles/")
        .then((response) => response.data),
    queryKey: queryKey(),
  });

  return { offerBundles: data ?? [], isLoading };
};

export const useInvalidateGetOfferBundles = () => {
  const queryClient = useQueryClient();
  const invalidate = async () =>
    await queryClient.invalidateQueries({
      queryKey: queryKey(),
    });

  return {
    invalidate,
  };
};
