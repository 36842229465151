import {
  AttractionGroup,
  Button,
  Form,
  FormItem,
  Input,
  Select,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { Calendar } from "../calendar";
import { useExtraOptionsAvailabilitySettings } from "./use-extra-options-availability-settings";

interface ExtraOptionsAvailabilitySettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const ExtraOptionsAvailabilitySettings = ({
  attractionId,
  attractionGroups,
}: ExtraOptionsAvailabilitySettingsProps) => {
  const {
    form,
    onSubmit,
    isPending,
    formRef,
    saveMutatedAvailabilities,
    onSubmitUnavailable,
    distinctLocaleGroupIds,
    onSubmitAvailable,
    allAvailabilities,
    selectedExtraOptions,
  } = useExtraOptionsAvailabilitySettings(attractionGroups, attractionId);

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>
          Extra options Availability settings
        </SettingCard.Title>
        <SettingCard.Description>
          Change extra option availability
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit} ref={formRef}>
          <Form.Field
            control={form.control}
            name="extraOptionLocaleGroupId"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label>Extra option</FormItem.Label>
                  <FormItem.Control>
                    <Select
                      onValueChange={(value) => field.onChange(value)}
                      value={field.value}
                    >
                      <Select.Trigger className="max-w-md h-max min-h-10">
                        <Select.Value placeholder="Select extra option" />
                      </Select.Trigger>
                      <Select.Content>
                        {distinctLocaleGroupIds.map((localeGroupId) => (
                          <Select.Item
                            value={localeGroupId}
                            key={localeGroupId}
                          >
                            {localeGroupId}
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select>
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <div className={"flex gap-40 items-center mt-10"}>
            <div className={"w-full max-w-md"}>
              <Form.Fields>
                <Form.Field
                  control={form.control}
                  name={`date`}
                  render={({ field }) => (
                    <FormItem>
                      <FormItem.Row>
                        <FormItem.Label>Date</FormItem.Label>
                        <FormItem.Control>
                          <Input {...field} type={"date"} />
                        </FormItem.Control>
                      </FormItem.Row>
                      <FormItem.Message />
                    </FormItem>
                  )}
                />

                <FormItem.Row>
                  <Form.Field
                    control={form.control}
                    name={`times`}
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>Times</FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} type={"number"} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />{" "}
                  <Form.Field
                    control={form.control}
                    name={`days`}
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>Days</FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} type={"number"} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                </FormItem.Row>
              </Form.Fields>
              <div className={"flex justify-end mt-6 gap-2"}>
                <Button
                  type={"button"}
                  variant="destructive"
                  onClick={onSubmitUnavailable}
                >
                  Not available
                </Button>
                <Button
                  type={"button"}
                  variant="positive"
                  onClick={onSubmitAvailable}
                >
                  Available
                </Button>
              </div>
            </div>

            <Calendar
              selectedExtraOptions={selectedExtraOptions}
              allAvailabilities={allAvailabilities}
            />
          </div>
        </form>

        <SettingCard.Footer>
          <SettingCard.Description>
            This setting will change variant's description only on the
            dashboard.
          </SettingCard.Description>
          <SettingCard.Button
            onClick={saveMutatedAvailabilities}
            isLoading={isPending}
          >
            Submit
          </SettingCard.Button>
        </SettingCard.Footer>
      </Form>
    </SettingCard>
  );
};
