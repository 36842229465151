import { useGetAttraction } from "@twocontinents/dashboard/shared";
import { Layout, Tabs } from "@twocontinents/dashboard/shared";
import { useAuth } from "@twocontinents/dashboard/shared/auth";
import { useParams } from "react-router-dom";

import { AttractionSettings } from "./attraction-settings";
import { CommissionsSettings } from "./commissions-settings";
import { ExtraOptionsSettings } from "./extra-options-settings";
import { GroupsSettings } from "./groups-settings";
import { VariantsSettings } from "./variants-settings";

export const Settings = () => {
  const { id: idParameter } = useParams<{ id: string }>();

  const id = Number(idParameter);

  const { attraction, isLoading } = useGetAttraction(id);
  const { user } = useAuth();

  if (isLoading || !attraction) {
    return (
      <Layout
        header={{
          title: "Attraction settings",
          description: "Manage attraction settings",
        }}
      />
    );
  }

  const { description, active, attractionGroups, extraOptions } = attraction;

  const currency = attraction.currency;

  const title = `${attraction.description} settings`;

  return (
    <Layout
      header={{
        title,
        description: "Manage attraction settings",
      }}
    >
      <Tabs defaultValue="attraction">
        <Tabs.List className="self-center">
          <Tabs.Trigger value={"attraction"}>Attraction</Tabs.Trigger>
          <Tabs.Trigger value={"groups"}>Groups</Tabs.Trigger>
          <Tabs.Trigger value={"variants"}>Variants</Tabs.Trigger>
          <Tabs.Trigger value={"extra-options"}>Extra options</Tabs.Trigger>
          {user?.isRoot && (
            <Tabs.Trigger value={"commissions"}>Commissions</Tabs.Trigger>
          )}
        </Tabs.List>
        <Tabs.Content value={"attraction"}>
          <AttractionSettings
            attractionId={id}
            defaultActive={active}
            defaultDescription={description}
            defaultRefundable={attraction.refundable}
            defaultHoursPriorReservationIsRefundable={
              attraction.hoursPriorReservationIsRefundable ?? undefined
            }
            defaultCurrency={currency}
          />
        </Tabs.Content>
        <Tabs.Content value={"groups"}>
          <GroupsSettings
            attractionId={id}
            attractionGroups={attractionGroups}
          />
        </Tabs.Content>
        <Tabs.Content value={"variants"}>
          <VariantsSettings
            attractionId={id}
            attractionGroups={attractionGroups}
          />
        </Tabs.Content>
        <Tabs.Content value={"extra-options"}>
          <ExtraOptionsSettings attractionId={id} extraOptions={extraOptions} />
        </Tabs.Content>{" "}
        {user?.isRoot && (
          <Tabs.Content value={"commissions"}>
            <CommissionsSettings attractionId={id} />
          </Tabs.Content>
        )}
      </Tabs>
    </Layout>
  );
};
