import { Layout } from "@twocontinents/dashboard/shared";

import { CreateInvoiceForm } from "./ui";

export const CreateInvoicePage = () => {
  return (
    <Layout
      header={{
        title: "Create Invoice",
        description: "Fill in the form below to create a new invoice.",
      }}
    >
      <CreateInvoiceForm />
    </Layout>
  );
};
