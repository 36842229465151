import { baseToast } from "./use-toast";

export const toast = {
  success: (message: string) => {
    baseToast({ title: "Success!", description: message, duration: 3000 });
  },
  error: (message: string) => {
    baseToast({
      title: "Error!",
      description: message,
      variant: "destructive",
      duration: 3000,
    });
  },
};
