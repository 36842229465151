import {
  AlertDialog,
  ApiLocaleSelect,
  AttractionCombobox,
  Button,
  DateTimePicker,
  Form,
  FormItem,
  Input,
  Label,
  NOT_SELECTED_ID,
  Textarea,
  buttonVariants,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";

import { useAddManualReviewDialog } from "./use-add-manual-review-dialog";

export const AddManualReviewDialog = () => {
  const {
    opened,
    open,
    form,
    onSubmit,
    isPending,
    pictures,
    attractions,
    createdAtTime,
    handleFileInputChange,
    fileInputRef,
    close,
    toggle,
  } = useAddManualReviewDialog();

  return (
    <AlertDialog open={opened} onOpenChange={toggle}>
      <AlertDialog.Trigger asChild>
        <Button className="w-fit self-end my-2" variant="outline">
          Add manual review
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Add manual review</AlertDialog.Title>
          <AlertDialog.Description className="text-white">
            Add manual review
          </AlertDialog.Description>
        </AlertDialog.Header>
        <Form {...form}>
          <form onSubmit={onSubmit} className="flex flex-col gap-6 mt-4">
            <Form.Field
              control={form.control}
              name="attractionId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-25">
                      Attraction
                    </FormItem.Label>
                    <AttractionCombobox
                      attractions={attractions}
                      setSelectedOption={(option) => {
                        field.onChange(option?.id ?? NOT_SELECTED_ID);
                      }}
                      selectedId={field.value}
                    />
                  </FormItem.Row>
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="createdAtDate"
              render={({ field }) => (
                <FormItem.Row>
                  <Label className="min-w-25">Creation Date</Label>
                  <DateTimePicker
                    date={field.value ? new Date(field.value) : undefined}
                    setDate={(date) =>
                      field.onChange(DateFormatter.formatToYYYYMMDD(date))
                    }
                    displayTimeInput={true}
                    time={createdAtTime}
                    setTime={(time) =>
                      form.setValue("createdAtTime", time ?? "00:00:00")
                    }
                  />
                </FormItem.Row>
              )}
            />
            <Form.Field
              control={form.control}
              name="rating"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-25">Rating</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} type="number" />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="content"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-25">
                      Content
                    </FormItem.Label>
                    <FormItem.Control>
                      <Textarea {...field} className="max-w-none" rows={10} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="locale"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-25">Locale</FormItem.Label>
                    <FormItem.Control>
                      <ApiLocaleSelect
                        value={field.value}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />{" "}
            <Form.Field
              control={form.control}
              name="customerName"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-25">
                      Customer Name
                    </FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <input
              accept=".jpeg,.jpg,.png,.webp"
              style={{ display: "none" }}
              onChange={handleFileInputChange}
              multiple
              type="file"
              id="raised-button-file"
              ref={fileInputRef}
            />
            <label htmlFor="raised-button-file">
              <Button
                type="button"
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              >
                Add Photos
              </Button>
            </label>
            <div className="flex gap-2 flex-wrap">
              {pictures.map((picture) => (
                <a
                  key={picture.name}
                  href={URL.createObjectURL(picture)}
                  target="_blank"
                  className="underline"
                >
                  {picture.name}
                </a>
              ))}
            </div>
            <AlertDialog.Footer>
              <AlertDialog.Cancel
                type="button"
                className={buttonVariants({
                  variant: "destructive",
                  className: "border-none",
                })}
              >
                Cancel
              </AlertDialog.Cancel>
              <Button
                isLoading={isPending}
                disabled={isPending}
                type="submit"
                variant="outline"
              >
                Submit
              </Button>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
