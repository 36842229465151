import { PropsWithChildren } from "react";

export type IndicatorStatus = "positive" | "neutral" | "negative";

interface Props extends PropsWithChildren {
  status?: IndicatorStatus;
}

const STATUS_TO_COLOR: Record<IndicatorStatus, string> = {
  negative: "bg-red-500",
  neutral: "bg-yellow-500",
  positive: "bg-green-500",
};

export const StatusIndicator = ({ children, status = "positive" }: Props) => {
  return (
    <div className="flex items-center">
      <div
        className={`mr-2 h-2.5 w-2.5 rounded-full ${STATUS_TO_COLOR[status]}`}
      ></div>{" "}
      {children}
    </div>
  );
};
