import { AttractionVariantSelectByGroups } from "@twocontinents/dashboard/attractions/shared";
import {
  AttractionCombobox,
  AttractionGroupSelect,
  DateTimePicker,
  Form,
  FormItem,
  Input,
  Label,
  NOT_SELECTED_ID,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";

import { useCreateTourForm } from "./use-create-tour-form";

export const CreateTourForm = () => {
  const {
    onSubmit,
    formMethods,
    isCreatingTour,
    attractions,
    groupsFromSelectedAttraction,
    time,
    currentAttraction,
    currentGroup,
    isFormValid,
    currentVariant,
    displayTimeInput,
  } = useCreateTourForm();

  const { setValue } = formMethods;

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Create tour</SettingCard.Title>
        <SettingCard.Description>
          Create new tour by filling out the form below
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...formMethods}>
        <form
          onSubmit={onSubmit}
          className="flex gap-6 flex-col justify-between"
        >
          <Form.Field
            control={formMethods.control}
            name="attractionId"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label>Attraction</FormItem.Label>
                  <AttractionCombobox
                    attractions={attractions}
                    setSelectedOption={(option) => {
                      field.onChange(option?.id ?? NOT_SELECTED_ID);
                    }}
                    selectedId={field.value}
                  />
                </FormItem.Row>
              </FormItem>
            )}
          />

          {currentAttraction && (
            <Form.Field
              control={formMethods.control}
              name="attractionGroupId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Attraction group</FormItem.Label>
                    <AttractionGroupSelect
                      attractionGroups={groupsFromSelectedAttraction}
                      onValueChange={field.onChange}
                    />
                  </FormItem.Row>
                </FormItem>
              )}
            />
          )}

          {currentGroup && (
            <Form.Field
              control={formMethods.control}
              name="attractionVariantId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Attraction variant</FormItem.Label>
                    <AttractionVariantSelectByGroups
                      attractionGroups={[currentGroup]}
                      onValueChange={field.onChange}
                      value={field.value}
                    />
                  </FormItem.Row>
                </FormItem>
              )}
            />
          )}

          {currentVariant && (
            <Form.Field
              control={formMethods.control}
              name="date"
              render={({ field }) => (
                <FormItem.Row>
                  <Label className="min-w-40">Data</Label>
                  <DateTimePicker
                    disablePastDates
                    date={field.value ? new Date(field.value) : undefined}
                    setDate={(date) =>
                      field.onChange(DateFormatter.formatToYYYYMMDD(date))
                    }
                    displayTimeInput={displayTimeInput}
                    time={time}
                    setTime={(time) => setValue("time", time)}
                  />
                </FormItem.Row>
              )}
            />
          )}

          <Form.Field
            control={formMethods.control}
            name="additionalDetails"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label>Additional Details</FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />

          <SettingCard.Footer>
            <SettingCard.Description>
              After submitting the form, the tour will be created
            </SettingCard.Description>
            <SettingCard.Button
              isLoading={isCreatingTour}
              disabled={isCreatingTour || !isFormValid}
            >
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
