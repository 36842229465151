import { ActivationLog } from "../activation-log";
import { ExtraOptionAvailability } from "../extra-option-availability";

interface DateAvailabilityConstructorProps {
  date: string;
  slotsAvailable: number;
  time?: string | null | undefined;
  extraOptions: ExtraOptionAvailability[];
  activationLogs: ActivationLog[];
}

export class DateAvailability {
  public date: string;
  public time?: string | undefined;
  public slotsAvailable: number;
  public extraOptions: ExtraOptionAvailability[];
  public activationLogs: ActivationLog[];

  public static id(date: string, time?: string | undefined): string {
    const timePart = time ? `-${time}` : "";
    return date + timePart;
  }

  public constructor({
    date,
    slotsAvailable,
    time,
    extraOptions,
    activationLogs,
  }: DateAvailabilityConstructorProps) {
    this.date = date;
    this.slotsAvailable = slotsAvailable;
    this.time = time ?? undefined;
    this.extraOptions = extraOptions;
    this.activationLogs = activationLogs;
  }

  public get isAvailable(): boolean {
    return this.slotsAvailable > 0 || this.slotsAvailable === -1;
  }

  public get id(): string {
    return DateAvailability.id(this.date, this.time);
  }

  public equals(date: string, time: string | undefined): boolean {
    return this.date === date && this.time === time;
  }
}
