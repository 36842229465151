import { PropsWithChildren } from "react";

import { cn } from "../utils";

interface CardWithTitleProps extends PropsWithChildren {
  className?: string;
}

export const DetailedCard = ({ children, className }: CardWithTitleProps) => {
  return (
    <section
      className={cn(
        "flex flex-col gap-2 rounded-md border border-slate-800 p-6 w-full",
        className,
      )}
    >
      {children}
    </section>
  );
};

interface DetailsContainerProps extends PropsWithChildren {
  className?: string;
}

const DetailsContainer = ({ children, className }: DetailsContainerProps) => {
  return (
    <div className={cn("flex justify-between flex-col", className)}>
      {children}
    </div>
  );
};

const Title = ({ children }: PropsWithChildren) => {
  return <h3 className=" text-xl font-semibold">{children}</h3>;
};

const Description = ({ children }: PropsWithChildren) => {
  return (
    <p className=" my-4 text-sm leading-relaxed text-slate-300">{children}</p>
  );
};

DetailedCard.DetailsContainer = DetailsContainer;
DetailedCard.Title = Title;
DetailedCard.Description = Description;
