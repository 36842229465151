import { Tour } from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  Button,
  buttonVariants,
} from "@twocontinents/dashboard/shared";

import { useSendMessageDialog } from "./use-send-message-dialog";

interface SendMessageDialogProps {
  tour: Tour;
}

export const SendMessageDialog = ({ tour }: SendMessageDialogProps) => {
  const { openAlertDialog, isPending, onSubmit } = useSendMessageDialog(tour);

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button variant="positive">Send immediately</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Title className="text-center">
          Are you sure you want to send this message right now?
        </AlertDialog.Title>
        <AlertDialog.Footer className="mt-4 flex flex-row !justify-center">
          <AlertDialog.Cancel
            className={buttonVariants({ variant: "destructive" })}
          >
            No
          </AlertDialog.Cancel>
          <Button
            onClick={onSubmit}
            isLoading={isPending}
            type="submit"
            variant="positive"
          >
            Yes
          </Button>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
