import { useMutation } from "@tanstack/react-query";
import { useInvalidateGetTour } from "@twocontinents/dashboard/attractions/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type CreateTourParticipantRequest =
  components["schemas"]["CreateTourParticipantsRequest"];

interface Props {
  tourId: number;
  body: CreateTourParticipantRequest;
}

export const useCreateTourParticipants = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetTour();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ body, tourId }: Props) => {
      return apiClient
        .POST("/private/attractions/tours/{id}/participants", {
          params: {
            path: {
              id: tourId,
            },
          },
          body: body,
        })
        .then((response) => response.data);
    },
    onSuccess: async (_, { tourId }) => {
      await invalidate(tourId);
      toast.success("Tour participant created successfully");
    },
    onError: () => {
      toast.error("Failed to create-financial-log tour participant");
    },
  });

  return { createTourParticipants: mutate, isPending };
};
