import {
  Button,
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { useRefundabilitySettings } from "./use-refundability-settings";

interface RefundabilitySettingsProps {
  attractionId: number;
  defaultRefundable: boolean;
  defaultHoursPriorReservationIsRefundable?: number;
}

export const RefundabilitySettings = ({
  attractionId,
  defaultRefundable,
  defaultHoursPriorReservationIsRefundable,
}: RefundabilitySettingsProps) => {
  const {
    form,
    toggleRefundable,
    toggleButtonVariant,
    toggleButtonLabel,
    onSubmit,
    isPending,
    refundable,
  } = useRefundabilitySettings(
    attractionId,
    defaultRefundable,
    defaultHoursPriorReservationIsRefundable,
  );
  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Refundability settings</SettingCard.Title>
        <SettingCard.Description>
          Activate or disable Refundability
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <div className="flex flex-col gap-8">
            <Form.Field
              control={form.control}
              name="refundable"
              render={() => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-64">
                      Refundable
                    </FormItem.Label>
                    <FormItem.Control>
                      <Button
                        variant={toggleButtonVariant}
                        onClick={toggleRefundable}
                        type="button"
                      >
                        {toggleButtonLabel}
                      </Button>
                    </FormItem.Control>
                  </FormItem.Row>
                </FormItem>
              )}
            />

            {refundable && (
              <Form.Field
                control={form.control}
                name="hoursPriorReservationIsRefundable"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label className="min-w-64">
                        Hours prior reservation is refundable
                      </FormItem.Label>
                      <FormItem.Control>
                        <Input type="number" {...field} />
                      </FormItem.Control>
                    </FormItem.Row>
                  </FormItem>
                )}
              />
            )}
          </div>

          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will set the refundability of the Attraction.
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
