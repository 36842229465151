import { AlertDialog, Button } from "@twocontinents/dashboard/shared";

import { useDeleteReviewPicture } from "../../data-access";
import { Review } from "../../types";

interface DeletePictureDialogProps {
  picture: Review["pictures"][number];
}

export const DeletePictureDialog = ({ picture }: DeletePictureDialogProps) => {
  const { deleteReviewPicture, isPending } = useDeleteReviewPicture();

  const handleDeletePictureDialogSubmit = () => {
    deleteReviewPicture({ reviewId: picture.id });
  };

  return (
    <AlertDialog>
      <AlertDialog.Trigger asChild>
        <Button variant="destructive">Delete picture</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>
            Are you sure you want to delete this picture?
          </AlertDialog.Title>
          <AlertDialog.Description>
            You will not be able to recover this picture.
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
          <AlertDialog.Action
            onClick={handleDeletePictureDialogSubmit}
            disabled={isPending}
          >
            Yes
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
