import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  Input,
  Select,
} from "@twocontinents/dashboard/shared";
import {
  AgentType,
  mapAgentTypeToString,
} from "@twocontinents/dashboard/travel-agencies/shared";

import { useCreateAgentModal } from "./use-create-agent-modal";

interface CreateAgentModalProps {
  travelAgencyId: number;
}

export const CreateAgentModal = ({ travelAgencyId }: CreateAgentModalProps) => {
  const { onSubmit, form, isPending, opened, open, close } =
    useCreateAgentModal(travelAgencyId);

  return (
    <AlertDialog open={opened}>
      <AlertDialog.Trigger asChild onClick={open}>
        <Button variant={"secondary"}>Create agent</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Create agent</AlertDialog.Title>
        </AlertDialog.Header>

        <Form {...form}>
          <form
            onSubmit={onSubmit}
            className="flex gap-6 flex-col justify-between"
          >
            <Form.Field
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Name</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Email</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} type={"email"} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="login"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Login</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Password</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Type</FormItem.Label>
                    <FormItem.Control>
                      <Select
                        value={field.value?.toString()}
                        onValueChange={(value) => field.onChange(value)}
                      >
                        <Select.Trigger className="max-w-md h-max min-h-10">
                          <Select.Value placeholder="Choose type" />
                        </Select.Trigger>
                        <Select.Content>
                          {Object.values(AgentType).map((type) => (
                            <Select.Item key={type} value={type}>
                              {mapAgentTypeToString(type)}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <AlertDialog.Footer>
              <AlertDialog.Cancel onClick={close}>Cancel</AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button type={"submit"} isLoading={isPending}>
                  Submit
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
