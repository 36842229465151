import { Loader2 } from "lucide-react";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";

import { PropsWithClassName } from "../types";
import { cn } from "../utils";
import { Button } from "./shadcn";

export const SettingCard = ({
  className,
  children,
}: PropsWithChildren & PropsWithClassName) => {
  return (
    <div
      className={cn(
        "flex w-full flex-col gap-6 rounded-md border border-slate-800 p-6 ",
        className,
      )}
    >
      {children}
    </div>
  );
};

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

interface SettingCardFooterProps extends PropsWithChildren {
  className?: string;
}

const SettingCardFooter = ({ children, className }: SettingCardFooterProps) => {
  return (
    <div
      className={cn(
        "flex bg-slate-900 px-6 py-4 items-center justify-between -mx-6 mt-auto -mb-6 mt-6",
        className,
      )}
    >
      {children}
    </div>
  );
};

const SettingCardDescription = ({ children }: PropsWithChildren) => {
  return <p className="text-sm">{children}</p>;
};

const SettingCardButtonGroup = ({ children }: PropsWithChildren) => {
  return <div className="flex gap-4">{children}</div>;
};

const SettingCardButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      type="submit"
      variant="outline"
      {...props}
      disabled={props.disabled}
    >
      {props.isLoading ? (
        <i className="px-2">
          <Loader2 className="animate-spin text-slate-900" />
        </i>
      ) : (
        <span className="text-center">{children}</span>
      )}
    </Button>
  );
};

const Header = ({
  children,
  className,
}: PropsWithChildren & PropsWithClassName) => {
  return (
    <div className={cn("flex gap-4 justify-between flex-col", className)}>
      {children}
    </div>
  );
};
const Title = ({ children }: PropsWithChildren) => {
  return <h3 className=" text-xl font-semibold">{children}</h3>;
};
const Description = ({ children }: PropsWithChildren) => {
  return (
    <p className=" my-4 text-sm leading-relaxed text-slate-300">{children}</p>
  );
};

SettingCard.Header = Header;
SettingCard.Title = Title;
SettingCard.Description = Description;
SettingCard.Footer = SettingCardFooter;
SettingCard.Description = SettingCardDescription;
SettingCard.ButtonGroup = SettingCardButtonGroup;
SettingCard.Button = SettingCardButton;
