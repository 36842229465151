import {
  AlertDialog,
  Button,
  buttonVariants,
} from "@twocontinents/dashboard/shared";

import { Reservation } from "../../../types";
import { useAssignTicketToReservationDialog } from "./use-assign-ticket-to-reservation-dialog";

interface AssignTicketToReservationDialogProps {
  reservation: Reservation;
  orderId: number;
}

export const AssignTicketToReservationDialog = ({
  orderId,
  reservation,
}: AssignTicketToReservationDialogProps) => {
  const {
    opened,
    handleAssignTicket,
    ticket,
    handleFileInputChange,
    fileInputRef,
    toggle,
    isPending,
  } = useAssignTicketToReservationDialog(orderId, reservation);

  return (
    <AlertDialog open={opened} onOpenChange={toggle}>
      <AlertDialog.Trigger asChild>
        <Button className="w-fit self-end my-2" variant="outline">
          {reservation.ticketUrl ? "Assign new ticket" : "Assign Ticket"}
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>
            Assign a ticket to the reservation
          </AlertDialog.Title>
          <AlertDialog.Description className="text-white">
            Assign a ticket to the reservation
          </AlertDialog.Description>
        </AlertDialog.Header>
        <div className="flex flex-col gap-6 mt-4">
          <input
            accept=".pdf"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
            multiple
            type="file"
            id="raised-button-file"
            ref={fileInputRef}
          />
          <label htmlFor="raised-button-file">
            <Button
              type="button"
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >
              Upload file
            </Button>
          </label>
          {ticket && (
            <div className="flex gap-2 flex-wrap">
              <a
                key={ticket.name}
                href={URL.createObjectURL(ticket)}
                target="_blank"
                className="underline"
              >
                {ticket.name}
              </a>
            </div>
          )}
          <AlertDialog.Footer>
            <AlertDialog.Cancel
              type="button"
              className={buttonVariants({
                variant: "destructive",
                className: "border-none",
              })}
            >
              Cancel
            </AlertDialog.Cancel>
            <Button
              isLoading={isPending}
              disabled={isPending}
              type="button"
              onClick={handleAssignTicket}
              variant="outline"
            >
              Submit
            </Button>
          </AlertDialog.Footer>
        </div>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
