import { usePaginationQueryParams } from "@twocontinents/dashboard/shared";
import { InvoiceStatus } from "@twocontinents/dashboard/travel-agencies/invoices/shared";
import {
  NumberParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

export const useInvoicesFiltersQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    travelAgencyName: withDefault(StringParam, undefined),
    invoiceId: withDefault(NumberParam, undefined),
    creationDate: withDefault(StringParam, undefined),
    status: withDefault(
      createEnumParam(Object.values(InvoiceStatus)),
      undefined,
    ),
  });

  const { page, size } = usePaginationQueryParams();

  return {
    query: {
      ...query,
      page,
      size,
    },
    setQuery,
  };
};
