import { AdditionalDetails, Layout } from "@twocontinents/dashboard/shared";
import { useParams } from "react-router-dom";

import { useGetOrder } from "../data-access";
import { CustomerDetailsSection } from "./customer-details-section";
import { OrderDetails } from "./order-details";
import { OrderDetailsSkeleton } from "./order-details";

export const OrderPage = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, order } = useGetOrder(Number(id));

  const shouldShowOrder = !isLoading && !!order;

  return (
    <Layout
      header={{
        title: "Order",
        description: `Manage order #${id}`,
      }}
    >
      {isLoading && <OrderDetailsSkeleton />}
      {shouldShowOrder && (
        <>
          <OrderDetails order={order} />
          {order.orderAdditionalDetails && (
            <AdditionalDetails
              additionalDetails={order.orderAdditionalDetails}
            />
          )}
          <CustomerDetailsSection
            customerDetails={order.customerDetails}
            companyDetails={order.companyDetails}
            invoiceType={order.invoiceType}
            travelAgencyId={order.travelAgencyId}
            travelAgencyName={order.travelAgencyName}
            agentOrderReferenceId={order.agentOrderReferenceId}
            agentName={order.agentName}
          />
        </>
      )}
    </Layout>
  );
};
