import {
  Pagination as NextUiPagination,
  type PaginationItemRenderProps,
  PaginationItemType,
} from "@nextui-org/pagination";
import { useCallback, useState } from "react";

import { usePaginationQueryParams } from "../hooks";
import { cn } from "../utils";
import { Input } from "./shadcn/input";
import { Select } from "./shadcn/select";

interface Props {
  setSize?: (size: number) => void;
  size?: number;
  totalPages: number;
  currentPage?: number;
  sizeLabel?: string;
  setPage?: (page: number) => void;
}

export const Pagination = ({
  currentPage,
  totalPages,
  setSize,
  size,
  sizeLabel,
  setPage,
}: Props) => {
  const [inputtedPage, setInputtedPage] = useState<number | undefined>(
    undefined,
  );

  // To consume page and size from query params use usePaginationQueryParams hook in the parent component
  const defaultPagination = usePaginationQueryParams();
  const currentPageOrDefault = currentPage ?? defaultPagination.page;

  const sizeOrDefault = size ?? defaultPagination.size;

  const setPageOrDefault = setPage ?? defaultPagination.setPage;
  const setSizeOrDefault = setSize ?? defaultPagination.setSize;

  const handleNextClick = useCallback(() => {
    const nextPage = currentPageOrDefault ? currentPageOrDefault + 1 : 1;
    if (totalPages && nextPage > totalPages - 1) return;
    setPageOrDefault(nextPage);
  }, [currentPageOrDefault, totalPages, setPageOrDefault]);

  const handlePreviousClick = useCallback(() => {
    if (!currentPageOrDefault) return;

    if (currentPageOrDefault > 0) {
      setPageOrDefault(currentPageOrDefault - 1);
    }
  }, [setPageOrDefault, currentPageOrDefault]);

  if (totalPages === 0) return null;
  const renderItem = ({ ref, value, isActive }: PaginationItemRenderProps) => {
    if (value === PaginationItemType.NEXT) {
      return (
        <button
          key={value.toString()}
          type="button"
          className="rounded-r-lg flex items-center justify-center px-4 h-10 ml-0 leading-tight borderrounded-r-lg border bg-slate-900 border-slate-700 text-slate-400 hover:bg-slate-700 hover:text-white"
          onClick={handleNextClick}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
        </button>
      );
    }

    if (value === PaginationItemType.PREV) {
      return (
        <button
          type="button"
          key={value.toString()}
          className={
            "rounded-l-lg flex items-center justify-center px-4 h-10 ml-0 leading-tight borderrounded-l-lg border bg-slate-900 border-slate-700 text-slate-400 hover:bg-slate-700 hover:text-white"
          }
          onClick={handlePreviousClick}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
        </button>
      );
    }

    if (value === PaginationItemType.DOTS) {
      return (
        <Input
          key={value.toString()}
          className="cursor-pointer text-center mx-2 w-[50px] rounded-none px-3 h-10 leading-tight text-gray-500 bg-white border border-gray-300  dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
          id="page-number"
          placeholder="..."
          value={inputtedPage ?? ""}
          onChange={(event) => {
            setInputtedPage(Number(event.currentTarget.value));
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              setPageOrDefault(Number(event.currentTarget.value) - 1);
              setInputtedPage(undefined);
            }
          }}
        />
      );
    }

    return (
      <button
        type="button"
        key={value.toString()}
        ref={ref}
        className={cn(
          "flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white",
          isActive &&
            "z-10 flex items-center justify-center px-4 h-10 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white",
        )}
        onClick={() => setPageOrDefault(value - 1)}
      >
        {value}
      </button>
    );
  };

  return (
    <nav className="flex justify-between items-center my-4">
      <p className="text-slate-300">
        Page {currentPageOrDefault + 1} of {totalPages}
      </p>
      <div className="ml-auto w-fit mr-5">
        <Select
          value={sizeOrDefault?.toString()}
          onValueChange={(value: string) => {
            setSizeOrDefault(Number.parseInt(value));
          }}
        >
          <Select.Trigger className="w-fit">
            <Select.Value placeholder={sizeLabel} />
          </Select.Trigger>
          <Select.Content>
            <Select.Group>
              <Select.Label>{sizeLabel}</Select.Label>
              <Select.Item value="5">5</Select.Item>
              <Select.Item value="10">10</Select.Item>
              <Select.Item value="25">25</Select.Item>
              <Select.Item value="50">50</Select.Item>
            </Select.Group>
          </Select.Content>
        </Select>
      </div>
      <NextUiPagination
        disableCursorAnimation
        showControls
        total={totalPages}
        initialPage={1}
        page={currentPageOrDefault + 1}
        className="gap-2"
        radius="full"
        renderItem={renderItem}
        variant="light"
        classNames={{
          wrapper: "flex items-center -space-x-px h-8 text-sm gap-0",
        }}
      />
    </nav>
  );
};
