import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  NOT_SELECTED_ID,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";

const queryKey = (groupId: number) => [
  "attractionGroupVariantsActivationLogs",
  groupId,
];

export const useGetAttractionGroupVariantsActivationLogs = (
  groupId: number,
) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryKey: queryKey(groupId),
    queryFn: () =>
      apiClient.GET("/private/attractions/groups/{id}/activation-logs", {
        params: {
          path: {
            id: groupId,
          },
        },
      }),
    enabled: groupId !== NOT_SELECTED_ID && groupId !== undefined,
  });

  return {
    activationLogs: data?.data?.activationLogs ?? [],
    isLoading,
  };
};

export const useInvalidateAttractionGroupVariantsActivationLogs = () => {
  const queryClient = useQueryClient();

  return (groupId: number) => {
    queryClient.invalidateQueries({ queryKey: queryKey(groupId) });
  };
};
