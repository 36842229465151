import { AttractionGroup } from "@twocontinents/dashboard/shared";

import { AttractionGroupEntity } from "./attraction-group-entity";

export class AttractionGroupEntityList {
  private readonly _attractionGroups: AttractionGroupEntity[];

  public constructor(attractionGroups: AttractionGroup[]) {
    this._attractionGroups = attractionGroups.map(
      (group) => new AttractionGroupEntity(group),
    );
  }

  public findById(id: number) {
    return this._attractionGroups.find((group) => group.id === id);
  }

  public get all(): AttractionGroupEntity[] {
    return this._attractionGroups;
  }
}
