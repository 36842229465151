import {
  Layout,
  buttonVariants,
  routes,
} from "@twocontinents/dashboard/shared";
import { Link } from "react-router-dom";

import { CreateTravelAgencyForm } from "./create-travel-agency-form";

export const TravelAgencyCreatePage = () => {
  return (
    <Layout
      header={{
        title: "Create Travel agency",
        description: "Create Travel agency with commissions",
      }}
    >
      <div className="flex justify-end">
        <Link
          to={routes.travelAgencies.index}
          className={buttonVariants({ variant: "secondary" })}
        >
          Back to list
        </Link>
      </div>
      <CreateTravelAgencyForm />
    </Layout>
  );
};
