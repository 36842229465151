import { useRef } from "react";
import { useDayRender } from "react-day-picker";
import { DayProps as LibraryDayProps } from "react-day-picker";

import { CrewAvailabilityEnum } from "../../../../types";
import { CalendarDayAvailability } from "../../../../types";

export interface CalendarDayProps extends LibraryDayProps {
  dateAvailability?: CalendarDayAvailability;
  isMutated: boolean;
  areAllDatesUnavailable: boolean;
  hasAnyCrew: boolean;
}
export const useCalendarDay = ({
  date,
  displayMonth,
  dateAvailability,
  areAllDatesUnavailable,
  hasAnyCrew,
}: CalendarDayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(date, displayMonth, buttonRef);

  const isAvailable = dateAvailability?.isAvailable;
  const isNotSet = dateAvailability === undefined;
  const isNotAvailable = isAvailable === false || areAllDatesUnavailable;
  const isPolishGuideAvailable =
    dateAvailability?.crewAvailability?.isAvailable(
      CrewAvailabilityEnum.POLISH_GUIDE,
    ) ?? true;
  const isEnglishGuideAvailable =
    dateAvailability?.crewAvailability?.isAvailable(
      CrewAvailabilityEnum.ENGLISH_GUIDE,
    ) ?? true;

  const isPolishDriverAvailable =
    dateAvailability?.crewAvailability?.isAvailable(
      CrewAvailabilityEnum.POLISH_DRIVER,
    ) ?? true;

  const showAvailabilityLetters =
    hasAnyCrew && (isAvailable || (isNotSet && !isNotAvailable));

  return {
    buttonRef,
    dayRender,
    isAvailable,
    isNotSet,
    isNotAvailable,
    showAvailabilityLetters,
    isPolishGuideAvailable,
    isEnglishGuideAvailable,
    isPolishDriverAvailable,
  };
};
