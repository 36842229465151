import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";
import { ApiError, ApplicationErrorCode } from "@twocontinents/shared";

import { useInvalidateGetTravelAgency } from "./get-travel-agency";

type CreateAgent = components["schemas"]["CreateAgentRequest"];

export const useCreateAgent = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetTravelAgency();

  const { mutate, isPending } = useMutation<unknown, ApiError, CreateAgent>({
    mutationFn: (request: CreateAgent) => {
      return apiClient
        .POST("/private/partners/travel-agencies/agents/", {
          body: request,
        })
        .then((response) => response.data);
    },
    onSuccess: async (_, variables) => {
      toast.success("Agent created successfully");
      await invalidate(variables.travelAgencyId);
    },
    onError: (err) => {
      if (
        err.applicationErrorCode ===
        ApplicationErrorCode.USER_WITH_GIVEN_LOGIN_ALREADY_EXISTS
      ) {
        toast.error("User with given login already exists");
        return;
      }
      toast.error("Could not create agent");
    },
  });

  return { createAgent: mutate, isPending };
};
