import { useMutation } from "@tanstack/react-query";
import {
  useInvalidateGetTour,
  useInvalidateGetTours,
} from "@twocontinents/dashboard/attractions/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";

interface Props {
  messageId: number;
}

export const useSendTourMessage = (tourId: number) => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetTour();
  const { invalidate: invalidateGetTours } = useInvalidateGetTours();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ messageId }: Props) => {
      return apiClient
        .POST("/private/attractions/tours/messages/{id}/send", {
          params: {
            path: {
              id: messageId,
            },
          },
        })
        .then((response) => response.data);
    },
    onSuccess: async () => {
      await invalidate(tourId);
      await invalidateGetTours();
      toast.success("Message sent successfully");
    },
    onError: () => {
      toast.error("Failed to send message");
    },
  });

  return { sendTourMessage: mutate, isPending };
};
