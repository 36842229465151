import { useMutation, useQueryClient } from "@tanstack/react-query";
import { routes, toast } from "@twocontinents/dashboard/shared";
import { useAuth } from "@twocontinents/dashboard/shared/auth";
import { components, env, getFormData } from "@twocontinents/shared";
import { useNavigate } from "react-router-dom";

type CreateInvoiceRequest =
  components["schemas"]["CreateTravelAgencyInvoiceRequest"];

interface Props {
  agencyId: number;
  body: CreateInvoiceRequest;
  file: File;
}

const createInvoice = async (
  { body, agencyId, file }: Props,
  token: string,
) => {
  const formData = getFormData(body, [{ key: "invoice", value: file }]);

  return await fetch(
    `${env.API_URL}/api/v1/private/partners/travel-agencies/${agencyId}/invoices`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((res) => {
    if (!res.ok) {
      return res.json().then((err) => {
        throw err;
      });
    }
    return res;
  });
};

export const useCreateInvoice = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { token } = useAuth();

  const { mutate, isPending } = useMutation({
    mutationFn: (props: Props) => createInvoice(props, token ?? ""),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["private-invoices"] });
      navigate(routes.travelAgencies.invoices.index);
      toast.success("Invoice created successfully");
    },
    onError: () => {
      toast.error("Error creating invoice");
    },
  });

  return { createInvoice: mutate, isPending };
};
