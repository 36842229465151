"use client";

import { GoogleMapProps } from "@react-google-maps/api";
import { Coordinates, MapGestureHandling, env } from "@twocontinents/shared";
import { Map, useMap } from "@vis.gl/react-google-maps";
import { CSSProperties, PropsWithChildren, useEffect } from "react";
import { useDebounceCallback } from "usehooks-ts";

import { Marker } from "./marker";

interface BaseGoogleMapsMapProps extends PropsWithChildren, GoogleMapProps {
  center?: Coordinates;
  mapContainerStyle?: CSSProperties;
  addMarker?: (latitude: number, longitude: number) => void;
  mapGestureHandling?: MapGestureHandling;
  map: google.maps.Map | null;
}

const DEFAULT_ZOOM = 10;
const DUBAI_COORDINATES = { lat: 25.0657, lng: 55.1713 };

export const BaseGoogleMapsMap = ({
  center = DUBAI_COORDINATES,
  mapContainerStyle,
  children,
  mapGestureHandling,
  addMarker,
  map,
  ...props
}: BaseGoogleMapsMapProps) => {
  const debouncedHandleZoomChange = useDebounceCallback(
    (map: google.maps.Map | null, zoom) => {
      if (map && zoom !== undefined) {
        map.setZoom(zoom);
      }
    },
    300,
  );

  useEffect(() => {
    if (map) {
      map.setCenter(center);
    }
  }, [center, map]);

  useEffect(() => {
    if (map) {
      debouncedHandleZoomChange(map, DEFAULT_ZOOM);
    }
  }, [debouncedHandleZoomChange, map, center]);

  return (
    <Map
      mapId={env.GOOGLE_MAP_ID}
      mapTypeId={env.GOOGLE_MAP_TYPE_ID}
      defaultZoom={DEFAULT_ZOOM}
      defaultCenter={center}
      style={{
        ...mapContainerStyle,
        outline: "none",
      }}
      gestureHandling={mapGestureHandling ?? "greedy"}
      zoomControl={true}
      scrollwheel={false}
      disableDoubleClickZoom={false}
      disableDefaultUI={false}
      {...props}
      onClick={(e) => {
        if (addMarker) {
          addMarker(e.detail.latLng?.lat ?? 0, e.detail.latLng?.lng ?? 0);
        }
      }}
    >
      {children}
    </Map>
  );
};

BaseGoogleMapsMap.Marker = Marker;
