import { Table, buttonVariants, routes } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { Link } from "react-router-dom";

import { TravelAgencyInvoice } from "../types";
import { UpdateStatusDialog } from "./update-status-dialog";

interface InvoicesTableProps {
  invoices: TravelAgencyInvoice[];
}

export const InvoicesTable = ({ invoices }: InvoicesTableProps) => {
  return (
    <Table className="bg-slate-950 ">
      <Table.Header>
        <Table.Row>
          <Table.Head>ID</Table.Head>
          <Table.Head>Number</Table.Head>
          <Table.Head>Travel agency</Table.Head>
          <Table.Head>Created At</Table.Head>
          <Table.Head>Issue Date</Table.Head>
          <Table.Head>Sell Date</Table.Head>
          <Table.Head>Payment Deadline</Table.Head>
          <Table.Head>Amount</Table.Head>
          <Table.Head>Status</Table.Head>
          <Table.Head>Order</Table.Head>
          <Table.Head>Actions</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {invoices.map((invoice) => (
          <Table.Row key={invoice.id}>
            <Table.Cell>{invoice.id}</Table.Cell>
            <Table.Cell>{invoice.externalProviderNumber ?? "N/A"}</Table.Cell>
            <Table.Cell>{invoice.externalProviderNumber ?? "N/A"}</Table.Cell>
            <Table.Cell>{invoice.travelAgencyName}</Table.Cell>
            <Table.Cell>
              {DateFormatter.formatToYYYYMMDD(invoice.createdAt)}
            </Table.Cell>
            <Table.Cell>{invoice.issueDate}</Table.Cell>
            <Table.Cell>{invoice.sellDate}</Table.Cell>
            <Table.Cell>{invoice.paymentDeadline}</Table.Cell>
            <Table.Cell>
              {invoice.amount} {invoice.currency}
            </Table.Cell>
            <Table.Cell>{invoice.status}</Table.Cell>
            <Table.Cell className="p-0">
              {invoice.orderId ? (
                <Link
                  className={buttonVariants({
                    variant: "link",
                    className: "text-slate-100 text-left",
                  })}
                  to={routes.attractions.orders.show.url(invoice.orderId)}
                >
                  {invoice.orderId}
                </Link>
              ) : (
                <span className="px-4 py-2">N/A</span>
              )}
            </Table.Cell>
            <Table.Cell className="flex gap-2">
              <Link
                to={invoice.url}
                target="_blank"
                className={buttonVariants({ variant: "outline" })}
              >
                Open
              </Link>
              <UpdateStatusDialog
                invoiceId={invoice.id}
                invoiceStatus={invoice.status}
                agencyId={invoice.travelAgencyId}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
