import { ChevronsUpDown } from "lucide-react";

import { cn } from "../../utils";
import { Button, Command, Label, Popover } from "../shadcn";
import {
  type AttractionOption,
  useAttractionCombobox,
} from "./use-attraction-combobox";

// eslint-disable-next-line unicorn/prefer-export-from
export { AttractionOption };

export interface AttractionComboboxProps {
  attractions: AttractionOption[];
  selectedId?: number;
  setSelectedOption: (value: AttractionOption) => void;
  withLabel?: boolean;
}

export function AttractionCombobox({
  selectedId,
  setSelectedOption,
  attractions,
  withLabel = false,
}: AttractionComboboxProps) {
  const {
    attractionsOptions,
    defaultAttractionOption,
    onSelect,
    open,
    setOpen,
    selectedOption,
  } = useAttractionCombobox(attractions, setSelectedOption, selectedId);

  const notFoundText = "Attraction not found.";
  const searchPlaceholder = "Search for attraction...";

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <div className="flex flex-col gap-2 border-slate-700">
          {withLabel && <Label>Atrakcja</Label>}

          {(attractions?.length ?? 0) > 0 && (
            <Button
              type="button"
              role="combobox"
              aria-expanded={open}
              className=" justify-between"
            >
              {selectedOption?.description ??
                defaultAttractionOption.description}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50 border-slate-700" />
            </Button>
          )}
        </div>
      </Popover.Trigger>
      <Popover.Content
        asChild
        align="start"
        className="w-80 p-0 bg-slate-950 text-slate-100 border-slate-700"
      >
        <Command
          className={cn("border-slate-700 font-sans  !overflow-y-hidden")}
        >
          <Command.Input placeholder={searchPlaceholder} />
          <Command.List>
            <Command.Empty>{notFoundText}</Command.Empty>
            <Command.Group className="border-slate-700 h-96 ">
              {attractionsOptions.map((attraction) => {
                return (
                  <AttractionSelectItem
                    key={attraction.id}
                    selectedOptionId={selectedOption.id}
                    {...attraction}
                    onSelect={onSelect}
                  />
                );
              })}
            </Command.Group>
          </Command.List>
        </Command>
      </Popover.Content>
    </Popover>
  );
}

interface AttractionSelectItemProps {
  selectedOptionId: number;
  id: number;
  description: string;
  onSelect: (value: string) => void;
}

const AttractionSelectItem = ({
  selectedOptionId,
  id,
  description,
  onSelect,
}: AttractionSelectItemProps) => {
  const isSelected = selectedOptionId === id;
  return (
    <Command.Item
      className={cn(
        "cursor-pointer  aria-selected:bg-slate-800 aria-selected:text-white",
        {
          "bg-slate-600 text-white": isSelected,
          "text-slate-100": !isSelected,
        },
      )}
      value={description}
      onSelect={onSelect}
    >
      {description}
    </Command.Item>
  );
};
