import {
  TourBase,
  TourFormatter,
} from "@twocontinents/dashboard/attractions/shared";
import {
  Button,
  Command,
  Popover,
  cn,
  routes,
} from "@twocontinents/dashboard/shared";
import { Check, ChevronsUpDown } from "lucide-react";
import { Link } from "react-router-dom";

import { useTourCombobox } from "./use-tour-combobox";

interface TourComboboxProps {
  tours: TourBase[];
  selectedTourId?: number;
  onTourChange: (tourId: number | undefined) => void;
}

export const TourCombobox = ({
  tours,
  onTourChange,
  selectedTourId,
}: TourComboboxProps) => {
  const { openChangeTourCombobox, selectedTour } =
    useTourCombobox(selectedTourId);

  return (
    <div>
      {tours.length === 0 ? (
        <p>
          Not found any tours.{" "}
          <Link to={routes.attractions.tours.create} className="underline">
            Create new tour
          </Link>
        </p>
      ) : (
        <>
          <Popover
            open={openChangeTourCombobox.value}
            onOpenChange={openChangeTourCombobox.setValue}
          >
            <Popover.Trigger asChild>
              <Button className="w-full" role="combobox">
                {selectedTour
                  ? TourFormatter.formatDetailedWithParticipantsDetails(
                      selectedTour,
                    )
                  : "Pick tour"}

                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </Popover.Trigger>
            <Popover.Content side="bottom" className="p-0 w-fit max-w-md">
              <Command>
                <Command.Input
                  value={selectedTourId?.toString()}
                  onInput={(e) => {
                    if (
                      !e.currentTarget.value ||
                      !e.currentTarget.value.trim()
                    ) {
                      onTourChange(undefined);
                      return;
                    }
                    if (Number.isNaN(Number(e.currentTarget.value))) return;
                    onTourChange(Number(e.currentTarget.value));
                  }}
                  placeholder="ID wycieczki"
                  className="font-sans"
                />

                <Command.List>
                  <Command.Empty
                    className={`font-sans flex justify-center items-center py-6`}
                  >
                    Nie znaleziono wycieczki
                  </Command.Empty>
                  <Command.Group>
                    {tours.map((tour) => (
                      <Command.Item
                        key={tour.id}
                        className={`${
                          tour.placesLeft > 0 || tour.maxParticipants == -1
                            ? "!text-green-400"
                            : "!text-red-400"
                        } font-sans font-bold `}
                        value={tour.id.toString()}
                        onSelect={(currentValue) => {
                          onTourChange(
                            Number(currentValue) === selectedTour?.id
                              ? -1
                              : Number(currentValue),
                          );
                          openChangeTourCombobox.setFalse();
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            selectedTour?.id === tour.id
                              ? "opacity-100"
                              : "opacity-0",
                          )}
                        />

                        {TourFormatter.formatWithParticipantsDetails(tour)}
                      </Command.Item>
                    ))}
                  </Command.Group>
                </Command.List>
              </Command>
            </Popover.Content>
          </Popover>
        </>
      )}
    </div>
  );
};
