import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import {
  AttractionGroup,
  AttractionVariant,
} from "@twocontinents/dashboard/attractions/shared";
import { FormEvent, useEffect, useMemo, useState } from "react";

import { useUpdateAttractionVariantsFormSequence } from "../../../data-access";

export const useFormSequenceSettings = (
  attractionGroups: AttractionGroup[],
  attractionId: number,
) => {
  const { isPending, updateVariantsFormSequence } =
    useUpdateAttractionVariantsFormSequence();

  const variants = useMemo(
    () => attractionGroups.flatMap((group) => group.attractionVariants),
    [attractionGroups],
  );

  const [variantsSequence, setVariantsSequence] = useState<AttractionVariant[]>(
    [],
  );

  useEffect(() => {
    if (variants.every((variant) => variant.formSequence === 1)) {
      setVariantsSequence(variants.toSorted((a, b) => a.id - b.id));
      return;
    }
    setVariantsSequence(
      variants?.toSorted((a, b) => a.formSequence - b.formSequence) ?? [],
    );
  }, [variants]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      if (!over || !active) return;
      setVariantsSequence((items) => {
        const oldIndex = items
          .map((item) => item.id)
          .indexOf(Number.parseInt(active.id.toString()));

        const newIndex = items
          .map((item) => item.id)
          .indexOf(Number.parseInt(over.id.toString()));

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const sortableVariants = variantsSequence.map((variant) => ({
    id: variant.id,
    name: variant.description,
  }));

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const variantToSequenceMap = Object.fromEntries(
      variantsSequence.map((attraction, index) => [attraction.id, index]),
    );
    updateVariantsFormSequence({
      variantsSequence: variantToSequenceMap,
      attractionId,
    });
  };

  return {
    sortableVariants,
    handleDragEnd,
    handleFormSubmit,
    isPending,
  };
};
