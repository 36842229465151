import { zodResolver } from "@hookform/resolvers/zod";
import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  Input,
  useOpened,
} from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAgentPassword } from "../data-access";

const UpdateAgentPasswordFormSchema = z.object({
  password: z.string().min(3),
});

export type UpdateAgentPasswordFormSchema = z.infer<
  typeof UpdateAgentPasswordFormSchema
>;

interface UpdateAgentModalProps {
  agentId: number;
}

export const UpdateAgentPasswordModal = ({
  agentId,
}: UpdateAgentModalProps) => {
  const { opened, open, close } = useOpened();
  const { updateAgentPassword, isPending } = useUpdateAgentPassword(agentId);

  const form = useForm<UpdateAgentPasswordFormSchema>({
    resolver: zodResolver(UpdateAgentPasswordFormSchema),
    mode: "onBlur",
  });

  const handleUpdateAgentPassword = form.handleSubmit((data) => {
    updateAgentPassword(data);
    close();
  });

  return (
    <AlertDialog open={opened}>
      <AlertDialog.Trigger asChild onClick={open}>
        <Button variant={"secondary"}>Update password</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Update agent password</AlertDialog.Title>
        </AlertDialog.Header>

        <Form {...form}>
          <form
            onSubmit={handleUpdateAgentPassword}
            className="flex gap-6 flex-col justify-between"
          >
            <Form.Field
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Password</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <AlertDialog.Footer>
              <AlertDialog.Cancel onClick={close}>Cancel</AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button type={"submit"} isLoading={isPending}>
                  Submit
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
