import {
  EmptyState,
  Layout,
  Pagination,
} from "@twocontinents/dashboard/shared";
import { Star } from "lucide-react";

import { AddManualReviewDialog } from "./add-manual-review-dialog";
import { ReviewsFilterBar } from "./reviews-filter-bar";
import { ReviewsTable } from "./reviews-table";
import { useReviewsManagement } from "./use-reviews-management";

export const ReviewsManagement = () => {
  const { isLoading, paginatedReviews } = useReviewsManagement();

  const showReviewsNotFound =
    (paginatedReviews?.reviews?.length ?? 0) === 0 && !isLoading;
  const showPagination = !isLoading && paginatedReviews;
  const showReviewsTable =
    paginatedReviews?.reviews &&
    (paginatedReviews?.reviews?.length ?? 0) > 0 &&
    !isLoading;

  return (
    <Layout
      header={{
        title: "Reviews",
        description: `Manage reviews.`,
      }}
    >
      <ReviewsFilterBar />
      <AddManualReviewDialog />
      {showReviewsNotFound && (
        <EmptyState>
          <EmptyState.Icon>
            <Star size={40} />
          </EmptyState.Icon>
          <EmptyState.Header>No reviews</EmptyState.Header>
          <EmptyState.Paragraph>
            Check if the filters are correct and try again.
          </EmptyState.Paragraph>
        </EmptyState>
      )}
      {showReviewsTable && <ReviewsTable reviews={paginatedReviews.reviews} />}
      {showPagination && (
        <Pagination
          totalPages={paginatedReviews.pagination.totalPages}
          sizeLabel="Ilość zamówień na stronę"
        />
      )}
    </Layout>
  );
};
