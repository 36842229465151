import {
  AttractionVariantSelectByGroups,
  Tour,
} from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  buttonVariants,
} from "@twocontinents/dashboard/shared";

import { useChangeTourVariantDialog } from "./use-change-tour-variant-dialog";

interface ChangeTourVariantDialogProps {
  tour: Tour;
}

export const ChangeTourVariantDialog = ({
  tour,
}: ChangeTourVariantDialogProps) => {
  const { form, isPending, onSubmit, openAlertDialog, attractionGroups } =
    useChangeTourVariantDialog(tour);

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button className="w-fit ">Change Attraction Variant</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Tour settings</AlertDialog.Title>
          <AlertDialog.Description>
            Change tour settings
          </AlertDialog.Description>
        </AlertDialog.Header>
        <Form {...form}>
          <form onSubmit={onSubmit}>
            <Form.Fields>
              <Form.Field
                control={form.control}
                name="attractionVariantId"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Variant</FormItem.Label>
                      <FormItem.Control>
                        <AttractionVariantSelectByGroups
                          attractionGroups={attractionGroups}
                          onValueChange={field.onChange}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            </Form.Fields>

            <AlertDialog.Footer className="mt-4">
              <AlertDialog.Cancel
                className={buttonVariants({ variant: "destructive" })}
              >
                Cancel
              </AlertDialog.Cancel>
              <Button isLoading={isPending} type="submit" variant="outline">
                Save
              </Button>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
