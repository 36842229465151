import { zodResolver } from "@hookform/resolvers/zod";
import {
  Tour,
  useGetAttractionVariantAgeGroups,
} from "@twocontinents/dashboard/attractions/shared";
import { NOT_SELECTED_ID_STRING } from "@twocontinents/dashboard/shared";
import { ApiLocale, Gender } from "@twocontinents/shared";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useCreateTourParticipants } from "../../data-access";

const CreateTourParticipantSchema = z.object({
  name: z.string().min(3, "Imię powinno mieć przynajmniej 3 znaki"),
  surname: z.string().min(3, "Nazwisko powinno mieć przynajmniej 3 znaki"),
  pickupLocation: z.string().optional(),
  ageGroupId: z.coerce.number(),
  additionalDetails: z.string().optional(),
  phoneNumber: z.string().optional(),
  peopleAmount: z.coerce.number().min(1, "Liczba osób musi być większa od 0"),
  gender: z.nativeEnum(Gender).or(z.literal(NOT_SELECTED_ID_STRING)),
  locale: z.nativeEnum(ApiLocale),
});

export type CreateTourParticipantSchema = z.infer<
  typeof CreateTourParticipantSchema
>;

export const useCreateTourParticipantsDialog = (tour: Tour) => {
  const openAlertDialog = useBoolean(false);

  const { ageGroups } = useGetAttractionVariantAgeGroups(
    tour.attractionVariant.id,
  );

  const { createTourParticipants, isPending } = useCreateTourParticipants();

  const form = useForm<CreateTourParticipantSchema>({
    resolver: zodResolver(CreateTourParticipantSchema),
    mode: "onBlur",
    defaultValues: { peopleAmount: 1 },
    shouldUnregister: true,
  });

  const onSubmit = form.handleSubmit((data) => {
    const { peopleAmount, gender, ...rest } = data;

    const createTourParticipantsRequest = Array.from({
      length: peopleAmount,
    }).map(() => ({
      ...rest,
      gender: gender === NOT_SELECTED_ID_STRING ? undefined : gender,
    }));

    createTourParticipants(
      {
        tourId: tour.id,
        body: {
          participants: createTourParticipantsRequest,
        },
      },
      {
        onSuccess: () => {
          openAlertDialog.setFalse();
        },
      },
    );
  });

  return {
    ageGroups,
    isPending,
    form,
    onSubmit,
    openAlertDialog,
  };
};
