import {
  AlertDialog,
  Button,
  FinancialLogCategory,
  FinancialLogCategorySelect,
  Form,
  FormItem,
  Input,
} from "@twocontinents/dashboard/shared";
import { AlertTriangle } from "lucide-react";

import { FinancialLog } from "../../types";
import { useCreateCorrectionFinancialLogModal } from "./use-create-correction-financial-log-modal";

interface CreateCorrectionFinancialLogModalProps {
  financialLog: FinancialLog;
}

export const CreateCorrectionFinancialLogModal = ({
  financialLog,
}: CreateCorrectionFinancialLogModalProps) => {
  const { form, onSubmit, isPending, opened, open, close, minValue, maxValue } =
    useCreateCorrectionFinancialLogModal(financialLog);

  return (
    <AlertDialog open={opened}>
      <AlertDialog.Trigger asChild onClick={open}>
        <Button variant={"secondary"}>Correction</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <Form {...form}>
          <form onSubmit={onSubmit}>
            <AlertDialog.Header>
              <AlertDialog.Title>
                Create correction for #{financialLog.id} financial log
              </AlertDialog.Title>
              <AlertDialog.Description className={"py-4"}>
                <Form.Fields className="max-w-md">
                  <Form.Field
                    control={form.control}
                    name="value"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-32">
                            Value (Target amount )
                          </FormItem.Label>
                          <FormItem.Control>
                            <Input
                              {...field}
                              step={0.01}
                              type={"number"}
                              min={minValue}
                              max={maxValue}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="category"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-32">
                            Category
                          </FormItem.Label>
                          <FormItem.Control>
                            <FinancialLogCategorySelect
                              value={field.value}
                              categories={Object.values(FinancialLogCategory)}
                              onValueChange={field.onChange}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="cause"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-32">
                            Cause
                          </FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="notes"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-32">
                            Notes
                          </FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  {financialLog.settled && (
                    <p className={"inline-flex gap-2 justify-end"}>
                      <AlertTriangle size={20} color="yellow" /> Attention!
                      Editing will create a correction
                    </p>
                  )}
                </Form.Fields>
              </AlertDialog.Description>
            </AlertDialog.Header>
            <AlertDialog.Footer>
              <AlertDialog.Cancel onClick={close}>Cancel</AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button type={"submit"} isLoading={isPending}>
                  Submit
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
