import { zodResolver } from "@hookform/resolvers/zod";
import { InvoiceStatus } from "@twocontinents/dashboard/travel-agencies/invoices/shared";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useUpdateInvoiceStatus } from "../../data-access";

const UpdateInvoiceStatusSchema = z.object({
  status: z.nativeEnum(InvoiceStatus),
});

type UpdateInvoiceStatusForm = z.infer<typeof UpdateInvoiceStatusSchema>;

export const useUpdateStatusDialog = (
  invoiceId: number,
  invoiceStatus: InvoiceStatus,
  agencyId: number,
) => {
  const openAlertDialog = useBoolean(false);
  const form = useForm<UpdateInvoiceStatusForm>({
    resolver: zodResolver(UpdateInvoiceStatusSchema),
    defaultValues: {
      status: invoiceStatus,
    },
  });

  const { isPending, updateInvoiceStatus } = useUpdateInvoiceStatus();

  const handleFormSubmit = form.handleSubmit((data) => {
    updateInvoiceStatus(
      { agencyId, id: invoiceId, body: data },
      { onSuccess: () => openAlertDialog.setFalse() },
    );
  });

  return {
    openAlertDialog,
    isPending,
    updateInvoiceStatus,
    form,
    handleFormSubmit,
  };
};
