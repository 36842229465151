/* eslint-disable max-lines-per-function */
import { useState } from "react";

import { useGetTravelAgencies } from "../../data-access";
import { BaseTravelAgency } from "../../types";

export const useTravelAgenciesCombobox = (
  setSelectedOption: (value: BaseTravelAgency) => void,
  selectedId?: number,
) => {
  const { data } = useGetTravelAgencies({ size: 1000 });
  const travelAgencies = data?.travelAgencies ?? [];
  const [open, setOpen] = useState(false);

  const onSelect = (value: string) => {
    const foundAgency = travelAgencies?.find(
      ({ name }) => name.toLowerCase() === value.toLowerCase(),
    );

    if (!foundAgency) {
      return setOpen(false);
    }

    setSelectedOption(foundAgency);
    setOpen(false);
  };

  const selectedOption = travelAgencies?.find(
    (agency) => agency.id === selectedId,
  );

  return {
    open,
    setOpen,
    onSelect,
    travelAgencies,
    selectedOption,
  };
};
