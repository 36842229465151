import { useGetTour } from "@twocontinents/dashboard/attractions/shared";
import { NOT_SELECTED_ID } from "@twocontinents/dashboard/shared";
import { useBoolean } from "usehooks-ts";

export const useTourCombobox = (tourId?: number) => {
  const openChangeTourCombobox = useBoolean(false);

  const { tour } = useGetTour(tourId ?? NOT_SELECTED_ID);

  return {
    openChangeTourCombobox,
    selectedTour: tour,
  };
};
