import {
  Button,
  DateTimePicker,
  Form,
  FormItem,
  Label,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { CsvTicketForm } from "./csv-ticket-form";
import { ManualTicketForm } from "./manual-ticket-form";
import { CreateAgeGroupTicketSchema } from "./use-age-groups-tickets-settings";

interface Props {
  isPending?: boolean;
  csvFile: File | null;
  setCsvFile: (file: File | null) => void;
}

export const CreateTicketForm = ({ isPending, setCsvFile, csvFile }: Props) => {
  const form = useFormContext<CreateAgeGroupTicketSchema>();
  const [mode, setMode] = useState<"manual" | "csv">("csv");

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "codes",
  });

  const expirationTime = form.watch("expirationTime");

  const isValid = form.formState.isValid && (fields.length > 0 || csvFile);

  return (
    <>
      <div className="flex gap-2 my-4">
        <Button
          variant={mode === "csv" ? "positive" : "outline"}
          type="button"
          onClick={() => {
            setMode("csv");
            form.setValue("codes", []);
          }}
        >
          CSV
        </Button>
        <Button
          variant={mode === "manual" ? "positive" : "outline"}
          type="button"
          onClick={() => {
            setMode("manual");
            setCsvFile(null);
          }}
        >
          Manual
        </Button>
      </div>

      <Form.Field
        control={form.control}
        name="expirationDate"
        render={({ field }) => (
          <FormItem.Row>
            <Label className="min-w-40">Expiration date</Label>
            <DateTimePicker
              disablePastDates
              date={field.value ? new Date(field.value) : undefined}
              setDate={(date) =>
                field.onChange(DateFormatter.formatToYYYYMMDD(date))
              }
              displayTimeInput={true}
              time={expirationTime}
              setTime={(time) => {
                if (time) {
                  form.setValue("expirationTime", time);
                }
              }}
            />
          </FormItem.Row>
        )}
      />
      {mode === "manual" ? (
        <ManualTicketForm fields={fields} append={append} remove={remove} />
      ) : (
        <CsvTicketForm csvFile={csvFile} setCsvFile={setCsvFile} />
      )}

      <Button
        type="submit"
        className="ml-auto"
        variant="positive"
        disabled={!isValid}
        isLoading={isPending}
      >
        Create Tickets
      </Button>
    </>
  );
};
