import { useMutation } from "@tanstack/react-query";
import { useInvalidateGetTour } from "@twocontinents/dashboard/attractions/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type MoveTourParticipantsRequest =
  components["schemas"]["MoveTourParticipantsRequest"];

interface Props {
  targetTourId: number;
  body: MoveTourParticipantsRequest;
}

export const useMoveTourParticipants = (currentTourId: number) => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetTour();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ body, targetTourId }: Props) => {
      return apiClient
        .PATCH("/private/attractions/tours/participants/move/{targetTourId}", {
          body,
          params: {
            path: {
              targetTourId,
            },
          },
        })
        .then((response) => response.data);
    },
    onSuccess(__, { targetTourId, body }) {
      toast.success(
        `Moved ${body.participantIds.length} participants to the new tour`,
      );
      void invalidate(currentTourId);
      void invalidate(targetTourId);
    },
    onError() {
      toast.error("Error moving participants to the new tour");
    },
  });

  return { moveTourParticipants: mutate, isPending };
};
