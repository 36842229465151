import { DropdownMenu, buttonVariants } from "@twocontinents/dashboard/shared";

import { Review } from "../../types";
import { DeletePictureDialog } from "./delete-picture-dialog";

interface ReviewContentDropdownProps {
  review: Review;
  setIsDropdownOpened: (opened: boolean) => void;
  isDropdownOpened: boolean;
}

export const ReviewContentDropdown = ({
  isDropdownOpened,
  review,
  setIsDropdownOpened,
}: ReviewContentDropdownProps) => {
  const reviewContent = review?.content?.trim()
    ? `Review content: ${review.content}`
    : "No content";

  return (
    <DropdownMenu modal open={isDropdownOpened}>
      <DropdownMenu.Trigger asChild>
        <span
          className="justify-start text-left p-0 h-full hover:!bg-slate-900"
          onClick={() => {
            setIsDropdownOpened(true);
          }}
        >
          {review.id}
        </span>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="start"
        side="bottom"
        className="flex flex-col max-w-[70vw]  p-2 overflow-auto max-h-[350px]"
        onInteractOutside={() => {
          setIsDropdownOpened(false);
        }}
      >
        <p className="py-2 px-6 max-w-full">{reviewContent}</p>
        <div className="flex border-t flex-col gap-2 p-1 mt-1">
          {review.pictures.map((picture, index) => (
            <div
              key={picture.id}
              rel="noreferrer"
              className="p-1 flex gap-4 items-center"
            >
              <p className="min-w-25">Photo no {index}</p>
              <a
                href={picture.url}
                target="_blank"
                rel="noreferrer"
                className={buttonVariants()}
              >
                See photo
              </a>
              <DeletePictureDialog picture={picture} />
            </div>
          ))}
        </div>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};
