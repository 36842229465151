import { Label, PropsWithClassName, cn } from "@twocontinents/dashboard/shared";
import { type FC, PropsWithChildren } from "react";

interface StatisticCardProps extends PropsWithChildren, PropsWithClassName {
  isLoading?: boolean;
}

type StatisticCardComponent = FC<StatisticCardProps> & {
  Label: FC<PropsWithClassName & PropsWithChildren>;
  Value: FC<PropsWithClassName & PropsWithChildren>;
};

const StatisticCard: StatisticCardComponent = ({
  className,
  children,
  isLoading,
}) => {
  if (isLoading) {
    return null;
  }
  return (
    <div
      className={cn(
        "border border-slate-700 p-6 justify-center rounded flex flex-col gap-2",
        className,
      )}
    >
      {children}
    </div>
  );
};

const StatisticCardLabel = ({
  className,
  children,
}: PropsWithClassName & PropsWithChildren) => {
  return (
    <Label className={cn("text-sm text-slate-200", className)}>
      {children}
    </Label>
  );
};

const StatisticCardValue = ({
  className,
  children,
}: PropsWithClassName & PropsWithChildren) => {
  return <p className={cn("text-3xl font-semibold", className)}>{children}</p>;
};

StatisticCard.Label = StatisticCardLabel;
StatisticCard.Value = StatisticCardValue;

export { StatisticCard };
