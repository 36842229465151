import {
  AgeGroupSelect,
  GenderSelect,
  Tour,
  TourParticipant,
} from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  ApiLocaleSelect,
  Button,
  Form,
  FormItem,
  Input,
  Textarea,
  buttonVariants,
} from "@twocontinents/dashboard/shared";

import { useEditParticipantDialog } from "./use-edit-tour-participant-dialog";

interface EditTourParticipantDialogProps {
  tour: Tour;
  tourParticipant: TourParticipant;
}
export const EditTourParticipantDialog = ({
  tour,
  tourParticipant,
}: EditTourParticipantDialogProps) => {
  const {
    ageGroups,
    extraOptions,
    form,
    isPending,
    onSubmit,
    openAlertDialog,
  } = useEditParticipantDialog(tour, tourParticipant);

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button variant="outline">Edit participant</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Edit tour participant data</AlertDialog.Title>
          <AlertDialog.Description className="text-white">
            Edit tour participant data
          </AlertDialog.Description>
        </AlertDialog.Header>
        <Form {...form}>
          <form onSubmit={onSubmit} className="flex flex-col gap-6 mt-4">
            <Form.Fields>
              <Form.Field
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Name</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="surname"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Surname</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="locale"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Locale</FormItem.Label>
                      <FormItem.Control>
                        <ApiLocaleSelect
                          value={field.value}
                          onValueChange={field.onChange}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="gender"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Gender</FormItem.Label>
                      <FormItem.Control>
                        <GenderSelect
                          value={field.value}
                          onValueChange={field.onChange}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="pickupLocation"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Pickup Location</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Phone number</FormItem.Label>
                      <FormItem.Control>
                        <Input {...field} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="ageGroupId"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Age group</FormItem.Label>
                      <FormItem.Control>
                        <AgeGroupSelect
                          ageGroups={ageGroups}
                          onValueChange={field.onChange}
                          value={field?.value?.toString()}
                        />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
              {extraOptions.map((extraOption, index) => (
                <div key={extraOption.id}>
                  <Form.Field
                    control={form.control}
                    name={`extraOptions.${index}.id`}
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Control>
                            <Input
                              name={field.name}
                              value={extraOption.id}
                              onChange={field.onChange}
                              type={"number"}
                              className="max-w-md hidden"
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    name={`extraOptions.${index}.amount`}
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>{extraOption.name}</FormItem.Label>
                          <FormItem.Control>
                            <Input
                              type="number"
                              value={field.value}
                              onChange={field.onChange}
                              name={field.name}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                </div>
              ))}
              <Form.Field
                control={form.control}
                name="additionalDetails"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Additional Details</FormItem.Label>
                      <FormItem.Control>
                        <Textarea {...field} className="max-w-none" rows={10} />
                      </FormItem.Control>
                    </FormItem.Row>
                    <FormItem.Message />
                  </FormItem>
                )}
              />
            </Form.Fields>

            <AlertDialog.Footer>
              <AlertDialog.Cancel
                className={buttonVariants({
                  variant: "destructive",
                  className: "border-none",
                })}
              >
                Cancel
              </AlertDialog.Cancel>
              <Button
                isLoading={isPending}
                disabled={isPending}
                type="submit"
                variant="outline"
              >
                Save
              </Button>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
