import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { GetTravelAgencyInvoicesFilters } from "../types";

const queryKey = (filters?: GetTravelAgencyInvoicesFilters) => [
  "private-invoices",
  filters ?? "all",
];

export const useGetInvoices = (filters?: GetTravelAgencyInvoicesFilters) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/partners/travel-agencies/invoices", {
          params: {
            query: filters,
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(filters),
  });

  return {
    paginatedInvoices: data,
    areInvoicesLoading: isLoading,
  };
};

export const useInvalidateGetInvoices = () => {
  const queryClient = useQueryClient();

  const invalidate = async (filters?: GetTravelAgencyInvoicesFilters) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(filters),
    });
  };

  return {
    invalidate,
  };
};
