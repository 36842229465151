type NullToUndefined<T> = T extends null
  ? undefined
  : T extends object
    ? { [K in keyof T]: NullToUndefined<T[K]> }
    : T;

export function replaceNullWithUndefined<T>(object: T): NullToUndefined<T> {
  if (object === null) {
    return undefined as NullToUndefined<T>;
  }

  if (typeof object === "object" && object !== null) {
    if (Array.isArray(object)) {
      return object.map((element) =>
        replaceNullWithUndefined(element),
      ) as NullToUndefined<T>;
    }

    const result: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(object)) {
      result[key] = replaceNullWithUndefined(value);
    }
    return result as NullToUndefined<T>;
  }

  return object as NullToUndefined<T>;
}
