import { Locale } from "@twocontinents/shared";

import { useGetBusinessPartner } from "../../data-access";
import { useAttractionPartnershipsQueryParams } from "../../hooks";
import { AttractionPartnership } from "../../types";

export const useBusinessPartnerShowPage = (id: number) => {
  const { businessPartner, isLoading } = useGetBusinessPartner(id);
  const attractionPartnerships = businessPartner?.attractionPartnerships ?? [];

  const { description, locale } = useAttractionPartnershipsQueryParams();

  const matchesDescription = (attractionPartnership: AttractionPartnership) => {
    return (
      !description ||
      attractionPartnership.description
        .toLowerCase()
        .includes(description.toLowerCase())
    );
  };

  const matchesLocale = (attractionPartnership: AttractionPartnership) => {
    return (
      !locale ||
      (attractionPartnership.locale as unknown as Locale) ===
        locale.toUpperCase()
    );
  };

  const filteredAttractionPartnerships = (
    attractionPartnerships?.filter((attractionPartnership) => {
      return (
        matchesDescription(attractionPartnership) &&
        matchesLocale(attractionPartnership)
      );
    }) ?? []
  ).toSorted((a, b) => a.id - b.id);

  return {
    businessPartner,
    isLoading,
    filteredAttractionPartnerships,
  };
};
