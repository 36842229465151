import { useMutation } from "@tanstack/react-query";
import {
  routes,
  toast,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { useInvalidateGetTravelAgencies } from "@twocontinents/dashboard/travel-agencies/shared";
import { components } from "@twocontinents/shared";
import { useNavigate } from "react-router-dom";

type CreateTravelAgency = components["schemas"]["CreateTravelAgencyRequest"];

export const useCreateTravelAgency = () => {
  const apiClient = usePrivateApiClient();
  const navigate = useNavigate();
  const { invalidate } = useInvalidateGetTravelAgencies();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: CreateTravelAgency) => {
      return apiClient
        .POST("/private/partners/travel-agencies/", {
          body: request,
        })
        .then((response) => response.data);
    },
    onSuccess: async () => {
      toast.success("Travel agency created successfully");
      await invalidate();
      navigate(routes.travelAgencies.index);
    },
    onError: () => {
      toast.error("Could not create travel agency");
    },
  });

  return { createTravelAgency: mutate, isPending };
};
