import createClient, { Middleware } from "openapi-fetch";

import { env } from "../../environment";
import { Locale } from "../../types/locale";
import { CurrencyCode } from "../../types/shop/currency-code";
import type { paths } from "./schema";

type OmitLocaleHeader<T> = T extends { parameters: infer P }
  ? P extends { header: { [key: string]: unknown } }
    ? Omit<T, "parameters"> & { parameters: Omit<P, "header"> }
    : T
  : T;

type ModifiedPaths = {
  [K in keyof paths]: {
    [M in keyof paths[K]]: OmitLocaleHeader<paths[K][M]>;
  };
};

type ApiClientProps = {
  locale?: Locale;
  currency?: CurrencyCode;
  accessToken?: string;
};

const myMiddleware: Middleware = {
  onResponse(response) {
    if (!response.ok) {
      return response.json().then((err) => {
        throw err;
      });
    }
    return response;
  },
};

export const getApiClient = (props?: ApiClientProps) => {
  const { currency, locale, accessToken } = props || {};
  const client = createClient<ModifiedPaths>({
    baseUrl: `${env.API_URL}/api/v1`,
    headers: {
      ...(locale ? { "Twc-Locale": locale } : {}),
      ...(currency ? { "Twc-Currency": currency.toUpperCase() } : {}),
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    },
  });
  client.use(myMiddleware);
  return client;
};

export type ApiClient = ReturnType<typeof getApiClient>;
