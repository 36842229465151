import { Layout } from "@twocontinents/dashboard/shared";
import { CurrencyCode } from "@twocontinents/shared";

import { ActivitySettings } from "./activity-settings";
import { CurrencySettings } from "./currency-settings";
import { DescriptionSettings } from "./description-settings";
import { RefundabilitySettings } from "./refundability-settings";

interface AttractionSettingsProps {
  attractionId: number;
  defaultActive: boolean;
  defaultRefundable: boolean;
  defaultHoursPriorReservationIsRefundable?: number;
  defaultDescription: string;
  defaultCurrency: CurrencyCode;
}

export const AttractionSettings = ({
  attractionId,
  defaultActive,
  defaultDescription,
  defaultCurrency,
  defaultRefundable,
  defaultHoursPriorReservationIsRefundable,
}: AttractionSettingsProps) => {
  return (
    <Layout.Content>
      <ActivitySettings
        attractionId={attractionId}
        defaultActive={defaultActive}
      />
      <DescriptionSettings
        attractionId={attractionId}
        defaultDescription={defaultDescription}
      />
      <CurrencySettings
        attractionId={attractionId}
        defaultCurrency={defaultCurrency}
      />
      <RefundabilitySettings
        attractionId={attractionId}
        defaultRefundable={defaultRefundable}
        defaultHoursPriorReservationIsRefundable={
          defaultHoursPriorReservationIsRefundable
        }
      />
    </Layout.Content>
  );
};
