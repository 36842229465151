import { zodResolver } from "@hookform/resolvers/zod";
import { AttractionGroup } from "@twocontinents/dashboard/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useUpdateAttractionVariantMetadata } from "../../../data-access";
import { AvailabilityProvider, TicketProvider } from "../../../types";
import {
  Metadata,
  MetadataForm,
  MetadataFormSchema,
} from "./metadata-form-schema";

export const useMetadataSettings = (
  attractionGroups: AttractionGroup[],
  attractionId: number,
) => {
  const raynaVariants = attractionGroups
    .flatMap((group) => group.attractionVariants)
    .filter(
      (variant) =>
        variant.ticketProvider === TicketProvider.RAYNA ||
        variant.externalAvailabilityProvider === AvailabilityProvider.RAYNA,
    );

  const form = useForm<MetadataForm>({
    resolver: zodResolver(MetadataFormSchema),
  });

  const { updateVariantMetadata, isPending } =
    useUpdateAttractionVariantMetadata();

  const onSubmit = form.handleSubmit((data) => {
    updateVariantMetadata({
      attractionId,
      variantId: data.variantId,
      body: {
        availabilityMetadata: data.availabilityMetadata,
        ticketMetadata: data.ticketMetadata,
      },
    });
  });

  const selectedVariantId = form.watch("variantId");

  const selectedVariant = raynaVariants.find(
    (variant) => variant.id === selectedVariantId,
  );

  const hasSelectedVariantAvailabilityFromRayna =
    selectedVariant?.externalAvailabilityProvider ===
    AvailabilityProvider.RAYNA;

  const hasSelectedVariantTicketFromRayna =
    selectedVariant?.ticketProvider === TicketProvider.RAYNA;

  useEffect(() => {
    if (
      hasSelectedVariantTicketFromRayna &&
      selectedVariant.externalTicketProviderMetadata
    ) {
      const metadata = JSON.parse(
        selectedVariant.externalTicketProviderMetadata,
      ) as Metadata;

      form.setValue("ticketMetadata", metadata);
    }

    if (
      hasSelectedVariantAvailabilityFromRayna &&
      selectedVariant.externalAvailabilityProviderMetadata
    ) {
      const metadata = JSON.parse(
        selectedVariant.externalAvailabilityProviderMetadata,
      ) as Metadata;

      form.setValue("availabilityMetadata", metadata);
    }
  }, [selectedVariant, form]);

  return {
    raynaVariants,
    form,
    onSubmit,
    isPending,
    hasSelectedVariantRaynaAvailabilityFromRayna:
      hasSelectedVariantAvailabilityFromRayna,
    hasSelectedVariantRaynaTicketFromRayna: hasSelectedVariantTicketFromRayna,
  };
};
