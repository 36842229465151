import { useRef } from "react";
import { useDayRender } from "react-day-picker";
import { DayProps as LibraryDayProps } from "react-day-picker";

export interface CalendarDayProps extends LibraryDayProps {
  isAvailable?: boolean;
}
export const useCalendarDay = ({
  date,
  displayMonth,
  isAvailable,
}: CalendarDayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(date, displayMonth, buttonRef);

  const isNotSet = isAvailable === undefined;
  const isNotAvailable = isAvailable === false;

  return {
    buttonRef,
    dayRender,
    isAvailable,
    isNotSet,
    isNotAvailable,
  };
};
