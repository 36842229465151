import { useMutation } from "@tanstack/react-query";
import { useInvalidateGetTour } from "@twocontinents/dashboard/attractions/shared";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type OverrideTourMessageRequest =
  components["schemas"]["OverrideTourMessageRequest"];

interface Props {
  messageId: number;
  body: OverrideTourMessageRequest;
}

export const useOverrideTourMessageContent = (tourId: number) => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetTour();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ body, messageId }: Props) => {
      return apiClient
        .PATCH("/private/attractions/tours/messages/{id}/override", {
          body,
          params: {
            path: {
              id: messageId,
            },
          },
        })
        .then((response) => response.data);
    },
    async onSuccess() {
      toast.success("Tour message updated");
      await invalidate(tourId);
    },
    onError() {
      toast.error("Unable to update tour message");
    },
  });

  return { overrideTourMessage: mutate, isPending };
};
