import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  Input,
} from "@twocontinents/dashboard/shared";

import { useAddTravelAgencyBalanceModal } from "./use-add-travel-agency-balance-modal";

interface AddTravelAgencyBalanceModalProps {
  travelAgencyId: number;
}

export const AddTravelAgencyBalanceModal = ({
  travelAgencyId,
}: AddTravelAgencyBalanceModalProps) => {
  const { opened, open, close, form, onSubmit, isPending } =
    useAddTravelAgencyBalanceModal(travelAgencyId);

  return (
    <AlertDialog open={opened}>
      <AlertDialog.Trigger asChild onClick={open}>
        <Button variant={"secondary"}>Add travel agency balance</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <Form {...form}>
          <form onSubmit={onSubmit}>
            <AlertDialog.Header>
              <AlertDialog.Title>
                Add balance to travel agency account
              </AlertDialog.Title>
              <AlertDialog.Description className={"py-4"}>
                <Form.Fields className="max-w-md">
                  <Form.Field
                    control={form.control}
                    name="valueAed"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Value (AED)
                          </FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} type={"number"} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                </Form.Fields>
              </AlertDialog.Description>
            </AlertDialog.Header>
            <AlertDialog.Footer>
              <AlertDialog.Cancel onClick={close}>Cancel</AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button type={"submit"} isLoading={isPending}>
                  Submit
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
