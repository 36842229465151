import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetCoupons } from "./get-coupons";

type CreateCouponBody = components["schemas"]["CreateCouponRequest"];

export const useCreateCoupon = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetCoupons();
  const { mutate, isPending } = useMutation({
    mutationFn: (body: CreateCouponBody) =>
      apiClient
        .POST("/private/coupons/", {
          body,
        })
        .then((response) => response.data),
    onSuccess: async () => {
      toast.success("Coupon created successfully");
      await invalidate();
    },
    onError: () => {
      toast.error("Failed to create coupon");
    },
  });

  return {
    createCoupon: mutate,
    isCreatingCoupon: isPending,
  };
};
