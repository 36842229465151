import { Link } from "react-router-dom";

import { routes } from "../../utils";
import { Avatar } from "../shadcn";

export const Navbar = () => {
  return (
    <nav className="border-b border-b-slate-800">
      <div className="container mx-auto flex justify-between py-4 2xl:px-24 3xl:px-52">
        <div className="flex items-center gap-6">
          <Link
            to={routes.home}
            className="relative mr-4 aspect-[202/85] h-16 flex items-center"
          >
            <img
              src="/twc-dark.svg"
              sizes="10vw"
              alt="TWC logo"
              loading="lazy"
            />
          </Link>
          <Link
            className="text-sm font-medium text-slate-400 transition hover:text-slate-300"
            to={routes.home}
          >
            Strona główna
          </Link>
          <Link
            className="text-sm font-medium text-slate-400 transition hover:text-slate-300"
            to={routes.home}
          >
            Ustawienia
          </Link>
        </div>
        <Avatar title="Zalogowano jako Hubert" className=" h-8 w-8">
          <Avatar.Image src="/avatar_hubert.jpg" />
          <Avatar.Fallback>PZH</Avatar.Fallback>
        </Avatar>
      </div>
    </nav>
  );
};
