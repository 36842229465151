import { zodResolver } from "@hookform/resolvers/zod";
import {
  MultiSelectOptionGroup,
  MultiSelectOptionType,
  useGetAttractions,
  useGetAttractionsDetails,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode } from "@twocontinents/shared";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateCoupon } from "../../data-access";

const AddCouponFormSchema = z
  .object({
    code: z
      .string({
        required_error: "Kod jest wymagany",
        invalid_type_error: "Kod jest wymagany",
      })
      .min(3, "Kod powinnien zawierać minimum 3 znaki"),
    description: z
      .string({
        required_error: "Opis jest wymagany",
        invalid_type_error: "Opis jest wymagany",
      })
      .min(3, "Opis powinien zawierać minimum 3 znaki"),
    value: z.coerce
      .number({
        required_error: "Wartość kuponu jest wymagana",
        invalid_type_error: "Wartość kuponu jest wymagana",
      })
      .positive("Wartość kuponu powinna być większa od 0"),
    currency: z.nativeEnum(CurrencyCode),
    availableUsages: z.coerce
      .number({
        required_error: "Ilość użyć jest wymagana",
        invalid_type_error: "Ilość użyć jest wymagana",
      })
      .optional(),
    startDateTime: z
      .string({
        required_error: "Data rozpoczęcia jest wymagana",
        invalid_type_error: "Data rozpoczęcia jest wymagana",
      })
      .optional(),
    endDateTime: z
      .string({
        required_error: "Data wygaśnięcia jest wymagana",
        invalid_type_error: "Data wygaśnięcia jest wymagana",
      })
      .optional(),
    reservationPeriodStartDateTime: z
      .string({
        required_error:
          "Data pierwszego dnia akceptowanych rezerwacji jest wymagana",
        invalid_type_error:
          "Data pierwszego dnia akceptowanych rezerwacji jest wymagana",
      })
      .optional(),
    reservationPeriodEndDateTime: z
      .string({
        required_error:
          "Data ostatniego dnia akceptowanych rezerwacji jest wymagana",
        invalid_type_error:
          "Data ostatniego dnia akceptowanych rezerwacji jest wymagana",
      })
      .optional(),
    attractionVariantIds: z.coerce.number().array(),
    infinityUsages: z.boolean(),
  })
  .superRefine((data, ctx) => {
    if (!data.startDateTime || !data.endDateTime) return;
    const startDateTime = new Date(data.startDateTime);
    const endDateTime = new Date(data.endDateTime);

    if (startDateTime >= endDateTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Data rozpoczęcia nie może być późniejsza lub równa dacie wygaśnięcia",
        path: ["startDateTime"],
      });
    }
  })
  .superRefine((data, ctx) => {
    if (
      !data.reservationPeriodStartDateTime ||
      !data.reservationPeriodEndDateTime
    )
      return;
    const reservationPeriodStartDateTime = new Date(
      data.reservationPeriodStartDateTime,
    );
    const reservationPeriodEndDateTime = new Date(
      data.reservationPeriodEndDateTime,
    );

    if (reservationPeriodStartDateTime >= reservationPeriodEndDateTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Data pierwszego dnia akceptowanych rezerwacji nie może być późniejsza lub równa dacie ostatniego dnia akceptowanych rezerwacji",
        path: ["reservationPeriodStartDateTime"],
      });
    }
  })
  .superRefine((data, ctx) => {
    if (!data.infinityUsages && !data.availableUsages) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Ilość użyć nie może być równa 0",
        path: ["availableUsages"],
      });
    }
  });

type AddCouponFormSchema = z.infer<typeof AddCouponFormSchema>;

export const useAddCouponForm = (
  setShowForm: Dispatch<SetStateAction<boolean>>,
) => {
  const [selectedVariants, setSelectedVariants] = useState<
    MultiSelectOptionType[]
  >([]);

  const closeForm = () => {
    setShowForm(false);
  };

  const { attractions: attractionsBase } = useGetAttractions();

  const { attractions, isLoading } = useGetAttractionsDetails(
    attractionsBase.map((attraction) => attraction.id),
  );

  const variantsOptions: MultiSelectOptionGroup[] = useMemo(() => {
    return (
      attractions?.map((attraction) => {
        return {
          label: attraction?.description ?? "",
          options:
            attraction?.attractionGroups.flatMap((group) =>
              group.attractionVariants
                .filter((variant) => variant.active)
                .map((variant) => {
                  return {
                    label: variant.description,
                    value: variant.id,
                  };
                }),
            ) ?? [],
        };
      }) ?? []
    );
  }, [attractions]);

  const { createCoupon, isCreatingCoupon } = useCreateCoupon();

  const form = useForm<AddCouponFormSchema>({
    mode: "onBlur",
    resolver: zodResolver(AddCouponFormSchema),
    defaultValues: {
      infinityUsages: false,
      availableUsages: 1,
      attractionVariantIds: [],
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = form;

  const infinityUsages = watch("infinityUsages");

  const onSubmit = (formData: AddCouponFormSchema) => {
    createCoupon(formData, { onSuccess: () => closeForm() });
  };

  useEffect(() => {
    setValue(
      "attractionVariantIds",
      selectedVariants.map((option) => option.value),
    );
  }, [selectedVariants, setValue]);

  return {
    form,
    register,
    handleSubmit,
    errors,
    variantsOptions,
    selectedVariants,
    setSelectedVariants,
    isLoading,
    onSubmit,
    isCreatingCoupon,
    infinityUsages,
    setValue,
    closeForm,
  };
};
