import React, { ReactNode } from "react";

import { cn } from "../../utils";

interface NavbarButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
  label: ReactNode;
}

export const NavbarButton = ({
  className,
  icon,
  label,
  ...props
}: NavbarButtonProps) => {
  return (
    <button
      {...props}
      className={cn("rounded-md hover:bg-slate-800", className)}
    >
      <span className="flex items-center px-3 py-2 text-sm font-medium text-slate-100">
        <i className=" mr-2">{icon}</i>
        {label}
      </span>
    </button>
  );
};
