import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { AttractionBase } from "../types";
import { usePrivateApiClient } from "../utils";

export const useGetAttractions = (
  options?: Partial<UseQueryOptions<AttractionBase[], unknown>>,
) => {
  const apiClient = usePrivateApiClient();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/attractions/")
        .then((res) => res.data?.attractions ?? []),
    queryKey: ["attractions"],
    ...options,
  });

  return { attractions: data ?? [], isLoading };
};

export const useGetAttractionsDetails = (attractionIds: number[]) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      Promise.all(
        attractionIds.map((attractionId) =>
          apiClient
            .GET(`/private/attractions/{id}`, {
              params: {
                path: { id: attractionId },
              },
            })
            .then((response) => response.data),
        ),
      ),
    queryKey: ["private-attractions", attractionIds],
    enabled: attractionIds.length > 0,
  });

  return { attractions: data ?? [], isLoading };
};
