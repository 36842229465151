import { zodResolver } from "@hookform/resolvers/zod";
import { useGetAttractions } from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { AttractionActivityFilter } from "../../types";

const AttractionFiltersFormSchema = z.object({
  name: z.string().optional(),
  active: z.string().optional(),
});

export type AttractionFiltersFormSchema = z.infer<
  typeof AttractionFiltersFormSchema
>;

const matchesName = (attractionName: string, search?: string) => {
  if (!search) return true;
  return attractionName.toLowerCase().includes(search.toLowerCase());
};

const matchesActivityStatus = (
  isAttractionActive: boolean,
  activeFilter?: string,
) => {
  if (activeFilter === AttractionActivityFilter.ALL) return true;
  const isActive = activeFilter === AttractionActivityFilter.ACTIVE;
  return isAttractionActive === isActive;
};

export const useAttractionsListing = () => {
  const { attractions } = useGetAttractions();

  const form = useForm<AttractionFiltersFormSchema>({
    resolver: zodResolver(AttractionFiltersFormSchema),
    defaultValues: {
      active: AttractionActivityFilter.ALL,
    },
  });

  const name = form.watch("name");
  const active = form.watch("active");

  const filteredAttractions =
    attractions?.filter((attraction) => {
      return (
        matchesName(attraction.description, name) &&
        matchesActivityStatus(attraction.active, active)
      );
    }) ?? [];

  const sortedAttractions = filteredAttractions.toSorted((a, b) => a.id - b.id);

  return {
    form,
    attractions: sortedAttractions,
  };
};
