import { OrderStatus } from "@twocontinents/dashboard/attractions/shared";

import { useBatchOrdersUpdate } from "../../../data-access";

export const useBatchOrdersUpdateButtons = (
  selectedOrderIds: number[],
  setSelectedOrderIds: (ids: number[]) => void,
) => {
  const { mutate, isPending } = useBatchOrdersUpdate();

  const handleSetAsRealized = () => {
    mutate({
      ids: selectedOrderIds,
      status: OrderStatus.COMPLETED,
    });
    setSelectedOrderIds([]);
  };

  const handleSetAsNotRealized = () => {
    mutate({
      ids: selectedOrderIds,
      status: OrderStatus.NEW,
    });
    setSelectedOrderIds([]);
  };

  const areButtonsDisabled = selectedOrderIds.length === 0 || isPending;

  return {
    handleSetAsRealized,
    handleSetAsNotRealized,
    isPending,
    areButtonsDisabled,
  };
};
