import { AttractionCrewAvailability } from "@twocontinents/dashboard/attractions/shared";

export enum CrewAvailabilityEnum {
  "POLISH_GUIDE" = "private-polish-guide",
  "ENGLISH_GUIDE" = "private-english-guide",
  "POLISH_DRIVER" = "polish-driver",
}

export class CrewAvailability {
  private availabilityMap: Map<CrewAvailabilityEnum, boolean> = new Map();

  public constructor(
    availabilityList: AttractionCrewAvailability["crewAvailabilities"],
  ) {
    Object.values(CrewAvailabilityEnum)
      .filter((value) => typeof value === "string")
      .forEach((guideType) => {
        this.availabilityMap.set(guideType as CrewAvailabilityEnum, true);
      });

    availabilityList.forEach((availability) => {
      if (
        availability.extraOptionId &&
        this.availabilityMap.has(
          availability.localeGroupId as CrewAvailabilityEnum,
        )
      ) {
        this.availabilityMap.set(
          availability.localeGroupId as CrewAvailabilityEnum,
          availability.available ?? true,
        );
      }
    });
  }

  public isAvailable(crewAvailabilityEnum: CrewAvailabilityEnum): boolean {
    return this.availabilityMap.get(crewAvailabilityEnum) ?? true;
  }
}
