import { Locale } from "@twocontinents/shared";
import {
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

export const useAttractionPartnershipsQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    description: withDefault(StringParam, undefined),
    locale: withDefault(createEnumParam(Object.values(Locale)), undefined),
  });

  const { description, locale } = query;
  const setDescription = (description: string) => setQuery({ description });
  const setLocale = (locale: Locale) => setQuery({ locale });

  return {
    description,
    locale,
    setDescription,
    setLocale,
  };
};
