import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttraction } from "../../../data-access";

const DescriptionSettingsSchema = z.object({
  description: z.string().min(3),
});

type DescriptionSettingsSchema = z.infer<typeof DescriptionSettingsSchema>;

export const useDescriptionSettings = (
  attractionId: number,
  defaultDescription: string,
) => {
  const { updatePrivateAttraction, isPending } = useUpdateAttraction();

  const form = useForm<DescriptionSettingsSchema>({
    resolver: zodResolver(DescriptionSettingsSchema),
    defaultValues: {
      description: defaultDescription,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit((body) => {
    updatePrivateAttraction({
      attractionId,
      body,
    });
  });

  return {
    form,
    onSubmit,
    isPending,
  };
};
