import {
  AlertDialog,
  ApiLocaleSelect,
  Button,
  CurrencyCodeSelect,
  DatePicker,
  FinancialLogCategory,
  FinancialLogCategorySelect,
  Form,
  FormItem,
  Input,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode } from "@twocontinents/shared";

import { OrderReservationSelect } from "./order-reservation-select";
import { useCreateManualFinancialLogModal } from "./use-create-manual-financial-log-modal";

export const CreateManualFinancialLogModal = () => {
  const {
    form,
    onSubmit,
    opened,
    open,
    close,
    isPending,
    reservations,
    orderId,
    wrongOrderId,
  } = useCreateManualFinancialLogModal();

  return (
    <AlertDialog open={opened}>
      <AlertDialog.Trigger asChild onClick={open}>
        <Button variant={"secondary"}>Create financial log</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <Form {...form}>
          <form onSubmit={onSubmit}>
            <AlertDialog.Header>
              <AlertDialog.Title>Create financial log</AlertDialog.Title>
              <AlertDialog.Description className={"py-4"}>
                <Form.Fields className="max-w-md">
                  <Form.Field
                    control={form.control}
                    name="value"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Value
                          </FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} type={"number"} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="currency"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Currency
                          </FormItem.Label>
                          <FormItem.Control>
                            <CurrencyCodeSelect
                              value={field.value}
                              currencies={Object.values(CurrencyCode)}
                              onValueChange={field.onChange}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="category"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Category
                          </FormItem.Label>
                          <FormItem.Control>
                            <FinancialLogCategorySelect
                              value={field.value}
                              categories={Object.values(FinancialLogCategory)}
                              onValueChange={field.onChange}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="cause"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Cause
                          </FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="settleDate"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Settle date
                          </FormItem.Label>
                          <FormItem.Control>
                            <DatePicker
                              date={field.value ?? undefined}
                              setDate={field.onChange}
                              classNames={{
                                trigger: "w-72",
                              }}
                              disabled={{ before: new Date() }}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="orderId"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Order ID
                          </FormItem.Label>
                          <FormItem.Control>
                            <Input
                              onChange={(e) => {
                                field.onChange(e);
                                form.setValue("locale", undefined);
                              }}
                              type={"number"}
                              value={field.value}
                            />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message>
                          {wrongOrderId && "Order not found"}
                        </FormItem.Message>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="notes"
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label className="min-w-16">
                            Notes
                          </FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                  {!orderId && (
                    <Form.Field
                      control={form.control}
                      name="locale"
                      render={({ field }) => (
                        <FormItem>
                          <FormItem.Row>
                            <FormItem.Label className="min-w-16">
                              Locale
                            </FormItem.Label>
                            <FormItem.Control>
                              <ApiLocaleSelect
                                value={field.value}
                                onValueChange={(e) => {
                                  field.onChange(e);
                                  form.setValue("orderId", undefined);
                                  form.setValue("reservationId", undefined);
                                }}
                              />
                            </FormItem.Control>
                          </FormItem.Row>
                          <FormItem.Message />
                        </FormItem>
                      )}
                    />
                  )}
                  {orderId && reservations.length > 0 && (
                    <Form.Field
                      control={form.control}
                      name="reservationId"
                      render={({ field }) => (
                        <FormItem>
                          <FormItem.Row>
                            <FormItem.Label className="min-w-16">
                              Reservation
                            </FormItem.Label>
                            <FormItem.Control>
                              <OrderReservationSelect
                                value={field.value}
                                reservations={reservations}
                                onValueChange={field.onChange}
                              />
                            </FormItem.Control>
                          </FormItem.Row>
                          <FormItem.Message />
                        </FormItem>
                      )}
                    />
                  )}
                </Form.Fields>
              </AlertDialog.Description>
            </AlertDialog.Header>
            <AlertDialog.Footer>
              <AlertDialog.Cancel onClick={close}>Cancel</AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button
                  type={"submit"}
                  isLoading={isPending}
                  disabled={wrongOrderId}
                >
                  Submit
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
