import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type AttractionVariantAgeGroups =
  components["schemas"]["PrivateAttractionVariantAgeGroupsResponse"];

const queryKey = (variantId: number) => [
  "private-attraction-variant-age-groups",
  variantId,
];

export const useGetAttractionVariantAgeGroups = (
  variantId: number,
  options?: Partial<
    UseQueryOptions<AttractionVariantAgeGroups | undefined, unknown>
  >,
) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/attractions/variants/{id}/age-groups", {
          params: {
            path: {
              id: variantId,
            },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(variantId),
    ...options,
  });

  return { ageGroups: data?.ageGroups ?? [], isLoading };
};

export const useInvalidateGetAttractionVariantAgeGroups = () => {
  const queryClient = useQueryClient();

  const invalidate = async (variantId: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(variantId),
    });
  };

  return { invalidate };
};
