import { DetailsTableSkeleton, Layout } from "@twocontinents/dashboard/shared";

interface ShowFinancialLogPageSkeletonProps {
  id: number;
}

export const ShowFinancialLogPageSkeleton = ({
  id,
}: ShowFinancialLogPageSkeletonProps) => {
  return (
    <Layout header={{ title: `Financial log #${id}` }}>
      <DetailsTableSkeleton />
    </Layout>
  );
};
