import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

const queryKey = (extraOptionIds: number[]) => [
  "extraOptionAvailabilities",
  extraOptionIds,
];

export type ExtraOptionAvailability =
  components["schemas"]["PrivateAttractionGroupAvailabilityResponse"]["extraOptionAvailabilities"][number];

export const useGetExtraOptionAvailabilities = (extraOptionIds: number[]) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryKey: queryKey(extraOptionIds),
    queryFn: () =>
      apiClient.GET("/private/attractions/groups/extra-options-availability", {
        params: {
          query: {
            extraOptionIds,
          },
        },
      }),
    enabled: extraOptionIds.length > 0,
  });

  return {
    availabilities: data?.data?.extraOptionAvailabilities ?? [],
    isLoading,
  };
};

export const useInvalidateExtraOptionAvailabilities = () => {
  const queryClient = useQueryClient();

  return (extraOptionId: number[]) => {
    queryClient.invalidateQueries({ queryKey: queryKey(extraOptionId) });
  };
};
