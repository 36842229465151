import dayjs from "dayjs";
import { SelectSingleEventHandler } from "react-day-picker";

interface UseDateRangePicker {
  date: Date | undefined;
  setDate: (date?: Date) => void;
  time: string | undefined;
  setTime: (time?: string) => void;
}

export const useDateTimePicker = ({
  date,
  setDate,
  setTime,
  time,
}: UseDateRangePicker) => {
  const handleSelect: SelectSingleEventHandler = (_, selected) => {
    const selectedDay = dayjs(selected);

    if (selectedDay.isSame(date)) {
      setDate(undefined);
      return;
    }

    setDate(selectedDay.toDate());
  };

  const handleTimeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setTime(value);
  };

  const hour = time ? Number(time.split(":")[0]) : 0;
  const minute = time ? Number(time.split(":")[1]) : 0;

  const selectedDateTime = date
    ? dayjs(date).hour(hour).minute(minute)
    : undefined;

  return {
    handleSelect,
    handleTimeChange,
    selectedDateTime,
  };
};
