/* eslint-disable max-lines-per-function */
import { useTableDropdown } from "@twocontinents/dashboard/shared";
import { useCallback, useState } from "react";

import { AttractionOrderBase } from "../../../types/attraction-order-base";

export const useOrdersTable = (orders: AttractionOrderBase[]) => {
  const { areDropdownsOpened, handleDropdownOpenChange, openDropdown } =
    useTableDropdown();

  const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
  const areAllOrdersSelected = selectedOrders.length === orders?.length;

  const mappedOrders = orders.map((order) => {
    const mostRecentReservation = order.reservations.toSorted(
      (a, b) =>
        new Date(a.reservationDate).getTime() -
        new Date(b.reservationDate).getTime(),
    )[0];
    return {
      ...order,
      reservationDate: mostRecentReservation.reservationDate,
      reservationTime: mostRecentReservation.reservationTime,
    };
  });

  const handleAllOrdersCheckboxChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedOrders(mappedOrders.map((order) => order.id) ?? []);
      } else {
        setSelectedOrders([]);
      }
    },
    [mappedOrders],
  );

  const handleSingleOrderCheckboxChange = useCallback(
    (checked: boolean, orderId: number) => {
      if (checked) {
        setSelectedOrders((previous) => [...previous, orderId]);
      } else {
        setSelectedOrders((previous) =>
          previous.filter((id) => id !== orderId),
        );
      }
    },
    [],
  );

  return {
    areDropdownsOpened,
    selectedOrders,
    setSelectedOrders,
    areAllOrdersSelected,
    openDropdown,
    mappedOrders,
    handleAllOrdersCheckboxChange,
    handleSingleOrderCheckboxChange,
    handleDropdownOpenChange,
  };
};
