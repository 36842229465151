import { useQuery, useQueryClient } from "@tanstack/react-query";

import { ALL_ITEMS_ID, NOT_SELECTED_ID } from "../constants";
import { usePrivateApiClient } from "../utils";

const queryKey = (attractionId: number) => ["private-attraction", attractionId];

export const useGetAttraction = (attractionId: number) => {
  const apiClient = usePrivateApiClient();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET(`/private/attractions/{id}`, {
          params: {
            path: { id: attractionId },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(attractionId),
    enabled: attractionId !== NOT_SELECTED_ID && attractionId !== ALL_ITEMS_ID,
  });

  return { attraction: data, isLoading };
};

export const useInvalidateGetAttraction = () => {
  const queryClient = useQueryClient();

  const invalidate = async (attractionId: number) => {
    await queryClient.invalidateQueries({ queryKey: queryKey(attractionId) });
  };

  return { invalidate };
};
