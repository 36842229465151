import { SettingCard } from "@twocontinents/dashboard/shared";
import { TravelAgencyForm } from "@twocontinents/dashboard/travel-agencies/travel-agency/shared";

import { useCreateTravelAgencyForm } from "./use-create-travel-agency-form";

export const CreateTravelAgencyForm = () => {
  const { createTravelAgency, isPending } = useCreateTravelAgencyForm();

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Create travel agency</SettingCard.Title>
        <SettingCard.Description>
          Create new travel agency by filling out the form below
        </SettingCard.Description>
      </SettingCard.Header>
      <TravelAgencyForm
        handleSubmit={createTravelAgency}
        footer={
          <SettingCard.Footer>
            <SettingCard.Description>
              After submitting the form, the travel agency will be created
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        }
      />
    </SettingCard>
  );
};
