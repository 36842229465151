import { Tour } from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  Button,
  buttonVariants,
} from "@twocontinents/dashboard/shared";

import { useCancelMessageDialog } from "./use-cancel-message-dialog";

interface CancelMessageDialogProps {
  tour: Tour;
}
export const CancelMessageDialog = ({ tour }: CancelMessageDialogProps) => {
  const { isPending, onSubmit, openAlertDialog } = useCancelMessageDialog(tour);
  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button variant="destructive">Cancel delivery</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Title className="text-center">
          Are you sure you want to cancel this message?
        </AlertDialog.Title>
        <AlertDialog.Footer className="mt-4 flex flex-row !justify-center">
          <AlertDialog.Cancel
            className={buttonVariants({ variant: "destructive" })}
          >
            No
          </AlertDialog.Cancel>
          <Button
            onClick={onSubmit}
            isLoading={isPending}
            type="submit"
            variant="positive"
          >
            Yes
          </Button>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
